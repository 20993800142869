export class NewsSearch {
  content: Content[];
  first: boolean;
  last: boolean;
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
export interface Tagtype {
  id: number;
  name: string;
}
export interface PropertyTypeTags {
  customOrder: number;
  id: number;
  name: string;
  regionId: number;
  tagtype: Tagtype;
}

export interface StateTags {
  customOrder: number;
  id: number;
  name: string;
  regionId: number;
  tagtype: Tagtype;
}

export interface ContentTags {
  propertyTypeTags: PropertyTypeTags[];
  stateTags: StateTags[];
}

export interface Content {
  contentTags: ContentTags;
  createdByUserId: any;
  createdDate: string;
  publishDate: string;
  externalAuthors: any[];
  firstAuthorId: any;
  headerImage: HeaderImage;
  headerImageCaption: string;
  headerImgId: number;
  headline: string;
  id: number;
  internalAuthors: any;
  modifiedByUserId: any;
  modifiedDate: string;
  newsSections: NewsSection[];
  primaryContent: string;
  secondAuthorId: any;
  sponsored: boolean;
  status: any;
  step: any;
  summary: string;
  topic: any;
}

export interface HeaderImage {
  fullPath: string;
}

export interface NewsSection {
  caption: string;
  createdByUserId: any;
  createdDate: any;
  description: string;
  id: number;
  imageId: number;
  mainImg: MainImg;
  modifiedByUserId: any;
  modifiedDate: any;
  norder: number;
}

export interface MainImg {
  fullPath: string;
}
