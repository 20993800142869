import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import { ErrorDialogService } from "../core/services/dialog/";
import { AuthenticationService, AccountService } from "../core";
import { WebsocketService } from "../websockets";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private account: AccountService,
    private websocketService: WebsocketService,
    private route: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let storageTokenVal = null;
    let storageUserVal = null;
    try {
      let value = "; " + document.cookie;
      let parts = value.split("; " + "tokenSIILA" + "=");
      let token = parts
        .pop()
        .split(";")
        .shift();
      storageTokenVal = localStorage.getItem("token");
      storageUserVal = localStorage.getItem("user");
      if (token != storageTokenVal) {
        localStorage.setItem("token", token);
        storageTokenVal = token;
      }
    } catch (error) {}
    const token: any = storageTokenVal;
    const user: any = storageUserVal;
    if (token) {
      req = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token)
      });
    }

    if (!req.headers.has("Content-Type")) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json")
      });
    }

    req = req.clone({ headers: req.headers.set("Accept", "application/json") });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 || error.status == 503) {
          this.auth.$isLoginSubject.next(false);
          this.account.currentUser$.next(null);
          this.websocketService.kick();
          try {
            let validToken = localStorage.getItem("token");
            const currentUrl = this.route.url;
            if (validToken || validToken == "") {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.removeItem("photoUser");
              document.cookie = `tokenSIILA=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=.${
                environment.platformId == "br" ? "siila.com.br" : "siila.com.mx"
              }; path=/`;
            }
            if (
              currentUrl.startsWith("/transaction") ||
              currentUrl.startsWith("/property")
            ) {
              this.route.navigateByUrl("/");
            }
          } catch (err) {}
        }
        return throwError(error);
      })
    );
  }
}
