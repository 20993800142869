import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  Inject
} from "@angular/core";
import * as pbi from "powerbi-client";
import { PowerBiService } from "../../../services/powerbi.service";
import {
  PowerBiReports,
  PowerBiConfig,
  TokenData
} from "../../../models/powerbi.model";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-siila-index-report",
  templateUrl: "./siila-index-report.component.html",
  styleUrls: ["./siila-index-report.component.scss"]
})
export class SiilaIndexReportComponent implements OnInit, OnDestroy {
  @ViewChild("reportContainer", { static: false }) reportContainer: ElementRef;
  currLang: string = this.translate.currentLang;
  reportError: boolean = false;
  reportLoading: boolean = false;
  isMobile: boolean = false;
  selectedTab: string = "brazil";
  currentCode = environment.platformId;

  private reportNames: { en: string; es: string; pt: string } = {
    en:
      this.currentCode == "mx" || this.currentCode == "co"
        ? "SiiLA MX Index EN"
        : "SiiLA Index EN",
    es: "SiiLA MX Index SP",
    pt: "SiiLA Index PT"
  };

  breakPointSubscription: Subscription;
  languageChangeSubscription: Subscription;

  constructor(
    private powerbiService: PowerBiService,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    private _d: MatDialog
  ) {
    console.log(data);
  }

  ngOnInit() {
    this.breakPointSubscription = this.subscribeToScreenSizeChange();
    this.languageChangeSubscription = this.subscribeToLangChange();
    if (this.currentCode == "latam" || this.currentCode == "br") {
      this.selectedTab = "brazil";
    } else {
      this.selectedTab = "mexico";
    }
  }

  ngAfterViewInit() {
    this.getReportData();
  }

  loadBRReport() {
    console.log("HOLLAAAA");
    //this.getReportData();
  }

  tabChanged(event: any) {
    this.resetPowerBiContainer();
    if (event == "0") {
      console.log("Load BR");
      this.selectedTab = "brazil";
      setTimeout(() => {
        this.getReportData();
      }, 1000);
    } else {
      console.log("Load MX");
      this.selectedTab = "mexico";
      setTimeout(() => {
        this.getReportData();
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.breakPointSubscription.unsubscribe();
    this.languageChangeSubscription.unsubscribe();
  }

  subscribeToScreenSizeChange() {
    return this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  subscribeToLangChange() {
    return this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.currLang != event.lang) {
        this.currLang = event.lang;
        //this.getReportData();
      }
    });
  }

  getReportName() {
    if (this.currentCode == "latam") {
      return this.selectedTab == "mexico"
        ? "SiiLA MX Index EN"
        : "SiiLA Index EN";
    }
    return this.reportNames[this.currLang];
  }

  async getReportData(fromServer: boolean = false) {
    const {
      powerbi,
      reportContainer
    }: {
      powerbi: any;
      reportContainer: HTMLElement;
    } = this.resetPowerBiContainer();

    this.reportLoading = true;
    this.reportError = false;
    const reportName = this.data ? this.data : this.getReportName();

    let reports: PowerBiReports = await this.powerbiService.getPowerBiReports(
      fromServer
    );
    if (!reports) {
      this.reportLoading = false;
      this.reportError = true;
      return;
    }

    const { id: reportId = "" } =
      reports.value.find((report: any) => report.name === reportName) || {};

    if (!reportId) {
      this.reportLoading = false;
      this.reportError = true;
      return;
    }

    const tokenData: TokenData = await this.powerbiService.getPowerbiEmbedToken(
      reportId,
      reports,
      fromServer
    );
    const { token, groupId } = tokenData || { token: "", groupId: "" };

    if (!token || !groupId) {
      this.reportLoading = false;
      this.reportError = true;
      return;
    }

    const reportConfig: PowerBiConfig = {
      type: "report",
      tokenType: 1,
      accessToken: token,
      embedUrl: `${
        this.powerbiService.powerBiBaseUrl
      }?reportId=${reportId}&groupId=${groupId}${this.getLocaleKey()}`,
      id: reportId,
      settings: {
        filterPaneEnabled: false,
        layoutType: this.isMobile
          ? pbi.models.LayoutType.MobilePortrait
          : pbi.models.LayoutType.Master
      }
    };

    this.showReport(reportConfig, powerbi, reportContainer);
  }

  showReport(
    reportConfig: PowerBiConfig,
    powerbi: any,
    reportContainer: HTMLElement
  ) {
    let report = powerbi.embed(reportContainer, reportConfig);
    report.off("loaded");
    report.on("loaded", () => {
      this.reportLoading = false;
      this.reportError = false;
    });
    report.on("error", () => {
      this.reportLoading = false;
      this.reportError = true;
    });
  }

  resetPowerBiContainer(): { powerbi: any; reportContainer: HTMLElement } {
    let reportContainer = this.reportContainer.nativeElement;
    let currentIframe = reportContainer.getElementsByTagName("iframe")[0];

    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );

    if (currentIframe) {
      powerbi.reset(reportContainer);
    }

    return { powerbi, reportContainer };
  }

  getLocaleKey(): string {
    switch (this.translate.currentLang) {
      case "pt":
        return "&language=pt&formatLocale=pt-BR";
      case "en":
        return "&language=en&formatLocale=en-US";
      case "es":
        return "&language=es&formatLocale=es-MX";
    }
  }

  close() {
    this._d.closeAll();
  }
}
