import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { WindowRef } from "../../window-ref.service";
import { AccountService } from "../auth/account.service";
import { NGXLogger } from "ngx-logger";
import * as _ from "lodash";
import { Observable } from "rxjs";

@Injectable()
export class DeviceRegistrationService {
  private endpoint: string = `${environment.apiURL}/account`;
  private currentDeviceInfo: any;
  private registeredDevices: any = [];
  private currentUser$: Observable<any>;

  constructor(
    private _cookies: CookieService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private _window: WindowRef,
    private account: AccountService,
    private _logger: NGXLogger
  ) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.currentUser$ = this.account.currentUser$;
    // this.getCurrentDeviceInfo();
  }

  async getCurrentDeviceInfo() {
    if (!this.currentDeviceInfo) {
      const userIP: any = await this.account.getUserIP();
      const userAgent = `"userAgent": "${this._window.nativeWindow.navigator.userAgent}"`;

      this.currentDeviceInfo = {
        deviceRegistrationFingerprint: btoa(
          `{"userAgent": "'${this._window.nativeWindow.navigator.userAgent}'"}` +
            new Date()
        ),
        deviceRegistrationFingerprintDecoded: `{"userAgent": "'${this._window.nativeWindow.navigator.userAgent}'"}`,
        currentDeviceUserAgent: userAgent,
        currentDeviceIP: `"ip": ${userIP.ip}`
      };
    }

    return this.currentDeviceInfo;
  }

  async retrieveUserDevices() {
    this.registeredDevices = await this.getUserDevices();
    //this.currentDeviceInfo = await this.getCurrentDeviceInfo();

    this.registeredDevices = _.map(this.registeredDevices, device => {
      const registrationKeys = atob(device.deviceRegistration).split("&&");
      device.deviceRegistrationKey = registrationKeys[0];
      device.deviceRegistrationLocation = registrationKeys[1];
      device.deviceRegistrationCreated = registrationKeys[2];
      device.deviceRegistrationFingerprint = atob(
        device.deviceRegistrationFingerprint
      );
      // device.validated = this.validateDeviceCookie(device);
      return device;
    });

    return this.registeredDevices;
  }

  setCustomCookies(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);

    var c_value =
      btoa(value) +
      (exdays == null ? "" : ";expires=" + exdate.toUTCString()) +
      "; Domain=.siila.com.br; path=/";
    this.setCookie(c_name, c_value, exdate);

    c_value =
      escape(value) +
      (exdays == null ? "" : ";expires=" + exdate.toUTCString()) +
      "; Domain=127.0.0.1; path=/";
    this.setCookie(c_name, c_value, exdate);

    c_value =
      escape(value) +
      (exdays == null ? "" : ";expires=" + exdate.toUTCString()) +
      "; Domain=52.67.205.22; path=/";
    this.setCookie(c_name, c_value, exdate);

    c_value =
      escape(value) +
      (exdays == null ? "" : ";expires=" + exdate.toUTCString()) +
      "; Domain=.siila.com.mx; path=/";
    this.setCookie(c_name, c_value, exdate);

    c_value =
      escape(value) +
      (exdays == null ? "" : ";expires=" + exdate.toUTCString()) +
      "; Domain=52.67.106.48; path=/";
    this.setCookie(c_name, c_value, exdate);

    c_value =
      escape(value) +
      (exdays == null ? "" : ";expires=" + exdate.toUTCString()) +
      "; Domain=52.67.78.156; path=/";
    this.setCookie(c_name, c_value, exdate);
  }

  validateDeviceCookie(deviceInfo) {
    const deviceRegistrationKey = deviceInfo.deviceRegistrationKey;
    const cookieIndex = deviceInfo.deviceRegistrationLocation;
    const cookieValue = this.getCookieValue(cookieIndex);
    if (!cookieValue || cookieValue == null) {
      this._logger.log("Cookie not Found! SIILA ", cookieIndex);
      if (cookieValue === deviceRegistrationKey) {
        deviceInfo.deviceRegistrationFingerprint = this.currentDeviceInfo.deviceRegistrationFingerprint;
        return true;
      } else {
        this._logger.log(
          "Cookie found but the value does not match the key! SIILA. CookieValue: ",
          cookieValue,
          " ,DeviceKey: ",
          deviceRegistrationKey
        );
      }
    }
    return false;
  }

  currentDeviceRegistered(userDevices, currentDevice) {
    if (!(userDevices instanceof Array)) {
      return;
    }

    for (var i = 0; i < userDevices.length; i++) {
      var device = userDevices[i];
      var value = this.getCookieValue(device.deviceRegistrationLocation);
      if (value == device.deviceRegistrationKey) {
        return device;
      }
    }
    return undefined;
  }

  getCookieValue(index) {
    const val = this._cookies
      .get("SIILA" + index)
      .split(";")
      .shift();
    return val;
  }

  deleteCookies() {
    for (var i = 1; i <= 20; i++) {
      this._cookies.delete("SIILA" + i);
    }
  }

  setCookie(c_name, value, exdays) {
    this._cookies.set(c_name, value, exdays);
  }

  isRegistrationRequired() {
    return this.http
      .get(`${this.endpoint}/deviceRegistrationRequired`)
      .toPromise();
  }

  getUserDevices() {
    return this.http.get(`${this.endpoint}/devices`).toPromise();
  }

  updateUserDevice(body) {
    return this.http.post(`${this.endpoint}/updateDevice`, body).toPromise();
  }

  createUserDevice() {
    const body = this.currentDeviceInfo;
    //this.setCookie(
    //  "SIILA",
    //  this.currentDeviceInfo.deviceRegistrationFingerprint,
    //  365
    //);
    return this.http.post(`${this.endpoint}/createDevice`, body).toPromise();
  }

  deviceAlreadyRegistered() {
    // logMessage.message = "The device is registered, the registration key matched but the fingerprint did not " +
    //   "-> db fingerprint: " + device.deviceRegistrationFingerprint + ", device fingerprint: " + currentDeviceFingerprint;
    // User.log(logMessage);
  }

  deviceIsDesynced() {
    // logMessage.message = "The device is registered but the comparison between the device registration key from DB and " +
    //   "device registration key from the local storage is failing -> db reg key: " + device.deviceRegistrationKey + ", local storage key: " + deviceInfo.deviceRegistrationKey;
    // User.log(logMessage);
  }

  validateCookie() {
    let value = "; " + document.cookie;
    let parts = value.split("; " + "tokenSIILA" + "=");
    let token = parts
      .pop()
      .split(";")
      .shift();
    if (token != "") {
      localStorage.setItem("token", token);
      return true;
    }

    return false;
  }
}

interface DeviceInfo {
  deviceRegistrationKey?: string;
  deviceRegistrationLocation?: string;
  deviceRegistrationCreated?: string;
  deviceRegistrationFingerprint: string;
}
