<div
  *ngIf="!isMobile; else contact"
  class="border-white mat-elevation-z15"
  style="padding: 8px;"
>
  <div class="demo-card" style="margin: 40px;">
    <div class="img-contact">
      <img class="contact-img" [src]="imgContactUs()" />
    </div>
    <div class="contact-info">
      <h1
        class="siila-desc siila-menu-color"
        translate="academy.{{ currentCode }}.academy.courses.contact.title"
      ></h1>
      <h2
        class="academy-description siila-grey"
        translate="investor-suvey.{{ currentCode }}.contact.description"
      ></h2>
      <form [formGroup]="form" class="contact-request">
        <div class="demo-form">
          <mat-form-field class="name-size">
            <mat-label class="name-text">{{ "name" | translate }}</mat-label>
            <input formControlName="firstName" matInput />
          </mat-form-field>
          <mat-form-field class="name-size">
            <mat-label class="name-text">{{
              "last-name" | translate
            }}</mat-label>
            <input formControlName="lastName" matInput />
          </mat-form-field>
        </div>
        <mat-form-field class="info-size">
          <mat-label class="name-text">{{ "address" | translate }}</mat-label>
          <input formControlName="email" matInput />
        </mat-form-field>
        <mat-form-field class="info-size">
          <mat-label class="name-text">{{ "phone" | translate }}</mat-label>
          <input formControlName="phoneNumber" matInput />
        </mat-form-field>
        <mat-form-field class="info-size">
          <mat-label class="name-text">{{ "company" | translate }}</mat-label>
          <input formControlName="company" matInput />
        </mat-form-field>
        <mat-form-field class="info-size">
          <mat-label class="name-text">{{ "position" | translate }}</mat-label>
          <input formControlName="position" matInput />
        </mat-form-field>
        <div class="academy-btn-touch">
          <button
            [disabled]="form.invalid"
            (click)="downloadForm()"
            mat-flat-button
            color="accent"
            class="btn-touch uppercase"
          >
            {{ "touchs" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #contact>
  <div class="contact-container mat-elevation-z15">
    <div class="contact-info">
      <h1
        class="siila-desc siila-menu-color"
        translate="academy.{{ currentCode }}.academy.courses.contact.title"
      ></h1>
      <div class="img-contact">
        <img class="contact-img" [src]="imgContactUs()" />
      </div>
      <h2
        class="academy-description siila-grey"
        translate="investor-suvey.{{ currentCode }}.contact.description"
      ></h2>
      <div class="contact-request">
        <form [formGroup]="form">
          <mat-form-field class="name-size">
            <mat-label class="name-text">{{ "name" | translate }}</mat-label>
            <input formControlName="firstName" matInput />
          </mat-form-field>
          <mat-form-field class="name-size">
            <mat-label class="name-text">{{
              "last-name" | translate
            }}</mat-label>
            <input formControlName="lastName" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "address" | translate }}</mat-label>
            <input formControlName="email" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "phone" | translate }}</mat-label>
            <input formControlName="phoneNumber" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "company" | translate }}</mat-label>
            <input formControlName="company" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "position" | translate
            }}</mat-label>
            <input formControlName="position" matInput />
          </mat-form-field>
          <div class="academy-btn-touch">
            <button
              mat-flat-button
              color="accent"
              (click)="downloadForm()"
              [disabled]="form.invalid"
              class="btn-touch uppercase"
            >
              {{ "touchs" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
