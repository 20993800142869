<div>
  <img [src]="background" [alt]="title" [title]="title" class="profile" />
  <div class="card-info">
    <div class="text-info">
      <h1 class="member-name siila-menu-color">{{ name }}</h1>
      <h2 class="member-job">{{ job }}</h2>
      <a [href]="email" class="member-email link-blue btn-decoration">{{
        emailText
      }}</a>
    </div>
    <div class="icon">
      <a [href]="link" target="_blank">
        <img
          class="linkedin-icon"
          src="../../../../../assets/icons/linkedin.svg"
        />
      </a>
    </div>
  </div>
</div>
