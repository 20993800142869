<div class="mat-elevation-z15 border-card">
  <div class="title-main">
    <h4
      translate="products.{{
        currentCode
      }}.products.features-card.marketAnalytics.overline"
      class="feature-overline siila-blue-color uppercase"
    ></h4>
    <h2
      translate="products.{{
        currentCode
      }}.products.features-card.marketAnalytics.description-title"
      class="feature-tittle siila-grey-dark"
    ></h2>
  </div>
  <div
    [ngClass]="{
      'card-market': this.includeMarketAnalyticsV1,
      'card-marketV2': !this.includeMarketAnalyticsV1
    }"
    class="mb-3"
  >
    <div class="market-container">
      <div class="market-info">
        <h1
          class="info-title siila-grey-dark"
          translate="products.{{
            currentCode
          }}.products.features-card.marketAnalytics.title-main"
        ></h1>
        <h3
          translate="products.{{
            currentCode
          }}.products.features-card.marketAnalytics.sub-tittle"
          class="product-description siila-grey"
        ></h3>
      </div>
      <div class="market-mac justandi">
        <img
          (click)="openTarted()"
          [src]="imgMarketAnalytics()"
          [alt]="imgMarketAnalyticsAlt()"
          [title]="imgMarketAnalyticsAlt()"
          class="mac-img"
        />
      </div>
    </div>
    <div class="check-list">
      <div class="check" *ngFor="let check of checkList">
        <img [src]="check.icon" />
        <div class="check-info">
          <span
            class="feature-product siila-grey-dark"
            [translate]="check.title"
          ></span>
          <br /><span
            class="feature-product-info siila-grey"
            [translate]="check.desc"
          ></span>
        </div>
      </div>
    </div>
    <button
      (click)="openTarted()"
      mat-flat-button
      color="accent"
      class="btn-more mb-3"
    >
      {{ "more" | translate }}
    </button>
  </div>
  <ng-container *ngIf="includeMarketAnalyticsV1 && currentCode != 'co'">
    <div class="border-line-product"></div>
    <p
      translate="products.{{
        currentCode
      }}.products.features-card.marketAnalytics.findMore"
      class="find-more siila-grey-dark"
    ></p>
    <div class="market-analytics-card">
      <ng-container *ngFor="let card of marketCardList">
        <app-info-card
          (click)="openInfoCard(card)"
          [icon]="card.src"
          [iconAltTitle]="card.iconAltTitle"
          [title]="card.title | langSelector"
          [text]="card.text | langSelector"
          [actionTxt]="card.actionText | langSelector"
          [background]="card.background"
        ></app-info-card>
      </ng-container>
    </div>
  </ng-container>
</div>
