import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { zip } from "rxjs";
import { environment } from "../../../../environments/environment";
import { NewsService } from "../../../core";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { MoreNews } from "../../models/more-news.model";
import { NewsSearch } from "../../models/news-search.model";
import { NewsTopics } from "../../models/news-topics.model";
import { NewsDetail } from "../../models/news.model";
import { I18nService } from "../../services";
import { DatePipe } from "@angular/common";
import { MetaTagService } from "../../services/meta-tag.service";
import { NewsNavigateService } from "../../services/news.navigate.service";

@Component({
  selector: "app-search-news",
  templateUrl: "./search-news.component.html",
  styleUrls: ["./search-news.component.scss"]
})
export class SearchNewsComponent implements OnInit {
  currentCode: string;
  isExpanded: boolean = false;
  isMobile = false;
  news: NewsSearch;
  keyword: string = null;
  total = 0;
  pageSize = 10;
  page = 1;
  browserLang: any;

  constructor(
    private newsService: NewsService,
    private i18: I18nService,
    public breakpointObserver: BreakpointObserver,
    private routeActive: ActivatedRoute,
    private router: Router,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private metaSrv: MetaTagService,
    private newsNavigate: NewsNavigateService
  ) {
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
  }

  ngOnInit() {
    this.metaSrv.setInsightsHomeTags();
    this.keyword = this.routeActive.snapshot.queryParamMap.get("keyword");
    if (this.keyword) {
      this.getNews();
    }
  }

  getAltTxt(text: string) {
    return this.siilaTranslationService.getTranslation(text);
  }

  getNews() {
    if (this.keyword != null && this.keyword != "") {
      this.newsService
        .getNewsSearch(
          this.browserLang,
          this.page - 1,
          this.pageSize,
          this.keyword
        )
        .subscribe((resp: NewsSearch) => {
          this.news = resp;
          this.total = resp.totalElements;
        });
    }
  }

  getNewsParam() {
    window.open(
      `search_news/lang/${this.i18.getCurrentLanguage()}?keyword=${
        this.keyword
      }`,
      "_self"
    );
  }

  headerImageNews(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  changeText(text: string) {
    const value = text.replace(/(<([^>]+)>)/gi, "");
    return value;
  }

  openNews(id: number, title) {
    this.newsNavigate.openNews(
      id,
      title.urlTitle ? title.urlTitle : title.headline,
      this.isMobile,
      "",
      false,
      this.browserLang
    );
  }

  validateKeyword() {
    if (this.keyword == null || this.keyword == "") {
      this.news = new NewsSearch();
      this.total = 0;
    }
  }

  goNewsTags(tag, color) {
    this.newsNavigate.goNewsTags(tag, color, tag.name, this.isMobile);
  }

  getLanguage() {
    return this.siilaTranslationService.getCurrentLanguage();
  }

  formatDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  goHome() {
    this.newsNavigate.goHome();
  }
}
