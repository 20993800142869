<div class="value-container" [ngClass]="currentCode == 'latam' ? 'm3' : 'm1'">
  <h6 class="badge" translate="global.siilaIndex.gdp"></h6>
  <div>
    <span class="badge m2" [negativePositiveBg]="indexValues.gdpChg">
      {{ (indexValues.gdpChg | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge m2">
      {{ (indexValues.gdpVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
  <h6 class="badge" translate="global.siilaIndex.cpi"></h6>
  <div>
    <span class="badge m2" [negativePositiveBg]="indexValues.cpiChg">
      {{ (indexValues.cpiChg | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge m2">
      {{ (indexValues.cpiVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
  <h6 class="badge" translate="global.siilaIndex.reference"></h6>
  <div>
    <span class="badge m2" [negativePositiveBg]="indexValues.referenceRateChg">
      {{ (indexValues.referenceRateChg | localNumberWithSign: "1.2-2") || "–" }}
      %
    </span>
    <span class="badge m2">
      {{ (indexValues.referenceRateVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
  <h6 class="badge" translate="global.siilaIndex.closing"></h6>
  <div>
    <span class="badge m2" [negativePositiveBg]="indexValues.closingIpcChg">
      {{ (indexValues.closingIpcChg | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge m2">
      {{ (indexValues.closingIpcVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
  <h6 class="badge" translate="global.siilaIndex.udis"></h6>
  <div>
    <span class="badge m2" [negativePositiveBg]="indexValues.udiChg">
      {{ (indexValues.udiChg | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge m2">
      {{ (indexValues.udiVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
</div>
