import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ProductItem } from "../../../core/models/products.model";
import { ProductService } from "../../../core/services/products.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { I18nService } from "../../services";

@Component({
  selector: "app-market-analytic-card",
  templateUrl: "./market-analytic-card.component.html",
  styleUrls: ["./market-analytic-card.component.scss"]
})
export class MarketAnalyticCardComponent implements OnInit {
  @Input() includeMarketAnalyticsV1 = false;
  @Input() parentComp;
  currentCode: string;
  translatePath: string;
  marketCardTranslatePath: string;
  resourseTranslatePanth: string;
  checkList = [];
  marketCardList = [];
  marketImgCard: string[];
  products: ProductItem[];
  lang: string;
  isMobile = false;
  marketAnalyticsAltSolutions: string;

  constructor(
    private productService: ProductService,
    private siilaTranslationService: SiilaTranslationService,
    private i18: I18nService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.lang = this.siilaTranslationService.getCurrentLanguage();
    console.log("lenguaje", this.lang);
    this.currentCode = environment.platformId;
    this.checkList = [...Array(5).keys()].map(i => {
      this.translatePath = `products.${this.currentCode}.products.features-card.marketAnalytics.check-list.`;
      this.marketCardTranslatePath = `products.${this.currentCode}.products.features-card.marketAnalytics.card-list.`;
      this.resourseTranslatePanth = `products.${this.currentCode}.products.marketing-card.featuredResources.resources.card-list.`;
      return {
        icon: "assets/icons/check.svg",
        title: `${this.translatePath}check${i + 1}.title`,
        desc: `${this.translatePath}check${i + 1}.desc`
      };
    });

    this.marketImgCard = [
      "assets/img/products-list/indices.png",
      "assets/img/products-list/investors.png",
      "assets/img/products-list/grocs.png"
    ];

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    this.marketAnalyticsAltSolutions = this.siilaTranslationService.getImgSEOTranslation(
      "solutions.marketanalyticsalt"
    );
    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.marketCardList = products.map((item, index) => {
        return {
          id: item.id,
          background: this.marketImgCard[index],
          src: `${environment.cloudfrontURL}${item.iconPath}/360w/${item.iconFilename}`,
          iconAltTitle: this.findIconAltTitle(item),
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        };
      });
    });
  }

  findIconAltTitle = (item: any) => {
    let card = "imgSEO.cardicons.";
    if (item.id == 101) {
      card += "gross";
    } else if (item.id == 102) {
      card += "index";
    } else {
      card += "investorsurvey";
    }
    return this.siilaTranslationService.getTranslationFromJSONFiles(card);
  };

  openTarted() {
    window.open(
      `${
        environment.applicationURL
      }/marketanalytics/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openInfoCard(card: any) {
    window.open(
      `/products/${card.id}/${this.i18.getTranslation(
        card.title
      )}/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  imgMarketAnalyticsAlt() {
    if (!this.parentComp) {
      return this.siilaTranslationService.getTranslationFromJSONFiles(
        "imgSEO.macpic"
      );
    }

    if (
      this.parentComp == "solutions101" ||
      this.parentComp == "solutions111" ||
      this.parentComp == "solutions103" ||
      this.parentComp == "solutions110" ||
      this.parentComp == "solutions104" ||
      this.parentComp == "solutions108" ||
      this.parentComp == "solutions115" ||
      this.parentComp == "solutions117" ||
      this.parentComp == "solutions118"
    ) {
      return this.marketAnalyticsAltSolutions;
    }

    if (
      this.parentComp == "solutions102" ||
      this.parentComp == "solutions109" ||
      this.parentComp == "solutions116"
    ) {
      return this.siilaTranslationService.getImgSEOTranslation(
        "brokerage.details"
      );
    }

    if (
      this.parentComp == "solutions105" ||
      this.parentComp == "solutions112" ||
      this.parentComp == "solutions119"
    ) {
      return this.siilaTranslationService.getImgSEOTranslation(
        "valuationAdvisor.details"
      );
    }

    if (
      this.parentComp == "solutions106" ||
      this.parentComp == "solutions113" ||
      this.parentComp == "solutions120"
    ) {
      return this.siilaTranslationService.getImgSEOTranslation(
        "serviceProvider.details"
      );
    }

    if (
      this.parentComp == "solutions107" ||
      this.parentComp == "solutions122" ||
      this.parentComp == "solutions114"
    ) {
      return this.siilaTranslationService.getImgSEOTranslation(
        "fiiInvestors.details"
      );
    }
  }

  imgMarketAnalytics() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.lang == "en") {
        img = img + "item-2-mockup SiiLA-EN-MX.png";
      } else {
        img = img + "item-2-mockup SiiLA-ES.png";
      }
    } else {
      if (this.lang == "en") {
        img = img + "item-2-mockup SiiLA-EN-BR.png";
      } else {
        img = img + "item-2-mockup SiiLA-PT.png";
      }
    }

    return img;
  }
}
