import { Component, Input } from "@angular/core";

@Component({
  selector: "app-ifix-values",
  templateUrl: "./ifix-values.component.html",
  styleUrls: ["./ifix-values.component.scss"]
})
export class IfixValuesComponent {
  @Input() ifixVal: number;
  @Input() ifixPercent: number;
}
