<section class="page-container">
  <div
    *ngIf="data"
    (click)="close()"
    class="row"
    style="display: block;
  text-align: right;
  margin: -41px 0px -25px 0px;
  cursor: pointer;"
  >
    <mat-icon>close</mat-icon>
    <div class="col h-100" #reportContainer></div>
  </div>

  <mat-tab-group *ngIf="!data" (selectedIndexChange)="tabChanged($event)">
    <mat-tab
      label="{{ 'navLinks.siilaBrasil' | translate }}"
      *ngIf="currentCode == 'latam' || currentCode == 'br'"
    >
      <ng-template matTabContent>
        <div class="siila-index-report row my-4 px-3 position-relative">
          <div class="col h-100" #reportContainer></div>
          <div
            class="h-100 w-100 d-flex justify-content-center align-items-center position-absolute z-index-1"
            *ngIf="reportError || reportLoading"
          >
            <app-loading-spinner *ngIf="reportLoading"></app-loading-spinner>
            <div class="text-center" *ngIf="reportError">
              <h3
                class="text-center text-red"
                translate="global.siilaIndex.reportLoadError"
              ></h3>
              <button
                class="btn siila-blue-bg text-white"
                translate="global.siilaIndex.tryAgain"
                (click)="getReportData(true)"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab
      label="{{ 'navLinks.siilaMexico' | translate }}"
      *ngIf="currentCode == 'latam' || currentCode == 'mx'"
    >
      <ng-template matTabContent>
        <div class="siila-index-report row my-4 px-3 position-relative">
          <div class="col h-100" #reportContainer></div>
          <div
            class="h-100 w-100 d-flex justify-content-center align-items-center position-absolute z-index-1"
            *ngIf="reportError || reportLoading"
          >
            <app-loading-spinner *ngIf="reportLoading"></app-loading-spinner>
            <div class="text-center" *ngIf="reportError">
              <h3
                class="text-center text-red"
                translate="global.siilaIndex.reportLoadError"
              ></h3>
              <button
                class="btn siila-blue-bg text-white"
                translate="global.siilaIndex.tryAgain"
                (click)="getReportData(true)"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</section>
