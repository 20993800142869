import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { HttpConfigInterceptor } from "./httpconfig.interceptor";
import { AuthInterceptor } from "./auth.interceptor";

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigInterceptor,
    multi: true
  }
  // { provide: HttpConfig, useClass: AuthInterceptor, multi: true }
];
