import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { LocalNumberPipeSpot } from "../pipes/number-pipe";
import { I18nService } from "./i18n.service";

@Injectable()
export class CommonGenericService {
  currentCode: string;
  constructor(
    private i18n: I18nService,
    private _decimalPipe: LocalNumberPipeSpot
  ) {
    this.currentCode = environment.platformId;
  }

  private isJSON(str: any) {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  }

  public transformArrayStringList(lists: any[], attr: string) {
    let transformList = lists.map(item => {
      return item[attr];
    });
    return transformList.join(", ");
  }

  public translateLocaleText(lists: any[], attr: string) {
    return lists.map(item => {
      const isValidJSON: boolean = this.isJSON(item[attr]);
      item[attr] = isValidJSON
        ? this.i18n.getTranslation(item[attr])
        : item[attr];
      return item;
    });
  }

  getAvailableAreaText(spaceRangesDTO: any, spotBuildingType?: string) {
    if (spotBuildingType && spotBuildingType == "C") {
      return this.getPositions(spaceRangesDTO);
    }

    if (spaceRangesDTO) {
      const rangeDTO = spaceRangesDTO;
      const minValue = rangeDTO.minArea;
      const maxValue = rangeDTO.maxArea;

      if (minValue != maxValue && minValue !== 0) {
        return `${this.formatNumberTo(minValue)} m² - ${this.formatNumberTo(
          maxValue
        )} m²`;
      } else if (minValue != maxValue && minValue === 0) {
        return `${this.formatNumberTo(maxValue)} m²`;
      } else {
        return `${this.formatNumberTo(maxValue)} m²`;
      }
    }

    return this.i18n.get("global.listing-card.negotiable");
  }

  getPositions(spaceRangesDTO: any) {
    if (spaceRangesDTO) {
      const rangeDTO = spaceRangesDTO;
      let minValue = rangeDTO.minPositions;
      let maxValue = rangeDTO.maxPositions;

      if (minValue != maxValue) {
        minValue = minValue == 0 ? 1 : minValue;
        maxValue = maxValue == 0 ? 1 : maxValue;
        return `${this.formatNumberTo(minValue)} - ${this.formatNumberTo(
          maxValue
        )}`;
      } else {
        minValue = minValue == 0 ? 1 : minValue;
        return `${this.formatNumberTo(minValue)}`;
      }
    }

    return this.i18n.get("global.listing-card.negotiable");
  }

  formatNumberTo(value?: number, decimals?: number) {
    if (value == null || value == undefined) return undefined;

    if (decimals == null || decimals == undefined) {
      value = Math.round(value);
      return this._decimalPipe.transform(value, `1.0-0`);
    }

    if (decimals >= 0) {
      const numberFormat = `1.${decimals}-${decimals}`;
      return this._decimalPipe.transform(value, numberFormat);
    }
  }

  getAvailableAreaLabel(spot: any) {
    let key = "global.listing-card.availableArea";
    if (spot.type == "C") {
      key = "global.listing-card.availablePositions";
    }

    return this.i18n.get(key) + ":";
  }

  getAskingRentPriceLabel(spot: any) {
    let key = "global.listing-card.askingRentCard";
    if (spot.type == "S") {
      key = "global.listing-card.askingPriceCard";
    }
    return this.i18n.get(key) + ":";
  }

  getAvgAskingRent(spaceRangesDTO: any, listing: any, latamCountry: string) {
    if (spaceRangesDTO) {
      const rangeDTO = spaceRangesDTO;
      const minValue = rangeDTO.minAskingPriceOrRent;
      const maxValue = rangeDTO.maxAskingPriceOrRent;

      /*  const decimals = spotBuildingType && spotBuildingType == 'S' ? 0 : 2; */

      if (minValue != maxValue && minValue !== 0) {
        return `${
          environment.platformId == "mx" ||
          latamCountry == "latamMx" ||
          environment.platformId == "co"
            ? listing.preferredCurrency
            : "R$"
        } ${this.formatNumberTo(minValue /* , decimals */)} - ${
          environment.platformId == "mx" ||
          latamCountry == "latamMx" ||
          environment.platformId == "co"
            ? ""
            : "R$"
        } ${this.formatNumberTo(maxValue /* , decimals */)}`;
      } else if (minValue != maxValue && minValue === 0) {
        return `${
          environment.platformId == "mx" ||
          latamCountry == "latamMx" ||
          environment.platformId == "co"
            ? listing.preferredCurrency
            : "R$"
        } ${this.formatNumberTo(maxValue /* , decimals */)}`;
      } else {
        if (minValue != 0 && minValue != null) {
          return `${
            environment.platformId == "mx" ||
            latamCountry == "latamMx" ||
            environment.platformId == "co"
              ? listing.preferredCurrency
              : "R$"
          } ${this.formatNumberTo(minValue /* , decimals */)}`;
        }
      }
    }

    return this.i18n.get("global.listing-card.negotiable");
  }
}
