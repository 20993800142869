<div class="flex-col">
  <div class="flex-row" [ngClass]="{'flex-wrap': handsetMode}">
    <img src="assets/img/device-registration/siila_cert.png"/>
    <p style="margin-left:5px;padding-top:20px;">Your SiiLA account will now be certified for use on this device. Your account will only have access from this device going forward. Click Register to proceed, or cancel to register using a different device.</p> 
    <!-- TODO: Show Spinner -->
  <!-- <div class="lock-container">
    <span class="lock" [ngClass]="{'unlocked': !locked}"></span>
  </div> -->
  </div>
  <mat-divider></mat-divider>
  <div class="flex-row actions">
    <button 
      mat-raised-button 
      (click)="dismiss()">
        <span>Cancel</span>
    </button>
    <button
      class="siila-submit"
      mat-raised-button
      (click)="registerCurrentDevice()">
        <span>Register</span>
    </button>
  </div>
</div>