import { Injectable } from "@angular/core";

@Injectable()
export class PropertyTypeHelperService {
  constructor() {}

  getPropertyTypeName(propertyType: number) {
    switch (propertyType) {
      case 1001:
        return "Industrial";
      case 2001:
        return "Office";
      case 3001:
        return "Retail";
      case 5001:
        return "Hotel";
      default:
        return null;
    }
  }

  getPropertyTypeTranslationName(propertyType: number) {
    switch (propertyType) {
      case 1001:
        return "global.industrial";
      case 2001:
        return "global.office";
      case 3001:
        return "global.retail";
      case 5001:
        return "global.hotel";
      default:
        return null;
    }
  }

  getSpotBuildingTypeTranslation(type: string) {
    switch (type) {
      case "L":
        return "global.listing-card.lease";
      case "S":
        return "global.listing-card.sale";
      case "C":
        return "global.listing-card.coworkingLbl";
      default:
        return null;
    }
  }
}
