<span>
  <button
    id="latamNav"
    aria-label="submit"
    mat-flat-button
    [matMenuTriggerFor]="menu"
    *ngIf="!handsetMode"
    [ngClass]="{ 'menu-item': isMobile, 'menu-item-latamnav': !isMobile }"
    class="blue-link mat-h3 "
  >
    <div class="icon-flex">
      <mat-icon *ngIf="isMobile">location_pin</mat-icon>
      <div class="nav-items">
        <a class="blue-link mat-h3">{{ currentCode }}</a>
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </div>
  </button>
</span>
<mat-menu #menu="matMenu">
  <a class="btn-decoration" *ngIf="isNotSameCountry('mx')">
    <button
      class="mat-sub-menu"
      (click)="openUrl('https://siila.com.mx', 'mx')"
      aria-labelledby="siila mexico"
      mat-menu-item
    >
      {{ "navLinks.siilaMexico" | translate }}
    </button>
  </a>
  <a class="btn-decoration" *ngIf="isNotSameCountry('co')">
    <button
      class="mat-sub-menu"
      (click)="openUrl('https://siila.com.co', 'co')"
      aria-labelledby="siila colombia"
      mat-menu-item
    >
      {{ "navLinks.siilaCol" | translate }}
    </button>
  </a>
  <a class="btn-decoration" *ngIf="isNotSameCountry('br')">
    <button
      class="mat-sub-menu"
      (click)="openUrl('https://siila.com.br', 'br')"
      aria-labelledby="siila"
      mat-menu-item
    >
      {{ "navLinks.siilaBrasil" | translate }}
    </button>
  </a>
  <a class="btn-decoration" *ngIf="isNotSameCountry('latam')">
    <button
      class="mat-sub-menu"
      (click)="openUrl('https://siila.com', 'latam')"
      aria-labelledby="siila mexico"
      mat-menu-item
    >
      {{ "navLinks.siilaColombia" | translate }}
    </button>
  </a>
</mat-menu>
