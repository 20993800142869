import {
  Injectable,
  Inject,
  PLATFORM_ID,
  NgZone,
  Injector
} from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { isPlatformBrowser } from "@angular/common";
import { AccountService } from "./account.service";
import { WindowRef } from "../../window-ref.service";
import { Router } from "@angular/router";
import {
  DeviceRegistrationDialogService,
  LoginDialogService,
  SafariPromptDialogService
} from "../services/dialog";
import { DeviceRegistrationService } from "../services/device-registration.service";
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";
import { ChangePasswordService } from "../../shared/components/static-pages/change-password/change-password.service";
import { InfoDialogService } from "../services/dialog/info-dialog.service";
import { WebsocketService, SiilaBusAuthActions } from "../../websockets";
import { take, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private host: string = "host";
  private dev: string = "/oauth/token";
  private sessionPersistenceEndpoint: string = "/api/authenticate";
  private advertisements: string = "api/service-provider/carousel-data";
  private broadcastChannel: any;
  public $isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  public userAccount: any;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private account: AccountService,
    private windowRef: WindowRef,
    private router: Router,
    private deviceRegistration: DeviceRegistrationService,
    private _log: NGXLogger,
    private _device: DeviceRegistrationDialogService,
    private ngZone: NgZone,
    private changePasswordService: ChangePasswordService,
    private loginDialogService: LoginDialogService,
    private infoDialog: InfoDialogService,
    private platformHelper: SafariPromptDialogService,
    private websocketService: WebsocketService,
    private translate: TranslateService
  ) {}

  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  public hasToken(): boolean {
    try {
      return !!localStorage.getItem("token");
    } catch (error) {}
    return false;
  }

  public isLoggedIn(): Observable<boolean> {
    return this.$isLoginSubject.asObservable();
  }

  public isSessionDestroyed(): Observable<any> {
    return this.http.get(
      this.sessionPersistenceEndpoint
      //{
      // headers: new HttpHeaders().set("accept", "text/plain; charset=utf-8"),
      // responseType: 'text'
      // }
    );
  }

  public submitLoginCredentials(username: any, password: any) {
    const body = new HttpParams()
      .set("username", username)
      .set("password", password)
      .set("secret", "mySecretOAuthSecret")
      .set("grant_type", "password")
      .set("scope", "read write")
      .set("client_id", "siilaapp");

    return this.http
      .post(this.dev, body, {
        headers: new HttpHeaders()
          .set("Content-Type", "application/x-www-form-urlencoded")
          .set("Accept", "application/json")
          .set(
            "Authorization",
            "Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA=="
          )
      })
      .toPromise()
      .then(res => {
        if (isPlatformBrowser(this.platformId) && res) {
          localStorage.setItem("token", JSON.stringify(res));
          this.$isLoginSubject.next(true);
        }
        return res;
      })
      .then(async (res: any) => {
        const tokenValue = res;
        const user: any = await this.account.getAccount().toPromise();
        const authorization_values = { tokenValue, user };
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("user", JSON.stringify(user));
        }
        this.userAccount = user;
        const userDevices = await this.deviceRegistration.retrieveUserDevices();
        // await this.websocketService.send(
        //   SiilaBusAuthActions.AUTHORIZE,
        //   authorization_values
        // );
        return {
          account: user,
          devices: userDevices,
          registrationRequired: user.deviceRegistrationRequired
        };
      })
      .then(data => {
        const { account, devices, registrationRequired } = data;
        //this.completeLoginProcess(account);
        this.deviceRegistration.getCurrentDeviceInfo().then(currentDevice => {
          if (!registrationRequired) {
            this.completeLoginProcess(account);
          } else {
            //TODO: add another condition where we check if device was validated
            const foundDevice = this.deviceRegistration.currentDeviceRegistered(
              devices,
              currentDevice
            );

            if (!foundDevice && devices.length < account.deviceAllowed) {
              this._device.openDialog(null, RegistrationSteps.New);
              this._device.dialogRef.afterClosed().subscribe(e => {
                switch (e.action.toLowerCase()) {
                  case "cancel":
                    this.logout();
                    break;
                  case "register":
                    //Make Request to register
                    this.registerDevice(account);
                    break;
                }
              });
            } else if (foundDevice) {
              this.completeLoginProcess(account);
            } else {
              // TODO: Send notification about unauthorized access
              console.log("Couldn't find registered device.");
              this.account.notifyMissingDeviceForUser();
              //this.logoutNoredirection();
              this._device.openDialog(null, RegistrationSteps.Existing);
              this._device.dialogRef.afterClosed().subscribe(e => {
                this.completeLoginProcess(account);
              });
            }
          }
        });
      })
      .catch(e => {
        switch (e.error.error_description) {
          case "LOCKED_ACCOUNT":
            console.log("LOCKED");
            this.infoDialog.openDialog({
              message: "Your account has been locked."
            });
            //this.showMessage('Account Locked', 'Your account has been locked.');
            break;
          case "INACTIVE_ACCOUNT":
            console.log("INACTIVE");
            this.infoDialog.openDialog({
              message:
                "Either your company or your account has been deactivated."
            });
            //this.showMessage('Inactive Account/Company', 'Either your company or your account has been deactivated.');
            break;
          default:
            if (
              e.error.error_description.slice(0, 22) == "SHOULD_CHANGE_PASSWORD"
            ) {
              this.changePasswordService.setKeyForChangePassword(
                e.error.error_description.split(":")[1]
              );
              //console.log("SHOULD_CHANGE_PASSWORD");
              this.loginDialogService.close();
              this.router.navigateByUrl("/change-password");
            } else {
              //console.log("Bad Credentials");
              this.loginDialogService.close();
              this.infoDialog.openDialog({
                message: this.translate.instant("bad_credentials")
              });
            }
        }
      });
  }

  completeLoginProcess(account) {
    const authorization_values = {
      tokenValue: JSON.parse(localStorage.getItem("token")),
      user: JSON.parse(localStorage.getItem("user"))
    };
    this.websocketService.send(
      SiilaBusAuthActions.AUTHORIZE,
      authorization_values
    );
    this.loginDialogService.close();
    //TODO: FIgure out if you need to auto navigate to community
    this.windowRef.nativeWindow.open(`${environment.redirectURL}`, "_self");
  }

  async registerDevice(account) {
    const createdUserDevice: any = await this.deviceRegistration.createUserDevice();
    this.deviceRegistration.deleteCookies();
    for (let i = 0; i < createdUserDevice.hashes.length; i++) {
      this.deviceRegistration.setCookie(
        "SIILA" + (i + 1),
        createdUserDevice.hashes[i],
        365
      );
    }
    /*
    this.deviceRegistration.setCookie(
      "SIILA" + createdUserDevice.deviceRegistrationLocation,
      createdUserDevice.deviceRegistrationKey,
      365
    );
    */
    this._log.info("created user device -> ", createdUserDevice);
    //Store cookie logic for new device
    this._device.openDialog(null, RegistrationSteps.Success);
    const registrationSuccessDialog = this._device.dialogRef;
    registrationSuccessDialog.afterClosed().subscribe(e => {
      this.completeLoginProcess(account);
    });
  }

  async logout() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        await this.http.post(`${environment.apiURL}/logout`, {}).toPromise();
      } catch (e) {
        console.error("Terminating db session failed.", e);
      }
      this._log.info("user session terminated");
      this.$isLoginSubject.next(false);
      this.account.currentUser$.next(null);
      this.websocketService.kick();
      // this.router.navigate([""]);
      if (localStorage.getItem("token") || localStorage.getItem("user")) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      this.windowRef.nativeWindow.location.reload();
    }
  }

  async logoutNoredirection() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        await this.http.post(`${environment.apiURL}/logout`, {}).toPromise();
      } catch (e) {
        console.error("Terminating db session failed.", e);
      }
      this._log.info("user session terminated");
      this.websocketService.send(SiilaBusAuthActions.KICK, JSON.stringify({}));
      this.$isLoginSubject.next(false);
      // this.router.navigate([""]);
      if (localStorage.getItem("token") || localStorage.getItem("user")) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
    }
  }

  getAdvertisements(size?: number) {
    if (size) {
      return this.http
        .get(this.advertisements + (size ? "?size=" + size : ""), {
          observe: "response"
        })
        .pipe(map(val => val.body));
    } else {
      return this.http
        .get(this.advertisements, {
          observe: "response"
        })
        .pipe(map(val => val.body));
    }
  }

  public removeCredentials() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("ls.token");
    this.$isLoginSubject.next(false);
  }
}

export enum RegistrationSteps {
  New = "new",
  Existing = "existing",
  Success = "success"
}
