<section class="siila-index-container">
  <div *ngFor="let capitalMarketsValue of capitalMarketsValues">
    <!-- Siila-index -->
    <div class="index-value" (click)="goToReportPage()">
      <app-siila-index-values
        [ngClass]="{ 'mt-0': currentCode == 'latam' || currentCode == 'co' }"
        class="center-component"
        [totalPercent]="capitalMarketsValue.totalChg"
        [totalVal]="capitalMarketsValue.totalVal"
        [incomePercent]="capitalMarketsValue.incomeChg"
        [appreciationPercent]="capitalMarketsValue.appreciationChg"
        [year]="capitalMarketsValue.year"
        [quarter]="capitalMarketsValue.quarter"
        [capitalMarket]="capitalMarketsValue"
      >
      </app-siila-index-values>

      <!-- Currency -->
      <app-currency-values
        [ngClass]="{ 'mt-0': currentCode == 'latam' || currentCode == 'co' }"
        class="center-component"
        [usdVal]="capitalMarketsValue.usdVal"
        [usdPercent]="capitalMarketsValue.usdChg"
        [canVal]="capitalMarketsValue.canVal"
        [canPercent]="capitalMarketsValue.canChg"
        [euroVal]="capitalMarketsValue.euroVal"
        [euroPercent]="capitalMarketsValue.euroChg"
        [capitalMarket]="capitalMarketsValue"
      ></app-currency-values>

      <!-- IBOVESPA AND IFIX -->
      <app-bovespa-values
        [ngClass]="{ 'mt-0': currentCode == 'latam' || currentCode == 'co' }"
        *ngIf="currentCode == 'br' || capitalMarketsValue.currentCode == 'br'"
        class="center-component"
        [bovespaVal]="capitalMarketsValue.bovespaVal"
        [bovespaPercent]="capitalMarketsValue.bovespaChg"
      ></app-bovespa-values>
      <app-ifix-values
        [ngClass]="{ 'mt-0': currentCode == 'latam' || currentCode == 'co' }"
        *ngIf="currentCode == 'br' || capitalMarketsValue.currentCode == 'br'"
        class="center-component"
        [ifixVal]="capitalMarketsValue.ifixVal"
        [ifixPercent]="capitalMarketsValue.ifixChg"
      ></app-ifix-values>

      <!-- SELIC -->
      <app-selic-values
        *ngIf="currentCode == 'br' || capitalMarketsValue.currentCode == 'br'"
        class="center-component"
        [selicRate]="capitalMarketsValue.selicRate"
        [selicDate]="capitalMarketsValue.selicDate"
      ></app-selic-values>
      <app-siila-index-values-mx
        *ngIf="currentCode == 'mx' || capitalMarketsValue.currentCode == 'mx'"
        [indexValues]="capitalMarketsValue"
      >
      </app-siila-index-values-mx>
    </div>
  </div>
</section>
