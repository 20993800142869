import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../core/auth/authentication.service";
import { WindowRef } from "../../../window-ref.service";
import { LoginDialogService } from "../../../core/services/dialog";
import { ToastNotificationService } from "../../../core/services/toast-notification.service";
import { ForgotDialogService } from "../../../core/services/dialog/forgot-dialog.service";
import { ForgotDialogComponent } from "../dialogs/forgot-dialog/forgot-dialog.component";
import { InfoDialogService } from "../../../core/services/dialog/info-dialog.service";

@Component({
  selector: "new-version",
  templateUrl: "./new-version.component.html",
  styleUrls: ["./new-version.component.scss"]
})
export class NewVersionComponent implements OnInit {
  @Input("landscapeMode") landscapeMode: any;

  disabled: boolean = false;

  // public matcher = new FormErrorStateMatcher();
  constructor(
    private dialog: LoginDialogService,
    private _toaster: ToastNotificationService,
    private forgotDialog: ForgotDialogService,
    private infoDialog: InfoDialogService,
    private windowRef: WindowRef,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {}

  launch() {
    if (localStorage.getItem("token")) {
      this.auth.removeCredentials();
    }
    this.windowRef.nativeWindow.location.reload();
  }

  showErrorNotification(e) {
    this._toaster.error(e);
  }

  dismissAsDialog() {
    this.dialog.close();
  }
}
