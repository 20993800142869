<div class="home-card-white mat-elevation-z15">
  <div class="industry-info">
    <h1
      *ngIf="!resource"
      class="industry-title siila-menu-color"
      translate="home.{{ currentCode }}.pillars.sub-title"
    ></h1>
    <h1
      *ngIf="resource"
      class="industry-title siila-menu-color"
      style="font-weight: 100;"
      [innerHTML]="titleResource"
    ></h1>
    <div class="container-carousel">
      <div class="logos-container">
        <div class="carousel-logo">
          <div *ngFor="let item of resource ? imageResource : imageCarousel">
            <img
              class="img-size cursor"
              (click)="openIndustry(item.url)"
              [src]="item.logo"
              [alt]="item.title"
              [title]="item.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
