<div class="pillar-card mat-elevation-z15">
  <div class="pillar-title">
    <h1
      class="pillar-sub-title siila-menu-color uppercase"
      translate="home.{{ currentCode }}.pillars.title"
    ></h1>
  </div>
  <div class="pillar-container">
    <div class="check-pillar" *ngFor="let check of pillarCheck">
      <div class="check-info">
        <span class="check-title" [translate]="check.title"></span>
        <span class="check-sub-title" [translate]="check.desc"></span>
      </div>
    </div>
  </div>
</div>
