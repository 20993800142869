import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit, Input } from "@angular/core";
import { platform } from "process";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { SolutionMenuItem } from "../../../../core/models/products.model";
import { LoginDialogService } from "../../../../core/services/dialog";
import { ProductService } from "../../../../core/services/products.service";

@Component({
  selector: "latam-nav-menu",
  templateUrl: "./latam-nav.component.html",
  styleUrls: ["../menu.component.scss"]
})
export class LatamNavComponent implements OnInit {
  @Input() handsetMode: boolean;
  @Input() menuOverlayDirection: string;
  isMobile = false;
  responsiveSub: Subscription;
  currentCode: string;
  menuText: string;

  constructor(private responsive: BreakpointObserver) {
    this.currentCode = environment.platformId.toUpperCase();
  }

  ngOnInit(): void {
    this.responsiveChanges();
  }

  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  openUrl(url, code) {
    const currentUrl = window.location.href;
    window.open(
      currentUrl.includes("resource") && code != "co" ? `${url}/resource` : url,
      this.isMobile ? "_self" : "_blank"
    );
  }

  isNotSameCountry(countryParam: string) {
    const platfformId = this.currentCode.toLowerCase().trim();
    const countryMenuItem = countryParam.toLowerCase().trim();
    const result = platfformId != countryMenuItem;
    return result;
  }
}
