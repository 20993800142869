import { Injectable } from "@angular/core";
import { BuildingFeatureDetails } from "../components/detail-property/detail-property.component";
import { I18nService } from "./i18n.service";

export type PropertyOptions = {
  name: string;
  iconSrc?: string;
  displayName: string;
  value?: any;
  enabled: boolean;
  customClass?: string;
  order?: number;
};

@Injectable({
  providedIn: "root"
})
export class PropertyFeaturesService {
  constructor(private i18: I18nService) {}

  buildMultifamilyPropertyFeatures(
    property?: any,
    featuresOptions?: BuildingFeatureDetails[] | null
  ): PropertyOptions[] {
    let multifamilyPropertyFeatures: PropertyOptions[] = [];

    if (!property) return multifamilyPropertyFeatures;

    var mixedUseOpts: PropertyOptions[] = [];
    var hvcaOpts: PropertyOptions[] = [];
    var laundryOpts: PropertyOptions[] = [];

    if (featuresOptions && featuresOptions.length > 0) {
      featuresOptions.forEach(element => {
        if (element.featureName == "mixedUse") {
          if (element.featureSelectedOptions.length > 0) {
            var mixedUseOpt = {
              name: "mixedUse",
              iconSrc: "assets/featuresIcons/home.png",
              value: true,
              displayName: element.featureDisplayName,
              enabled: true
            };
            mixedUseOpts.push(mixedUseOpt);
          }
        } else if (element.featureName == "HVAC") {
          if (element.featureSelectedOptions.length > 0) {
            element.featureSelectedOptions.forEach(selectedOptName => {
              var hvcaOpt = {
                name: `${element.featureName}-${selectedOptName}`,
                iconSrc: "assets/featuresIcons/ac_unit.svg",
                value: true,
                displayName: selectedOptName,
                enabled: true
              };
              hvcaOpts.push(hvcaOpt);
            });
          }
        } else if (element.featureName == "laundry") {
          if (element.featureSelectedOptions.length > 0) {
            element.featureSelectedOptions.forEach(selectedOptName => {
              var laundryOpt = {
                name: `${element.featureName}-${selectedOptName}`,
                iconSrc: "assets/featuresIcons/laundry.png",
                value: true,
                displayName: selectedOptName,
                enabled: true
              };
              laundryOpts.push(laundryOpt);
            });
          }
        }
      });
    }

    var propertyManagerOnSiteOpt: PropertyOptions = {
      name: "propertyManagerOnSite",
      iconSrc: "assets/featuresIcons/person.svg",
      value: property?.managerOnSite,
      displayName: this.i18.get("detail.managerOnSite"),
      order: 1,
      enabled: true
    };
    var leedTypeOpt: PropertyOptions = {
      name: "leedType",
      iconSrc: "assets/featuresIcons/eco.svg",
      value: property?.leedType,
      displayName: this.i18.getTranslation(property?.leedType?.name),
      enabled: true
    };
    var securityAccessOpt: PropertyOptions = {
      name: "securityAccess",
      iconSrc: "assets/featuresIcons/security.svg",
      value: property?.securityAccess,
      displayName: this.i18.get("detail.securityAccess"),
      enabled: true
    };
    var elevatorsOpt: PropertyOptions = {
      name: "elevators",
      iconSrc: "assets/featuresIcons/elevator.png",
      value: property?.hasElevators,
      displayName: this.i18.get("detail.elevators"),
      enabled: true
    };
    var hoursMaintenance24FtOpt: PropertyOptions = {
      name: "24HourMaintenance",
      iconSrc: "assets/featuresIcons/build-circle.png",
      value: property?.hoursMaintenance24,
      displayName: this.i18.get("detail.24HourMaintenance"),
      enabled: true
    };
    var sprinklersOpt: PropertyOptions = {
      name: "sprinklers",
      iconSrc: "assets/featuresIcons/highlight.svg",
      value: property?.sprinklers,
      displayName: this.i18.get("detail.fireSuppression"),
      enabled: true
    };
    var poolOpt: PropertyOptions = {
      name: "pool",
      iconSrc: "assets/featuresIcons/pool.png",
      value: property?.pool,
      displayName: this.i18.get("detail.pool"),
      enabled: true
    };
    var furnishedOpt: PropertyOptions = {
      name: "furnished",
      iconSrc: "assets/featuresIcons/living.png",
      value: property?.furnished,
      displayName: this.i18.get("detail.furnished"),
      enabled: property?.furnished
    };
    var petFriendlyOpt: PropertyOptions = {
      name: "petFriendly",
      iconSrc: "assets/featuresIcons/pets.png",
      value: property?.petFriendly,
      displayName: this.i18.get("detail.petFriendly"),
      enabled: true
    };
    var packageCenterOpt: PropertyOptions = {
      name: "packageCenter",
      iconSrc: "assets/featuresIcons/deployed-code.png",
      value: property?.packageCenter,
      displayName: this.i18.get("detail.packageCenter"),
      enabled: true
    };
    var highSpeedInternetAccessOpt: PropertyOptions = {
      name: "highSpeedInternetAccess",
      iconSrc: "assets/featuresIcons/wifi-solid.svg",
      value: property?.highSpeedInternetAccess,
      displayName: this.i18.get("detail.highSpeedInternetAccess"),
      customClass: "blue-svg-icon",
      enabled: true
    };
    var fitnessCenterOpt: PropertyOptions = {
      name: "fitnessCenter",
      iconSrc: "assets/featuresIcons/fitness_center.png",
      value: property?.fitnessCenter,
      displayName: this.i18.get("detail.FitnessCenter"),
      enabled: true
    };
    var businessCenterOpt: PropertyOptions = {
      name: "businessCenter",
      iconSrc: "assets/featuresIcons/desktop-windows.png",
      value: property?.businessCenter,
      displayName: this.i18.get("detail.businessCenter"),
      enabled: true
    };

    // Order of the features
    if (propertyManagerOnSiteOpt.enabled) {
      multifamilyPropertyFeatures.push(propertyManagerOnSiteOpt);
    }
    if (leedTypeOpt.enabled) {
      multifamilyPropertyFeatures.push(leedTypeOpt);
    }
    if (securityAccessOpt.enabled) {
      multifamilyPropertyFeatures.push(securityAccessOpt);
    }
    if (elevatorsOpt.enabled) {
      multifamilyPropertyFeatures.push(elevatorsOpt);
    }
    if (hoursMaintenance24FtOpt.enabled) {
      multifamilyPropertyFeatures.push(hoursMaintenance24FtOpt);
    }
    if (sprinklersOpt.enabled) {
      multifamilyPropertyFeatures.push(sprinklersOpt);
    }
    if (mixedUseOpts.length > 0) {
      multifamilyPropertyFeatures.push(...mixedUseOpts);
    } else if (property?.mixedUse) {
      multifamilyPropertyFeatures.push({
        name: "mixedUse",
        iconSrc: "assets/featuresIcons/home.png",
        value: true,
        displayName: this.i18.get("detail.mixedUse"),
        enabled: true
      });
    }
    if (poolOpt.enabled) {
      multifamilyPropertyFeatures.push(poolOpt);
    }

    // options
    if (hvcaOpts.length > 0) {
      multifamilyPropertyFeatures.push(...hvcaOpts);
    }
    if (laundryOpts.length > 0) {
      multifamilyPropertyFeatures.push(...laundryOpts);
    }
    if (petFriendlyOpt.enabled) {
      multifamilyPropertyFeatures.push(petFriendlyOpt);
    }
    if (highSpeedInternetAccessOpt.enabled) {
      multifamilyPropertyFeatures.push(highSpeedInternetAccessOpt);
    }
    this.addPropertyOptionTo(furnishedOpt, multifamilyPropertyFeatures);
    if (packageCenterOpt.enabled) {
      multifamilyPropertyFeatures.push(packageCenterOpt);
    }
    if (businessCenterOpt.enabled) {
      multifamilyPropertyFeatures.push(businessCenterOpt);
    }

    if (fitnessCenterOpt.enabled) {
      multifamilyPropertyFeatures.push(fitnessCenterOpt);
    }

    return multifamilyPropertyFeatures;
  }

  addPropertyOptionTo(
    propertyOption: PropertyOptions,
    optionCollection: PropertyOptions[]
  ) {
    if (propertyOption.enabled) {
      optionCollection.push(propertyOption);
    }
  }
}
