import { Injectable, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class DemoDialogService {
  dialogRef: any;

  constructor(private dialog: MatDialog) {}

  open(
    comp: any,
    submitMailing = false,
    page?: string,
    action?: string,
    email?: string,
    title: string = null,
    lower: boolean = true
  ) {
    if (!this.dialog.openDialogs.length) {
      this.dialogRef = this.dialog.open(comp, {
        width: "490px",
        //height: "636px",
        data: {
          submitMailing: submitMailing,
          page: page,
          action: action,
          email: email,
          title: title ? (lower ? title.toLowerCase() : title) : "SiiLA"
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
