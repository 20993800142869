<div class="flex-col">
  <div class="flex-row" [ngClass]="{ 'flex-wrap': handsetMode }">
    <img src="assets/img/device-registration/siila_lock.png" />
    <p style="margin-left:5px;padding-top:20px;">
      Your account is Registered to another device. If you want to permanently
      switch to this device contact SiiLA Support for assistance.
    </p>
  </div>
  <mat-divider></mat-divider>
  <div class="flex-row actions">
    <button
      id="half-width"
      style="margin-top:10%;"
      mat-raised-button
      class="siila-submit"
      (click)="dismiss()"
    >
      <span>OK</span>
    </button>
  </div>
</div>
