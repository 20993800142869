import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../../shared/services";
import { environment } from "../../../environments/environment";
import { Location } from "@angular/common";

interface HomePageURI {
  url: string;
  queryParams: string;
}

@Injectable()
export class SiilaTranslationService {
  private currentLanguage: string;
  private supportedLanguages: string[] = ["pt", "en", "es"];
  private currentCode: string;

  constructor(
    private _ts: TranslateService,
    private languageService: LanguageService,
    private location: Location
  ) {
    this.currentCode = environment.platformId;
    if (this.currentCode == "mx" || this.currentCode == "co") {
      this.supportedLanguages = ["en", "es"];
    } else if (this.currentCode == "br") {
      this.supportedLanguages = ["pt", "en"];
    } else {
      this.supportedLanguages = ["en"];
    }
  }

  getTranslation(textValue: string) {
    this.currentLanguage = this.getCurrentLanguage();
    if (textValue) {
      try {
        let newValue = JSON.parse(textValue)[this.currentLanguage];

        if (newValue == undefined && this.currentLanguage === "pt") {
          newValue = JSON.parse(textValue)["pt-br"]
            ? JSON.parse(textValue)["pt-br"]
            : JSON.parse(textValue)["en"];
        }

        if (newValue == undefined && this.currentLanguage === "es") {
          newValue = JSON.parse(textValue)["es"]
            ? JSON.parse(textValue)["es"]
            : JSON.parse(textValue)["en"];
        }

        if (typeof newValue === "object") {
          newValue = newValue[this.currentLanguage];
          if (newValue == undefined && this.currentLanguage === "pt") {
            newValue = newValue["pt-br"] ? newValue["pt-br"] : newValue["en"];
          }
          if (newValue == undefined && this.currentLanguage === "es") {
            newValue = newValue["es"] ? newValue["es"] : newValue["en"];
          }
        }

        let result = newValue
          ? newValue
          : JSON.parse(textValue)[Object.keys(JSON.parse(textValue))[0]];

        return result;
      } catch (e) {
        //console.log("error parsing --> ", textValue);
      }
    }
    return textValue;
  }

  getImgSEOTranslation(translationKey: string): string {
    let key = "imgSEO." + translationKey;
    return this.getTranslationFromJSONFiles(key);
  }

  getTranslationFromJSONFiles(translationKey: string): string {
    let result = "";
    this._ts.get(translationKey).subscribe(data => {
      result = data;
    });
    return result;
  }

  getCurrentLanguage() {
    let language: string = "en";
    if (this.currentLanguage === undefined) {
      let storageValue = null;
      try {
        storageValue = localStorage.getItem("currentLanguageHP");
      } catch (error) {}

      if (storageValue) {
        language = storageValue;
      } else {
        language = this._ts.getBrowserLang();
      }
      return this.supportedLanguages.includes(language) ? language : "en";
    }

    return this.currentLanguage;
  }

  setLanguage(language: string, skipLangUrlPath?: boolean) {
    this.registrateLanguage(language);
    sessionStorage.setItem("language", language);
    this.currentLanguage = language;
    if (!skipLangUrlPath)
      this.addCurrentLanguageToPath(window.location.pathname);
    try {
      localStorage.setItem("currentLanguageHP", language);
      document.documentElement.setAttribute("lang", language);
    } catch (err) {}
    return this._ts.use(language);
  }

  getLanguageBasedOnURL(url: string) {
    const urlParts = url.split("/");
    let lang: string = urlParts[urlParts.length - 1];
    const indexOfQueryParams = lang.indexOf("?");
    if (indexOfQueryParams != -1) {
      lang = lang.substr(0, indexOfQueryParams);
    }

    switch (lang.toLowerCase()) {
      case "pt-br":
        return "pt";
      case "en":
        return "en";
      case "es":
        return "es";
      default:
        return undefined;
        break;
    }
  }

  registrateLanguage(language: string) {
    switch (language) {
      case "pt":
        this.languageService.registerCulture("pt-BR");
        break;
      case "en":
        this.languageService.registerCulture("en-US");
        break;
      case "es":
        this.languageService.registerCulture("es-MX");
        break;
      default:
        this.languageService.registerCulture("pt-BR");
    }
  }

  removeLanguageFromURL(url: string) {
    let newUrl = url;
    const urlParams = url.split("/");
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      const langPathURL: string = urlParams[urlParams.length - 2];
      if (this.isLanguageParam(langParam)) {
        newUrl = urlParams.slice(0, urlParams.length - 2).join("/");
      } else if (langPathURL === "lang") {
        newUrl = urlParams.slice(0, urlParams.length - 2).join("/");
      }
    }
    return newUrl;
  }

  isLanguageParam(requestParam: string) {
    return this.supportedLanguages.includes(requestParam);
  }

  private getUrlExtension(url) {
    return url
      .split(/[#?]/)[0]
      .split(".")
      .pop()
      .trim();
  }

  public addCurrentLanguageToPath(currentPath: string) {
    if (currentPath) {
      const ext = this.getUrlExtension(currentPath);
      if (ext !== currentPath) {
        return currentPath;
      }
    }

    const homeURI = this.separateURIAndQueryParams(currentPath);
    const url = homeURI.url;
    const queryParams = homeURI.queryParams;

    let newURL = this.concatLanguagePathURL(this.removeLanguageFromURL(url));
    if (queryParams) {
      newURL = newURL.concat(queryParams);
    }
    const state = history.state;
    //this.location.go(newURL, "", state);
    this.updateUrlWithoutNavigation(newURL);
    return newURL;
  }

  public updateUrlWithoutNavigation(newUrl: string) {
    const state = history.state;
    history.replaceState(state, "", newUrl);
  }

  private separateURIAndQueryParams(currentPath: string): HomePageURI {
    const indexQueryParam = currentPath.indexOf("?");
    let url = currentPath;
    let queryParams = null;

    if (indexQueryParam != -1) {
      url = currentPath.substring(0, indexQueryParam);
      queryParams = currentPath.substring(indexQueryParam, currentPath.length);
    }

    return { url: url, queryParams: queryParams };
  }

  concatLanguagePathURL(currentPath: string) {
    let path = currentPath;
    if (path == null || path === undefined) {
      return path;
    }
    if (path.endsWith("/")) {
      path = path.slice(0, path.length - 1);
    }
    if (this.currentLanguage === "en") {
      path = path.concat("/lang/en");
    } else if (this.currentLanguage === "pt") {
      path = path.concat("/lang/pt-br");
    } else if (this.currentLanguage === "es") {
      path = path.concat("/lang/es");
    }
    return path;
  }
}
