import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MetaService } from "@ngx-meta/core";
import { environment } from "../../../environments/environment";
import { SiilaTranslationService } from "../../core/services/siila-translation.service";
import {
  DetailedProduct,
  DetailedSolution
} from "../../core/models/products.model";
import { NewsDetail } from "../models/news.model";

export interface MetaTag {
  name: string;
  content: any;
}

@Injectable()
export class MetaTagService {
  RESOURCE_SOCIAL_SHARE_IMG =
    "/assets/icons/RESOURCE-SOCIAL-SHARE-SiiLA-May9-HR.png";

  constructor(
    private title: Title,
    private metaSrv: MetaService,
    private siilaTranslationService: SiilaTranslationService
  ) {}

  private setDynamicTags(
    tags: MetaTag[],
    title?: string,
    noUpperCase?: boolean
  ) {
    if (title && (!noUpperCase || noUpperCase == undefined)) {
      title = title.toUpperCase();
    }
    this.title.setTitle(title || this.title.getTitle());
    if (title) {
      this.metaSrv.setTitle(title);
    }

    tags.forEach(t => {
      if (t.name) {
        this.updateTag(t.name, t.content);
      }
    });
  }

  private updateTag(tagName: string, contentTag: string) {
    this.metaSrv.removeTag(`property='${tagName}'`);
    this.metaSrv.removeTag(`name='${tagName}'`);
    this.metaSrv.setTag(tagName, contentTag);
  }

  getMetaTagsFrom(
    title: string,
    description: string,
    keywords: string,
    imgPath?: string
  ): any[] {
    let baseImg = "/assets/icons/SOCIAL-SHARE-SiiLA.png";
    if (imgPath) {
      baseImg = imgPath;
    }

    const tags = [
      // general
      { name: "name", content: title },
      { name: "keywords", content: keywords },
      { name: "og:email", content: this.contactEmail() },
      {
        name: "lang",
        content: this.siilaTranslationService.getCurrentLanguage()
      },
      { name: "author", content: "SiiLA" },
      { name: "og:url", content: `${encodeURI(window.location.href)}` },
      { name: "og:site_name", content: title },
      { name: "og:site_url", content: `${encodeURI(window.location.href)}` },
      // Title
      { name: "og:title", content: title }, //don't prepend for og:title tag but do for generic and twitter
      { name: "twitter:title", content: title },
      {
        name: "mobile-web-app-title",
        content: encodeURIComponent(title)
      },
      // Description
      { name: "og:description", content: description },
      { name: "description", content: description },
      { name: "twitter:description", content: description },
      // Image
      {
        name: "og:image",
        property: "og:image",
        content: encodeURI(environment.applicationURL + baseImg)
      },
      {
        name: "image",
        content: encodeURI(environment.applicationURL + baseImg)
      },
      {
        name: "twitter:image",
        content: encodeURI(environment.applicationURL + baseImg)
      },
      {
        name: "og:image:width",
        content: 440
      },
      {
        name: "og:image:height",
        content: 220
      }
    ];

    //this.addCanonicalTag(`${encodeURI(window.location.href)}`);

    return tags;
  }

  public addCanonicalTag(currentPath: string) {
    let path = currentPath;
    if (path == null || path === undefined) {
      return path;
    }
    //if (path.endsWith("/lang/en")) {
    //  path = path.slice(0, path.length - 8);
    //}

    /* const linkTag = document.createElement('link');
    linkTag.setAttribute('rel', 'canonical');
    linkTag.href = path;
    document.head.appendChild(linkTag); */

    var existingCanonicalLink = document.querySelector(
      `link[rel='canonical']`
    ) as HTMLLinkElement;

    if (!existingCanonicalLink) {
      const linkTag = document.createElement("link");
      linkTag.setAttribute("rel", "canonical");
      linkTag.href = path;
      document.head.appendChild(linkTag);
    } else {
      existingCanonicalLink.href = path;
    }
  }

  private contactEmail() {
    return environment.platformId === "mx" || environment.platformId == "co"
      ? "contacto@siila.com.mx"
      : environment.platformId === "br"
      ? "contato@siila.com.br"
      : "contact@siila.com";
  }

  public setHomePageMetaTags() {
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.home.title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.home.tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.home.subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.home.keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle, true);
  }

  public setSpotPageMetaTags() {
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.spot.title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.spot.tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.spot.subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      "metatags.spot.keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle, true);
  }

  public setAllProductsMetaTags() {
    const rootFolder = "metatags.allProducts.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  public setMarketAnalyticsMetaTags() {
    const rootFolder = "metatags.marketAnalytics.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  public setAcademyMetaTags() {
    const rootFolder = "metatags.academy.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  public setFibraAnalyticsMetaTags() {
    const platformId = environment.platformId;
    const rootFolder = "metatags.fibraanalytics.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title" + platformId
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle" + platformId
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject" + platformId
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  public setAboutMetaTags() {
    const rootFolder = "metatags.about.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  public setProductPageMetaTags(product: DetailedProduct) {
    if (product.id == 103 || product.id == 105) {
      this.setInvestorSurveyTags();
      return;
    }
    // Grocs BR
    if (product.id == 101) {
      this.setGrocsTags();
      return;
    }

    if (product.id == 102 || product.id == 104) {
      this.setIndicesTags();
      return;
    }

    const rootFolder = "metatags.solution.";
    const productName = this.siilaTranslationService.getTranslation(
      product.productShortName
    );
    const title = "SiiLA: " + productName;
    const tabTitle = productName;

    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setIndicesTags() {
    const rootFolder = "metatags.indices.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setGrocsTags() {
    const rootFolder = "metatags.grocs.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setInvestorSurveyTags() {
    const rootFolder = "metatags.investorSurvey.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement("div");
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || "";
    text = text ? text.replace(/[\n\r\v\f]/g, " ") : "";
    return text;
  }

  truncateText(text: string) {
    if (text.length <= 250) {
      return text;
    }
    const truncatedText = text.substring(0, 247);
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");
    if (lastSpaceIndex !== -1) {
      return truncatedText.substring(0, lastSpaceIndex) + "...";
    }
    return truncatedText;
  }

  getCloudImgURL(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  public setInsightsDetailTags(news: NewsDetail) {
    const tabTitle = `${this.siilaTranslationService.getTranslation(
      news.headline
    )}`;
    const title = tabTitle;
    let content = this.siilaTranslationService.getTranslation(
      news.primaryContent
    );
    if (content) {
      content = this.removeHTMLCode(content);
      content = this.truncateText(content);
    }

    let headerImgCaption;
    if (news.headerImageCaption) {
      headerImgCaption = this.siilaTranslationService.getTranslation(
        news.headerImageCaption
      );
    }

    const headerImg = this.getCloudImgURL(news.headerImage.fullPath);
    let tags = this.getMetaTagsFrom(title, content, null);
    if (tags) {
      for (let t of tags) {
        if (
          t.name === "og:image" ||
          t.name === "image" ||
          t.name === "twitter:image"
        ) {
          t.content = encodeURI(headerImg);
        }
      }
      if (tags && headerImgCaption) {
        tags.push({ name: "og:image:alt", content: headerImgCaption });
        tags.push({ name: "twitter:image:alt", content: headerImgCaption });
      }
    }
    this.setDynamicTags(tags, tabTitle, true);
  }

  public setSolutionstMetaTags(solution: DetailedSolution) {
    if (solution.id == 101 || solution.id == 108) {
      this.setInstitutionalInvestors();
      return;
    }

    if (solution.id == 102 || solution.id == 109) {
      this.setBrokerageTags();
      return;
    }

    if (solution.id == 103 || solution.id == 110) {
      this.setOwnersOperatorDeveloperTags();
      return;
    }

    if (solution.id == 104 || solution.id == 111) {
      this.setTenantsTags();
      this.setTenantsTags();
      return;
    }

    if (solution.id == 105 || solution.id == 112) {
      this.setValuationAdvisorTags();
      return;
    }

    if (solution.id == 106 || solution.id == 113) {
      this.setServiceProviderTags();
      return;
    }

    if (solution.id == 107 || solution.id == 114 || solution.id == 122) {
      this.setReitInvestorTags();
      return;
    }

    const solutionName = this.siilaTranslationService.getTranslation(
      solution.shortName
    );
    const rootFolder = "metatags.solution.";
    const title = "SiiLA: " + solutionName;
    const tabTitle = solutionName;

    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );
    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setInstitutionalInvestors() {
    const rootFolder = "metatags.investors.";
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const title =
      this.siilaTranslationService.getTranslationFromJSONFiles(
        rootFolder + "title"
      ) + tabTitle;
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setOwnersOperatorDeveloperTags() {
    const rootFolder = "metatags.owneroperatordevelopers.";
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const title =
      this.siilaTranslationService.getTranslationFromJSONFiles(
        rootFolder + "title"
      ) + tabTitle;
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setTenantsTags() {
    const rootFolder = "metatags.tenants.";
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const title =
      this.siilaTranslationService.getTranslationFromJSONFiles(
        rootFolder + "title"
      ) + tabTitle;
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  public setInsightsHomeTags() {
    const platformId = environment.platformId;
    const rootFolder = "metatags.insights.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject" + platformId
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(
      title,
      content,
      keywords,
      this.RESOURCE_SOCIAL_SHARE_IMG
    );
    this.setDynamicTags(tags, tabTitle, true);
  }

  public setPressHomeTags() {
    const platformId = environment.platformId;
    const rootFolder = "metatags.press.";
    const title = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "title"
    );
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );
    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle, true);
  }

  setBrokerageTags() {
    const rootFolder = "metatags.brokerage.";
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const title =
      this.siilaTranslationService.getTranslationFromJSONFiles(
        rootFolder + "title"
      ) + tabTitle;
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }

  setValuationAdvisorTags() {
    const rootFolder = "metatags.valuationAdvisor.";
    this.setSolutionTags(rootFolder);
  }

  setServiceProviderTags() {
    const rootFolder = "metatags.serviceProvider.";
    this.setSolutionTags(rootFolder);
  }

  setReitInvestorTags() {
    const rootFolder = "metatags.reitInvestors.";
    this.setSolutionTags(rootFolder);
  }

  setSolutionTags(rootFolder: string) {
    const tabTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "tabTitle"
    );
    const title =
      this.siilaTranslationService.getTranslationFromJSONFiles(
        rootFolder + "title"
      ) + tabTitle;
    const content = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "subject"
    );

    const keywords = this.siilaTranslationService.getTranslationFromJSONFiles(
      rootFolder + "keywords"
    );

    let tags = this.getMetaTagsFrom(title, content, keywords);
    this.setDynamicTags(tags, tabTitle);
  }
}
