import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Press } from "../models/press.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PressService {
  constructor(private http: HttpClient) {}

  getArticlePress(id) {
    return this.http
      .get(`${environment.apiURL}/press-article/${id}`)
      .toPromise();
  }

  getFetchPressItems(selectedCountry, filters, pageSize, page) {
    return this.http
      .post(
        `${environment.apiURL}/content/press/search/ALL/en?page=${page}&per_page=${pageSize}`,
        filters
      )
      .toPromise();
  }

  downloadPdf(articleId, lang, hash): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.apiURL}/content/press/${articleId}/${hash}/pdfs/${lang}`,
      { observe: "response", responseType: "blob" }
    );
  }

  incrementView(articleId, hash) {
    return this.http.put(
      `${environment.apiURL}/content/press/${articleId}/${hash}/view-counts/increment`,
      true
    );
  }
}
