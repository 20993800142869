<section class="product-container">
  <div class="card-solution mat-elevation-z15">
    <div class="solution-container">
      <h1 class="solution-title">
        {{ solutions?.solutionName | langSelector }}
      </h1>
      <div class="item-card">
        <div
          class="item-text solutions-icon"
          *ngFor="let solution of solutions?.iconTexts"
        >
          <mat-icon class="item-icon">{{ solution.iconCode }}</mat-icon>
          {{ solution?.labelText | langSelector }}
        </div>
      </div>
    </div>
  </div>
  <div class="border-card-white mat-elevation-z15">
    <div class="benefits">
      <h1 class="title-gnrl siila-blue-color uppercase">
        {{ "solutions.static-text" | translate }}
      </h1>
      <h2 class="title-solution siila-menu-color">
        {{ "solutions.static-sub-title" | translate }}
        <span *ngIf="solutions && solutions.headerTitle">{{
          solutions.headerTitle | langSelector
        }}</span>
      </h2>
    </div>
    <div
      *ngFor="let benefits of solutions?.beneficts; ;let even = even ;let last = last"
    >
      <div class="solutions-flex" *ngIf="even; else left">
        <div class="solution-info-rigth">
          <h1 class="title-text siila-blue-color uppercase">
            {{ benefits.title | langSelector }}
          </h1>
          <div
            class="grocs-sub-title siila-menu-color"
            [innerHTML]="benefits.description | langSelector"
          ></div>
        </div>
        <img
          class="solution-img-rigth"
          [alt]="headerImageAlt(benefits)"
          [title]="headerImageAlt(benefits)"
          [src]="headerImage(benefits)"
        />
      </div>

      <div class="border-line-grocs" *ngIf="!last"></div>

      <ng-template #left>
        <div class="solutions-flex-left">
          <img
            class="benefit-img-left"
            [alt]="headerImageAlt(benefits)"
            [title]="headerImageAlt(benefits)"
            [src]="headerImage(benefits)"
          />
          <div class="solution-info-left">
            <h1 class="title-text siila-blue-color uppercase">
              {{ benefits.title | langSelector }}
            </h1>
            <div
              class="grocs-sub-title siila-menu-color"
              [innerHTML]="benefits.description | langSelector"
            ></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <section class="dynamic-container">
    <app-market-analytic-card
      [parentComp]="solutionKey"
      [includeMarketAnalyticsV1]="solutions.includeMarketAnalyticsV1"
      *ngIf="solutions.includeMarketAnalytics"
      [style.order]="sortComponent('MA')"
    ></app-market-analytic-card>
    <div
      [style.order]="sortComponent('SPOT')"
      *ngIf="solutions.includeSiilaSpot"
      class="mat-elevation-z15 border-card"
    >
      <div class="first-info">
        <h1
          class="title-gnrl siila-blue-color uppercase"
          translate="spot.{{ currentCode }}.spot.spot-market.title"
        ></h1>
        <h2
          class="feature-tittle siila-menu-color"
          translate="spot.{{ currentCode }}.spot.spot-market.sub-title"
        ></h2>
      </div>
      <div class="spot-flex">
        <div class="solution-card-mac">
          <p
            class="title-solution siila-blue-color"
            translate="spot.{{ currentCode }}.spot.spot-market.text"
          ></p>
          <img
            class="img-spot-mac"
            [alt]="imgspotmacalt"
            [title]="imgspotmacalt"
            [src]="imgSiila()"
          />
        </div>
        <ng-container *ngIf="solutions.includeSiilaSpotV1">
          <div class="siila-info" style="margin-right: 40px;">
            <div class="check-list-solution">
              <div *ngFor="let check of solutionList">
                <span
                  class="feature-product siila-menu-color"
                  [translate]="check.title"
                ></span>
                <div class="space-text">
                  <img [src]="check.icon" />
                  <span
                    class="solution-info siila-menu-color"
                    [translate]="check.text"
                  ></span>
                </div>
              </div>
              <div class="btn-siila-spot">
                <button
                  *ngIf="currentCode != 'co'"
                  (click)="openContactDialog(action.learnMore)"
                  mat-flat-button
                  color="accent"
                  class="btn-moreV1"
                >
                  {{ "more-uni" | translate }}
                </button>
                <button
                  *ngIf="currentCode == 'co'"
                  (click)="openSpot('mx')"
                  mat-flat-button
                  color="accent"
                  class="btn-moreV1"
                >
                  {{ "news.mexico" | translate }}
                </button>
                <button
                  *ngIf="currentCode == 'co'"
                  (click)="openSpot('br')"
                  mat-flat-button
                  color="accent"
                  class="btn-moreV1 ml-2"
                >
                  {{ "news.brasil" | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="solutions.includeSiilaSpotV2">
          <div class="siila-infoV2">
            <h1
              class="solution-info siila-menu-color"
              translate="products.{{
                currentCode
              }}.products.marketing-card.marketing-product.text"
            ></h1>
            <div class="check-list-solutionV3">
              <span
                class="feature-product siila-menu-color"
                translate="spot.{{
                  currentCode
                }}.spot.spot-market.market-listV2.title"
              ></span>
              <div *ngFor="let check of solutionListV2">
                <div class="flex-row">
                  <img [src]="check.icon" />
                  <span
                    class="solution-info siila-menu-color"
                    [translate]="check.text"
                  ></span>
                </div>
              </div>
            </div>
            <div class="btn-siila-spot">
              <button
                *ngIf="currentCode != 'co'"
                (click)="openHomePage()"
                mat-flat-button
                color="accent"
                class="btn-moreV1"
              >
                {{ "visit" | translate }}
              </button>
              <button
                *ngIf="currentCode == 'co'"
                (click)="openSpot('mx')"
                mat-flat-button
                color="accent"
                class="btn-moreV1"
              >
                {{ "news.mexico" | translate }}
              </button>
              <button
                *ngIf="currentCode == 'co'"
                (click)="openSpot('br')"
                mat-flat-button
                color="accent"
                class="btn-moreV1 ml-2"
              >
                {{ "news.brasil" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="solutions.includeSiilaSpotV3">
          <div class="siila-infoV2">
            <div class="check-list-solutionV3">
              <span
                class="feature-product siila-menu-color"
                translate="spot.{{
                  currentCode
                }}.spot.spot-market.market-listV2.title"
              ></span>
              <div *ngFor="let check of solutionListV2">
                <div class="flex-row">
                  <img [src]="check.icon" />
                  <span
                    class="solution-info siila-menu-color"
                    [translate]="check.text"
                  ></span>
                </div>
              </div>
              <span
                class="feature-product siila-menu-color"
                translate="spot.{{
                  currentCode
                }}.spot.spot-market.market-listV3.title"
              ></span>
              <div *ngFor="let check of solutionListV3">
                <div class="flex-row">
                  <img [src]="check.icon" />
                  <span
                    class="solution-info siila-menu-color"
                    [translate]="check.text"
                  ></span>
                </div>
              </div>
            </div>
            <div class="btn-siila-spot">
              <button
                *ngIf="currentCode != 'co'"
                (click)="openContactDialog(action.learnMore)"
                mat-flat-button
                color="accent"
                class="btn-moreV1"
              >
                {{ "more-uni" | translate }}
              </button>
              <button
                *ngIf="currentCode == 'co'"
                (click)="openSpot('mx')"
                mat-flat-button
                color="accent"
                class="btn-moreV1"
              >
                {{ "news.mexico" | translate }}
              </button>
              <button
                *ngIf="currentCode == 'co'"
                (click)="openSpot('br')"
                mat-flat-button
                color="accent"
                class="btn-moreV1 ml-2"
              >
                {{ "news.brasil" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="solutionId === 104"
      [style.order]="sortComponent('GROCS')"
      class="border-card mat-elevation-z15"
    >
      <div class="spot-flex">
        <div class="solution-grocs-img">
          <img
            [alt]="solutiongrocs"
            [title]="solutiongrocs"
            src="../../../../../assets/img/solution-list/solution-grocs.png"
          />
        </div>
        <div class="solution-grocs-info">
          <h1
            class="title-gnrl siila-blue-color"
            translate="solutions.grocs.title"
          ></h1>
          <h2
            class="feature-tittle siila-grey-dark"
            translate="solutions.grocs.sub-title"
          ></h2>
          <h3
            *ngIf="browserLang === 'en' || browserLang === 'es'"
            class="solution-desc-grocs siila-grey-dark"
            translate="solutions.grocs.description"
          ></h3>
          <div class="check-list-solution-grocs" *ngFor="let item of grocsList">
            <img [src]="item.icon" />
            <span
              class="feature-sub-tittle siila-grey"
              [translate]="item.text"
            ></span>
          </div>
          <div>
            <button
              (click)="openGrocs()"
              mat-flat-button
              color="accent"
              class="btn-more"
            >
              {{ "more" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      [style.order]="sortComponent('ACADEMY')"
      *ngIf="solutions.includeSiilaAcademy"
      class="mat-elevation-z15 border-card"
    >
      <div class="spot-flex">
        <div class="academy-img-solution">
          <img
            class="img-academy"
            [alt]="imgAcademyAlt"
            [title]="imgAcademyAlt"
            [src]="imgAcademy()"
          />
        </div>
        <div class="academy-info-solution">
          <h2
            translate="products.{{
              currentCode
            }}.products.marketing-card.siilaAcademy.title"
            class="title-gnrl siila-blue-color"
          ></h2>
          <h1
            translate="products.{{
              currentCode
            }}.products.marketing-card.siilaAcademy.sub-title"
            class="feature-tittle siila-grey-dark"
          ></h1>
          <p
            translate="products.{{
              currentCode
            }}.products.marketing-card.siilaAcademy.text"
            class="feature-sub-tittle siila-grey"
          ></p>
          <div class="btn-siila-academy">
            <button
              (click)="openAcademy()"
              mat-flat-button
              color="accent"
              class="btn-more"
            >
              {{ "moreInfo" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      [style.order]="sortComponent('FII')"
      *ngIf="solutions.includeFiiDataPro"
      class="mat-elevation-z15 border-card"
    >
      <div class="products-flex">
        <div class="siila-data-info-solution">
          <h2
            translate="products.{{
              currentCode
            }}.products.marketing-card.dataPro.title"
            class="title-gnrl siila-blue-color"
          ></h2>
          <h1
            translate="products.{{
              currentCode
            }}.products.marketing-card.dataPro.sub-title"
            class="feature-tittle siila-grey-dark"
          ></h1>
          <p
            [ngStyle]="{
              'text-align': currentCode != 'co' ? 'inherit' : 'justify'
            }"
            translate="products.{{
              currentCode
            }}.products.marketing-card.dataPro.text"
            class="feature-sub-tittle siila-grey"
          ></p>
          <div class="btn-siila-spot">
            <button
              *ngIf="currentCode != 'co'"
              (click)="openFII()"
              mat-flat-button
              color="accent"
              class="btn-more"
            >
              {{ "moreInfo" | translate }}
            </button>
            <button
              *ngIf="currentCode == 'co'"
              (click)="openFIIMx()"
              mat-flat-button
              color="accent"
              class="btn-more"
            >
              {{ "news.mexico" | translate }}
            </button>
            <button
              *ngIf="currentCode == 'co'"
              (click)="openFII()"
              mat-flat-button
              color="accent"
              class="btn-more ml-2"
            >
              {{ "news.brasil" | translate }}
            </button>
          </div>
        </div>
        <div class="data-pro-img">
          <img
            class="img-data"
            [title]="imgFiiAlt"
            [alt]="imgFiiAlt"
            [src]="imgFii()"
          />
        </div>
      </div>
    </div>
    <div
      [style.order]="sortComponent('FII')"
      *ngIf="solutions.includeFiiDataPro && currentCode == 'latam'"
      class="mat-elevation-z15 border-card"
    >
      <div class="products-flex">
        <div class="siila-data-info-solution">
          <h2
            translate="products.mx.products.marketing-card.dataPro.title"
            class="title-gnrl siila-blue-color"
          ></h2>
          <h1
            translate="products.mx.products.marketing-card.dataPro.sub-title"
            class="feature-tittle siila-grey-dark"
          ></h1>
          <p
            translate="products.mx.products.marketing-card.dataPro.text"
            class="feature-sub-tittle siila-grey"
          ></p>
          <button
            (click)="openFIIMx()"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "moreInfo" | translate }}
          </button>
        </div>
        <div class="data-pro-img">
          <img
            class="img-data"
            [title]="imgFiiAltMx"
            [alt]="imgFiiAltMx"
            [src]="imgFiiMx()"
          />
        </div>
      </div>
    </div>
    <div
      [style.order]="sortComponent('DOWNLOAD')"
      *ngIf="solutions.includeDownloadResource"
      class="mat-elevation-z15 border-card-white"
    ></div>
    <app-methodology
      *ngIf="solutionId !== 106 && solutionId !== 113"
      [style.order]="sortComponent('METHODOLOGY')"
    ></app-methodology>
    <app-pillars
      *ngIf="solutionId !== 106 && solutionId !== 113"
      [style.order]="sortComponent('PILLARS')"
    ></app-pillars>
    <app-solution-list
      [style.order]="sortComponent('SERVE')"
    ></app-solution-list>
    <!--  <app-exclusive-access
      *ngIf="solutionId === 106 || solutionId === 113"
      [style.order]="sortComponent('ACCESS')"
    ></app-exclusive-access> -->
  </section>
  <app-btn-up></app-btn-up>
</section>
