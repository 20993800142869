import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "info-dialog-component",
  template: `
    <div class="flex-col">
      <div class="flex-row" [ngClass]="{ 'flex-wrap': handsetMode }">
        <p style="margin-left:5px;padding-top:20px;">
          {{ data.message }}
        </p>
      </div>
      <mat-divider></mat-divider>
      <div
        class="flex-row"
        style="justify-content:space-evenly; padding-top:20px;"
      >
        <button
          class="siila-submit login-btn"
          (click)="dismiss()"
          mat-raised-button
        >
          <span>OK</span>
        </button>
      </div>
    </div>
  `,
  styles: [``]
})
export class InfoDialogComponent {
  title = "Angular-Interceptor";
  handsetMode: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _breakpoint: BreakpointObserver,
    private _d: MatDialog
  ) {
    this._breakpoint
      .observe([Breakpoints.Handset])
      .subscribe(result => (this.handsetMode = result.matches));
  }

  dismiss() {
    this._d.closeAll();
  }
}
