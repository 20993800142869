import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DeviceDetectorService } from "ngx-device-detector";

// TODO: Translations for this module
@Component({
  selector: "before-install",
  templateUrl: "./install-prompt.component.html",
  styleUrls: ["./install-prompt.component.scss"]
})
export class InstallPromptComponent {
  imgInstall;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _d: MatDialog,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.imgInstall = this.isChrome()
      ? "assets/img/install-prompt/SiiLA-chrome.gif"
      : "assets/img/install-prompt/SiiLA-safari.gif";
  }

  isChrome(): boolean {
    return this.deviceDetectorService.browser.toLowerCase() === "chrome";
  }

  dismiss() {
    this._d.closeAll();
  }
}
