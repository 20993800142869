import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ImageService } from "../../../core/services/image.service";

import {
  Gallery,
  GalleryItem,
  ImageItem,
  ThumbnailsPosition,
  GalleryRef
} from "@ngx-gallery/core";

import {
  faStar,
  faShareAltSquare,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";
import { Lightbox } from "@ngx-gallery/lightbox";
import { WindowRef } from "../../../window-ref.service";
import { environment } from "../../../../environments/environment";
import { I18nService } from "../../services/i18n.service";
import { PropertyTypeHelperService } from "../../services/property-helper.service";
import { CommonGenericService } from "../../services/common.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ListingsService } from "../../../core";
import { FeaturedListingService } from "../../../core/services/featured-listing.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "featured-listing-card",
  templateUrl: "./featured-listing-card.component.html",
  styleUrls: ["./featured-listing-card.component.scss"]
})
export class FeaturedListingCard implements OnInit {
  @Input() listing: any;
  @Input() listing2: any;
  @Input() latamCountry: "";
  @Input() detail = false;
  @Input() hideLogo = false;
  listingPhotos: GalleryItem[];
  faStar: any;
  listingGallery: GalleryRef;
  propertyType: any;
  listingSubtypes: any;
  faShareAltSquare: any;
  faExternalLinkAlt: any;
  spotListingDetailsURL: any;
  coworkingSubTypeId: number = 2003;
  spotType: string;
  isMobile = false;
  buildingTitle: string;
  platformId = environment.platformId;

  constructor(
    public router: Router,
    private imageService: ImageService,
    private _lightbox: Lightbox,
    private gallery: Gallery,
    private windowRef: WindowRef,
    private i18nService: I18nService,
    private propertyHelperService: PropertyTypeHelperService,
    public commonService: CommonGenericService,
    private snackbar: MatSnackBar,
    private featureListingService: FeaturedListingService,
    private translateService: TranslateService,
    private siilaTranslationService: SiilaTranslationService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    this.buildingTitle = this.siilaTranslationService.getTranslation(
      this.detail ? this.listing.title : this.listing?.buildingTitle
    );
    this.imageService.latamCountry = this.latamCountry;
    this.faStar = faStar;
    this.listingGallery = this.gallery.ref("listingPhotosGallery");
    this.listingGallery.setConfig({
      thumbPosition: ThumbnailsPosition.Bottom
      //itemTemplate: this.itemTemplate,
      //gestures: false,
      //imageSize: 'cont',
      // loadingIcon: 'Loading...'
    });
    this.faShareAltSquare = faShareAltSquare;
    this.faExternalLinkAlt = faExternalLinkAlt;
    this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(
      !this.detail ? this.listing.buildingTypeId : this.listing.buildingType.id
    );
    if (!this.propertyType && this.listing.buildingType) {
      this.propertyType = this.i18nService.getTranslation(
        this.listing.buildingType.name
      );
    }
    this.spotType = this.propertyHelperService.getSpotBuildingTypeTranslation(
      this.listing.type
    );
    if (this.listing.propertySubTypes && this.listing.propertySubTypes.length) {
      //const propertySubTypes: any = this.commonService.translateLocaleText(
      //  this.listing.propertySubTypes,
      //   "name"
      //);

      const propertySubTypes: any = this.listing.propertySubTypes.map(item => {
        const isValidJSON: boolean = this.isJSON(item["name"]);
        item["name"] = isValidJSON
          ? this.siilaTranslationService.getTranslation(item["name"])
          : item["name"];
        return item;
      });

      this.listingSubtypes = this.commonService.transformArrayStringList(
        propertySubTypes,
        "name"
      );
    }
    /* this.settingNewDetailsURL(); */
    /*  this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.settingNewDetailsURL();
    }); */
  }

  private settingNewDetailsURL() {
    this.featureListingService
      .checkListingDetailRoute(
        this.listing.id,
        this.i18nService.getCurrentLanguage()
      )
      .then(newURLFormat => {
        this.spotListingDetailsURL = `${newURLFormat.detailsURL}`;

        this.spotListingDetailsURL = this.i18nService.addCurrentLanguageToPath(
          this.spotListingDetailsURL
        );
      });
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  getMediumLargeImg(id: any) {
    return this.imageService.mediumLargeThumbnail(id);
  }

  getLargeImg(id: any) {
    return this.imageService.largeThumbnail(id);
  }

  public async openCard(id: number, $event: Event) {
    console.log(this.listing);
    $event.stopImmediatePropagation();

    let lang = this.i18nService.getCurrentLanguage();

    if (window.location.href.includes("pt-br")) {
      lang = "pt-br";
    } else if (window.location.href.includes("en")) {
      lang = "en";
    }
    this.featureListingService.latamCountry = this.latamCountry;

    let res: any = await this.featureListingService
      .getSpotBuildingDetailURLFormat(id, lang)
      .toPromise();

    if (res && !this.detail) {
      let detailsURL = `${res}`;
      window.open(detailsURL, "_blank");
    } else {
      window.open(
        `${
          environment.applicationURL
        }/property/${id}/lang/${this.i18nService.getCurrentLanguage()}`,
        "blank"
      );
    }
  }

  public isCompanySameAsAUser(listing: any) {
    return (
      listing &&
      listing.offeredByUser.company.name ===
        listing.offeredByUser.firstNameAndLastName
    );
  }

  private isJSON(str: any) {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  }

  getSubHeaderCardInfo() {
    var address;
    if (this.detail && this.listing.address) {
      address = `${this.i18nService.getTranslation(
        this.listing.address
      )} ,${this.i18nService.getTranslation(this.listing.city?.name)} ,
      ${this.abbreviatedText(
        this.i18nService.getTranslation(this.listing?.city?.countryState?.name)
      )}`;
      return address;
    } else {
      address = `${this.i18nService.getTranslation(
        this.listing.buildingAddress
          ? this.listing.buildingAddress
          : this.listing.address
      )} `;
      return this.truncateText(address, 90);
    }
  }

  private truncateText(text: string, length: number) {
    if (!text) return;
    return text.length > length ? text.substring(0, length) + "..." : text;
  }

  getAvailableTexRegion() {
    if (this.detail) {
      return this.listing.subMarket
        ? this.i18nService.getTranslation(this.listing?.subMarket?.name)
        : this.i18nService.getTranslation(this.listing?.region?.name);
    } else {
      return this.getAvgAskingRent();
    }
  }

  getAvailableAreaText() {
    return this.commonService.getAvailableAreaText(
      this.listing.spaceRangesDTO,
      this.listing.type
    );
  }

  getAvgAskingRent() {
    return this.commonService.getAvgAskingRent(
      this.listing.spaceRangesDTO,
      this.listing,
      this.latamCountry
    );
  }

  public getCompanyLogoImg(id: any) {
    return id ? this.getMediumImg(id) : "../../../assets/no-company-logo.png";
  }

  abbreviatedText(text: string): string {
    const value = text.split(" ");
    if (value.length > 1) {
      const firstLetters = value.map(item => item.charAt(0)).join(".");
      return `${firstLetters}.`;
    } else {
      return text;
    }
  }
}
