import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { MailChimpForm } from "../../shared/components/mail-chimp/mail-chimp.component";
import { SiilaTranslationService } from "./siila-translation.service";

@Injectable()
export class MailChimpService {
  private baseEndpoint = `${environment.apiURL}/mailchimp`;

  constructor(
    private http: HttpClient,
    private siilaTranslationService: SiilaTranslationService
  ) {}

  submitMailchimpFormData(body: MailChimpForm) {
    return this.http.post(this.getEndpointFromLocale(), body);
  }

  getEndpointFromLocale() {
    switch (this.siilaTranslationService.getCurrentLanguage()) {
      case "en":
        return `${this.baseEndpoint}/en`;
        break;
      case "es":
        return `${this.baseEndpoint}/es`;
        break;
      case "pt":
        return `${this.baseEndpoint}/pt`;
        break;
      default:
        return `${this.baseEndpoint}/en`;
        break;
    }
  }
}
