<div
  class="card"
  [ngClass]="{
    wide: tags
  }"
  [style.background-image]="'url(' + background + ')'"
>
  <div class="tags-container">
    <ng-container *ngIf="tags">
      <div *ngFor="let tag of tags" class="tags uppercase">
        {{ tag | translate }}
      </div>
    </ng-container>
  </div>
  <img
    [src]="icon"
    [alt]="iconAltTitle"
    [title]="iconAltTitle"
    *ngIf="icon"
    class="card-img"
  />
  <h1
    [translate]="title"
    class="card-title "
    [ngClass]="{
      'siila-blue-color': background === undefined
    }"
  ></h1>
  <h2
    [translate]="text"
    class="card-text"
    [ngClass]="{
      'siila-grey': background === undefined
    }"
  ></h2>
  <div class="btn-info-card">
    <button
      mat-button
      class="card-btn uppercase"
      [ngClass]="{ wide: tags, 'siila-blue-dark': background === undefined }"
    >
      {{ actionTxt | translate }}
    </button>
  </div>
</div>
