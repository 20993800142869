<div class="value-container">
  <div class="index-value">
    <h6
      *ngIf="currentCode == 'br' || capitalMarket?.currentCode == 'br'"
      class="badge siila-value"
      translate="global.siilaIndex.currencyTitle1"
    ></h6>
    <h6
      *ngIf="currentCode == 'mx' || capitalMarket?.currentCode == 'mx'"
      class="badge siila-value"
      translate="global.siilaIndex.currencyTitle4"
    ></h6>
    <div class="center-data">
      <span class="badge" [negativePositiveBg]="usdPercent">
        {{ (usdPercent | localNumberWithSign: "1.2-2") || "–" }} %
      </span>
      <span class="badge">{{ (usdVal | localNumber: "1.2-2") || "–" }}</span>
    </div>
  </div>
  <div
    class="index-value"
    *ngIf="currentCode == 'br' || capitalMarket?.currentCode == 'br'"
  >
    <h6 class="badge" translate="global.siilaIndex.currencyTitle2"></h6>
    <div class="center-data">
      <span class="badge" [negativePositiveBg]="canPercent">
        {{ (canPercent | localNumberWithSign: "1.2-2") || "–" }} %
      </span>
      <span class="badge">{{ (canVal | localNumber: "1.2-2") || "–" }}</span>
    </div>
  </div>
  <div
    class="index-value"
    *ngIf="currentCode == 'br' || capitalMarket?.currentCode == 'br'"
  >
    <h6 class="badge" translate="global.siilaIndex.currencyTitle3"></h6>
    <div class="center-data">
      <span class="badge" [negativePositiveBg]="euroPercent">
        {{ (euroPercent | localNumberWithSign: "1.2-2") || "–" }} %
      </span>
      <span class="badge">{{ (euroVal | localNumber: "1.2-2") || "–" }}</span>
    </div>
  </div>
</div>
