import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { CapitalMarketsValues } from "../components/capital-markets-section/capital-markets.model";

@Injectable({ providedIn: "root" })
export class SiilaIndexService {
  constructor(private http: HttpClient) {}

  getCapitalMarketsValues(currentCode = "") {
    return this.http
      .get(
        `${
          currentCode == "br"
            ? environment.apiURLBR
            : currentCode == "mx"
            ? environment.apiURLMX
            : environment.apiURL
        }/siilaIndex/currentValues`
      )
      .pipe(
        map((values: CapitalMarketsValues) => {
          return {
            ...values,
            appreciationChg: this.parsePercentDecimal(values.appreciationChg),
            incomeChg: this.parsePercentDecimal(values.incomeChg),
            totalChg: this.parsePercentDecimal(values.totalChg)
          };
        })
      );
  }

  parsePercentDecimal(value: number): number {
    return typeof value === "number" ? value * 100 : null;
  }
}
