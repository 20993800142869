import { Component, OnInit, Inject } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MailChimpService } from "../../../core/services/mail-chimp.service";
import { NotifierService } from "angular-notifier";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "mail-chimp-subscribe",
  templateUrl: "./mail-chimp.component.html",
  styleUrls: ["./mail-chimp.component.scss"]
})
export class MailchimpFormComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  formGroup: FormGroup;
  mailChimpForm: MailChimpForm;
  handsetMode: boolean;
  subscribed: boolean;
  dialogRef: any;

  constructor(
    private _formBuilder: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public _m: MailChimpService,
    private _notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      //TODO: require a type for this operation
      //TODO_DESC: describe the dialog ref
      this.dialogRef = data;
    }
    breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.handsetMode = result.matches;
      });
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      emailCtrl: [null, [Validators.required, Validators.email]],
      firstNameCtrl: ["", Validators.required],
      lastNameCtrl: ["", Validators.required],
      phoneCtrl: [null]
    });
  }

  submit() {
    const formData: MailChimpForm = {
      email: this.formGroup.controls.emailCtrl.value,
      status: "subscribed",
      firstName: this.formGroup.controls.firstNameCtrl.value,
      lastName: this.formGroup.controls.lastNameCtrl.value,
      phoneNumber: this.formGroup.controls.phoneCtrl.value
    };
    this._m
      .submitMailchimpFormData(formData)
      .toPromise()
      .then(() => {
        this.dialogRef.closeAll();
      })
      .catch(e => {
        if (e) {
          this.dialogRef.closeAll();
          this._notifier.notify("error", "Error subscribing to newsletter!");
        }
      });
  }

  dismiss() {
    this.dialogRef.closeAll();
  }
}

export interface MailChimpForm {
  email: string;
  status: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}
