<div class="value-container">
  <div class="index-value">
    <h6
      *ngIf="currentCode == 'br' || capitalMarket?.currentCode == 'br'"
      class="badge siila-value"
    >
      {{
        "global.siilaIndex.siilaIndexTitle1"
          | translate: { quarter: quarter || "–", year: year || "–" }
      }}
    </h6>
    <h6
      *ngIf="currentCode == 'mx' || capitalMarket?.currentCode == 'mx'"
      class="badge siila-value"
    >
      {{
        "global.siilaIndex.siilaIndexTitleMx"
          | translate: { quarter: quarter || "–", year: year || "–" }
      }}
    </h6>

    <div class="center-data">
      <span class="badge" [negativePositiveBg]="totalPercent">
        {{ (totalPercent | localNumberWithSign: "1.2-2") || "–" }} %
      </span>
      <span class="badge">{{ (totalVal | localNumber: "1.2-2") || "–" }}</span>
    </div>
    <span class="badge">=</span>
  </div>
  <div class="index-value">
    <h6 *ngIf="isMobile" class="badge">
      {{ "global.siilaIndex.siilaIndexTitle2Mobile" | translate }}
    </h6>
    <h6 *ngIf="!isMobile" class="badge">
      {{ "global.siilaIndex.siilaIndexTitle2" | translate }}
    </h6>
    <span class="badge" [negativePositiveBg]="incomePercent">
      {{ (incomePercent | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge">+</span>
  </div>
  <div class="index-value">
    <h6 class="badge">
      {{
        (isMobile
          ? "global.siilaIndex.siilaIndexTitle3Mobile"
          : "global.siilaIndex.siilaIndexTitle3"
        ) | translate
      }}
    </h6>
    <span class="badge" [negativePositiveBg]="appreciationPercent">
      {{ (appreciationPercent | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
  </div>
</div>
