import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnDestroy
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ProductService } from "../../../core/services/products.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { runInThisContext } from "vm";
import { I18nService } from "../../services";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
@Component({
  selector: "app-download-quarterly-update",
  templateUrl: "./download-quarterly-update.component.html",
  styleUrls: [
    "./download-quarterly-update.component.scss",
    "../static-pages/fii-data-pro/fii-data-pro.component.scss"
  ]
})
export class DownloadQuarterlyUpdateComponent implements OnInit, OnDestroy {
  @Input() productNumber: number;

  isMobile: boolean;
  subscription: Subscription;
  currentCode: string;
  form: FormGroup;
  lang: string;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private responsive: BreakpointObserver,
    private fb: FormBuilder,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private i18: I18nService,
    private siilaTranslationService: SiilaTranslationService
  ) {
    this.currentCode = environment.platformId;
    this.lang = this.siilaTranslationService.getCurrentLanguage();
    let requestType: string = "QUARTERLY";
    /*    if (this.productNumber === 103 || this.productNumber === 106) {
      requestType = "INDICES";
    } */
    this.form = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: [""],
      email: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      position: ["", Validators.required],
      requestType: [requestType]
    });
  }

  ngOnInit(): void {
    this.subscription = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  downloadForm() {
    let formValue = this.form.value;
    this.form.reset();
    let message = this.i18.get("global.newsForm.download");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.requestFileIndex(formValue).subscribe(
      response => {
        this.productService.downloadPdf(response.resourceUrl);

        if (response.success === false) {
          this.snackBar.open(errorMsj, action);
        } else {
          this.snackBar.open(message, action);
        }
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
  }

  imgContactUs() {
    let img = "../../../../../assets/img/academy-list/contactIvestor.png";
    /*  if (this.currentCode == "mx") {
      if (this.lang == "en") {
        img = img + "all-products-item7-report-MX-EN.jpg";
      } else {
        img = img + "all-products-item7-report-MX-SP.jpg";
      }
    } else {
      if (this.lang == "en") {
        img = img + "all-products-item7-report-EN.jpg";
      } else {
        img = img + "all-products-item7-report-PT.jpg";
      }
    } */

    return img;
  }
}
