import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2
} from "@angular/core";

@Directive({
  selector: "[negativePositiveBg]"
})
export class NegativePositiveBg implements OnChanges {
  @Input("negativePositiveBg") value: any;

  constructor(private _el: ElementRef, private _r: Renderer2) {}

  ngOnChanges() {
    if (this.value >= 0) {
      this._r.addClass(this._el.nativeElement, "siila-green-bg");
      this._r.removeClass(this._el.nativeElement, "siila-red-bg");
    } else if (this.value < 0) {
      this._r.addClass(this._el.nativeElement, "siila-red-bg");
      this._r.removeClass(this._el.nativeElement, "siila-green-bg");
    }
  }
}
