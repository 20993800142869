<div class="border-card mat-elevation-z15 solutions">
  <div class="solution-text">
    <h1
      translate="about.{{ currentCode }}.about.solutions.title"
      class="company-tittle uppercase"
    ></h1>
  </div>
  <div id="solutionContainerLinks" class="solution-container">
    <div
      *ngFor="let card of solutions; let last = last"
      (click)="openServe(card.id)"
      class="solution-card"
    >
      <div class="title-card all-upper">
        {{ card.shortName | langSelector }}
        <img class="arrow-icon" src="assets/icons/arrow-left.svg" />
      </div>
    </div>
    <div
      class="solution-card mb-4"
      (click)="openAllProducts()"
      style="cursor: pointer;background-color:#f90"
    >
      <div class="title-card all-upper">
        {{ "all-view" | translate }}
        <img class="arrow-icon" src="assets/icons/arrow-left.svg" />
      </div>
    </div>

    <!--   <div>
      <button
        (click)="openAllProducts()"
        mat-flat-button
        color="accent"
        class="all-view uppercase"
      >
        <div style="width: 174px; white-space: pre-wrap; text-align: left;">
          <span>{{ "all-view" | translate }}</span>
        </div>
      </button>
      <img class="arrow-view" src="assets/icons/arrow-left.svg" />
    </div> -->
  </div>
</div>
