<form [formGroup]="formGroup">
  <div class="siila-blue-color uppercase" style="text-align:center;">
    <span
      style="font-size:1.5em;color:#00334c;"
      translate="resetYourPassword"
    ></span>
  </div>
  <div style="padding-top:20px;">
    <span style="color:#00334c;" translate="resetYourPasswordDesc"> </span>
  </div>
  <mat-form-field style="width:100%; padding-top: 10px;">
    <input
      matInput
      placeholder="Email"
      type="email"
      formControlName="emailCtrl"
      required
    />
  </mat-form-field>
</form>
<div style="width:100%;display:flex;justify-content:center;">
  <button
    aria-label="submit"
    class="siila-submit"
    mat-button
    style="font-size:1.2em;width:100%;"
    [disabled]="formGroup.invalid"
    (click)="submit()"
  >
    <span translate="submit"></span>
  </button>
</div>
