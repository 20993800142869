import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ChangePasswordService } from "./change-password.service";
import { InfoDialogService } from "../../../../core/services/dialog/info-dialog.service";
import { Router } from "@angular/router";

@Component({
  selector: "change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  registerForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private infoDialog: InfoDialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.fb.group(
      {
        emailGroup: this.fb.group({
          password: ["", Validators.compose([Validators.required])],
          confirmPass: ["", Validators.compose([Validators.required])]
        })
      },
      {
        validator: this.checkFormErrors
      }
    );
  }

  async changePassword() {
    let changePasswordObj: any = {};
    changePasswordObj.newPassword = this.registerForm.controls.emailGroup.value.password;
    changePasswordObj.secretKey = this.changePasswordService.getKeyForChangePassword();
    this.changePasswordService
      .changePassword(changePasswordObj)
      .then((response: any) => {
        console.log("Password change successful");
        this.infoDialog.openDialog({
          message: "Password changed. Please log in using your new password."
        });
        this.router.navigateByUrl("/");
      })
      .catch(err => {
        console.log("Password change failed");
      });
  }

  checkFormErrors(formGroup: any) {
    let password = formGroup.controls.emailGroup.controls["password"].value;
    let confirmPass =
      formGroup.controls.emailGroup.controls["confirmPass"].value;
    if (password.length < 6) {
      return password.length < 6 ? { passwordLength: true } : null;
    } else if (password !== confirmPass) {
      return password !== confirmPass ? { passwordMatch: true } : null;
    }

    if (formGroup.controls.emailGroup.status == "INVALID") {
      return { passwordLength: true };
    }
  }
}
