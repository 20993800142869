<div class="flex-row flex-justify">
  <div class="flex-privacy">
    <div *ngIf="currentEnv === 'br'">
      <div class="title-section-head">
        <h3
          class="title-section row title-border"
          translate="terms-of-use.title"
        ></h3>
      </div>

      <p class="bold-terms" translate="terms-of-use.important"></p>

      <p translate="terms-of-use.paragraphs.p1"></p>

      <p translate="terms-of-use.paragraphs.p2"></p>

      <p translate="terms-of-use.paragraphs.p3"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s1"></p>

      <p translate="terms-of-use.paragraphs.p4"></p>

      <p translate="terms-of-use.paragraphs.p5"></p>

      <p translate="terms-of-use.paragraphs.p6"></p>

      <p translate="terms-of-use.paragraphs.p7"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s2"></p>

      <p translate="terms-of-use.paragraphs.p8"></p>

      <p translate="terms-of-use.paragraphs.p9"></p>

      <p translate="terms-of-use.paragraphs.p10"></p>

      <p translate="terms-of-use.paragraphs.p11"></p>

      <p translate="terms-of-use.paragraphs.p12"></p>

      <p translate="terms-of-use.paragraphs.p13"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s3"></p>

      <p translate="terms-of-use.paragraphs.p14"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s4"></p>

      <p translate="terms-of-use.paragraphs.p15"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s5"></p>

      <p translate="terms-of-use.paragraphs.p16"></p>

      <p translate="terms-of-use.paragraphs.p17"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s6"></p>

      <p translate="terms-of-use.paragraphs.p18"></p>

      <p translate="terms-of-use.paragraphs.p19"></p>

      <p class="bold-terms" translate="terms-of-use.subtitles.s7"></p>

      <p translate="terms-of-use.paragraphs.p20"></p>

      <div class="pull-right footer-privacy footer-rights">
        <p translate="terms-of-use.date"></p>
        <p translate="terms-of-use.reserved"></p>
      </div>
    </div>

    <div *ngIf="currentEnv === 'mx' || currentEnv === 'co'">
      <h2 class="terms-title" translate="terms-of-use.mx-terms.title"></h2>
      <h2 class="terms-title2" translate="terms-of-use.mx-terms.header"></h2>
      <ul class="terms-ul">
        <li class="terms-list" translate="terms-of-use.mx-terms.p1"></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p2"></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p3"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p4title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p4"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p5title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p5"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p6title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p6"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p7title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p7"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p8title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p8"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p9title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p9"></li>
        <li
          class="terms-list terms-title2"
          translate="terms-of-use.mx-terms.p10title"
        ></li>
        <li class="terms-list" translate="terms-of-use.mx-terms.p10"></li>
        <li class="terms-list">
          <p class="terms-foot" translate="terms-of-use.mx-terms.bottom"></p>
        </li>
        <li class="terms-list">
          <p class="terms-foot" translate="terms-of-use.reserved"></p>
        </li>
      </ul>
    </div>
  </div>
</div>
