import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "blog",
  template: "",
  styleUrls: []
})
export class BlogRedirectComponent {
  externalBlogRoute: string;

  constructor(private router: Router) {
    this.externalBlogRoute = `${
      environment.platformId == "mx" || environment.platformId == "co"
        ? "https://www.siilamexico.blog"
        : "https://www.siilabrasil.blog"
    }${this.router.url.replace("/blog", "")}`;
    window.open(this.externalBlogRoute, "_self");
  }
}
