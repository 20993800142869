import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { I18nService } from "../../services";

@Component({
  selector: "app-state-market",
  templateUrl: "./state-market.component.html",
  styleUrls: ["./state-market.component.scss"]
})
export class StateMarketComponent implements OnInit {
  @Input() val: number = 0;
  responsiveSub: Subscription;
  isMobile = false;
  currentCode: string;
  browserLang: string = "en";
  constructor(
    private responsive: BreakpointObserver,
    private siilaTranslationService: SiilaTranslationService,
    private i18nService: I18nService
  ) {
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
  }

  ngOnInit(): void {
    this.responsiveChanges();
  }

  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  openTarted() {
    window.open(
      `${
        environment.applicationURL
      }/marketanalytics/lang/${this.i18nService.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }

  openSpot(lang = null) {
    if (lang) {
      window.open(
        `${
          lang == "mx"
            ? environment.applicationURLMx
            : environment.applicationURLBr
        }/SPOT/lang/${lang == "mx" ? this.browserLang : "en"}`,
        this.isMobile || this.val == 1 ? "_self" : "_blank"
      );
    } else {
      window.open(
        `${
          environment.applicationURL
        }/SPOT/lang/${this.i18nService.getCurrentLanguage()}`,
        this.isMobile || this.val == 1 ? "_self" : "_blank"
      );
    }
  }

  openProducts() {
    window.open(
      `${
        environment.applicationURL
      }/products/lang/${this.i18nService.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }

  imgMarketAnalyticsAlt() {
    return this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.marketProductOverview"
    );
  }

  imgMarketAnalytics() {
    let img = "../../../assets/img/home-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "item-2-mockup SiiLA-EN-MX.png";
      } else {
        img = img + "item-2-mockup SiiLA-ES.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "item-2-mockup SiiLA-EN-BR.png";
      } else {
        img = img + "item-2-mockup SiiLA-PT.png";
      }
    }

    return img;
  }

  imgHomeSpotAlt() {
    return this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.platformOverview"
    );
  }

  imgHomeSpot() {
    let img = "../../../assets/img/home-list/";
    if (this.currentCode == "mx") {
      if (this.browserLang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-ES.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-PT.png";
      }
    }

    return img;
  }
}
