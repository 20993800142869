import { TranslateService } from "@ngx-translate/core";
import { SiilaTranslationService } from "../core/services/siila-translation.service";
import { Injector } from "@angular/core";
import { LOCATION_INITIALIZED } from "@angular/common";
import { take } from "rxjs/operators";
import { environment } from "../../environments/environment";

export function appInitializerFactory(
  translateService: TranslateService,
  siilaTranslationService: SiilaTranslationService,
  injector: Injector
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        translateService.addLangs(["pt", "en", "es"]);
        translateService.setDefaultLang("en");
        const url = window.location.pathname;
        const currentCode = environment.platformId;
        if (!validateUrlLang(url)) {
          if (currentCode == "latam") {
            localStorage.setItem("currentLanguageHP", "en");
          } else if (currentCode == "br") {
            localStorage.setItem("currentLanguageHP", "pt");
            translateService.setDefaultLang("pt");
          } else if (currentCode == "mx" || currentCode == "co") {
            localStorage.setItem("currentLanguageHP", "es");
            translateService.setDefaultLang("es");
          }
        }

        const language = siilaTranslationService.getCurrentLanguage();
        siilaTranslationService
          .setLanguage(language)
          .pipe(take(1))
          .subscribe(
            () => resolve(null),
            err => console.error(err),
            () => resolve(null)
          );
      });
    });
}

export function validateUrlLang(url) {
  if (
    url.indexOf("lang/en") >= 0 ||
    url.indexOf("lang/es") >= 0 ||
    url.indexOf("lang/pt-br") >= 0
  ) {
    return true;
  }
  return false;
}
