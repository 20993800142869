import { Routes } from "@angular/router";
import { NewsDetailComponent } from "./news-detail.component";
import { PageNotFoundComponent } from "../../shared/components";

export const routes: Routes = [
  {
    path: "detail/:id",
    component: NewsDetailComponent,
    data: { title: "Industry News", description: "Industry News" }
  },
  {
    path: "detail/:id/lang/:language",
    component: NewsDetailComponent,
    data: { title: "Industry News", description: "Industry News" }
  }
  // { path: '', redirectTo: '/', pathMatch: 'full' },
  // { path: '404', component: PageNotFoundComponent },
  // { path: '**', redirectTo: '404' }
];
