import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { CapitalMarketsValues } from "../capital-markets.model";

@Component({
  selector: "app-siila-index-values-mx",
  templateUrl: "./siila-index-values-mx.component.html",
  styleUrls: ["./siila-index-values-mx.component.scss"]
})
export class SiilaIndexValuesMxComponent implements OnInit {
  @Input() indexValues: any;
  currentCode: string;
  constructor() {
    this.currentCode = environment.platformId;
  }

  ngOnInit(): void {}
}
