import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "[app-app-top-banner]",
  templateUrl: "./app-top-banner.component.html",
  styleUrls: ["./app-top-banner.component.scss"],
  animations: [
    trigger("visibleHidden", [
      state(
        "visible",
        style({
          maxHeight: "40vh",
          padding: "1rem",
          transform: "scaleY(1)"
        })
      ),
      state(
        "hidden",
        style({
          maxHeight: 0,
          padding: "0 1rem",
          transform: "scaleY(0)"
        })
      ),
      transition("hidden => visible", [animate("0.35s")]),
      transition("visible => hidden", [animate("0.35s")])
    ])
  ]
})
export class AppTopBannerComponent implements OnInit {
  @Input() buttonText: string;
  @Input() visible: boolean = false;
  @Output() onButtonClick = new EventEmitter();
  mobileScreen: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.subscribeBreakpoint();
  }

  subscribeBreakpoint() {
    return this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe(result => {
        this.mobileScreen = result.matches;
      });
  }

  onActionClick() {
    this.onButtonClick.emit();
  }
}
