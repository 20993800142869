import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "existing-device-dialog",
  templateUrl: "./existing-device.component.html",
  styleUrls: ["./existing-device.component.scss"]
})
export class ExistingDeviceDialogComponent implements OnInit {
  handsetMode: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _breakpoint: BreakpointObserver,
    private _d: MatDialog
  ) {
    this._breakpoint
      .observe([Breakpoints.Handset])
      .subscribe(result => (this.handsetMode = result.matches));
  }

  ngOnInit(): void {}

  dismiss() {
    this._d.getDialogById("registerDialog").close({ action: "ok" });
  }
}
