import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "new-device-success",
  template: `
    <div class="flex-col">
      <div class="flex-row" [ngClass]="{ 'flex-wrap': handsetMode }">
        <img src="assets/img/device-registration/siila_cert.png" />
        <p style="margin-left:5px;padding-top:20px;">
          Your Device has been successfully registered.
        </p>
        <p style="text-align:center;">Welcome to SiiLA</p>
        <!-- TODO: Show Spinner -->
        <!-- <div class="lock-container">
        <span class="lock" [ngClass]="{'unlocked': !locked}"></span>
      </div> -->
      </div>
      <mat-divider></mat-divider>
      <div class="flex-row actions">
        <button mat-raised-button (click)="proceed()">
          <span>Proceed</span>
        </button>
      </div>
    </div>
  `,
  styleUrls: ["./new-device.component.scss"]
})
export class NewDeviceSuccessComponent implements OnInit {
  handsetMode = false;

  constructor(private _d: MatDialog, private _breakpoint: BreakpointObserver) {
    this._breakpoint
      .observe([Breakpoints.Handset])
      .subscribe(result => (this.handsetMode = result.matches));
  }

  ngOnInit(): void {}

  proceed() {
    this._d.closeAll();
  }
}
