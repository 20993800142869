import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DeviceRegistrationService } from "../../../../../core/services/device-registration.service";

@Component({
  selector: "new-device-dialog",
  templateUrl: "./new-device.component.html",
  styleUrls: ["./new-device.component.scss"]
})
export class NewDeviceDialogComponent implements OnInit {
  public handsetMode: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _d: MatDialog,
    private _breakpoint: BreakpointObserver,
    private deviceRegistrationService: DeviceRegistrationService
  ) {
    this._breakpoint.observe([Breakpoints.Handset]).subscribe(result => {
      this.handsetMode = result.matches;
    });
  }

  registerCurrentDevice() {
    this._d.getDialogById("registerDialog").close({ action: "register" });
  }

  dismiss() {
    this._d.getDialogById("registerDialog").close({ action: "cancel" });
  }

  ngOnInit(): void {}
}
