import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";

@Component({
  selector: "app-info-card",
  templateUrl: "./info-card.component.html",
  styleUrls: ["./info-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoCardComponent implements OnInit {
  @Input() tags: string[];
  @Input() title: string;
  @Input() text: string;
  @Input() actionTxt: string;
  @Input() icon: string;
  @Input() iconAltTitle: string;
  @Input() background: string;

  constructor() {}

  ngOnInit(): void {}
}
