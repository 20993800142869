<a [href]="spotListingLink" appExternalUrl class="story-listing-card">
  <img class="story-listing-card__img" [src]="storyListingImage" alt="" />
  <div class="story-listing-card__info">
    <p class="listing-name" localize [textValue]="listingName"></p>

    <p class="listing-type">
      {{ listingType }}
    </p>

    <p
      ng-if="listingTitle"
      class="listing-title"
      localize
      [textValue]="listingTitle"
      [ngStyle]="{ '-webkit-box-orient': 'vertical' }"
    ></p>
  </div>
</a>
