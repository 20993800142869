<div class="mat-elevation-z15 border-card join-our">
  <div class="data-pro-flex">
    <div class="mainling-Card">
      <span
        translate="home.{{ currentCode }}.mainList.joinMailingList"
        class="feature-tittle uppercase"
      ></span>
      <p
        translate="home.{{ currentCode }}.mainList.mainText"
        class="product-description"
      ></p>
      <mat-form-field class="email">
        <mat-label class="email-text">{{ "email" | translate }}</mat-label>
        <input [(ngModel)]="email" style="color: white;" matInput />
      </mat-form-field>
      <button
        [disabled]="validEmail()"
        mat-flat-button
        color="accent"
        class="btn-send uppercase"
        (click)="sendEmail()"
      >
        {{ "send" | translate }}
      </button>
      <div class="social-icons">
        <span
          class="social-title"
          translate="home.{{ currentCode }}.socialMedia"
        ></span>
        <div class="social">
          <a
            [href]="icon.link"
            target="_blank"
            *ngFor="let icon of currentSocial"
          >
            <img [src]="icon.ref" [alt]="icon.alt" [title]="icon.title" />
          </a>
        </div>
      </div>
    </div>
    <div class="mainling-img">
      <img
        class="mainling-media"
        [alt]="commercialestatenewslatamSEO.alt"
        [src]="commercialestatenewslatamSEO.src"
        [title]="commercialestatenewslatamSEO.title"
      />
    </div>
  </div>
</div>
