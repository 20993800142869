<section class="glossary-container">
  <div class=" border-card-white mat-elevation-z15">
    <div class="glossary-content">
      <h1
        class="glossary-title uppercase"
        translate="fii-data.{{ currentCode }}.fii-data.glossary-title"
      ></h1>
      <h2
        class="fii-sub-title siila-menu-color "
        translate="fii-data.{{ currentCode }}.fii-data.glossary-sub-title"
      ></h2>
    </div>
    <div #spacer></div>
    <div
      [ngClass]="{ 'is-sticky': isSticky }"
      stickyThing
      [spacer]="spacer"
      marginTop="64"
      (stickyStatus)="stickyStatus($event.isSticky)"
    >
      <div class="menu scroll">
        <ng-container *ngFor="let item of glossary">
          <div class="item-letter" (click)="scrollTo(item.letter)">
            {{ item.letter }}
          </div>
        </ng-container>
      </div>
    </div>

    <div class="search">
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input
          (keyup)="search($event.target.value)"
          matInput
          placeholder="search"
        />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="glossary-line"></div>
    <div *ngFor="let letter of filteredResult; let last = last">
      <div class="letter">
        <h1 [id]="letter.letter" class="info-letter siila-blue-color">
          {{ letter.letter }}
        </h1>
      </div>
      <div class="info-text" *ngFor="let content of letter.content">
        <div class="info-item">
          <h2 class="info-title siila-blue-color">{{ content.title }}</h2>
          <p class="info-description siila-grey">{{ content.description }}</p>
        </div>
      </div>

      <div class="glossary-line" *ngIf="!last"></div>
    </div>
  </div>
  <app-btn-up></app-btn-up>
</section>
