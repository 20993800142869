import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LanguageService {
  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || "en-US";
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    this.locale = culture;

    // Register LocalChange is deprecated on angular 13
    // switch (culture) {
    //   case "pt-BR": {
    //     registerLocaleData(localePT);
    //     break;
    //   }
    //   case "en-US": {
    //     registerLocaleData(localeEn);
    //     break;
    //   }
    // }
  }
}
