<div class="request-close">
  <button
    aria-label="close"
    aria-labelledby=""
    class="btn-close"
    mat-flat-button
    (click)="dismiss()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<form [formGroup]="form" class="height-form">
  <div class="siila-blue-color" style="text-align:center;">
    <span class="request-title siila-blue-color"
      >{{ "global.menu.options.interested" | translate }}
    </span>
    <p class="request-title siila-blue-color">{{ data.title }}?</p>
    <div style="margin-bottom: 18px;">
      <span
        style="color: rgba(0, 0, 0, 0.6); font-size: 15px;"
        translate="global.menu.options.shootUs"
      ></span>
    </div>
  </div>
  <input
    type="text"
    [class.is-invalid]="form.get('email').invalid && form.get('email').touched"
    class="form-control mt-10"
    formControlName="email"
    placeholder="{{ 'email' | translate }} *"
  />
  <div *ngIf="!isMobile" style="display: flex;gap: 8px;">
    <input
      type="text"
      [class.is-invalid]="
        form.get('firstName').invalid && form.get('firstName').touched
      "
      class="form-control mt-10"
      formControlName="firstName"
      placeholder="{{ 'name' | translate }} *"
    />
    <input
      type="text"
      [class.is-invalid]="
        form.get('lastName').invalid && form.get('lastName').touched
      "
      class="form-control mt-10"
      formControlName="lastName"
      placeholder="{{ 'last-name' | translate }} *"
    />
  </div>
  <div *ngIf="isMobile">
    <input
      type="text"
      [class.is-invalid]="
        form.get('firstName').invalid && form.get('firstName').touched
      "
      class="form-control mt-10"
      formControlName="firstName"
      placeholder="{{ 'name' | translate }} *"
    />
    <input
      type="text"
      [class.is-invalid]="
        form.get('lastName').invalid && form.get('lastName').touched
      "
      class="form-control mt-10"
      formControlName="lastName"
      placeholder="{{ 'last-name' | translate }} *"
    />
  </div>

  <input
    type="text"
    [class.is-invalid]="
      form.get('company').invalid && form.get('company').touched
    "
    class="form-control mt-10"
    formControlName="company"
    placeholder="{{ 'global.newsForm.company' | translate }} *"
  />
  <input
    type="text"
    class="form-control mt-10"
    formControlName="position"
    placeholder="{{ 'position' | translate }}"
  />
  <textarea
    class="form-control mt-10"
    name="description"
    placeholder="{{ 'global.newsForm.description' | translate }}"
    formControlName="description"
  ></textarea>

  <div *ngIf="imgCaptcha != '' && imgCaptcha != undefined" class="row mt-10">
    <div>
      <img
        style="width: 200px;margin-left: 3px;"
        src="{{ imgCaptcha }}"
        alt=""
      />
      <span
        (click)="generateCaptcha()"
        style="color: rgba(0, 0, 0, 0.6); font-size: 15px;cursor: pointer;margin-left: 10px;color: #007bff;
       "
        >{{ "global.newsForm.reloadImg" | translate }}</span
      >
    </div>

    <div
      style="text-align: center; 
    margin-top: -11px;
    padding: 15px;"
    >
      <input
        formControlName="inputCaptcha"
        class="form-control"
        placeholder="{{ 'global.newsForm.captchaPlaceholder' | translate }}"
        type="text"
      />
    </div>
    <label
      class="mt-2"
      style="color: red;"
      for=""
      *ngIf="errorCaptcha && form.controls.inputCaptcha.value == ''"
      >{{ "global.newsForm.verificationText" | translate }}</label
    >
    <label
      class="mt-2"
      style="color: red;"
      for=""
      *ngIf="
        errorCaptcha &&
        form.controls.inputCaptcha.value != '' &&
        form.controls.inputCaptcha.value != textCaptcha
      "
      >{{ "global.newsForm.verifyText" | translate }}</label
    >
  </div>

  <div class="btn-request mt-10">
    <button
      aria-label="submit"
      class="siila-submit"
      mat-button
      style="font-size: 12px;width:100%;"
      [disabled]="form.invalid"
      (click)="submitDemo()"
    >
      <span translate="sendMessage"></span>
    </button>
  </div>
  <br />
  <mat-checkbox
    *ngIf="currentCode != 'latam' && currentCode != 'co'"
    [ngClass]="
      form.value.createSpotAccount == true ? 'checkBlue' : 'checkWhite'
    "
    class="size"
    style="white-space: break-spaces;"
    formControlName="createSpotAccount"
    >{{ "global.newsForm.createSpotAccount" | translate }}</mat-checkbox
  >

  <mat-checkbox
    [ngClass]="form.value.joinMailingList == true ? 'checkBlue' : 'checkWhite'"
    class="size"
    style="white-space: break-spaces;"
    formControlName="joinMailingList"
    >{{ "global.newsForm.joinMailingList" | translate }}</mat-checkbox
  >

  <div style="padding-top:6px;">
    <span
      class="siila-blue-color"
      style="font-size: 12px;"
      [innerHTML]="'global.newsForm.terms' | translate"
    ></span>
  </div>
</form>
