<!-- <mat-card class="mat-card" (click)="navigateToNewsDetail(article.id)">
  <img
    mat-card-image
    [alt]="getArticleTitle()"
    [title]="getArticleTitle()"
    [src]="getMediumImg(article.mainImageId)"
    default="assets/img/defaultImage.png"
  />
  <div class="blue-spacer"></div>
  <p class="article-title">{{ getArticleTitle() }}</p>
  <div class="bottom">
    <p class="source-date">{{ article.storySource }}</p>
    <p class="source-date" *ngIf="getLanguage() === 'en'">
      {{ article.storyDate | date: "fullDate":"":"en-US" }}
    </p>
    <p class="source-date" *ngIf="getLanguage() === 'es'">
      {{ article.storyDate | date: "fullDate":"":"es-MX" }}
    </p>
    <p class="source-date first-letter" *ngIf="getLanguage() === 'pt'">
      {{ article.storyDate | date: "fullDate":"":"pt-BR" }}
    </p>
  </div>
</mat-card> -->

<mat-card class="mat-card" (click)="openNews(article2.id, article2)">
  <img
    mat-card-image
    alt="story"
    [src]="
      article2?.headerImage?.fullPath
        ? headerImageNews(article2?.headerImage?.fullPath)
        : ''
    "
    [alt]="getAltTxt()"
    [title]="getAltTxt()"
    default="assets/img/defaultImage.png"
  />
  <div class="blue-spacer"></div>
  <p *ngIf="currentCode != 'co'" class="article-title">
    {{ article2.headline | langSelector }}
  </p>
  <p *ngIf="currentCode == 'co'" class="article-title">
    {{ translateNews(article2.headline) }}
  </p>
  <div class="bottom">
    <p class="source-date" *ngIf="getLanguage() === 'en'">
      {{ formatDate(article2.publishDate, "MM/dd/yyyy") }}
    </p>
    <p class="source-date" *ngIf="getLanguage() === 'es'">
      {{ formatDate(article2.publishDate, "dd/MM/yyyy") }}
    </p>
    <p class="source-date" *ngIf="getLanguage() === 'pt'">
      {{ formatDate(article2.publishDate, "dd/MM/yyyy") }}
    </p>
  </div>
</mat-card>
