import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-pillars",
  templateUrl: "./pillars.component.html",
  styleUrls: ["./pillars.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PillarsComponent implements OnInit {
  pillarCheck = [];
  pillarPath: string;
  currentCode: string;

  constructor() {
    this.currentCode = environment.platformId;
    this.pillarPath = `home.${this.currentCode}.pillars.pillar-check.`;
    this.pillarCheck = [...Array(6).keys()].map(i => {
      return {
        title: `${this.pillarPath}check${i + 1}.title`,
        desc: `${this.pillarPath}check${i + 1}.desc`
      };
    });
  }

  ngOnInit(): void {}
}
