<div class="width-100 text-center">
  <div class="forgot-password">
    <div class="title-section text-left bottom-border container-fluid">
      <h3>Change Password</h3>
    </div>
    <form [formGroup]="registerForm" name="registerForm" autocomplete="off">
      <div class="section-properties" formGroupName="emailGroup">
        <p class="text-left">
          Choose a strong password and don't reuse it for other accounts.
        </p>

        <div class="form-group">
          <input
            type="password"
            id="password"
            name="password"
            class="form-control input-border"
            placeholder="Password"
            [ngClass]="{
              'red-border':
                !registerForm.get('emailGroup.password').pristine &&
                registerForm.errors?.passwordLength
            }"
            min="6"
            formControlName="password"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            id="confirmPass"
            class="form-control input-border"
            min="6"
            [ngClass]="{
              'red-border':
                !registerForm.get('emailGroup.confirmPass').pristine &&
                registerForm.errors?.passwordMatch
            }"
            name="confirmPass"
            formControlName="confirmPass"
            placeholder="Confirm Password"
          />
        </div>
        <div class="flex-row" style="justify-content:space-evenly;">
          <button
            class="siila-submit login-btn block-btn"
            mat-raised-button
            (click)="changePassword()"
            [disabled]="
              registerForm.errors?.required ||
              registerForm.errors?.passwordLength ||
              registerForm.errors?.passwordMatch
            "
          >
            CHANGE PASSWORD
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
