export class ResponsiveSlideHelper {
  public static SLIDE_BREAKPOINTS = [
    "(max-width: 999px)",
    "(max-width: 1000px)",
    "(max-width: 1300px)",
    "(max-width: 1400px)",
    "(max-width: 1500px)"
  ];

  public static matchMediaWidth(mediaWidths: any) {
    const mediaMatchResults = Object.keys(mediaWidths.breakpoints).map(k => {
      return {
        key: k,
        breakpoint: mediaWidths.breakpoints[k]
      };
    });

    const requestAmount = mediaMatchResults => {
      let result;
      if (mediaMatchResults.every(match => !match.breakpoint)) {
        result = 5;
      } else {
        const match = mediaMatchResults.find(media => media.breakpoint);
        switch (match.key) {
          case this.SLIDE_BREAKPOINTS[4]:
            result = 4;
            break;
          case this.SLIDE_BREAKPOINTS[3]:
            result = 3;
            break;
          case this.SLIDE_BREAKPOINTS[2]:
            result = 2;
            break;
          case this.SLIDE_BREAKPOINTS[1]:
            result = 1;
            break;
          case this.SLIDE_BREAKPOINTS[0]:
            result = 1;
            break;
        }
      }
      return result;
    };

    return requestAmount(mediaMatchResults);
  }

  constructor() {}

  public static get DEFAULT_SLIDE_CONFIG() {
    return {
      arrows: false,
      mobileFirst: true,
      infinite: false,
      variableWidth: true,
      swipe: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        }
      ]
    };
  }
  public static get INFINITE_SLIDE_CONFIG() {
    return {
      arrows: false,
      mobileFirst: true,
      infinite: true,
      swipe: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        }
      ]
    };
  }

  public static get DEFAULT_SLIDE_CONFIG_ACADEMY() {
    return {
      arrows: false,
      mobileFirst: true,
      infinite: false,
      variableWidth: true,
      swipe: true,
      responsive: [
        {
          breakpoint: 1980,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 4,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        }
      ]
    };
  }
}
