import {
  NgModule,
  Component,
  Optional,
  SkipSelf,
  InjectionToken
} from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  ErrorDialogService,
  SafariPromptDialogService,
  LoginDialogService,
  DemoDialogService,
  ContactDialogService,
  DeviceRegistrationDialogService
} from "./services/dialog";

import { ForgotDialogService } from "./services/dialog/forgot-dialog.service";
import { InfoDialogService } from "./services/dialog/info-dialog.service";
import { SnackBar } from "./services/snack-bar.service";
import { CookieService } from "ngx-cookie-service";
import { AccountService } from "./auth/account.service";
import { NewFeaturesService } from "./services/new-features.service";
import { WindowRef } from "../window-ref.service";
import { Notifications } from "./services/notifications.service";
import { AuthenticationService } from "./auth/authentication.service";
import { NewsService } from "./services/news.service";
import { ImageService } from "./services/image.service";
import { ListingsService } from "./services/listings.service";
import { DeviceRegistrationService } from "./services/device-registration.service";
import { FooterComponent } from "./footer/footer.component";
import { ToastNotificationService } from "./services/toast-notification.service";
import { MailChimpService } from "./services/mail-chimp.service";
import { SharedModule } from "../shared/shared.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  RouterModule,
  PreloadAllModules,
  ActivatedRouteSnapshot
} from "@angular/router";
import { routes, RouteProvider } from "./core.routing.module";
import { EnsureModuleLoadedOnceGuard, AuthGuard } from "./guards/";
import { PagerService } from "./services/pager.service";
import { MaterialModule } from "../material.module";
import { ChangePasswordService } from "../shared/components/static-pages/change-password/change-password.service";
import { CrossStorageService } from "./auth";
import { WebsocketModule } from "../websockets";
import { environment } from "../../environments/environment";
import { SiilaTranslationService } from "./services/siila-translation.service";
import { AnalyticsService } from "../shared/services/analytics.service";
import { NewVersionService } from "./services/dialog/new-version.service";
import { UserActivityService } from "./services/user-activity.service";
import { NewsFeatureModule } from "../modules/news/news.module";
import { StaticImagesSEOService } from "./services/static-images.service";

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    TranslateModule,
    MaterialModule,
    WebsocketModule.config({
      url: environment.ws
    }),
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabled"
    }),
    NewsFeatureModule
  ],
  exports: [FooterComponent, RouterModule],
  providers: [
    RouteProvider,
    AnalyticsService,
    SnackBar,
    CookieService,
    AccountService,
    ChangePasswordService,
    NewFeaturesService,
    WindowRef,
    Notifications,
    AuthenticationService,
    UserActivityService,
    NewsService,
    ImageService,
    ListingsService,
    LoginDialogService,
    DeviceRegistrationService,
    ToastNotificationService,
    SafariPromptDialogService,
    MailChimpService,
    DemoDialogService,
    ContactDialogService,
    DeviceRegistrationDialogService,
    ForgotDialogService,
    ErrorDialogService,
    InfoDialogService,
    PagerService,
    AuthGuard,
    CrossStorageService,
    SiilaTranslationService,
    NewVersionService,
    StaticImagesSEOService
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  //Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
