import { Component, Input } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { CapitalMarketsValues } from "../capital-markets.model";

@Component({
  selector: "app-currency-values",
  templateUrl: "./currency-values.component.html",
  styleUrls: ["./currency-values.component.scss"]
})
export class CurrencyValuesComponent {
  @Input() usdVal: number;
  @Input() usdPercent: number;
  @Input() canVal: number;
  @Input() canPercent: number;
  @Input() euroVal: number;
  @Input() euroPercent: number;
  @Input() capitalMarket: CapitalMarketsValues;
  currentCode: string;

  constructor() {
    this.currentCode = environment.platformId;
  }
}
