import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { Subscription } from "rxjs";
import * as e from "express";
import { NewsService } from "../../../../core/services/news.service";
@Component({
  selector: "app-language-menu",
  templateUrl: "./language-menu.component.html",
  styleUrls: ["./language-menu.component.scss"]
})
export class LanguageSelectionComponent implements OnInit {
  isMobile = false;
  responsiveSub: Subscription;

  public selectedLanguage: string;
  public currentRegion: string;
  public isHandsetMode: boolean = false;
  private languageLinks: { en: string; es: string; pt: string } = {
    en: "navLinks.english",
    es: "navLinks.spanish",
    pt: "navLinks.portuguese"
  };
  currentCode: string;

  constructor(
    private breakPointObserver: BreakpointObserver,
    private siilaTranslationService: SiilaTranslationService,
    private responsive: BreakpointObserver,
    private newsService: NewsService
  ) {
    this.breakPointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isHandsetMode = result.matches;
    });
    this.currentCode = environment.platformId;
  }

  ngOnInit() {
    const language: string = this.siilaTranslationService.getCurrentLanguage();
    this.selectedLanguage = this.languageLinks[language];
    this.responsiveChanges();
  }

  setupLanguage(language: string) {
    this.siilaTranslationService.setLanguage(language);
    this.selectedLanguage = this.languageLinks[language];
    this.newsService.notifiChnagesLang();
    window.location.reload();
  }

  public isItemActive(language: string) {
    return this.selectedLanguage.toLowerCase() ===
      this.languageLinks[language].toLowerCase()
      ? "active-menu-item"
      : "";
  }

  public isCurrentRegion(language: string) {
    return environment.applicationURL.includes(language);
  }

  public isLocalStorageSupported() {
    var test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }
}
