import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { PowerBiReports, TokenData } from "../models/powerbi.model";

@Injectable({ providedIn: "root" })
export class PowerBiService {
  powerBiBaseUrl: string = "https://app.powerbi.com/reportEmbed";
  powerBiReports: PowerBiReports = null;
  tokensByReportId: { [key: number]: TokenData } = {};

  constructor(private http: HttpClient) {}

  prefetchReports() {
    if (!this.powerBiReports) {
      this.http
        .get<PowerBiReports>(`${environment.apiURL}/powerbi/getReports`)
        .subscribe((reports: PowerBiReports) => {
          this.powerBiReports = reports;
        });
    }
  }

  async getPowerBiReports(fromServer?: boolean): Promise<PowerBiReports> {
    if (fromServer || !this.powerBiReports) {
      try {
        const reports = await this.http
          .get<PowerBiReports>(`${environment.apiURL}/powerbi/getReports`)
          .toPromise();
        this.powerBiReports = reports;
      } catch {
        this.powerBiReports = null;
      }
    }
    return this.powerBiReports;
  }

  async getPowerbiEmbedToken(
    reportId: string,
    reports: PowerBiReports,
    fromServer?: boolean
  ): Promise<TokenData> {
    if (fromServer || !this.tokensByReportId[reportId]) {
      try {
        const tokenData = await this.http
          .post(
            `${environment.apiURL}/powerbi/getToken/report/${reportId}`,
            reports
          )
          .toPromise();
        this.tokensByReportId[reportId] = tokenData;
      } catch {
        delete this.tokensByReportId[reportId];
      }
    }
    return this.tokensByReportId[reportId];
  }
}
