import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-solution-layout",
  templateUrl: "./solution-layout.component.html",
  styleUrls: ["./solution-layout.component.scss"]
})
export class SolutionLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
