<div
  class="home-card-carousel"
  [ngStyle]="{ height: buildings?.length == 0 ? '198px' : 'auto' }"
>
  <div class="carousel-container">
    <app-loading-spinner *ngIf="buildings?.length == 0"></app-loading-spinner>
    <ngx-slick-carousel
      id="slick-listings-detail"
      #slickCarouselDetail
      #slickModal="slick-carousel"
      [config]="slideConfig"
      class="app-gradient carousel slick-carousel-tile carousel-dimensions"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)"
    >
      <div ngxSlickItem *ngFor="let building of buildings" class="slide">
        <featured-listing-card
          [listing]="building"
          [latamCountry]="latamCountry"
          [detail]="detail"
          [hideLogo]="hideLogo"
        ></featured-listing-card>
      </div>
    </ngx-slick-carousel>
    <button
      mat-basic-button
      #videoPrev
      aria-label="left arrow"
      class="news-leftLs btnCard"
      (click)="loadPage('back', slickCarouselDetail)"
      *ngIf="slickCarouselDetail.currentIndex && !handsetMode"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-basic-button
      #videoNext
      aria-label="right arrow"
      class="xxrightarrow news-rightLs btnCard"
      (click)="loadPage('next', slickCarouselDetail)"
      [disabled]="disableNext"
      *ngIf="!lastSlideActiveCehck && !handsetMode"
    >
      <mat-icon *ngIf="!disableNext">chevron_right</mat-icon>
    </button>
    <span
      class="flex-row progress-display"
      [hidden]="!getSlideProgress().length"
      >{{ getSlideProgress() }}</span
    >
  </div>
  <!--  <div class="banner mb-3" *ngIf="currentCode == 'co'">
    <span
      translate="products.{{
        currentCode
      }}.products.marketing-card.marketing-product.comingSoon"
    >
    </span>
  </div> -->
</div>
