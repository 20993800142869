import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightSearch"
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, args: string): string {
    if (!args) {
      return value;
    }

    let value2 = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    let args2 = args
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const index = value2.indexOf(args2);

    if (index === -1) {
      return value;
    }

    const endIndex = index + args2.length;
    if (index == 0 && index < 20) {
      return (
        value.substring(0, index) +
        '<span class="highlight-news">' +
        value.substring(index, endIndex) +
        "</span>" +
        value.substring(endIndex)
      );
    } else {
      return (
        "..." +
        value.substring(index - 20, index) +
        '<span class="highlight-news">' +
        value.substring(index, endIndex) +
        "</span>" +
        value.substring(endIndex)
      );
    }
  }
}
