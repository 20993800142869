import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { AuthenticationService } from "../auth";

@Injectable()
export class AuthGuard implements CanActivate {
  private isLoggedIn$: BehaviorSubject<boolean>;

  constructor(private _auth: AuthenticationService) {
    this.isLoggedIn$ = this._auth.$isLoginSubject;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated();
  }

  isAuthenticated() {
    return this.isLoggedIn$.value;
  }
}
