import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { Location } from "@angular/common";

@Component({
  selector: "terms-of-use",
  templateUrl: "./terms-of-use.component.html",
  styleUrls: ["./terms-of-use.component.scss"]
})
export class TermsOfUseComponent implements OnInit {
  browserLang: string = "pt";
  currentEnv: string;
  routerSub: Subscription;
  constructor(
    private siilaTranslationService: SiilaTranslationService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.currentEnv = environment.platformId;
    this.routerSub = this.route.paramMap.subscribe(params => {
      this.browserLang = params.get("lang");
      if (this.browserLang !== undefined && this.browserLang !== null) {
        this.siilaTranslationService.setLanguage(
          this.browserLang == undefined ? "en" : this.browserLang
        );
        this.location.replaceState("/terms-of-use");
      }
    });
  }

  ngOnInit(): void {}
}
