<div style="min-height:100%;" *ngIf="article && articleId" class="story-detail">
  <app-breadcrumb-nav
    [handsetMode]="handsetMode"
    [textLabel]="'NEWS HOME'"
    [returnFragment]="'news'"
    [blueButtons]="true"
  >
  </app-breadcrumb-nav>
  <div
    [ngStyle]="{
      'justify-content': handsetMode ? 'space-between' : 'flex-end'
    }"
    style="display:flex;"
  >
    <button
      class="siila-blue-submit"
      mat-raised-button
      [ngStyle]="{ 'margin-left': handsetMode ? '20px' : '5px' }"
      style="height:36;width:90px;"
      (click)="navigateToPrevious()"
      *ngIf="currentPosition.hasPrevious"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button
      class="siila-blue-submit"
      mat-raised-button
      [ngStyle]="{
        'margin-left': !currentPosition.hasPrevious ? '18px' : '5px'
      }"
      style="margin-right:5px;height:36;width:90px;"
      (click)="navigateToNext()"
      *ngIf="currentPosition.hasNext"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
  <h2 style="padding:10px;" localize [textValue]="article.title"></h2>
  <div style="width:100%;">
    <p style="padding:10px;">{{ article.storyDate }}</p>

    <div
      class="slick-carousel-segment"
      [ngStyle]="{ 'flex-direction': handsetMode ? 'column' : 'row' }"
    >
      <div class="slick-carousel-contain">
        <ngx-slick-carousel
          style="width:100%;"
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div
            ngxSlickItem
            *ngFor="let image of article.storyImages"
            class="slide"
            style="padding-right:30px;padding-left:30px; padding-top: 20px;background-color: #fafafa;"
          >
            <img
              [src]="getMediumImg(image)"
              alt=""
              [ngStyle]="{
                width: handsetMode ? '100%' : '300px',
                'max-height': handsetMode ? '400px' : 'auto'
              }"
            />

            <button
              class="leftRs"
              style="background:#FF9900;"
              *ngIf="article.storyImages.length > 1"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              style="background:#FF9900;"
              class="rightRs"
              *ngIf="article.storyImages.length > 1"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <span>{{ image.caption }}</span>

            <hr />
            <div
              class="social-media-container"
              style="margin-top: 1rem; margin-bottom: 1rem;"
            >
              <div class="social-media-buttons">
                <img
                  *ngFor="let socialLink of socialMediaLinks"
                  class="image-social"
                  [src]="socialLink.img"
                  (click)="shareLink(socialLink)"
                />
              </div>
            </div>
            <div class="cssToolTip">
              <span #toolTip>{{ socialMediaLinks[0].options.title }}</span>
            </div>
            <hr />
            <p style="flex:0.50;">
              {{ "global.menu.entities.newsSource" | translate }}:
              {{ article.storySource }}
            </p>
          </div>
        </ngx-slick-carousel>
      </div>
      <div>
        <p
          style="max-width:1100px;font-size:18px;"
          class="href-color"
          localize
          [textValue]="article.content"
        ></p>
        <div
          class="story-detail__listings"
          *ngIf="article.storyListings && article.storyListings.length"
        >
          <div class="story-detail__listings-header">
            <img src="../../../assets/img/siila-spot-brand-br copy.png" />
            <h4 class="title">
              {{ "global.menu.entities.storyListingsTitle" | translate }}
            </h4>
          </div>
          <div class="story-detail__listing-cards">
            <story-listing-card
              *ngFor="let story of article.storyListings"
              [storyListing]="story"
            >
            </story-listing-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="width:100%;overflow:hidden; display: flex; flex-direction: column; justify-content: flex-end;"
  >
    <div style="padding:10px;display:flex;justify-content:space-evenly;">
      <div style="flex:1;display:flex;justify-content:flex-end;">
        <button
          class="siila-blue-submit"
          mat-button
          [ngStyle]="{ 'margin-left': handsetMode ? '20px' : '5px' }"
          style="height:36;width:90px;"
          (click)="navigateToPrevious()"
          *ngIf="currentPosition.hasPrevious"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button
          class="siila-blue-submit"
          mat-button
          [ngStyle]="{
            'margin-left': !currentPosition.hasPrevious ? '20px' : '5px'
          }"
          style="margin-right:5px;height:36;width:90px;"
          (click)="navigateToNext()"
          *ngIf="currentPosition.hasNext"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
