<div class="index-nav">
  <div
    *ngIf="!isExpanded"
    class="index-icon"
    (click)="playButton(isExpanded)"
    onClick="document.getElementById('mymarquee').stop();"
  >
    <mat-icon>pause</mat-icon>
  </div>
  <div
    *ngIf="isExpanded"
    class="index-icon"
    (click)="playButton(isExpanded)"
    onClick="document.getElementById('mymarquee').start();"
  >
    <mat-icon>play_arrow</mat-icon>
  </div>
  <marquee
    *ngIf="isMobile"
    behavior="scroll"
    direction="left"
    onmouseover="this.stop();"
    onmouseout="this.start();"
    id="mymarquee"
    scrolldelay="190"
  >
    <app-capital-markets-section></app-capital-markets-section>
  </marquee>

  <marquee
    *ngIf="!isMobile"
    behavior="scroll"
    direction="left"
    onmouseover="this.stop();"
    onmouseout="this.start();"
    id="mymarquee"
    scrolldelay="90"
  >
    <app-capital-markets-section></app-capital-markets-section>
  </marquee>
</div>
<section class="home-container">
  <div
    *ngIf="!portraitMode; else headerMobile"
    class="home-card mat-elevation-z15"
    [ngClass]="{
      'home-card-pt': browserLang === 'pt',
      'background-br': currentCode === 'br' || currentCode === 'latam'
    }"
  >
    <div class="home-info">
      <h1
        class="home-title"
        translate="home.{{ currentCode }}.mainHeadline"
      ></h1>
      <h2
        class="home-sub-title"
        translate="home.{{ currentCode }}.secondaryHeadline"
      ></h2>
      <div class="buttons-home">
        <button mat-flat-button class="btn-home" (click)="playPauseVideo()">
          <span
            *ngIf="browserLang === 'en'"
            class="wistia_embed wistia_async_aogdddka9c popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true"
            style="display:inline;position:relative"
            ><a class="popupVideoButton" style="color: black">{{
              "what" | translate
            }}</a></span
          >
          <span
            *ngIf="browserLang === 'es'"
            class="wistia_embed wistia_async_g5dkefxng2 popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true"
            style="display:inline;position:relative"
            ><a class="popupVideoButton" style="color: black">{{
              "what" | translate
            }}</a></span
          >
          <span
            *ngIf="browserLang === 'pt'"
            class="wistia_embed wistia_async_d50duchn28 popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true"
            style="display:inline;position:relative"
            ><a class="popupVideoButton" style="color: black">{{
              "what" | translate
            }}</a></span
          >
          <mat-icon style="padding-left: 5px;">play_circle</mat-icon>
        </button>
        <button
          mat-flat-button
          class="btn-home"
          (click)="playPauseVideo()"
          *ngIf="false"
        >
          {{ "what" | translate }}
          <mat-icon style="padding-left: 5px;">play_circle</mat-icon>
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="openDemoDialog()"
          class="btn-home-more"
        >
          {{ "demo" | translate }}
        </button>
      </div>
    </div>
    <div class="home-img">
      <div
        style="width:500px"
        *ngIf="browserLang === 'en' && currentCode === 'br'"
      >
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_m9jprhk22h seo=false videoFoam=true test"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/m9jprhk22h/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width:500px"
        *ngIf="browserLang === 'en' && currentCode != 'br'"
      >
        <div
          id="test"
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_9byr8fyho1 seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/9byr8fyho1/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:500px" *ngIf="browserLang === 'es'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_3orowoyw8h seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/3orowoyw8h/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:500px" *ngIf="browserLang === 'pt'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_d5ne2f8jzh seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/d5ne2f8jzh/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #headerMobile>
    <div class=" home-info home-card mat-elevation-z15">
      <h1
        class="home-title"
        translate="home.{{ currentCode }}.mainHeadline"
      ></h1>
      <h2
        class="home-sub-title"
        translate="home.{{ currentCode }}.secondaryHeadline"
      ></h2>
      <div class="home-img">
        <div *ngIf="browserLang === 'en' && currentCode === 'br'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_m9jprhk22h seo=false videoFoam=true test"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/m9jprhk22h/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="browserLang === 'en' && currentCode != 'br'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                id=""
                class="wistia_embed wistia_async_9byr8fyho1 seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/9byr8fyho1/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="browserLang === 'es'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_3orowoyw8h seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/3orowoyw8h/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="browserLang === 'pt'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_d5ne2f8jzh seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/d5ne2f8jzh/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-home">
        <button mat-flat-button class="btn-home">
          <span
            *ngIf="browserLang === 'en'"
            class="wistia_embed wistia_async_aogdddka9c popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true"
            style="display:inline;position:relative"
            ><a style="color: black">{{ "what" | translate }}</a></span
          >
          <span
            *ngIf="browserLang === 'es'"
            class="wistia_embed wistia_async_g5dkefxng2 popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true"
            style="display:inline;position:relative"
            ><a style="color: black">{{ "what" | translate }}</a></span
          >
          <span
            *ngIf="browserLang === 'pt'"
            class="wistia_embed wistia_async_d50duchn28 popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true"
            style="display:inline;position:relative"
            ><a style="color: black">{{ "what" | translate }}</a></span
          >
          <mat-icon style="padding-left: 5px;">play_circle</mat-icon>
        </button>
        <button
          mat-flat-button
          color="accent"
          (click)="openDemoDialog()"
          class="btn-home-more"
        >
          {{ "demo" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
  <app-state-market [val]="1"></app-state-market>
  <industry-news
    id="news"
    #news
    class="industry-container mat-elevation-z15"
  ></industry-news>
  <div class="home-card-comercial mat-elevation-z15">
    <div class="commercial-info">
      <h1
        class="commercial-title"
        translate="home.{{ currentCode }}.commercial.title"
      ></h1>
    </div>
    <div class="commercial-data">
      <div class="card-data-left">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          +{{ 20000 | numberSuffix }}
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">+{{ 20 }} mil</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.industrial"
        ></h1>
      </div>
      <div class="card-data-middle">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          +USD${{ 72000000000 | numberSuffix }}
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">
          +USD$ {{ 720 }} bilhões
        </p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.sale"
        ></h1>
      </div>
      <div class="card-data-right">
        <p class="number-style">{{ 85 }}%</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.latam"
        ></h1>
      </div>
      <div class="card-data-left">
        <p class="number-style">{{ 3 }}</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.countries"
        ></h1>
      </div>
      <div class="card-data-middle">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          {{ 180000000 | numberSuffix }} m²
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">
          +{{ 180 }} milhões m²
        </p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.Analyzed"
        ></h1>
      </div>
      <div class="card-data-right">
        <p
          *ngIf="browserLang === 'en' || browserLang === 'es'"
          class="number-style"
        >
          +{{ 120000 | numberSuffix }}
        </p>
        <p *ngIf="browserLang === 'pt'" class="number-style">+{{ 120 }} mil</p>
        <div class="home-line-orange"></div>
        <h1
          class="data-style"
          translate="home.{{ currentCode }}.commercial.data.Tenants"
        ></h1>
      </div>
    </div>
  </div>
  <div>
    <div
      class="home-card-spot mat-elevation-z15"
      *ngIf="!portraitMode; else mobile"
    >
      <div class="siila-menu">
        <img class="logo-menu" alt="siila" [src]="spotLogo" />
        <h1
          class="home-spot-title"
          translate="home.{{ currentCode }}.viewProperties"
        ></h1>
      </div>
      <div class="search-label">
        <div
          class="content-select"
          *ngIf="currentCode == 'latam' || currentCode == 'co'"
        >
          <select
            style="width: 170px;"
            class="option-label"
            [(ngModel)]="latamCountry"
          >
            <option value="latamMx">{{
              "navLinks.siilaMexico" | translate
            }}</option>
            <option value="latamBr">{{
              "navLinks.siilaBrasil" | translate
            }}</option>
          </select>
          <i></i>
        </div>
        <div class="content-select">
          <select
            [ngStyle]="{ width: currentCode == 'latam' ? '170' : '250px' }"
            class="option-label"
            [(ngModel)]="SaleLease"
          >
            <option value="sale">{{ "global.forSale" | translate }}</option>
            <option value="lease">{{ "global.forLease" | translate }}</option>
          </select>
          <i></i>
        </div>
        <div class="content-select">
          <select
            [ngStyle]="{ width: currentCode == 'latam' ? '170' : '250px' }"
            [(ngModel)]="propertyType"
            class="option-label"
          >
            <option value="office">{{ "global.office" | translate }}</option>
            <option value="warehouse">{{
              "global.industrial" | translate
            }}</option>
            <option value="coworking">{{
              "global.coworkingLabel" | translate
            }}</option>
          </select>
          <i></i>
        </div>
        <input
          class="email-label"
          type="email"
          [(ngModel)]="property"
          placeholder="{{ 'search' | translate }}"
        />
        <div class="search">
          <button
            (click)="openSearch()"
            mat-flat-button
            color="accent"
            class="btn-search"
          >
            {{ "looking" | translate }}
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="title-content">
      <h1
        class="commercial-title siila-menu-color"
        translate="home.{{ currentCode }}.commercial.properties-title2"
      ></h1>
      <h2
        class="sub-title-propie"
        translate="home.{{ currentCode }}.commercial.properties-sub-title"
      ></h2>
    </div>
    <featured-listings
      [latamCountry]="latamCountry"
      *ngIf="currentCode == 'latam' || currentCode == 'co'"
    ></featured-listings>
    <featured-listings
      *ngIf="currentCode != 'latam' && currentCode != 'co'"
    ></featured-listings>
  </div>
  <ng-template #mobile>
    <div
      [ngClass]="
        currentCode == 'latam' || currentCode == 'co'
          ? 'home-card-spot-latam'
          : 'home-card-spot'
      "
    >
      <div class="siila-menu">
        <img class="logo-menu" alt="siila" [src]="spotLogo" />
        <h1
          class="home-spot-title"
          translate="home.{{ currentCode }}.viewProperties"
        ></h1>
      </div>
      <div class="button-container">
        <div
          [ngClass]="SaleLease === 'lease' ? 'buttons-sale' : 'buttons-lease'"
        >
          <span class="btn-text" (click)="SaleLease = 'lease'">{{
            "global.forLease" | translate
          }}</span>
        </div>
        <div
          [ngClass]="SaleLease !== 'lease' ? 'buttons-sale' : 'buttons-lease'"
        >
          <span class="btn-text" (click)="SaleLease = 'sale'">{{
            "global.forSale" | translate
          }}</span>
        </div>
      </div>
      <div
        [ngClass]="
          currentCode == 'latam' || currentCode == 'co'
            ? 'search-label-latam'
            : 'search-label'
        "
      >
        <div
          class="content-select"
          *ngIf="currentCode == 'latam' || currentCode == 'co'"
        >
          <select class="option-label" [(ngModel)]="latamCountry">
            <option value="latamMx">{{
              "navLinks.siilaMexico" | translate
            }}</option>
            <option value="latamBr">{{
              "navLinks.siilaBrasil" | translate
            }}</option>
          </select>
          <i></i>
        </div>
        <div class="content-select">
          <select [(ngModel)]="propertyType" class="option-label">
            <option value="office">{{ "global.office" | translate }}</option>
            <option value="warehouse">{{
              "global.industrial" | translate
            }}</option>
            <option value="coworking">{{
              "global.coworkingLabel" | translate
            }}</option>
          </select>
          <i></i>
        </div>
        <div>
          <input
            class="email-label"
            type="email"
            [(ngModel)]="property"
            placeholder="{{ 'search' | translate }}"
          />
          <button
            (click)="openSearch()"
            mat-flat-button
            color="accent"
            class="btn-search"
          >
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <app-solution-list [val]="1"></app-solution-list>
  <app-pillars></app-pillars>
  <app-industry></app-industry>
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
