<div class="value-container">
  <h6 class="badge" translate="global.siilaIndex.ifixTitle"></h6>
  <div>
    <span class="badge" [negativePositiveBg]="ifixPercent">
      {{ (ifixPercent | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge">
      {{ (ifixVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
</div>
