<mat-toolbar class="breadcrumb-bar">
  <mat-toolbar-row class="breadcrumb-main-row">
    <button
      [ngClass]="{ 'blue-btn': blueButtons }"
      class="siila-submit button-margin"
      (click)="back()"
      mat-raised-button
    >
      <mat-icon>subdirectory_arrow_left</mat-icon>
      <span [translate]="'back'"></span>
    </button>
    <button
      [ngClass]="{ 'blue-btn': blueButtons }"
      class="siila-submit deep-button-margin"
      (click)="returnToFragment()"
      mat-raised-button
    >
      <fa-icon
        class="icon-display"
        [icon]="additionalIcon.iconRef"
        size="lg"
      ></fa-icon>
      <span [translate]="textLabel"></span>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
