import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "../../../core/services/image.service";
import { environment } from "../../../../environments/environment";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

@Component({
  selector: "story-listing-card",
  templateUrl: "./story-listing-card.component.html",
  styleUrls: ["./story-listing-card.component.scss"]
})
export class StoryListingCardComponent implements OnInit {
  constructor(
    private imageService: ImageService,
    private siilaTranslationService: SiilaTranslationService,
    private translate: TranslateService
  ) {}
  @Input() storyListing: StoryListng;

  storyListingInfo: StoryListngData | ListingInfoDTO;
  spotListingLink: string;
  storyListingImage: string;
  listingTitle: string;
  listingName: string;
  listingType: string;
  private listingTypeOffers: {} = {
    L: "forLease",
    S: "forSale",
    C: "forCoworking"
  };

  ngOnInit() {
    switch (this.storyListing.listingUrlType) {
      case "SEARCH_LINK":
        this.storyListingInfo = this.storyListing.listingCarouselData;
        this.spotListingLink =
          environment.spotURL + "/search" + this.storyListing.listingUrl;
        this.storyListingImage = this.imageService.mediumThumbnail(
          this.storyListingInfo.imageId
        );
        this.listingName = this.storyListingInfo.displayHeading;

        var listingType: string = this.siilaTranslationService.getTranslation(
          this.storyListingInfo.displayName
        );
        var type: string = this.storyListingInfo.carouselType;
        this.listingType = `${listingType}: ${type}`;
        break;
      case "SINGLE":
        this.storyListingInfo = this.storyListing.listingInfoDTO;
        this.spotListingLink = this.storyListing.listingUrl;
        this.storyListingImage = this.imageService.mediumThumbnail(
          this.storyListingInfo.firstPhoto
        );
        this.listingName = this.storyListingInfo.buildingName;
        this.listingTitle = this.storyListingInfo.title;

        var type: string = this.siilaTranslationService.getTranslation(
          this.storyListingInfo.buildingType.name
        );
        var listingSubTypes: string = (
          this.storyListingInfo.propertySubTypes || []
        )
          .map(subType => this.siilaTranslationService.getTranslation(subType))
          .join(", ");
        console.log({ listingSubTypes });

        this.translate
          .get(
            `global.listing-card.${
              this.listingTypeOffers[this.storyListingInfo.isForLeaseSale]
            }`,
            { colon: listingSubTypes ? ":" : "" }
          )
          .subscribe((res: string) => {
            this.listingType = `${type} ${res} ${listingSubTypes}`;
          });
        break;
      default:
        break;
    }
  }
}

interface StoryListngData {
  id: number;
  displayHeading: string;
  displayName: string;
  carouselType: string;
  imageId: number;
  searchUrl: string;
  displayOrder: number;
}

interface ListingInfoDTO {
  firstPhoto: number;
  buildingName: string;
  isForLeaseSale: string;
  propertySubTypes: string[];
  title: string;
  buildingType: { id: number; name: string };
}

interface StoryListng {
  id: number;
  order: number;
  carouselDataId: number;
  listingUrlType: string;
  listingUrl: string;
  listingInfoDTO: ListingInfoDTO;
  listingCarouselData: StoryListngData;
}
