import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class ChangePasswordService {
  private changePasswordEndpoint: string = `${environment.apiURL}/account/forced-change-password`;
  private keyForChangePassword: String;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  public changePassword(body) {
    return this.http.post(`${this.changePasswordEndpoint}`, body).toPromise();
  }

  setKeyForChangePassword(key: String) {
    this.keyForChangePassword = key;
  }

  getKeyForChangePassword() {
    return this.keyForChangePassword;
  }
}
