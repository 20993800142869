import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";

@Component({
  selector: "app-member",
  templateUrl: "./member.component.html",
  styleUrls: ["./member.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberComponent implements OnInit {
  @Input() name: string;
  @Input() job: string;
  @Input() email: string;
  @Input() emailText: string;
  @Input() icon: string;
  @Input() background: string;
  @Input() link: string;
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
