<section *ngIf="product" class="grocs-container">
  <div *ngIf="!isMobile; else mobile" class="card-grocs mat-elevation-z15">
    <div
      [ngClass]="{
        'grocs-extra-content': this.product.headerExtraContent,
        'grocs-no-content': !this.product.headerExtraContent
      }"
    >
      <div class="grocs-info">
        <h1 class="title-gnrl">
          {{ product.productShortName | langSelector }}
        </h1>
        <h2 class="title-grocs">{{ product.title | langSelector }}</h2>
        <p class="feature-sub-tittle">{{ product.subtitle | langSelector }}</p>
        <div class="feature-button">
          <button
            (click)="
              openContactDialog(product.productShortName, action.moreInfo)
            "
            *ngIf="product.primaryButtonText"
            mat-flat-button
            color="accent"
            class="btn-more-grocs uppercase"
          >
            {{ product.primaryButtonText | langSelector }}
          </button>
        </div>
      </div>
      <div *ngIf="product.headerExtraContent" style="align-self: center;">
        <img
          id="investorsurvey"
          *ngIf="!isVideo"
          [alt]="picAlt"
          [title]="picAlt"
          [src]="productDescriptionImage(product.headerExtraImage)"
        />
        <div
          *ngIf="
            isVideo &&
            (productId === 102 || productId === 104 || productId === 107)
          "
        >
          <div class="company-video" *ngIf="currentCode === 'br'">
            <div class="video-mobile" *ngIf="browserLang === 'en'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_0pujsh229y seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/0pujsh229y/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'es'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_0pujsh229y seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/0pujsh229y/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'pt'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_5uccxlhk3x seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/5uccxlhk3x/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="company-video"
            *ngIf="currentCode === 'mx' || currentCode === 'latam'"
          >
            <div class="video-mobile" *ngIf="browserLang === 'en'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_wp1xh37x3v seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/wp1xh37x3v/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'es'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_lr74jgba8n seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/lr74jgba8n/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'pt'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_5uccxlhk3x seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/5uccxlhk3x/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #mobile>
    <div *ngIf="this.product.headerExtraContent" class="card-grocs-mobile">
      <div class="grocs-info">
        <h1 class="title-gnrl uppercase">
          {{ product.productShortName | langSelector }}
        </h1>
        <h2 class="title-grocs">{{ product.title | langSelector }}</h2>
        <img
          class="extra-img"
          *ngIf="!isVideo"
          [src]="productDescriptionImage(product.headerExtraImage)"
        />
        <div
          *ngIf="
            isVideo &&
            (productId === 102 || productId === 104 || productId === 107)
          "
        >
          <div class="company-video" *ngIf="currentCode === 'br'">
            <div class="video-mobile" *ngIf="browserLang === 'en'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_0pujsh229y seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/0pujsh229y/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'es'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_mbd0cfj7vr seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/mbd0cfj7vr/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'pt'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_5uccxlhk3x seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/5uccxlhk3x/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="company-video"
            *ngIf="currentCode === 'MX' || currentCode === 'latam'"
          >
            <div class="video-mobile" *ngIf="browserLang === 'en'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_wp1xh37x3v seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/wp1xh37x3v/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'es'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_lr74jgba8n seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/lr74jgba8n/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'pt'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_5uccxlhk3x seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/5uccxlhk3x/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="feature-sub-tittle">{{ product.subtitle | langSelector }}</p>
        <div class="feature-button">
          <button
            *ngIf="product.primaryButtonText"
            mat-flat-button
            (click)="
              openContactDialog(product.productShortName, action.subscribe)
            "
            color="accent"
            class="btn-info-grocs uppercase"
          >
            {{ product.primaryButtonText | langSelector }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!this.product.headerExtraContent" class="card-grocs-mobile">
      <div class="grocs-info">
        <h1 class="title-gnrl uppercase">
          {{ product.productShortName | langSelector }}
        </h1>
        <h2 class="title-grocs">{{ product.title | langSelector }}</h2>
        <p class="feature-sub-tittle">{{ product.subtitle | langSelector }}</p>
        <div class="feature-button">
          <button
            (click)="
              openContactDialog(product.productShortName, action.subscribe)
            "
            *ngIf="product.secondaryButtonText"
            mat-flat-button
            color="accent"
            class="btn-more-grocs uppercase"
          >
            {{ product.secondaryButtonText | langSelector }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="border-card-coverage mat-elevation-z15">
    <div>
      <div class="grocs-description">
        <h1 class="title-gnrl siila-blue-color">
          {{ product.productDescriptionPreTitle | langSelector }}
        </h1>
        <h2
          [innerHTML]="product.productDescriptionTitle | langSelector"
          class="title-feature siila-menu-color"
        ></h2>
      </div>
      <div class="siila-index">
        <div
          [innerHTML]="product.productDescription | langSelector"
          class="feature-sub-grocs siila-grey"
        ></div>
        <div class="grocs-img">
          <img
            style="margin-top: 38px;"
            class="img-mobile index-pic"
            [alt]="indexpic"
            [title]="indexpic"
            [src]="productDescriptionImage(product.productDescriptionImage)"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="product.beneficts?.length > 0"
    class="border-card-white mat-elevation-z15"
  >
    <div class="benefits">
      <div class="flex-row center">
        <h1 class="title-gnrl siila-blue-color">
          {{ "grocs." + currentCode + ".benefit2" | translate }}
          {{ product.productShortName | langSelector }}
        </h1>
        <h1 *ngIf="browserLang === 'es'" class="title-gnrl siila-blue-color">
          ?
        </h1>
      </div>
      <h2 class="title-feature siila-menu-color">
        {{ product.benefictsSectionTitle | langSelector }}
      </h2>
    </div>
    <div
      *ngFor="let benefits of product.beneficts; ;let even = even ;let last = last"
    >
      <div class="products-flex" *ngIf="even; else left">
        <div class="benefit-info-rigth">
          <h1 class="title-gnrl siila-blue-color uppercase">
            {{ benefits.title | langSelector }}
          </h1>
          <h2
            class="feature-benefit siila-menu-color"
            [innerHTML]="benefits.description | langSelector"
          ></h2>
        </div>
        <img
          class="benefit-img-rigth"
          [alt]="productDescriptionImageAlt(benefits)"
          [title]="productDescriptionImageAlt(benefits)"
          [src]="productDescriptionImage(benefits.image)"
        />
      </div>

      <div class="border-line-grocs" *ngIf="!last"></div>

      <ng-template #left>
        <div class="data-pro-flex">
          <img
            class="benefit-img-left"
            [src]="productDescriptionImage(benefits.image)"
            alt]="productDescriptionImageAlt(benefits)"
            [title]="productDescriptionImageAlt(benefits)"
          />
          <div class="benefit-info-left">
            <h1 class="title-gnrl siila-blue-color uppercase">
              {{ benefits.title | langSelector }}
            </h1>
            <h2
              class="feature-benefit siila-menu-color"
              [innerHTML]="benefits.description | langSelector"
            ></h2>
          </div>
        </div>
      </ng-template>
    </div>
    <button
      (click)="openContactDialog(product.productShortName, action.subscribe)"
      *ngIf="productId === 101 || productId === 104; else space"
      mat-flat-button
      color="accent"
      class="btn-sub uppercase"
    >
      {{ "sub" | translate }}
    </button>
    <ng-template #space>
      <div class="space"></div>
    </ng-template>
  </div>
  <div *ngIf="product.assetCoverage" class="card-mapping mat-elevation-z15">
    <div class="map-card">
      <div class="map-info">
        <h1 class="title-gnrl">
          {{ product.assetCoverage.preTitle | langSelector }}
        </h1>
        <h2 class="title-grocs">
          {{ product.assetCoverage.title | langSelector }}
        </h2>
        <p class="feature-sub">
          {{ product.assetCoverage.description | langSelector }}
        </p>
        <div>
          <button
            (click)="
              openContactDialog(product.productShortName, action.moreInfo)
            "
            mat-flat-button
            color="accent"
            class="btn-grocs"
            style="margin: -20px 0px 16px 0px;"
          >
            {{ "moreInfo" | translate }}
          </button>
        </div>
      </div>
      <div class="map-check">
        <h1 class="region-title">
          {{ product.assetCoverage.listTitle | langSelector }}
        </h1>
        <div class="check-grocs-region">
          <div *ngFor="let item of totalAssetItems">
            <div class="asset-items">
              <img class="icon-color" src="assets/icons/check.svg" />
              <div
                [innerHTML]="product.assetCoverage[item] | langSelector"
                class="map-title"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div class="bottom-grocs">
          <button
            (click)="
              openContactDialog(product.productShortName, action.moreInfo)
            "
            mat-flat-button
            color="accent"
            class="btn-grocs"
          >
            {{ "moreInfo" | translate }}
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div
    *ngIf="productId === 102 || productId === 104 || productId === 107"
    class="card-interested mat-elevation-z15"
  >
    <div class="indice-container">
      <div class="interested-info">
        <h1 class="title-gnrl">
          {{ product.productShortName | langSelector }}
        </h1>

        <h2
          class="title-interested"
          [innerHTML]="'grocs.' + currentCode + '.title' | translate"
        ></h2>
        <h3
          class="feature-sub"
          translate="grocs.{{ currentCode }}.sub-title"
        ></h3>
        <button
          (click)="openSiilaIndex()"
          mat-flat-button
          color="accent"
          class="btn-interested"
        >
          {{ "more" | translate }}
        </button>
      </div>
      <div class="interested-img">
        <img [alt]="indexbars" [title]="indexbars" [src]="loadSbiImage()" />
      </div>
    </div>
  </div>
  <div
    *ngIf="
      product.marketCoverage &&
      (productId === 101 || productId === 102 || productId === 104)
    "
    class="border-card-white mat-elevation-z15"
  >
    <div class="marketCoverage">
      <div
        [ngClass]="{
          'coverage-info2': this.productId === 102 || this.productId === 104,
          'coverage-info': this.productId === 103 || this.productId === 106
        }"
      >
        <h1 class="title-gnrl siila-blue-color">
          {{ product.marketCoverage.preTitle | langSelector }}
        </h1>
        <h2 class="title-feature siila-menu-color">
          {{ product.marketCoverage.title | langSelector }}
        </h2>
      </div>
      <div class="market-coverage-container">
        <div class="check-grocs">
          <div *ngFor="let item of totalMarketItems">
            <div class="asset-items">
              <img class="check-map" src="assets/icons/check.svg" />
              <div class="map-title siila-blue-color">
                {{ product.marketCoverage[item] | langSelector }}
              </div>
            </div>
          </div>
          <span>
            <hr />
          </span>

          <p class="grocs-sub-title  siila-grey" style="margin-top: 10px;">
            {{ product.marketCoverage.description | langSelector }}
          </p>

          <button
            (click)="
              openContactDialog(product.productShortName, action.subscribe)
            "
            mat-flat-button
            color="accent"
            class="btn-sub-coverage"
          >
            {{ "sub" | translate }}
          </button>
        </div>
        <div class="coverage-media">
          <img
            class="coverage-img"
            [alt]="coverageAlt"
            [title]="coverageAlt"
            [src]="productDescriptionImage(product.marketCoverage.image)"
          />
        </div>
      </div>
    </div>
  </div>
  <div>
    <industry-news
      [tagId]="tagId"
      id="news"
      #news
      class="industry-container mat-elevation-z15"
    ></industry-news>
  </div>
  <div *ngIf="productId === 107" class="border-card-white mat-elevation-z15">
    <div class="marketCoverage">
      <div
        [ngClass]="{
          'coverage-info2': this.productId === 107 || this.productId === 104,
          'coverage-info': this.productId === 103 || this.productId === 106
        }"
      >
        <h1 class="title-gnrl siila-blue-color"></h1>
        <h2 class="title-feature siila-menu-color">
          INDICES AVAILABLE
        </h2>
      </div>
      <div class="market-coverage-container">
        <div class="check-grocs">
          <div style="display: flex; column-gap: 30px;">
            <div>
              <div *ngFor="let item of totalMarketItems">
                <div class="asset-items">
                  <img class="check-map" src="assets/icons/check.svg" />
                  <div class="map-title siila-blue-color">
                    {{ product.marketCoverage[item] | langSelector }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div class="asset-items">
                  <img class="check-map" src="assets/icons/check.svg" />
                  <div class="map-title siila-blue-color">
                    SMI - General
                  </div>
                </div>
                <div class="asset-items">
                  <img class="check-map" src="assets/icons/check.svg" />
                  <div class="map-title siila-blue-color">
                    SMI - Offices
                  </div>
                </div>
                <div class="asset-items">
                  <img class="check-map" src="assets/icons/check.svg" />
                  <div class="map-title siila-blue-color">
                    SMI - Industrial
                  </div>
                </div>
                <div class="asset-items">
                  <img class="check-map" src="assets/icons/check.svg" />
                  <div class="map-title siila-blue-color">
                    SMI - Industrial CDMX
                  </div>
                </div>
                <div class="asset-items">
                  <img class="check-map" src="assets/icons/check.svg" />
                  <div class="map-title siila-blue-color">
                    SMI - Industrial Border
                  </div>
                </div>
              </div>
            </div>
          </div>

          <span>
            <hr />
          </span>

          <p class="grocs-sub-title  siila-grey" style="margin-top: 10px;">
            {{ product.marketCoverage.description | langSelector }}
          </p>

          <button
            (click)="
              openContactDialog(product.productShortName, action.subscribe)
            "
            mat-flat-button
            color="accent"
            class="btn-sub-coverage"
          >
            {{ "sub" | translate }}
          </button>
        </div>
        <div class="coverage-media">
          <img
            class="coverage-img"
            [src]="productDescriptionImage(product.marketCoverage.image)"
          />
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="productId === 102 || productId === 105">
    <app-methodology></app-methodology>
    <app-download-quarterly-update
      *ngIf="showInvestor"
    ></app-download-quarterly-update>
    <app-solution-list> </app-solution-list>
  </ng-container>
  <ng-container
    *ngIf="productId === 101 || productId === 104 || productId === 106"
  >
    <app-solution-list></app-solution-list>
    <app-download-quarterly-update
      *ngIf="showInvestor"
    ></app-download-quarterly-update>
    <app-methodology></app-methodology>
  </ng-container>
  <ng-container *ngIf="productId === 103 || productId === 108">
    <app-methodology></app-methodology>
    <app-download-quarterly-update
      *ngIf="showInvestor && productId === 106"
    ></app-download-quarterly-update>
    <app-solution-list></app-solution-list>
  </ng-container>
  <app-btn-up></app-btn-up>
</section>
