import { Injectable, NgZone } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { WebsocketService } from "../../websockets";
import { Observable } from "rxjs";

@Injectable()
export class CrossStorageService {
  observer: Observable<any>;

  constructor(private http: HttpClient, private socket: WebsocketService) {}

  sendMessage() {}
}
