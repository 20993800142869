import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserActivity } from "../models/user-activity.model";

@Injectable()
export class UserActivityService {
  private endpoint: string = "/api/user-activities/";

  constructor(private http: HttpClient) {}

  public saveUserActivity(userActivity: UserActivity) {
    try {
      userActivity.application = "SIILA";
      return this.http.post(this.endpoint, userActivity).toPromise();
    } catch (error) {
      console.error(error);
    }
  }
}
