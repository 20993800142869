<div class="footer-section">
  <div
    [ngClass]="{
      'footer-wrapper': this.handsetMode,
      'footer-wrapper-desktop': !this.handsetMode,
      'fit-new-feature': fitWithFeatureBanner && !handsetMode
    }"
    class="flex-col"
  >
    <span>
      <img
        [alt]="footerLogoTitle"
        [title]="footerLogoTitle"
        src="../../../assets/img/footer-logo.png"
        class="footer-logo"
      />
      <br />
      <a
        class="logo-desc"
        href="http://reixcorp.com/"
        target="_blank"
        translate="footer.headers.reix"
      ></a>
    </span>

    <div class="contact-block-width">
      <span
        class="col-header uppercase"
        translate="footer.headers.contact"
      ></span>
      <div class="footer-links">
        <span
          *ngFor="let contact of currentContact"
          class="icon-list-item icon"
        >
          <!-- <fa-icon style="min-height:20px;min-width:20px;" [icon]="contact.ref"></fa-icon> -->
          <div class="contact-link">
            <mat-icon> {{ contact.matIcon }}</mat-icon>
          </div>
          <span class="content-text" style="margin-left: 0px;">
            <a
              *ngIf="browserLang === 'en' || browserLang === 'pt'"
              target="_blank"
              [href]="contact.link"
              [innerHTML]="contact.text | translate"
              mat-basic-button
            ></a>
            <a
              *ngIf="browserLang === 'es'"
              target="_blank"
              [href]="contact.link"
              [innerHTML]="contact.textEs"
              mat-basic-button
            ></a>
          </span>
        </span>
      </div>
    </div>

    <div *ngIf="currentEnv != 'co'">
      <span
        class="col-header uppercase"
        translate="footer.headers.products"
      ></span>
      <div class="footer-links">
        <span
          class="icon-list-item indent-text"
          *ngFor="let product of currentProduct"
        >
          <a
            [class]="product.class"
            [href]="product.link"
            [translate]="product.text"
            [target]="product.target"
          ></a>
        </span>
      </div>
    </div>
    <div>
      <span class="col-header uppercase" translate="navLinks.solutions"></span>
      <div class="footer-links">
        <span
          class="icon-list-item indent-text"
          *ngFor="let link of solutions"
          (click)="goSolutions(link)"
        >
          <a>{{ link.shortName | langSelector }}</a>
        </span>
      </div>
    </div>
    <!-- <div class="hide">
      <span class="col-header uppercase" translate="navLinks.blog"></span>
      <div class="footer-links">
        <span
          class="icon-list-item indent-text"
          *ngFor="let insights of insightsLink"
        >
          <a
            [href]="insights.link"
            [translate]="insights.text"
            [target]="insights.target"
          ></a>
        </span>
      </div>
    </div> -->
    <div style="display:flex;">
      <span
        class="col-header"
        translate="home.{{ currentEnv }}.socialMedia"
      ></span>
      <div class="social">
        <a
          target="_blank"
          [href]="icon.link"
          *ngFor="let icon of currentSocial"
        >
          <img [src]="icon.ref" [alt]="icon.alt" [title]="icon.title" />
        </a>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="copyright">
      <a
        class="copyright"
        translate="footer.headers.reixCopyright"
        href="http://reixcorp.com/"
        target="_blank"
      ></a>
      <a
        class="copyright"
        translate="footer.headers.terms-of-use"
        [href]="termsOfUseUrl"
        [target]="handsetMode ? '_self' : '_blank'"
      ></a>
      |
      <a
        class="copyright"
        translate="footer.headers.privacy-policy"
        [href]="privacyUrl"
        [target]="handsetMode ? '_self' : '_blank'"
      ></a>
    </div>
  </div>
</div>
