import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { environment } from "../../../../../environments/environment";
import { CapitalMarketsValues } from "../capital-markets.model";

@Component({
  selector: "app-siila-index-values",
  templateUrl: "./siila-index-values.component.html",
  styleUrls: ["./siila-index-values.component.scss"]
})
export class SiilaIndexValuesComponent implements OnInit {
  isMobile: boolean = false;
  @Input() totalPercent: number;
  @Input() totalVal: number;
  @Input() incomePercent: number;
  @Input() appreciationPercent: number;
  @Input() year: number;
  @Input() quarter: number;
  @Input() capitalMarket: CapitalMarketsValues;
  currentCode: string;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.currentCode = environment.platformId;
  }

  ngOnInit() {
    return this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }
}
