import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from "@angular/common/http";
import { NGXLogger } from "ngx-logger";
import { PagerService } from "./pager.service";
import { switchMap, tap, map } from "rxjs/operators";
import { I18nService } from "../../shared/services";

@Injectable()
export class ListingsService extends PagerService {
  private featuredListingIdsEndpoint: string = `${environment.apiURL}/_search/listings/featured/fast/all`;
  private activeListingIdsEndpoint: string = `${environment.apiURL}/_search/listings/active/fast/all`;
  private listingDetailsEndpoint: string = `${environment.apiURL}/_search/listings/fast/detail`;
  private activeListingDetailsEndpoint: string = `${environment.apiURL}/_search/listings/active/fast/detail`;
  private spotBuildingIdsAPI: string = `/spotbuildings/search/ids/fast`;
  private spotBuildingDetailsAPI: string = `/spotbuildings/search/detail`;
  private advertisements: string =
    "api/service-provider/carousel-data?application=SIILA";

  private listingIds = [];
  private loadedListings = [];
  totalCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 5;
  latamCountry = "";

  constructor(
    private http: HttpClient,
    private _log: NGXLogger,
    private i18nService: I18nService
  ) {
    super();
  }

  public get getCurrentPage(): number {
    return this.currentPage;
  }

  public get getTotalPageCount(): number {
    return this.totalCount;
  }

  async getFeaturedListingIds() {
    const res: any = await this.http
      .get(this.featuredListingIdsEndpoint)
      .toPromise();
    this.listingIds = res;
    return res;
  }

  async getListings() {
    let payload = { isFeaturedWithoutCheckingDate: true, randomOrder: true };
    const { body: ids }: { body: number[] } = (await this.searchSpotBuildingIds(
      payload,
      1
    )) as { headers: HttpHeaders; body: number[] };
    let spotBuildingList: any = await this.getSpotBuildingDetails(
      1,
      ids.length,
      ids
    );
    return spotBuildingList;
  }

  async searchSpotBuildingIds(searchRequest: any, page: number) {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("page", page.toString());
    urlSearchParams.append("per_page", "4");
    urlSearchParams.append("lang", this.i18nService.getCurrentLanguage());
    const host =
      this.latamCountry == "latamMx"
        ? environment.apiURLMX
        : this.latamCountry == "latamBr"
        ? environment.apiURLBR
        : environment.apiURL;

    console.log("Search SPot Building IDS HOST ", host, this.latamCountry);
    return await this.http
      .post(
        host + this.spotBuildingIdsAPI + "?" + urlSearchParams,
        searchRequest,
        {
          observe: "response"
        }
      )
      .pipe(
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise();
  }

  async getSpotBuildingDetails(
    page: number,
    pageSize: number,
    spotBuildingIds: number[]
  ): Promise<any[]> {
    let params = "";

    let detailsURL =
      (this.latamCountry == "latamMx"
        ? environment.apiURLMX
        : this.latamCountry == "latamBr"
        ? environment.apiURLBR
        : "/api") +
      `${this.spotBuildingDetailsAPI}?page=${page}&per_page=${pageSize}${params}`;

    return await this.http
      .post(detailsURL, spotBuildingIds)
      .toPromise()
      .then((response: any[]) => {
        const listings = response;
        return listings;
      });
  }

  async getActiveListingIds() {
    const res: any = await this.http
      .get(this.activeListingIdsEndpoint)
      .toPromise();
    this.listingIds = res;
  }

  getDefaultActiveListings(pageSize): Promise<any> {
    return this.http
      .get(
        this.activeListingDetailsEndpoint +
          `?page=1&per_page=` +
          (pageSize || this.pageSize),
        {
          observe: "response"
        }
      )
      .pipe(
        tap(
          (response: HttpResponse<any>) =>
            (this.totalCount = parseInt(response.headers.get("x-total-count")))
        ),
        map(val => val.body)
      )
      .toPromise()
      .then((listings: any[]) => {
        this.appendListings(listings);

        return listings;
      });
  }

  getAdvertisements(size?: number) {
    if (size) {
      return this.http
        .get(this.advertisements + (size ? "&size=" + size : ""), {
          observe: "response"
        })
        .pipe(map(val => val.body));
    } else {
      return this.http
        .get(this.advertisements, {
          observe: "response"
        })
        .pipe(map(val => val.body));
    }
  }

  async getListingDetails(pageSize: number) {
    this.totalCount = this.listingIds.length;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append(
      "per_page",
      pageSize.toString() || this.pageSize.toString()
    );
    return await this.http
      .post(
        `${this.listingDetailsEndpoint}?${urlSearchParams}`,
        this.listingIds
      )
      .toPromise()
      .then((listings: any[]) => {
        this.appendListings(listings);
        return listings;
      });
  }

  async getActiveListingDetails() {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("per_page", "100");
    return await this.http
      .get(this.activeListingDetailsEndpoint + urlSearchParams.toString())
      .toPromise();
  }

  public setTotalCount(totalCountHeader: string) {
    if (!totalCountHeader) {
      return;
    }
    this.totalCount = parseInt(totalCountHeader);
  }

  async queryByPage(page: number, pageSize?: number) {
    return await this.http
      .post(
        `${this.listingDetailsEndpoint}?page=${page}&per_page=${pageSize ||
          this.pageSize}`,
        this.listingIds
      )
      .toPromise()
      .then((listings: any[]) => {
        this.appendListings(listings);
        return listings;
      });
  }

  public appendListings(listings: any[]) {
    this.loadedListings = [...this.loadedListings, ...listings];
  }

  fetchPage(page) {
    const pagination = this.getPager(this.totalCount, page, this.pageSize);

    return {
      pagination: pagination,
      pageQuery$: async () => {
        return await this.queryByPage(page);
      },
      nextPage: page + 1
    };
  }
}
