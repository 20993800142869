import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from "@angular/core";

import { DatePipe, ViewportScroller } from "@angular/common";
import { Article } from "./news.component";
import { ImageService } from "../../../core/services/image.service";
import {
  CarouselRestoreService,
  CarouselState,
  I18nService
} from "../../services";
import { Router } from "@angular/router";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { environment } from "../../../../environments/environment";
import { NewsSearch } from "../../models/news-search.model";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { NewsNavigateService } from "../../services/news.navigate.service";

@Component({
  selector: "news-card",
  templateUrl: "./news-card.component.html",
  styleUrls: ["./news-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsCardComponent implements OnInit {
  @Input("article") article: Article;
  @Input("currentPage") page: any;
  @Input("loadedItems") loadedItems: any[];
  @Input("totalItemCount") totalItemCount: number;
  @Input("currentSlideIndex") currentSlideIndex: number;
  @Input("latamCountry") latamCountry: string;
  @Input("article2") article2;
  currentCode = "";
  isMobile = false;
  browserLang;

  constructor(
    private imageService: ImageService,
    private router: Router,
    private CarouselRestoreService: CarouselRestoreService,
    private _viewportScrollbar: ViewportScroller,
    private siilaTranslationService: SiilaTranslationService,
    public breakpointObserver: BreakpointObserver,
    private i18: I18nService,
    private datePipe: DatePipe,
    private newsNavigate: NewsNavigateService
  ) {
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
  }

  ngOnInit(): void {
    this.imageService.latamCountryNews = this.latamCountry;
  }

  getMediumImg(id) {
    return this.imageService.mediumThumbnailNews(id);
  }

  getSmallImg(id) {
    return this.imageService.smallThumbnailNews(id);
  }

  navigateToNewsDetail(id) {
    const CarouselState: CarouselState = {
      currentPage: this.page,
      totalItemCount: this.totalItemCount,
      currentSlideIndex: this.currentSlideIndex,
      loadedObjects: this.loadedItems ? this.loadedItems : null,
      pagePosition: this._viewportScrollbar.getScrollPosition()
    };
    this.CarouselRestoreService.setState(CarouselState);
    this.router.navigateByUrl(
      `/news/detail/${id}/lang/${this.i18.getCurrentLanguage()}`
    );
  }

  openNews(id: number, title) {
    this.newsNavigate.openNews(
      id,
      title.urlTitle ? title.urlTitle : title.headline,
      this.isMobile,
      "",
      false,
      this.browserLang,
      true,
      this.latamCountry
    );
  }

  getLanguage() {
    return this.siilaTranslationService.getCurrentLanguage();
  }

  getArticleTitle() {
    return this.siilaTranslationService.getTranslation(this.article2.headline);
  }

  getAltTxt() {
    return this.siilaTranslationService.getTranslation(
      this.article2.headerImageCaption
    );
  }

  headerImageNews(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  formatDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  translateNews(value) {
    return JSON.parse(value as string)["en"];
  }
}
