import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../../environments/environment";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { I18nService } from "../../../services";
import { CaptchaService } from "../../../services/captcha.service";

@Component({
  selector: "demo-dialog",
  templateUrl: "./demo-dialog.component.html",
  styleUrls: ["./demo-dialog.component.scss"]
})
export class DemoDialogComponent implements OnInit {
  @Input() useMailchimp: Boolean = false;
  form: FormGroup;
  currentCode;
  browserLang: string = "en";
  isMobile: boolean = false;
  imgCaptcha = "";
  textCaptcha: string;
  errorCaptcha = false;

  constructor(
    private _fb: FormBuilder,
    private _d: MatDialog,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private siilaTranslationService: SiilaTranslationService,
    private i18: I18nService,
    private breakpointObserver: BreakpointObserver,
    private captchaService: CaptchaService
  ) {
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.useMailchimp = data.submitMailing;
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  get validateParams() {
    return this.data.page && this.data.action ? true : false;
  }

  ngOnInit(): void {
    this.generateCaptcha();
    this.form = this._fb.group({
      email: [
        this.data.email,
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      status: ["subscribed"],
      page: [this.data.page],
      action: [this.data.action],
      company: ["", Validators.required],
      position: [""],
      description: [""],
      createSpotAccount: [true],
      joinMailingList: [true],
      inputCaptcha: [""]
    });
  }

  submitDemo() {
    let message = this.i18.get("global.newsForm.message");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.validateCaptcha();
    if (!this.errorCaptcha) {
      if (this.data.page && this.data.action) {
        this.productService.infoRequest(this.form.value).subscribe(
          response => {
            this.form.reset();
            this.form.controls.createSpotAccount.setValue(true);
            this.form.controls.joinMailingList.setValue(true);
            this.snackBar.open(message, action);
            this._d.closeAll();
          },
          () => this.snackBar.open(errorMsj)
        );
      } else {
        this.productService.demoRequest(this.form.value).subscribe(
          response => {
            this.form.reset();
            this.form.controls.createSpotAccount.setValue(true);
            this.form.controls.joinMailingList.setValue(true);
            this.snackBar.open(message, action);
            this._d.closeAll();
          },
          () => this.snackBar.open(errorMsj)
        );
      }
    }
  }

  submitMailing() {
    let message = this.i18.getTranslation("global.newsForm.message");
    let action = this.i18.getTranslation("global.newsForm.action");
    let errorMsj = this.i18.getTranslation("global.newsForm.errorMsj");
    this.productService.mailingRequest(this.form.value).subscribe(
      response => {
        this.form.reset();
        this.snackBar.open(message, action);
        this._d.closeAll();
      },
      () => this.snackBar.open(errorMsj, action)
    );
  }

  dismiss() {
    this._d.closeAll();
  }

  generateCaptcha() {
    this.captchaService.generateCaptcha();
    this.imgCaptcha = this.captchaService.imgCaptcha;
    this.textCaptcha = this.captchaService.textCaptcha;
  }

  validateCaptcha() {
    this.errorCaptcha =
      this.form.controls.inputCaptcha.value != this.textCaptcha ||
      this.form.controls.inputCaptcha.value == "";
    return this.errorCaptcha;
  }
}
