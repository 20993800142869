<section
  class="top-banner"
  [ngClass]="{ 'mobile-mode': mobileScreen }"
  [@visibleHidden]="visible ? 'visible' : 'hidden'"
>
  <div class="top-banner__content">
    <ng-content></ng-content>
  </div>
  <div class="top-banner__actions">
    <button class="accept mat-flat-button" (click)="onActionClick()">
      {{ "global.acceptCookies.accept" | translate | uppercase }}
    </button>
  </div>
</section>
