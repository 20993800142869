import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { identifierName } from "@angular/compiler";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ItemsControl } from "@ngu/carousel/lib/ngu-carousel/ngu-carousel";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import {
  AssetCoverage,
  DetailedProduct,
  ProductDescriptionImage,
  ProductItem
} from "../../../../core/models/products.model";
import { DemoDialogService } from "../../../../core/services/dialog";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { I18nService } from "../../../services";
import { action, page } from "../../../utils/params";
import { DemoDialogComponent } from "../../dialogs";
import { MetaTagService } from "../../../services/meta-tag.service";
import { CaptchaService } from "../../../services/captcha.service";
@Component({
  selector: "app-grocs",
  templateUrl: "./grocs.component.html",
  styleUrls: [
    "./grocs.component.scss",
    "../product-list/product-list.component.scss",
    "../market-analytics/market-analytics.component.scss",
    "../spot/spot.component.scss"
  ]
})
export class GrocsComponent implements OnInit, OnDestroy {
  benefitPath = "grocs.benefits";
  mappingPath = "grocs.mapping.regions.";
  productId: number;
  routerSub: Subscription;
  product: DetailedProduct;
  assets: AssetCoverage;
  isVideo = false;
  videoId: string;
  totalAssetItems: string[];
  totalMarketItems: string[];
  products: ProductItem[];
  responsiveSub: Subscription;
  isMobile = false;
  showInvestor: any;
  currentCode: string;
  browserLang: string = "en";
  action = action;
  tagId = null;
  indexpic: string;
  indexbars: string;
  coverageAlt: string;
  picAlt: string;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private responsive: BreakpointObserver,
    private siilaTranslationService: SiilaTranslationService,
    private demo: DemoDialogService,
    private i18: I18nService,
    private metaTagService: MetaTagService,
    private captchaService: CaptchaService
  ) {
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    if (this.currentCode == "mx") {
      this.tagId = 64;
    }

    if (this.currentCode == "co") {
      this.tagId = 64;
    }

    if (this.currentCode == "br") {
      this.tagId = 60;
    }

    if (this.currentCode == "latam") {
      this.tagId = 68;
    }
  }

  productDescriptionImageAlt(benefits: any) {
    console.log("Benefits ", benefits);
    let alt = "";

    if (benefits) {
      const productId = benefits.productId;
      const id = benefits.id;

      if (productId == 101 || productId == 106) {
        switch (id) {
          case 101:
          case 119:
            return this.siilaTranslationService.getImgSEOTranslation(
              "grocs.mappic"
            );
          case 103:
          case 121:
            return this.siilaTranslationService.getImgSEOTranslation(
              "grocs.interior"
            );
          case 102:
          case 120:
            return this.siilaTranslationService.getImgSEOTranslation(
              "grocs.dashboard"
            );
        }
      }
    }

    return alt;
  }

  productDescriptionImage(item: ProductDescriptionImage): string {
    return `${environment.cloudfrontURL}${item.filePathInBucket}/640w/${item.filename}`;
  }

  ngOnInit(): void {
    this.indexpic = this.siilaTranslationService.getImgSEOTranslation(
      "index.pic" + environment.platformId
    );
    this.indexbars = this.siilaTranslationService.getImgSEOTranslation(
      "index.bars" + environment.platformId
    );
    this.coverageAlt = this.siilaTranslationService.getImgSEOTranslation(
      "index.coverage" + environment.platformId
    );

    this.picAlt = this.siilaTranslationService.getImgSEOTranslation(
      "investorsurvey.picAlt"
    );

    this.routerSub = this.route.paramMap.subscribe(params => {
      this.productId = +params.get("id");
      if (this.productId == 103 || this.productId == 105) {
        this.indexpic = this.siilaTranslationService.getImgSEOTranslation(
          "investorsurvey.picworld"
        );
      } else if (this.productId == 101) {
        this.indexpic = this.siilaTranslationService.getImgSEOTranslation(
          "grocs.firstpic"
        );
      }
      this.productService.getProduct(this.productId).subscribe(product => {
        this.product = product;
        this.metaTagService.setProductPageMetaTags(this.product);
        this.isVideo = !!product.headerExtraVideoUrl;
        if (this.isVideo) {
          this.videoId = product.headerExtraVideoUrl
            .split("v=")[1]
            .split("&")[0];
        }
        if (product.assetCoverage) {
          this.totalAssetItems = Object.keys(
            product.assetCoverage
          ).filter(item => item.includes("item"));
        }

        if (product.marketCoverage) {
          this.totalMarketItems = Object.keys(product.marketCoverage).filter(
            item => item.includes("item") && product.marketCoverage[item]
          );
        }
      });

      this.showInvestor = this.productId === 103 || this.productId === 105;
    });
    this.responsiveChanges();
  }
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    this.responsiveSub.unsubscribe();
  }

  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  openContactDialog(page: string, action: string) {
    this.demo.open(
      DemoDialogComponent,
      false,
      this.i18.getTranslation(page),
      action,
      "",
      this.i18.getTranslation(this.product.productShortName)
    );
  }

  openSiilaIndex() {
    window.open(
      `${
        environment.applicationURL
      }/siila_index/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  loadSbiImage() {
    let img = "../../../../../assets/img/analytics-list/";
    if (this.currentCode == "mx") {
      if (this.browserLang == "en") {
        img = img + "NOTE-SMI-MXEN copiar.png";
      } else {
        img = img + "NOTE-SMI-MXES copiar.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "NOTE-SBI-BREN copiar.png";
      } else {
        img = img + "NOTE-SBI-BRPT copiar.png";
      }
    }

    return img;
  }
}
