import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { SiilaTranslationService } from "../../core/services/siila-translation.service";
import { I18nService } from "./i18n.service";

@Injectable()
export class NewsNavigateService {
  currentCode = environment.platformId;
  constructor(
    private router: Router,
    private siilaTranslationService: SiilaTranslationService,
    private i18: I18nService
  ) {}

  openNews(
    id: number,
    title,
    isMobile,
    pageSize,
    isIOS,
    browserLang,
    condition = false,
    latamCountry?
  ) {
    if (isMobile) {
      localStorage.setItem("scrollPosition", window.pageYOffset.toString());
      localStorage.setItem("pageSize", pageSize.toString());
    }

    if (isIOS) {
      this.router.navigateByUrl(this.addUrl(browserLang, title, id));
    } else {
      if (condition) {
        window.open(
          `${
            this.currentCode == "co"
              ? latamCountry == "latamBr"
                ? environment.applicationURLBr + "/"
                : environment.applicationURLMx + "/"
              : ""
          }${this.addUrl(browserLang, title, id)}`,
          isMobile ? "_self" : "_blank"
        );
      } else {
        window.open(
          this.addUrl(browserLang, title, id),
          isMobile ? "_self" : "_blank"
        );
      }
    }
  }

  addUrl(browserLang, title, id) {
    return `${
      browserLang == "pt" || browserLang == "es" ? "noticias" : "news"
    }/${this.siilaTranslationService
      .getTranslation(title)
      .toLowerCase()
      .replaceAll(/[ %?]/g, "-")
      .replaceAll(
        /['"()<>&\\\/]/g,
        ""
      )}/${id}/lang/${this.i18.getCurrentLanguage()}`;
  }

  openNewsSearch(isMobile, keyword) {
    const encodedKeyword = encodeURIComponent(keyword);
    window.open(
      `search_news/lang/${this.i18.getCurrentLanguage()}?keyword=${encodedKeyword}`,
      isMobile ? "_self" : "_blank"
    );
  }

  goNewsTags(tag, color, stateName, isMobile) {
    window.open(
      `resource/tags/${this.siilaTranslationService.getTranslation(
        stateName
      )}/${tag.id}/${color}/lang/${this.i18.getCurrentLanguage()}`,
      isMobile ? "_self" : "_blank"
    );
  }

  goHome(latam = false, isMobile?) {
    window.open(
      latam
        ? "https://siila.com/resource" +
            `/lang/${this.i18.getCurrentLanguage()}`
        : `resource/lang/${this.i18.getCurrentLanguage()}`,
      isMobile ? "_self" : "_blank"
    );
  }
}
