<div class="highlighs-container">
  <div class="card-sale">
    <h1 class="card-title">{{ highlighsPath + "1.title" | translate }}</h1>
    <h2 class="card-text">{{ highlighsPath + "1.text" | translate }}</h2>
  </div>

  <div class="card-asset">
    <h1 class="card-title">{{ highlighsPath + "2.title" | translate }}</h1>
    <h2 class="card-text">{{ highlighsPath + "2.text" | translate }}</h2>
  </div>

  <div class="card-stacking">
    <h1 class="card-title">{{ highlighsPath + "3.title" | translate }}</h1>
    <h2 class="card-text">{{ highlighsPath + "3.text" | translate }}</h2>
  </div>

  <div class="card-features">
    <h1 class="card-title">{{ highlighsPath + "4.title" | translate }}</h1>
    <p class="card-text">
      <span>{{ highlighsPath + "4.text" | translate }}</span>
      <a
        (click)="openClubFii()"
        *ngIf="
          currentCode == 'br' && (browserLang === 'en' || browserLang === 'pt')
        "
        class="card-text link-blue btn-decoration"
      >
        {{ highlighsPath + "4.text1" | translate }}
        <span
          *ngIf="
            currentCode == 'br' &&
            (browserLang === 'en' || browserLang === 'pt')
          "
          class="card-text"
        >
          {{ highlighsPath + "4.text2" | translate }}
        </span>
      </a>
    </p>
  </div>
</div>
