import { Component, OnInit } from "@angular/core";
import {
  Notification,
  NotificationType
} from "../../../core/models/notification.model";
import { ToastNotificationService } from "../../../core/services/toast-notification.service";

@Component({
  selector: "toaster-notification",
  templateUrl: "./toaster-notification.component.html",
  styleUrls: ["./toaster-notification.component.scss"]
})
export class ToasterNotificationComponent implements OnInit {
  notifications: Notification[] = [];

  constructor(public _toast: ToastNotificationService) {}

  ngOnInit(): void {
    this._toast.getAlert().subscribe((alert: Notification) => {
      this.notifications = [];
      if (!alert) {
        this.notifications = [];
        return;
      }
      this.notifications.push(alert);
      setTimeout(() => {
        this.notifications = this.notifications.filter(x => x !== alert);
      }, 4000);
    });
  }

  removeNotification(notification: Notification) {
    this.notifications = this.notifications.filter(x => x !== notification);
  }

  cssClass(notification: Notification) {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case NotificationType.Success:
        return "toast-success";
      case NotificationType.Error:
        return "toast-error";
      case NotificationType.Info:
        return "toast-info";
      case NotificationType.Warning:
        return "toast-warning";
    }
  }
}
