import { Component, Input } from "@angular/core";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";

@Component({
  selector: "app-selic-values",
  templateUrl: "./selic-values.component.html",
  styleUrls: ["./selic-values.component.scss"]
})
export class SelicValuesComponent {
  @Input() selicRate: number;
  @Input() selicDate: string;

  constructor(private siilaTranslationService: SiilaTranslationService) {}

  getLanguage() {
    return this.siilaTranslationService.getCurrentLanguage();
  }
}
