<div class="value-container">
  <h6 class="badge" translate="global.siilaIndex.selicTitle"></h6>
  <div>
    <span class="badge" [negativePositiveBg]="selicRate">
      {{ (selicRate | localNumber: "1.2-2") || "–" }} %
    </span>
    <span class="badge" *ngIf="getLanguage() === 'en'">
      {{ (selicDate | date: "dd.MMM.yyyy":"":"en-US") || "–" }}
    </span>
    <span class="badge" *ngIf="getLanguage() === 'pt'">
      {{ (selicDate | date: "dd.MMM.yyyy":"":"en-US") || "–" }}
    </span>
  </div>
</div>
