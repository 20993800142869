import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { DemoDialogService } from "../../../../core/services/dialog";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { I18nService } from "../../../services";
import { MetaTagService } from "../../../services/meta-tag.service";
import { action, page } from "../../../utils/params";
import { DemoDialogComponent } from "../../dialogs";
import { CaptchaService } from "../../../services/captcha.service";

@Component({
  selector: "app-fii-data-pro",
  templateUrl: "./fii-data-pro.component.html",
  styleUrls: [
    "./fii-data-pro.component.scss",
    "../grocs/grocs.component.scss",
    "../product-list/product-list.component.scss",
    "../market-analytics/market-analytics.component.scss"
  ]
})
export class FiiDataProComponent implements OnInit {
  currentCode: string;
  isMobile = false;
  responsiveSub: Subscription;
  fiiDataPath: string;
  highlighsPath: string;
  form: FormGroup;
  lang: string;
  routerSub: Subscription;
  tagId = null;
  imgFiiAlt: string;
  laptops: string;
  colleagues: string;
  iconsInvestor: string;
  iconsadvisor: string;
  iconsmarket: string;
  iconsfinancial: string;
  demoAlt: string;
  constructor(
    private responsive: BreakpointObserver,
    private demoDialog: DemoDialogService,
    private demo: DemoDialogService,
    private i18: I18nService,
    private _fb: FormBuilder,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private siilaTranslationService: SiilaTranslationService,
    private metatagservice: MetaTagService,
    private route: ActivatedRoute,
    private captchaService: CaptchaService
  ) {
    this.currentCode = environment.platformId;
    this.lang = this.siilaTranslationService.getCurrentLanguage();
    if (this.currentCode == "mx") {
      this.tagId = 64;
    }

    if (this.currentCode == "br" || this.currentCode == "latam") {
      this.tagId = 60;
    }

    /* if (this.currentCode == "latam") {
      this.tagId = 68;
    } */
    if (this.currentCode == "latam") {
      if (window.location.href.includes("fibra-analytics")) {
        this.currentCode = "mx";
      }

      if (window.location.href.includes("fii-data-pro")) {
        this.currentCode = "br";
      }
    }
    this.fiiDataPath = `fii-data.${this.currentCode}.fii-data.segment`;
  }

  ngOnInit(): void {
    let platformId = this.currentCode;
    this.imgFiiAlt = this.siilaTranslationService.getImgSEOTranslation(
      "fii.main" + platformId
    );
    this.laptops = this.siilaTranslationService.getImgSEOTranslation(
      "fii.laptops" + platformId
    );
    this.colleagues = this.siilaTranslationService.getImgSEOTranslation(
      "fii.colleagues" + platformId
    );
    this.demoAlt = this.siilaTranslationService.getImgSEOTranslation(
      "fii.demo" + platformId
    );
    const iconsPath = "fii.icons" + platformId + ".";
    this.iconsInvestor = this.siilaTranslationService.getImgSEOTranslation(
      iconsPath + "investor"
    );
    this.iconsadvisor = this.siilaTranslationService.getImgSEOTranslation(
      iconsPath + "advisor"
    );
    this.iconsmarket = this.siilaTranslationService.getImgSEOTranslation(
      iconsPath + "market"
    );
    this.iconsfinancial = this.siilaTranslationService.getImgSEOTranslation(
      iconsPath + "financial"
    );

    this.routerSub = this.route.paramMap.subscribe(params => {
      this.metatagservice.setFibraAnalyticsMetaTags();
    });
    this.form = this._fb.group({
      email: ["", [Validators.required, Validators.email]],
      fullName: ["", Validators.required],
      status: ["subscribed"],
      page: ["fii-data-pro"],
      action: ["get demo"],
      company: ["", Validators.required],
      description: ["", Validators.required]
    });
    this.responsiveChanges();
  }
  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  openContactDialog() {
    this.demo.open(
      DemoDialogComponent,
      false,
      page.fiiData,
      action.subscribe,
      "",
      this.i18.get(`fii-data.${this.currentCode}.fii-data.title`)
    );
  }

  openMarket() {
    window.open(
      `${
        this.currentCode == "mx"
          ? environment.applicationURLMx
          : environment.applicationURLBr
      }/market-fundamentals/lang/${
        this.currentCode == "mx" ? this.lang : "en"
      }`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  public openDemoDialog() {
    this.demoDialog.open(DemoDialogComponent);
  }

  submitDemo() {
    let formValue = this.form.value;
    this.form.reset();
    let message = this.i18.get("global.newsForm.message");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");

    this.productService.demoRequest(formValue).subscribe(
      response => {
        this.snackBar.open(message, action);
      },
      () => this.snackBar.open(errorMsj)
    );
  }

  imgFii() {
    let img = "../../../../../assets/img/fii-data-pro/";
    if (this.currentCode == "mx") {
      img = img + "FIBRAS-item1-officepic.jpg";
    } else {
      img = img + "FII-DATA-PRO-item1-officepic.jpeg";
    }

    return img;
  }

  imgFiiProducts() {
    let img = "../../../../../assets/img/fii-data-pro/";
    if (this.currentCode == "mx") {
      img = img + "ALL-SCREENS-MEXICO-MX - ALL LANGUAGES-3.png";
    } else {
      img = img + "ALL-SCREENS-SiiLA-BR - ALL LANGUAGES.png";
    }

    return img;
  }

  imgFiiDemo() {
    let img = "../../../../../assets/img/fii-data-pro/";
    if (this.currentCode == "mx") {
      img = img + "CONTACT-FORM-FIBRAS-SiiLA.png";
    } else {
      img = img + "request-demo.png";
    }

    return img;
  }
}
