import { Injectable, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {
  DetailedProduct,
  AssetCoverage,
  ProductItem,
  SolutionMenuItem,
  DetailedSolution,
  DownloadQuarterlyUpdate,
  DownloadQuarterlyResponse
} from "../models/products.model";
import { TranslateService } from "@ngx-translate/core";
import { env } from "process";
import { SrvRecord } from "dns";
import { SiilaTranslationService } from "./siila-translation.service";

@Injectable({ providedIn: "root" })
export class ProductService {
  lang: string;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private siilaTranslationService: SiilaTranslationService
  ) {
    this.lang = this.siilaTranslationService.getCurrentLanguage();
  }

  /**
   * Get a list of Products add ons
   */
  getMarketAnalytics() {
    const currentEnv = environment.platformId.toUpperCase();
    return this.http.get<ProductItem[]>(
      `${environment.apiURL}/content/product?groupCode=MARKET_ANALYTICS&countryCode=${currentEnv}`
    );
  }

  getProduct(id: number) {
    return this.http.get<DetailedProduct>(`
    ${environment.apiURL}/content/product/${id}`);
  }

  getSolutionList() {
    const countryCode = environment.platformId.toUpperCase();
    return this.http.get<SolutionMenuItem[]>(
      `${environment.apiURL}/content/solution?countryCode=${countryCode}`
    );
  }

  getSolution(id: number) {
    return this.http.get<DetailedSolution>(
      `${environment.apiURL}/content/solution/${id}`
    );
  }

  requestFile(downloadQuarterlyUpdate: DownloadQuarterlyUpdate) {
    return this.http.post<DownloadQuarterlyResponse>(
      `${environment.apiURL}/info/download-quarterly-update`,
      downloadQuarterlyUpdate
    );
  }

  demoRequest(downloadQuarterlyUpdate: Partial<DownloadQuarterlyUpdate>) {
    if (environment.platformId == "latam") {
      downloadQuarterlyUpdate.latam = true;
    }

    if (environment.platformId == "co") {
      downloadQuarterlyUpdate.colombia = true;
    }

    return this.http.post<DownloadQuarterlyResponse>(
      `${environment.apiURL}/info/demoRequest`,
      downloadQuarterlyUpdate
    );
  }

  mailingRequest(downloadQuarterlyUpdate: Partial<DownloadQuarterlyUpdate>) {
    const langLenguaje = this.translate.currentLang.toLowerCase();
    return this.http.post<DownloadQuarterlyResponse>(
      `${environment.apiURL}/mailchimp/${langLenguaje}`,
      downloadQuarterlyUpdate
    );
  }

  requestFileIndex(downloadQuarterlyUpdate: DownloadQuarterlyUpdate) {
    if (environment.platformId == "latam") {
      downloadQuarterlyUpdate.latam = true;
    }

    if (environment.platformId == "co") {
      downloadQuarterlyUpdate.colombia = true;
    }

    downloadQuarterlyUpdate.lang = this.lang;
    return this.http.post<DownloadQuarterlyResponse>(
      `${environment.apiURL}/info/download-index`,
      downloadQuarterlyUpdate
    );
  }

  downloadPdf(filePath: string) {
    const link = document.createElement("a");
    link.href = filePath;
    link.target = "_blank";
    link.click();
  }

  getLangSelector() {
    return this.translate.currentLang === "pt"
      ? "pt-br"
      : this.translate.currentLang;
  }

  infoRequest(downloadQuarterlyUpdate: Partial<DownloadQuarterlyUpdate>) {
    if (environment.platformId == "latam") {
      downloadQuarterlyUpdate.latam = true;
    }

    if (environment.platformId == "co") {
      downloadQuarterlyUpdate.colombia = true;
    }
    return this.http.post<DownloadQuarterlyResponse>(
      `${environment.apiURL}/info/infoRequest `,
      downloadQuarterlyUpdate
    );
  }

  sendEmail(email: string) {
    let params;
    params = {
      email: email
    };
    if (environment.platformId == "latam") {
      params = {
        email: email,
        latam: true
      };
    }

    if (environment.platformId == "co") {
      params = {
        email: email,
        colombia: true
      };
    }

    return this.http.post<any>(
      `${environment.apiURL}/info/mailing-list`,
      params
    );
  }
}
