<section class="spot-container">
  <div *ngIf="!isMobile; else commercial" class="spot-card mat-elevation-z15">
    <div class="spot-flex">
      <div class="spot-info">
        <h2
          class="title-gnrl"
          style="padding-bottom: 20px;"
          translate="spot.{{ currentCode }}.spot.title"
        ></h2>
        <h2
          class="feature-tittle-spot"
          translate="spot.{{ currentCode }}.spot.sub-title"
        ></h2>
        <p
          class="feature-sub-tittle"
          translate="spot.{{ currentCode }}.spot.info"
        ></p>
        <div class="button">
          <button (click)="openSpot()" mat-flat-button class="btn-info">
            {{ "spot-btn" | translate }}
          </button>
          <button
            (click)="downloadFormIndex(1)"
            mat-flat-button
            color="accent"
            class="btn-donw uppercase"
          >
            {{ "down" | translate }}
          </button>
        </div>
      </div>
      <div class="company-video" *ngIf="currentCode === 'br'">
        <div class="video-mobile" *ngIf="browserLang === 'en'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_mbd0cfj7vr seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/mbd0cfj7vr/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-mobile" *ngIf="browserLang === 'es'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_mbd0cfj7vr seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/mbd0cfj7vr/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-mobile" *ngIf="browserLang === 'pt'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_n492bdk3t2 seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/n492bdk3t2/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="company-video"
        *ngIf="currentCode === 'mx' || currentCode === 'latam'"
      >
        <div class="video-mobile" *ngIf="browserLang === 'en'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_wt99v7crnh seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/wt99v7crnh/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-mobile" *ngIf="browserLang === 'es'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_apchjqcj47 seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/apchjqcj47/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-mobile" *ngIf="browserLang === 'pt'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_wt99v7crnh seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/wt99v7crnh/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #commercial>
    <div class="spot-card mat-elevation-z15">
      <div class="spot-flex">
        <div class="spot-info">
          <h2
            class="feature-tittle-spot"
            translate="spot.{{ currentCode }}.spot.sub-title"
          ></h2>
          <div class="company-video" *ngIf="currentCode === 'br'">
            <div class="video-mobile" *ngIf="browserLang === 'en'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_mbd0cfj7vr seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/mbd0cfj7vr/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'es'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_mbd0cfj7vr seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/mbd0cfj7vr/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'pt'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_n492bdk3t2 seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/n492bdk3t2/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="company-video"
            *ngIf="currentCode === 'mx' || currentCode === 'latam'"
          >
            <div class="video-mobile" *ngIf="browserLang === 'en'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_wt99v7crnh seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/wt99v7crnh/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'es'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_apchjqcj47 seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/apchjqcj47/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video-mobile" *ngIf="browserLang === 'pt'">
              <div
                class="wistia_responsive_padding"
                style="padding:56.25% 0 0 0;position:relative;"
              >
                <div
                  class="wistia_responsive_wrapper"
                  style="height:100%;left:0;position:absolute;top:0;width:100%;"
                >
                  <div
                    class="wistia_embed wistia_async_wt99v7crnh seo=false videoFoam=true"
                    style="height:100%;position:relative;width:100%"
                  >
                    <div
                      class="wistia_swatch"
                      style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                    >
                      <img
                        src="https://fast.wistia.com/embed/medias/wt99v7crnh/swatch"
                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                        alt=""
                        aria-hidden="true"
                        onload="this.parentNode.style.opacity=1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            class="feature-sub-tittle"
            translate="spot.{{ currentCode }}.spot.info"
          ></p>
          <div class="button">
            <button (click)="openSpot()" mat-flat-button class="btn-info">
              {{ "spot-btn" | translate }}
            </button>
            <button
              mat-flat-button
              color="accent"
              (click)="downloadFormIndex(1)"
              class="btn-donw uppercase"
            >
              {{ "down" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="border-card-white-spot mat-elevation-z15">
    <div class="main-spot">
      <div class="featured-title">
        <h1
          class="title-propie siila-menu-color"
          translate="spot.{{ currentCode }}.spot.spot-info.why"
        ></h1>
        <h2
          class="sub-title-propie"
          translate="spot.{{ currentCode }}.spot.spot-info.title"
        ></h2>
      </div>
      <div class="btn-latam" *ngIf="currentCode == 'latam'">
        <button
          class="form-control"
          [ngClass]="latamCountry == 'latamMx' ? 'btn-active' : 'btn-no-active'"
          (click)="latamCountry = 'latamMx'"
        >
          {{ "navLinks.siilaMexico" | translate }}
        </button>
        <button
          class="form-control"
          [ngClass]="latamCountry == 'latamBr' ? 'btn-active' : 'btn-no-active'"
          (click)="latamCountry = 'latamBr'"
        >
          {{ "navLinks.siilaBrasil" | translate }}
        </button>
      </div>
      <featured-listings
        [latamCountry]="latamCountry"
        *ngIf="currentCode == 'latam'"
      ></featured-listings>
      <featured-listings *ngIf="currentCode != 'latam'"></featured-listings>
      <div class="button-spot">
        <button
          (click)="openTenants()"
          mat-flat-button
          color="accent"
          class="btn-size"
        >
          {{ "tenants" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div>
    <industry-news
      [tagId]="tagId"
      [isSpot]="true"
      id="news"
      #news
      class="industry-container mat-elevation-z15"
    ></industry-news>
  </div>
  <div
    *ngIf="!isMobile; else mobile"
    class="border-card-white mat-elevation-z15"
  >
    <div class="spot-flex">
      <div class="build-img">
        <img
          class="spot-size"
          [alt]="spotPic"
          [title]="spotPic"
          [src]="imgProperty()"
        />
      </div>
      <div class="spot-benefits">
        <div class="check-list-spot">
          <h1
            class="demo-title siila-blue-color"
            translate="spot.{{ currentCode }}.spot.spot-info.info"
          ></h1>
          <div class="check-spot" *ngFor="let check of spotBenefits">
            <img [src]="check.icon" />
            <div class="check-info-spot">
              <span
                class="feature-product siila-grey-dark"
                [translate]="check.title"
              ></span>
              <span
                class="feature-product-info siila-grey"
                [translate]="check.desc"
              ></span>
            </div>
          </div>
        </div>
        <div class="btn-spot">
          <button
            (click)="openContactDialog(action.subscribe)"
            mat-flat-button
            color="accent"
            class="btn-donw uppercase"
          >
            {{ "more-uni" | translate }}
          </button>
        </div>
      </div>
      <div></div>
    </div>
  </div>
  <ng-template #mobile>
    <div class="border-card-white mat-elevation-z15">
      <div class="spot-flex">
        <div class="spot-benefits">
          <div class="check-list-spot">
            <h1
              class="demo-title siila-blue-color"
              translate="spot.{{ currentCode }}.spot.spot-info.info"
            ></h1>
            <div class="build-img">
              <img
                class="spot-size"
                [alt]="imgHomePageSpotAlt"
                [title]="imgHomePageSpotAlt"
                [src]="imgHomePageSpot()"
              />
            </div>
            <div class="check-spot" *ngFor="let check of spotBenefits">
              <img [src]="check.icon" />
              <div class="check-info-spot">
                <span
                  class="feature-product siila-menu-color"
                  [translate]="check.title"
                ></span>
                <span
                  class="feature-product-info siila-grey"
                  [translate]="check.desc"
                ></span>
              </div>
            </div>
          </div>
          <div class="btn-spot">
            <button
              (click)="openContactDialog(action.subscribe)"
              mat-flat-button
              color="accent"
              class="btn-donw uppercase"
            >
              {{ "more-uni" | translate }}
            </button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </ng-template>
  <div class="border-card-white mat-elevation-z15">
    <div>
      <div class="siila-spot">
        <h1
          class="title-gnrl siila-blue-color uppercase"
          translate="spot.{{ currentCode }}.spot.spot-market.title"
        ></h1>
        <h2
          class="feature-tittle siila-menu-color"
          translate="spot.{{ currentCode }}.spot.spot-market.sub-title"
        ></h2>
        <div>
          <img
            class="spot-mac-img"
            [alt]="imgHomePageSpotAlt"
            [title]="imgHomePageSpotAlt"
            [src]="imgHomePageSpot()"
          />
        </div>
        <div class="market-text">
          <p
            class="title-solution siila-menu-color uppercase"
            translate="spot.{{ currentCode }}.spot.spot-market.text"
          ></p>
        </div>
        <div
          [ngClass]="{
            'check-market-list': browserLang === 'en' || browserLang === 'pt',
            'check-market-listV2': browserLang === 'es'
          }"
        >
          <div class="check-market-container" *ngFor="let check of spotMarket">
            <div class="check-info-market">
              <span
                class="feature-product siila-blue-color"
                [translate]="check.title"
              ></span>
              <span
                class="feature-spot-info siila-menu-color"
                [translate]="check.text"
              ></span>
              <span
                class="feature-spot-info siila-menu-color"
                [translate]="check.desc"
              ></span>
            </div>
          </div>
        </div>
        <h3
          class="spot-title text-dark"
          translate="spot.{{ currentCode }}.spot.spot-market.description"
        ></h3>
        <button
          (click)="openContactDialog(action.startNow)"
          mat-flat-button
          color="accent"
          class="btn-more"
        >
          {{ "start" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z15 border-card-white">
    <div class="main-menu">
      <h1
        class="title-solution siila-menu-color"
        translate="products.{{ currentCode }}.products.demo.main-title"
      ></h1>
    </div>
    <div class="demo-card mat-elevation-z8" style="margin: 40px;">
      <div class="demo-request-list">
        <img
          class="img-digital"
          [alt]="imgDigitalAlt"
          [title]="imgDigitalAlt"
          [src]="imgDigital()"
        />
      </div>
      <div class="demo-info-spot">
        <div class="text-hide">
          <h1
            class="demo-main-title siila-grey-dark"
            translate="products.{{ currentCode }}.products.demo.title-spot"
          ></h1>
        </div>
        <form [formGroup]="form" class="demo-request-spot">
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "name" | translate }}</mat-label>
            <input formControlName="firstName" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "last-name" | translate
            }}</mat-label>
            <input formControlName="lastName" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "address" | translate }}</mat-label>
            <input formControlName="email" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "phone" | translate }}</mat-label>
            <input formControlName="phoneNumber" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "company" | translate }}</mat-label>
            <input formControlName="company" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "position" | translate
            }}</mat-label>
            <input formControlName="position" matInput />
          </mat-form-field>
          <div class="demo-btn-spot">
            <button
              [disabled]="form.invalid"
              (click)="downloadFormIndex()"
              mat-flat-button
              color="accent"
              class="btn-demo-spot"
            >
              {{ "btn-load" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z15 border-card-white">
    <div class="service-info">
      <h1
        class="pro-title siila-blue-color"
        translate="spot.{{ currentCode }}.spot.spot-service.title"
      ></h1>
      <h2
        class="title-solution siila-menu-color"
        translate="spot.{{ currentCode }}.spot.spot-service.sub-title"
      ></h2>
    </div>
    <div class="service-container">
      <div class="news-item" *ngFor="let item of serviceList">
        <img
          style="height: 274;"
          [alt]="item.alt"
          [title]="item.alt"
          [src]="item.icon"
        />
        <span
          class="service-style siila-grey-dark"
          [translate]="item.title"
        ></span>
      </div>
    </div>
    <div class="btn-service">
      <button
        (click)="openContactDialog(action.contactUs)"
        mat-flat-button
        color="accent"
        class="serv-btn uppercase"
      >
        {{ "contact" | translate }}
      </button>
    </div>
  </div>
  <app-solution-list></app-solution-list>
  <!-- <app-featured-resources></app-featured-resources> -->
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
