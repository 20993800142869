<div class="flex-row flex-justify">
  <div class="flex-privacy">
    <div *ngIf="currentEnv === 'br'" style="padding-top: 30px;">
      <div class="title-section-head">
        <h3
          class="title-section row title-border"
          translate="privacy-policy.title"
        ></h3>
      </div>

      <p translate="privacy-policy.paragraphs.p1"></p>

      <h4 translate="privacy-policy.subtitles.s1"></h4>
      <h6 translate="privacy-policy.subtitles.second.sec1"></h6>
      <p translate="privacy-policy.paragraphs.p2"></p>

      <h6 translate="privacy-policy.subtitles.second.sec2"></h6>
      <p translate="privacy-policy.paragraphs.p3"></p>

      <h6 translate="privacy-policy.subtitles.second.sec3"></h6>
      <p translate="privacy-policy.paragraphs.p4"></p>

      <h6 translate="privacy-policy.subtitles.second.sec4"></h6>
      <p translate="privacy-policy.paragraphs.p5"></p>

      <h4 translate="privacy-policy.subtitles.s2"></h4>
      <p translate="privacy-policy.paragraphs.p6"></p>

      <h4 translate="privacy-policy.subtitles.s3"></h4>
      <p translate="privacy-policy.paragraphs.p7"></p>

      <h6 translate="privacy-policy.subtitles.second.sec5"></h6>
      <p translate="privacy-policy.paragraphs.p8"></p>
      <p translate="privacy-policy.paragraphs.p9"></p>
      <h6 translate="privacy-policy.subtitles.second.sec6"></h6>
      <p translate="privacy-policy.paragraphs.p10"></p>
      <p translate="privacy-policy.paragraphs.p11"></p>
      <p translate="privacy-policy.paragraphs.p12"></p>
      <p translate="privacy-policy.paragraphs.p13"></p>

      <h4 translate="privacy-policy.subtitles.s4"></h4>
      <p translate="privacy-policy.paragraphs.p14"></p>

      <h4 translate="privacy-policy.subtitles.s5"></h4>
      <p translate="privacy-policy.paragraphs.p15"></p>

      <h4 translate="privacy-policy.subtitles.s6"></h4>
      <p translate="privacy-policy.paragraphs.p16"></p>

      <h4 translate="privacy-policy.subtitles.s7"></h4>
      <p translate="privacy-policy.paragraphs.p17"></p>

      <h4 translate="privacy-policy.subtitles.s8"></h4>
      <p translate="privacy-policy.paragraphs.p18"></p>

      <h4 translate="privacy-policy.subtitles.s9"></h4>
      <p translate="privacy-policy.paragraphs.p19"></p>

      <div class="pull-right footer-privacy footer-rights">
        <p translate="privacy-policy.date"></p>
        <p translate="privacy-policy.reserved"></p>
      </div>
    </div>

    <div
      *ngIf="currentEnv === 'mx' || currentEnv === 'co'"
      style="padding-top: 30px;"
    >
      <h2 class="terms-center" translate="terms-of-use.mx.termsHeader"></h2>
      <h2 class="terms-center2" translate="terms-of-use.mx.termsSubHeader"></h2>
      <p
        class="terms-header"
        [innerHTML]="'terms-of-use.mx.header1' | translate"
      ></p>
      <p
        class="terms-header"
        [innerHTML]="'terms-of-use.mx.header2' | translate"
      ></p>
      <ol>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t1' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t2' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t3' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t4' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t5' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t6' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t7' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t8' | translate"
        ></li>
        <li
          class="terms-list"
          [innerHTML]="'terms-of-use.mx.t9' | translate"
        ></li>
      </ol>
      <p class="terms-foot" translate="terms-of-use.mx.footText"></p>
    </div>
  </div>
</div>
