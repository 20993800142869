import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  NewDeviceDialogComponent,
  ExistingDeviceDialogComponent,
  NewDeviceSuccessComponent
} from "../../../../shared/components/dialogs/";

import { NGXLogger } from "ngx-logger";
import { WindowRef } from "../../../../window-ref.service";

@Injectable()
export class DeviceRegistrationDialogService {
  dialogRef: any;

  constructor(
    public _d: MatDialog,
    private _logger: NGXLogger,
    private _windowRef: WindowRef
  ) {}

  /**
   * @param {*} [data]
   */
  openDialog(data?: any, dialogKey?: string): void {
    let dialogComp: any = this.getDialogByKey(dialogKey);

    this._d.closeAll();

    this.dialogRef = this._d.open(dialogComp, {
      id: "registerDialog",
      height: "360px",
      width: "550px",
      data: data,
      disableClose: true
    });
  }

  getDialogByKey(key: string) {
    switch (key.toLowerCase()) {
      case "new":
        return NewDeviceDialogComponent;
      case "existing":
        return ExistingDeviceDialogComponent;
      case "success":
        return NewDeviceSuccessComponent;
    }
  }

  /**
   * @returns
   */
  close() {
    this.dialogRef.close({ action: "close" });
  }
}
