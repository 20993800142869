export interface DynamicComponentManifest {
  componentId: string;
  path: string;
  loadChildren: string;
}

export interface DynamicComponent {
  ALLOWS_REGIONS: Regions[];
}

export enum Regions {
  ES = "es",
  BR = "br",
  MX = "mx"
}
