/**
 * @function _guid
 * @description Creates GUID for user based on several different browser variables
 * It will never be RFC4122 compliant but it is robust
 * @returns {Number}
 * @private
 */
export function guiid() {
  const socketSecondFactorHash = localStorage.getItem("client-fingerprint");
  const guest_murmur = localStorage.getItem("client-guest-murmur");

  const encodedGuiid = window.btoa(`${socketSecondFactorHash}${guest_murmur}`);

  return encodedGuiid;
}

export function isString(obj) {
  return obj !== undefined && obj !== null && obj.constructor == String;
}
