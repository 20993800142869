<div class="d-flex align-items-center modal-heading">
  <h2 mat-dialog-title translate="newFeatureHeader" class="title"></h2>
  <embed alt="siila" src="assets/img/SiiLA_SVG.svg" class="brand-logo" />
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="py-2 mt-2 overflow-auto">
  <div class="mb-5" *ngFor="let newFeature of newFeatures">
    <!--h4 class="header" [innerHtml]="newFeature.header"></h4-->
    <p class="description" [innerHtml]="newFeature.description"></p>
    <ul
      class="feature-list"
      *ngIf="newFeature.items && newFeature.items.length"
    >
      <li *ngFor="let item of newFeature.items" class="feature-content-list">
        <p class="feature-content bold" [innerHtml]="item"></p>
      </li>
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    aria-label="ok"
    mat-flat-button
    class="siila-blue-submit"
    matDialogClose
  >
    <span translate="ok"></span>
  </button>
</mat-dialog-actions>
