import { environment } from "../../../environments/environment";
import { Link } from "./footer.component";

//MX Links
export const socialMediaIconsMX: Link[] = [
  {
    ref: "assets/icons/linkedin.svg",
    link: "https://www.linkedin.com/company/siila-mx"
  },
  {
    ref: "assets/icons/youtube.svg",
    link: "https://www.youtube.com/channel/UCTm4KXeOEGPEyCNyCHH_Jgw"
  },
  {
    ref: "assets/icons/facebook.svg",
    link: "https://www.facebook.com/SiiLA.mx"
  },
  {
    ref: "assets/icons/instagram.svg",
    link: "https://www.instagram.com/siilaspot.mx/"
  },
  {
    ref: "assets/icons/twitter.svg",
    link: "https://twitter.com/SiiLA_mx"
  },
  {
    ref: "assets/icons/tiktok.svg",
    link: "https://www.tiktok.com/@siilaspot.mx"
  }
];

export const contactIconsMX: Link[] = [
  {
    ref: "assets/icons/contact-icons/case.png",
    link: "/about",
    text: "About SiiLA",
    textEs: "Sobre SiiLA",
    matIcon: "cases"
  },
  {
    ref: "assets/icons/contact-icons/phone.png",
    link: "tel:+52(55)64606529",
    text: "+52 (55) 6460-6529",
    textEs: "+52 (55) 6460-6529",
    matIcon: "phone"
  },
  {
    ref: "assets/icons/contact-icons/email-sm.png",
    link: "mailto:contacto@siila.com.mx",
    text: "contacto@siila.com.mx",
    textEs: "contacto@siila.com.mx",
    matIcon: "email"
  },
  {
    ref: "assets/icons/contact-icons/globe.png",
    link: "https://www.siila.com.mx",
    text: "www.siila.com.mx",
    textEs: "www.siila.com.mx",
    matIcon: "public"
  },
  {
    ref: "assets/icons/contact-icons/pin.png",
    link:
      "https://www.google.com/maps/place/SiiLA/@19.4244158,-99.1700071,17z/data=!3m1!4b1!4m6!3m5!1s0x85d201649c346d13:0x580849fb9052afb!8m2!3d19.4244158!4d-99.1678184!16s%2Fg%2F11f5p1nzfs",
    text: "SiiLA MX HQ",
    textEs: "SiiLA MX HQ",
    matIcon: "location_pin"
  }
];

//CO Links
export const socialMediaIconsCO: Link[] = [
  {
    ref: "assets/icons/linkedin.svg",
    link: "https://www.linkedin.com/company/siila-co/"
  },
  {
    ref: "assets/icons/youtube.svg",
    link: "https://www.youtube.com/channel/UCTm4KXeOEGPEyCNyCHH_Jgw"
  },
  {
    ref: "assets/icons/facebook.svg",
    link: "https://www.facebook.com/SiiLA.mx"
  },
  {
    ref: "assets/icons/instagram.svg",
    link: "https://www.instagram.com/siila.co/"
  },
  {
    ref: "assets/icons/twitter.svg",
    link: "https://twitter.com/SiiLA_mx"
  },
  {
    ref: "assets/icons/tiktok.svg",
    link: "https://www.tiktok.com/@siilaspot.mx"
  }
];

export const contactIconsCO: Link[] = [
  {
    ref: "assets/icons/contact-icons/case.png",
    link: "/about",
    text: "About SiiLA",
    textEs: "Sobre SiiLA",
    matIcon: "cases"
  },
  {
    ref: "assets/icons/contact-icons/phone.png",
    link: "tel:+52(55)64606529",
    text: "+52 (55) 6460-6529",
    textEs: "+52 (55) 6460-6529",
    matIcon: "phone"
  },
  {
    ref: "assets/icons/contact-icons/email-sm.png",
    link: "mailto:contacto@siila.com.co",
    text: "contacto@siila.com.co",
    textEs: "contacto@siila.com.co",
    matIcon: "email"
  },
  {
    ref: "assets/icons/contact-icons/globe.png",
    link: "https://www.siila.com.co",
    text: "www.siila.com.co",
    textEs: "www.siila.com.co",
    matIcon: "public"
  },
  {
    ref: "assets/icons/contact-icons/pin.png",
    link:
      "https://www.google.com/maps/place/SiiLA/@19.4244158,-99.1700071,17z/data=!3m1!4b1!4m6!3m5!1s0x85d201649c346d13:0x580849fb9052afb!8m2!3d19.4244158!4d-99.1678184!16s%2Fg%2F11f5p1nzfs",
    text: "SiiLA CO HQ",
    textEs: "SiiLA CO HQ",
    matIcon: "location_pin"
  }
];

export const productLinksMX: Link[] = [
  /* {
    link: "/products",
    text: "footer.headers.products",
    target: "_self"
  }, */
  {
    link: "/marketanalytics",
    text: "footer.headers.marketAnalytics",
    target: "_self",
    class: "uppercase"
  },
  {
    link:
      environment.platformId == "mx" || environment.platformId == "latam"
        ? "fibra-analytics"
        : "/fii-data-pro",
    text: "footer.headers.fibra",
    target: "_self",
    class: "lowercase"
  },
  /*  {
    link: "/products/104/grocs",
    text: "footer.headers.grocs",
    target: "_self"
  }, */
  {
    link: "/products/105/siila-mexico-index",
    target: "_self",
    text: "footer.headers.indices",
    class: "uppercase"
  },
  {
    link: "/products/106/investor-survey",
    target: "_self",
    text: "footer.headers.survey",
    class: "uppercase"
  },
  {
    link: "/SPOT",
    text: "footer.headers.siilaSpot",
    target: "_self",
    class: "uppercase"
  },

  {
    link: "/academy",
    text: "footer.headers.academy",
    target: "_self",
    class: "uppercase"
  } /* ,
  {
    link:
      "https://www.clubefii.com.br/plano-de-assinatura-fii-data-professional?cod_rev=siila",
    target: "_self",
    text: "footer.headers.indices"
  } */
];

export const solutionsLinksMX: Link[] = [
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Institutional Investors",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Brokerage Firms",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Developers",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Service Providers",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Fii Investors",
    target: "_blank"
  }
];

//Latam Links
export const socialMediaIconsLatam: Link[] = [
  {
    ref: "assets/icons/linkedin.svg",
    link: "https://www.linkedin.com/company/siila/"
  },
  {
    ref: "assets/icons/youtube.svg",
    link: "https://www.youtube.com/channel/UCTm4KXeOEGPEyCNyCHH_Jgw"
  },
  {
    ref: "assets/icons/facebook.svg",
    link: "https://www.facebook.com/SiiLASPOT.MX"
  },
  {
    ref: "assets/icons/instagram.svg",
    link: "https://www.instagram.com/siila.latam/"
  },
  {
    ref: "assets/icons/twitter.svg",
    link: "https://twitter.com/SiiLA_mx"
  },
  {
    ref: "assets/icons/tiktok.svg",
    link: "https://www.tiktok.com/@siilaspot.mx"
  }
];

export const contactIconsLatam: Link[] = [
  {
    ref: "assets/icons/contact-icons/case.png",
    link: "/about",
    text: "About SiiLA",
    textEs: "Sobre SiiLA",
    matIcon: "cases"
  },
  {
    ref: "assets/icons/contact-icons/phone.png",
    link: "tel:+1(972)6163048",
    text: "+1 (972) 616-3048",
    textEs: "+1 (972) 616-3048",
    matIcon: "phone"
  },
  {
    ref: "assets/icons/contact-icons/email-sm.png",
    link: "mailto:contact@siila.com",
    text: "contact@siila.com",
    textEs: "contact@siila.com",
    matIcon: "email"
  },
  {
    ref: "assets/icons/contact-icons/globe.png",
    link: "https://www.siila.com",
    text: "www.siila.com",
    textEs: "www.siila.com",
    matIcon: "public"
  },
  {
    ref: "assets/icons/contact-icons/pin.png",
    link: "https://goo.gl/maps/VtzVcYTReXvB8CBB6",
    text: "SiiLA US HQ",
    textEs: "SiiLA US HQ",
    matIcon: "location_pin"
  }
];

export const productLinksLatam: Link[] = [
  /* {
    link: "/products",
    text: "footer.headers.products",
    target: "_self"
  }, */
  {
    link: "/marketanalytics",
    text: "footer.headers.marketAnalytics",
    target: "_self",
    class: "uppercase"
  },
  {
    link: "/fii-data-pro",
    text: "footer.headers.fii",
    target: "_self",
    class: "lowercase"
  },
  {
    link: "/fibra-analytics",
    text: "footer.headers.fibra",
    target: "_self",
    class: "lowercase"
  },
  /*  {
    link: "/products/104/grocs",
    text: "footer.headers.grocs",
    target: "_self"
  }, */
  {
    link: "/products/105/siila-mexico-index",
    target: "_self",
    text: "footer.headers.indices",
    class: "uppercase"
  },
  {
    link: "/products/106/investor-survey",
    target: "_self",
    text: "footer.headers.survey",
    class: "uppercase"
  },
  {
    link: "/SPOT",
    text: "footer.headers.siilaSpot",
    target: "_self",
    class: "uppercase"
  },

  {
    link: "/academy",
    text: "footer.headers.academy",
    target: "_self",
    class: "uppercase"
  } /* ,
  {
    link:
      "https://www.clubefii.com.br/plano-de-assinatura-fii-data-professional?cod_rev=siila",
    target: "_self",
    text: "footer.headers.indices"
  } */
];

export const solutionsLinksLatam: Link[] = [
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Institutional Investors",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Brokerage Firms",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Developers",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Service Providers",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Fii Investors",
    target: "_blank"
  }
];

//BR Links

export const socialMediaIconsBR: Link[] = [
  {
    ref: "assets/icons/linkedin.svg",
    link: "https://www.linkedin.com/company/siila-br"
  },
  {
    ref: "assets/icons/youtube.svg",
    link: "https://www.youtube.com/c/SiiLABR"
  },
  {
    ref: "assets/icons/facebook.svg",
    link: "https://web.facebook.com/SiiLA.BR/"
  },
  {
    ref: "assets/icons/instagram.svg",
    link: "https://www.instagram.com/siila.br"
  },
  {
    ref: "assets/icons/twitter.svg",
    link:
      "https://twitter.com/SiiLA_br?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
  },
  {
    ref: "assets/icons/tiktok.svg",
    link: "https://www.tiktok.com/@siilaspot.br"
  }
];

export const contactIconsBR: Link[] = [
  {
    ref: "assets/icons/contact-icons/case.png",
    link: "/about",
    text: "footer.headers.aboutSiila",
    matIcon: "cases"
  },
  {
    ref: "assets/icons/contact-icons/phone.png",
    link: "tel:+551130469595",
    text: "+55 11 3046-9595",
    matIcon: "phone"
  },
  {
    ref: "assets/icons/contact-icons/email-sm.png",
    link: "mailto:contato@siila.com.br",
    text: "contato@siila.com.br",
    matIcon: "email"
  },
  {
    ref: "assets/icons/contact-icons/globe.png",
    link: "https://www.siila.com.br",
    text: "www.siila.com.br",
    matIcon: "public"
  },
  {
    ref: "assets/icons/contact-icons/pin.png",
    link:
      "https://www.google.com.br/maps/place/SiiLA/@-23.5980393,-46.6836632,15z/data=!4m6!3m5!1s0x94ce50cc78363403:0xcfe09d29279d7fa8!8m2!3d-23.5980393!4d-46.6836632!16s%2Fg%2F11bysjw3gp",
    text: "SEDE DA SiiLA NO BRASIL",
    matIcon: "location_pin"
  }
];

export const productLinksBR: Link[] = [
  {
    link: "/marketanalytics",
    text: "footer.headers.marketAnalytics",
    target: "_self",
    class: "uppercase"
  },
  {
    link: "/products/101/grocs",
    text: "footer.headers.grocs",
    target: "_self",
    class: "uppercase"
  },
  {
    link: "/products/102/siila-brasil-index",
    target: "_self",
    text: "footer.headers.indices",
    class: "uppercase"
  },
  {
    link: "/products/103/investor-survey",
    target: "_self",
    text: "footer.headers.survey",
    class: "uppercase"
  },
  {
    link: "/SPOT",
    text: "footer.headers.marketList",
    target: "_blank",
    class: "uppercase"
  },
  {
    link: "/academy",
    text: "footer.headers.academy",
    target: "_self",
    class: "uppercase"
  },
  {
    link:
      environment.platformId == "mx" || environment.platformId == "latam"
        ? "fibra-analytics"
        : "/fii-data-pro",
    text: "footer.headers.fii",
    target: "_self",
    class: "lowercase"
  }
];

export const solutionsLinksBR: Link[] = [
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Institutional Investors",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Brokerage Firms",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Developers",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Service Providers",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.solutions.Fii Investors",
    target: "_blank"
  }
];

export const insightsLink: Link[] = [
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.insights.news",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.insights.resources",
    target: "_blank"
  },
  {
    link: "https://www.siilabrasil.blog/",
    text: "footer.headers.insights.media",
    target: "_blank"
  }
];
