<div class="language-menu">
  <button
    class="selected-language "
    [ngClass]="{ 'menu-item': isMobile, 'menu-item-desktop': !isMobile }"
    mat-flat-button
    [matMenuTriggerFor]="languageSelectionMenu"
  >
    <div class="icon-flex">
      <mat-icon *ngIf="isMobile">language</mat-icon>
      <div class="nav-items">
        <span class="language-label blue-link mat-h3">{{
          selectedLanguage | translate
        }}</span>
        <mat-icon *ngIf="isLocalStorageSupported()">arrow_drop_down</mat-icon>
      </div>
    </div>
  </button>
  <mat-menu #languageSelectionMenu>
    <button
      mat-menu-item
      class="no-outline"
      [ngClass]="isItemActive('en')"
      (click)="setupLanguage('en')"
    >
      <span class="language-item mat-h3" translate="navLinks.english"></span>
    </button>

    <button
      mat-menu-item
      class="no-outline"
      *ngIf="currentCode != 'latam' && isCurrentRegion('br')"
      [ngClass]="isItemActive('pt')"
      (click)="setupLanguage('pt')"
    >
      <span class="language-item mat-h3" translate="navLinks.portuguese"></span>
    </button>

    <button
      mat-menu-item
      class="no-outline"
      *ngIf="isCurrentRegion('mx') || currentCode == 'co'"
      [ngClass]="isItemActive('es')"
      (click)="setupLanguage('es')"
    >
      <span class="language-item mat-h3" translate="navLinks.spanish"></span>
    </button>
  </mat-menu>
</div>
