<section class="product-container">
  <div class="card-feature mat-elevation-z15">
    <div class="card-info">
      <h4
        translate="products.{{ currentCode }}.products.features-card.overline"
        class="title-gnrl"
      ></h4>
      <h2
        translate="products.{{ currentCode }}.products.features-card.tittle"
        class="feature-tittle"
      ></h2>
      <h3
        translate="products.{{ currentCode }}.products.features-card.sub-tittle"
        class="product-description"
      ></h3>
      <div>
        <button
          (click)="openContactDialog()"
          mat-flat-button
          color="accent"
          class="btn-more"
        >
          {{ "more" | translate }}
        </button>
      </div>
    </div>
    <div class="product-video">
      <div class="video-mobile" *ngIf="lang === 'en'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_aogdddka9c seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/aogdddka9c/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-mobile" *ngIf="lang === 'es'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_g5dkefxng2 seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/g5dkefxng2/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-mobile" *ngIf="lang === 'pt'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_d50duchn28 seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/d50duchn28/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-market-analytic-card
    [includeMarketAnalyticsV1]="true"
  ></app-market-analytic-card>
  <div class="mat-elevation-z15 border-card-market">
    <div class="marketing-title">
      <h1
        translate="products.{{
          currentCode
        }}.products.marketing-card.marketing-product.title"
        class="title-gnrl siila-blue-color"
      ></h1>
      <h2
        translate="products.{{
          currentCode
        }}.products.marketing-card.marketing-product.sub-title"
        class="marketing-sub siila-grey-dark"
      ></h2>
    </div>
    <div class="products-flex">
      <div class="marketing-mac">
        <img
          [alt]="imgMarketingTitle"
          [title]="imgMarketingTitle"
          (click)="openSpot()"
          class="img-product cursor"
          [src]="imgMarketing()"
        />
        <div style="display: flex;">
          <button
            *ngIf="currentCode != 'co'"
            (click)="openSpot()"
            [hidden]="!isMobile"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "more" | translate }}
          </button>
          <button
            *ngIf="currentCode == 'co'"
            (click)="openSpot('mx')"
            [hidden]="!isMobile"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "news.mexico" | translate }}
          </button>
          <button
            *ngIf="currentCode == 'co'"
            (click)="openSpot('br')"
            [hidden]="!isMobile"
            mat-flat-button
            color="accent"
            class="btn-more ml-2"
          >
            {{ "news.brasil" | translate }}
          </button>
        </div>
      </div>
      <div class="siila-info">
        <h2
          translate="products.{{
            currentCode
          }}.products.marketing-card.marketing-product.real2"
          class="title-gnrl siila-blue-color"
        ></h2>
        <h1
          translate="products.{{
            currentCode
          }}.products.marketing-card.marketing-product.spot"
          class="feature-tittle siila-grey-dark"
        ></h1>
        <p
          [ngStyle]="{
            'text-align': currentCode != 'co' ? 'inherit' : 'justify'
          }"
          translate="products.{{
            currentCode
          }}.products.marketing-card.marketing-product.text"
          class="product-description siila-grey"
        ></p>
        <div class="product-btn" [hidden]="isMobile">
          <button
            *ngIf="currentCode != 'co'"
            (click)="openSpot()"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "more" | translate }}
          </button>
          <button
            *ngIf="currentCode == 'co'"
            (click)="openSpot('mx')"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "news.mexico" | translate }}
          </button>
          <button
            *ngIf="currentCode == 'co'"
            (click)="openSpot('br')"
            mat-flat-button
            color="accent"
            class="btn-more ml-2"
          >
            {{ "news.brasil" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="border-line"></div>
      <div
        *ngIf="currentCode == 'co' || (currentCode == 'latam' && lang == 'en')"
      >
        <div
          class="home-card-spot mat-elevation-z15"
          *ngIf="!isMobile; else mobile"
        >
          <div class="siila-menu">
            <img
              class="logo-menu"
              alt="siila"
              src="../../../../../assets/img/home-list/logo-mx.png"
            />
            <h1
              class="home-spot-title"
              translate="home.{{ currentCode }}.viewProperties"
            ></h1>
          </div>
          <div class="search-label">
            <div
              class="content-select"
              *ngIf="currentCode == 'latam' || currentCode == 'co'"
            >
              <select
                style="width: 170px;"
                class="option-label"
                [(ngModel)]="latamCountry"
              >
                <option value="latamMx">{{
                  "navLinks.siilaMexico" | translate
                }}</option>
                <option value="latamBr">{{
                  "navLinks.siilaBrasil" | translate
                }}</option>
              </select>
              <i></i>
            </div>
            <div class="content-select">
              <select
                [ngStyle]="{ width: currentCode == 'latam' ? '170' : '250px' }"
                class="option-label"
                [(ngModel)]="SaleLease"
              >
                <option value="sale">{{ "global.forSale" | translate }}</option>
                <option value="lease">{{
                  "global.forLease" | translate
                }}</option>
              </select>
              <i></i>
            </div>
            <div class="content-select">
              <select
                [ngStyle]="{ width: currentCode == 'latam' ? '170' : '250px' }"
                [(ngModel)]="propertyType"
                class="option-label"
              >
                <option value="office">{{
                  "global.office" | translate
                }}</option>
                <option value="warehouse">{{
                  "global.industrial" | translate
                }}</option>
                <option value="coworking">{{
                  "global.coworkingLabel" | translate
                }}</option>
              </select>
              <i></i>
            </div>
            <input
              class="email-label"
              type="email"
              [(ngModel)]="property"
              placeholder="{{ 'search' | translate }}"
            />
            <div class="search">
              <button
                (click)="openSearch()"
                mat-flat-button
                color="accent"
                class="btn-search"
              >
                {{ "looking" | translate }}
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="featured-title">
        <div class="title-content" *ngIf="currentCode == 'co'">
          <h1
            class="commercial-title siila-menu-color"
            translate="home.{{ currentCode }}.commercial.properties-title2"
          ></h1>
          <h2
            class="sub-title-propie"
            translate="home.{{ currentCode }}.commercial.properties-sub-title"
          ></h2>
        </div>
        <div *ngIf="currentCode != 'co'">
          <h1
            class="pro-title siila-blue-color uppercase"
            translate="home.{{ currentCode }}.commercial.properties-title2"
          ></h1>
          <h2
            class="feature-tittle siila-grey-dark"
            translate="home.{{ currentCode }}.commercial.properties-sub-title2"
          ></h2>
        </div>

        <div class="btn-latam" *ngIf="currentCode == 'latam'">
          <button
            class="form-control"
            [ngClass]="
              latamCountry == 'latamMx' ? 'btn-active' : 'btn-no-active'
            "
            (click)="latamCountry = 'latamMx'"
          >
            {{ "navLinks.siilaMexico" | translate }}
          </button>
          <button
            class="form-control"
            [ngClass]="
              latamCountry == 'latamBr' ? 'btn-active' : 'btn-no-active'
            "
            (click)="latamCountry = 'latamBr'"
          >
            {{ "navLinks.siilaBrasil" | translate }}
          </button>
        </div>
      </div>
      <featured-listings
        [latamCountry]="latamCountry"
        *ngIf="currentCode == 'latam' || currentCode == 'co'"
      ></featured-listings>
      <featured-listings
        *ngIf="currentCode != 'latam' && currentCode != 'co'"
      ></featured-listings>
    </div>
    <ng-template
      #mobile
      *ngIf="currentCode == 'co' || (currentCode == 'latam' && lang == 'en')"
    >
      <div class="home-card-spot-latam">
        <div class="siila-menu">
          <img
            class="logo-menu"
            alt="siila"
            src="../../../../../assets/img/home-list/logo-mx.png"
          />
          <h1
            class="home-spot-title"
            translate="home.{{ currentCode }}.viewProperties"
          ></h1>
        </div>
        <div class="button-container">
          <div
            [ngClass]="SaleLease === 'lease' ? 'buttons-sale' : 'buttons-lease'"
          >
            <span class="btn-text" (click)="SaleLease = 'lease'">{{
              "global.forLease" | translate
            }}</span>
          </div>
          <div
            [ngClass]="SaleLease !== 'lease' ? 'buttons-sale' : 'buttons-lease'"
          >
            <span class="btn-text" (click)="SaleLease = 'sale'">{{
              "global.forSale" | translate
            }}</span>
          </div>
        </div>
        <div class="search-label-latam">
          <div
            class="content-select"
            *ngIf="currentCode == 'latam' || currentCode == 'co'"
          >
            <select class="option-label" [(ngModel)]="latamCountry">
              <option value="latamMx">{{
                "navLinks.siilaMexico" | translate
              }}</option>
              <option value="latamBr">{{
                "navLinks.siilaBrasil" | translate
              }}</option>
            </select>
            <i></i>
          </div>
          <div class="content-select">
            <select [(ngModel)]="propertyType" class="option-label">
              <option value="office">{{ "global.office" | translate }}</option>
              <option value="warehouse">{{
                "global.industrial" | translate
              }}</option>
              <option value="coworking">{{
                "global.coworkingLabel" | translate
              }}</option>
            </select>
            <i></i>
          </div>
          <div>
            <input
              class="email-label"
              type="email"
              [(ngModel)]="property"
              placeholder="{{ 'search' | translate }}"
            />
            <button
              (click)="openSearch()"
              mat-flat-button
              color="accent"
              class="btn-search"
            >
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="btn-see" *ngIf="currentCode != 'co'">
      <button
        (click)="openHome()"
        mat-flat-button
        color="accent"
        class="btn-grocs"
      >
        {{ "see" | translate }}
      </button>
    </div>
  </div>
  <div class="mat-elevation-z15 border-card">
    <div class="pro-flex">
      <div class="academy-img">
        <img
          class="img-academy"
          [alt]="imgAcademyAlt"
          [title]="imgAcademyAlt"
          [src]="imgAcademy()"
        />
        <div class="btn-more-product" [hidden]="!isMobile">
          <button
            (click)="openAcademy()"
            mat-flat-button
            color="accent"
            class="btn-more uppercase"
          >
            {{ "enroll" | translate }}
          </button>
        </div>
      </div>
      <div class="academy-info">
        <h2
          translate="products.{{
            currentCode
          }}.products.marketing-card.siilaAcademy.title"
          class="title-gnrl siila-blue-color"
        ></h2>
        <h1
          translate="products.{{
            currentCode
          }}.products.marketing-card.siilaAcademy.sub-title"
          class="feature-tittle siila-grey-dark"
        ></h1>
        <p
          translate="products.{{
            currentCode
          }}.products.marketing-card.siilaAcademy.text"
          class="product-description siila-grey"
        ></p>
        <div [hidden]="isMobile">
          <button
            (click)="openAcademy()"
            mat-flat-button
            color="accent"
            class="btn-more uppercase"
          >
            {{ "enroll" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z15 border-card" *ngIf="currentCode == 'latam'">
    <div class="pro-flex-fii">
      <div class="siila-data-info">
        <h2
          translate="products.mx.products.marketing-card.dataPro.title"
          class="title-gnrl siila-blue-color"
        ></h2>
        <h1
          translate="products.mx.products.marketing-card.dataPro.sub-title"
          class="feature-tittle siila-grey-dark"
        ></h1>
        <p
          translate="products.mx.products.marketing-card.dataPro.text"
          class="product-description siila-grey"
        ></p>
        <div class="btn-data-pro">
          <button
            (click)="openFiiDataMx()"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "moreInfo" | translate }}
          </button>
        </div>
      </div>
      <div class="data-pro-img">
        <img
          class="img-data"
          [alt]="imgFiiAlt"
          [title]="imgFiiAlt"
          [src]="imgFiiMx()"
        />
      </div>
    </div>
  </div>
  <div class="mat-elevation-z15 border-card">
    <div class="pro-flex-fii">
      <div class="siila-data-info">
        <h2
          translate="products.{{
            currentCode
          }}.products.marketing-card.dataPro.title"
          class="title-gnrl siila-blue-color"
        ></h2>
        <h1
          translate="products.{{
            currentCode
          }}.products.marketing-card.dataPro.sub-title"
          class="feature-tittle siila-grey-dark"
        ></h1>
        <p
          [ngStyle]="{
            'text-align': currentCode != 'co' ? 'inherit' : 'justify'
          }"
          translate="products.{{
            currentCode
          }}.products.marketing-card.dataPro.text"
          class="product-description siila-grey"
        ></p>
        <div class="btn-data-pro">
          <button
            *ngIf="currentCode != 'co'"
            (click)="openFiiData()"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "moreInfo" | translate }}
          </button>
          <button
            *ngIf="currentCode == 'co'"
            (click)="openFiiDataMx()"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "news.mexico" | translate }}
          </button>
          <button
            *ngIf="currentCode == 'co'"
            (click)="openFiiData()"
            mat-flat-button
            color="accent"
            class="btn-more ml-2"
          >
            {{ "news.brasil" | translate }}
          </button>
        </div>
      </div>
      <div class="data-pro-img">
        <img
          class="img-data"
          [alt]="imgFiiAlt"
          [title]="imgFiiAlt"
          [src]="imgFii()"
        />
      </div>
    </div>
  </div>
  <div
    class="mat-elevation-z15 border-card-white"
    style="padding: 38px;
  margin: 34px 0px 34px 0px;border-radius: 12px;"
  >
    <div class="demo-card mat-elevation-z8" style="margin-top: 0px !important;">
      <img
        [src]="imgAllProducts()"
        [alt]="imgAllProductsAlt"
        [title]="imgAllProductsAlt"
        class="demo-request-img"
      />
      <div class="demo-info-container">
        <h1
          class="demo-main-title siila-grey-dark"
          translate="products.{{ currentCode }}.products.demo.title"
        ></h1>
        <form [formGroup]="form" class="demo-request-media">
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "fullName" | translate
            }}</mat-label>
            <input formControlName="fullName" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "address" | translate }}</mat-label>
            <input formControlName="email" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "phone" | translate }}</mat-label>
            <input formControlName="phoneNumber" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "company" | translate }}</mat-label>
            <input formControlName="company" matInput />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "position" | translate
            }}</mat-label>
            <input formControlName="position" matInput />
          </mat-form-field>
          <div class="demo-btn">
            <button
              [disabled]="form.invalid"
              (click)="downloadFormIndex()"
              mat-flat-button
              color="accent"
              class="btn-demo-request"
            >
              {{ "btn-load" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-solution-list></app-solution-list>
  <app-pillars></app-pillars>
  <!-- <app-featured-resources></app-featured-resources> -->
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
