<section class="about-container">
  <div class="card-company mat-elevation-z15">
    <div class="company">
      <div class="company-info">
        <h3
          translate="about.{{ currentCode }}.about.company"
          class="title-gnrl uppercase"
        ></h3>
        <h2
          translate="about.{{ currentCode }}.about.title"
          class="company-tittle"
        ></h2>
      </div>
      <div class="company-video">
        <div class="video-mobile" *ngIf="browserLang === 'en'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_aogdddka9c seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/aogdddka9c/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-mobile" *ngIf="browserLang === 'es'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_g5dkefxng2 seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/g5dkefxng2/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="video-mobile" *ngIf="browserLang === 'pt'">
          <div
            class="wistia_responsive_padding"
            style="padding:56.25% 0 0 0;position:relative;"
          >
            <div
              class="wistia_responsive_wrapper"
              style="height:100%;left:0;position:absolute;top:0;width:100%;"
            >
              <div
                class="wistia_embed wistia_async_d50duchn28 seo=false videoFoam=true"
                style="height:100%;position:relative;width:100%"
              >
                <div
                  class="wistia_swatch"
                  style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/d50duchn28/swatch"
                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                    alt=""
                    aria-hidden="true"
                    onload="this.parentNode.style.opacity=1;"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-btn">
      <button
        (click)="openContactDialog()"
        mat-flat-button
        color="accent"
        class="btn-touch uppercase"
      >
        {{ "touchs" | translate }}
      </button>
    </div>
  </div>

  <div
    [ngClass]="{
      'border-desktop': this.isMobile,
      'border-card mat-elevation-z15': !this.isMobile
    }"
    class="company-margin"
  >
    <div class="about-silla">
      <h3
        translate="about.{{ currentCode }}.about.about-siila.title"
        class="title-gnrl siila-blue-color"
      ></h3>
      <div>
        <p
          translate="about.{{ currentCode }}.about.about-siila.text"
          class="company-sub-tittle siila-grey"
        >
          {{ "about." + currentCode + ".about.about-siila.text" | translate }}
          <a
            href="http://reixcorp.com/"
            target="_blank"
            translate="about.{{ currentCode }}.about.about-siila.text1"
            class="company-sub-tittle link-blue btn-decoration"
          ></a>
          <span
            [innerHTML]="
              'about.' + currentCode + '.about.about-siila.text2' | translate
            "
            class="company-sub-tittle siila-grey"
          ></span>
        </p>
      </div>
    </div>
    <div class="border-line-company"></div>

    <div
      [ngClass]="{
        'border-desktop': this.isMobile,
        'border-card': !this.isMobile
      }"
    >
      <div id="leadership" class="about-team">
        <h2
          translate="about.{{ currentCode }}.about.our-team.sub-title"
          class="title-gnrl uppercase siila-blue-color"
        ></h2>
      </div>
      <div id="Mobilecheck1" class="card-member" *ngIf="this.isMobile">
        <app-member
          class="about-member mat-elevation-z5"
          *ngFor="let member of checkMember"
          [background]="member.background"
          [icon]="member.icon"
          [name]="member.name"
          [title]="member.title"
          [job]="member.job"
          [link]="member.link"
          [email]="member.email"
          [emailText]="member.emailText"
        ></app-member>
      </div>
      <div id="Mobilecheck2" class="card-member" *ngIf="this.isMobile">
        <app-member
          class="about-member mat-elevation-z5"
          *ngFor="let member of checkMember2"
          [background]="member.background"
          [icon]="member.icon"
          [name]="member.name"
          [title]="member.title"
          [job]="member.job"
          [link]="member.link"
          [email]="member.email"
          [emailText]="member.emailText"
        ></app-member>
      </div>
      <div id="desktop1" class="card-member2" *ngIf="!this.isMobile">
        <app-member
          class="about-member mat-elevation-z5"
          *ngFor="let member of checkMember"
          [background]="member.background"
          [icon]="member.icon"
          [name]="member.name"
          [title]="member.title"
          [job]="member.job"
          [link]="member.link"
          [email]="member.email"
          [emailText]="member.emailText"
        ></app-member>
      </div>
      <div class="card-member2" *ngIf="!this.isMobile">
        <app-member
          class="about-member mat-elevation-z5"
          *ngFor="let member of checkMember2"
          [background]="member.background"
          [icon]="member.icon"
          [name]="member.name"
          [title]="member.title"
          [job]="member.job"
          [link]="member.link"
          [email]="member.email"
          [emailText]="member.emailText"
        ></app-member>
      </div>
    </div>
  </div>

  <div class="border-card mat-elevation-z15 reix-color">
    <div class="reix-logo">
      <img
        class="cursor"
        (click)="openReixLink()"
        src="../../../../../assets/img/company-list/reix-logo.png"
      />
    </div>
    <div class="reix-card">
      <div class="reix-info">
        <p class="about-reix" [innerHTML]="aboutReixInfo"></p>
        <button
          (click)="openReixLink()"
          mat-flat-button
          color="accent"
          class="btn-more"
          style="margin-bottom: 26px;"
        >
          {{ "more" | translate }}
        </button>
      </div>
      <div class="reix-img">
        <img
          class="reix-img-mobile cursor"
          (click)="openReixLink()"
          [alt]="computerreixtitle"
          [title]="computerreixtitle"
          src="../../../../../assets/img/company-list/company-item3-computer-reix.png"
        />
      </div>
    </div>
  </div>
  <app-solution-list></app-solution-list>
  <app-pillars></app-pillars>
  <app-industry></app-industry>
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
