import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  Input,
  SimpleChanges
} from "@angular/core";

import { NewsService } from "../../../core/services/news.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ResponsiveSlideHelper } from "../../../core/_helpers";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { CarouselPager } from "../../../core/models";
import { Directions } from "../../../core/models/enums/direction.enum";
import { NGXLogger } from "ngx-logger";
import * as _ from "lodash";
import { CarouselRestoreService } from "../../services";
import { ViewportScroller } from "@angular/common";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { NewsSearch } from "../../models/news-search.model";

export interface Article {
  id: string;
  mainImageId: string;
  storySource: string;
  storyDate: string;
  title: string;
  source: string;
}

@Component({
  selector: "industry-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
  animations: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedNewsComponent implements CarouselPager {
  @Input() tagId = null;
  @Input() isMarket = false;
  @Input() isSpot = false;
  @ViewChild("slickCarousel", { static: true })
  carousel: SlickCarouselComponent;

  pageSize = 10;
  articles: Article[] = [];
  handsetMode: boolean;

  currentPage: number = 1;
  totalItemCount: number = 0;
  direction: string = "next";
  disableNext: boolean = false;
  lastPageLoaded: boolean = false;
  activeElements: any = [];
  visibleElements: any = [];
  slideProgress: string = "";

  slideConfig: any = ResponsiveSlideHelper.DEFAULT_SLIDE_CONFIG;
  slideBreakpoints: any = ResponsiveSlideHelper.SLIDE_BREAKPOINTS;

  lastSlideActiveCehck: boolean;
  count: number = 0;
  slideCurrentIndex = 1;
  lang: string;
  currentCode = "";
  latamCountry = "";
  articles2: NewsSearch;
  showNews = false;

  constructor(
    private newsService: NewsService,
    private breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _log: NGXLogger,
    private CarouselRestoreService: CarouselRestoreService,
    private _viewportScroller: ViewportScroller,
    private _ts: TranslateService,
    private siilaTranslationService: SiilaTranslationService
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.handsetMode = result.matches;
    });
    this.lang = this.siilaTranslationService.getCurrentLanguage();

    this.currentCode = environment.platformId;

    if (this.currentCode == "co" || this.currentCode == "latam") {
      this.latamCountry = "latamMx";
      this.lang = "mx";
    }
    this._ts.onLangChange.subscribe(response => {
      if (this.articles) {
        this.articles = [...this.articles];
      }
    });
  }

  ngOnInit(): void {
    this.changeCountry(this.latamCountry);
  }

  changeCountry(value: string) {
    this.latamCountry = value;
    if (this.latamCountry == "latamBr") {
      this.lang = "br";
      this.tagId = this.isMarket ? 60 : this.isSpot ? 63 : this.tagId;
    }

    if (this.latamCountry == "latamMx") {
      this.lang = "mx";
      this.tagId = this.isMarket ? 64 : this.isSpot ? 67 : this.tagId;
    }

    this.articles = [];
    this.articles2 = null;
    this.carousel.ngOnDestroy();
    this.carousel.initSlick();
    this.currentPage = 1;
    this.restoreToSlidePosition();
    this.showNews = false;
  }

  requestDefaultNews(pageSize: number) {
    this.newsService.latamCountry = this.latamCountry;

    this.newsService
      .getNewsSearch(this.lang, this.currentPage - 1, 3, null, this.tagId)
      .subscribe(resp => {
        this.articles2 = resp as NewsSearch;
        this.totalItemCount = this.articles2.totalElements;
        this.disableNext = false;
        this.lastSlideActiveCehck = false;

        if (this.articles2?.content.length > 0) {
          this.showNews = true;
        }
      });

    /*
    this.newsService
      .requestDefaultNews(pageSize)
      .then(response => {
        this.totalItemCount = response.headers.get("x-total-count");
        this.articles = response.body;
      })
      .catch(error => {
        console.log("requestDefaultNews::httperrorresponse", error.status);
        this._log.error(error);
      });
*/
    this._cdr.markForCheck();
  }

  ngAfterViewChecked(): void {
    this.activeElements = this.getActiveSlides();
    this.visibleElements = this.getVisibleElementsCount();
    if (this.activeElements.length > 0 && this.count == 0) {
      this.count = 1;
      this.lastSlideActiveCehck =
        this.activeElements.length == this.totalItemCount;
    }
    this._cdr.detectChanges();
  }

  getActiveSlides() {
    const list = document.getElementById("slick-news");
    const activeElements = list.querySelectorAll(
      ".slick-slide.ng-star-inserted"
    );

    return activeElements;
  }

  getVisibleElementsCount() {
    const list = document.getElementById("slick-news");
    const activeElements = list.querySelectorAll(
      ".slick-slide.slick-active.ng-star-inserted"
    ).length;

    return activeElements;
  }

  loadPage(direction, carousel) {
    switch (direction) {
      case Directions.Next:
        const lastSlideActive = this.isEndOfSlideTrack();
        if (!lastSlideActive) {
          this.nextSlide(carousel);
        } else {
          this.appendPage(Directions.Next, carousel);
        }
        break;
      case Directions.Back:
        this.prevSlide(carousel);
        this.lastSlideActiveCehck = false;
        break;
    }
  }

  isEndOfSlideTrack(): boolean {
    if (
      this.getVisibleElementsCount() < 2 ||
      this.activeElements.length === this.totalItemCount
    ) {
      return this.activeElements[
        this.activeElements.length - 1
      ].classList.contains("slick-active");
    } else if (
      this.getVisibleElementsCount() < 4 &&
      this.activeElements.length !== this.totalItemCount
    ) {
      return this.activeElements[
        this.activeElements.length - 2
      ].classList.contains("slick-active");
    } else {
      return this.activeElements[
        this.activeElements.length - 1
      ].classList.contains("slick-active");
    }
  }

  async appendPage(direction: string, carousel) {
    const currentPage = this.currentPage;
    switch (direction) {
      case Directions.Next:
        this.disableNext = true;
        const nextPage = !this.lastPageLoaded ? currentPage + 1 : currentPage;
        this.currentPage = nextPage;
        const page = null;
        const articles = [];

        this.newsService
          .getNewsSearch(this.lang, this.currentPage - 1, 3, null, this.tagId)
          .subscribe(resp => {
            const news = resp as NewsSearch;
            news.content.forEach(n => this.articles2.content.push(n));
            this.totalItemCount = news.totalElements;
            this.articles2.totalPages = news.totalPages;

            if (
              news.content.length &&
              this.activeElements.length !== this.totalItemCount
            ) {
              this.lastPageLoaded = false;
              setTimeout(() => {
                this.nextSlide(carousel);
                this.disableNext = false;
              }, 800);
            } else {
              this.lastPageLoaded = true;
              this.disableNext = false;
              carousel.slickGoTo(0);
            }
          });
        break;
      case Directions.Back:
        break;
    }
  }

  slideProgressData() {
    const visibleElements = this.getVisibleElementsCount();
    const rounded =
      visibleElements > 0 ? (this.totalItemCount / visibleElements) % 1 > 0 : 0;
    const elementsPerPage =
      visibleElements > 0 ? this.totalItemCount / visibleElements : 0;
    const resizingTotalSlideCount = rounded
      ? Math.ceil(elementsPerPage)
      : Math.floor(elementsPerPage);
    const currentTrackIndex = this.slideCurrentIndex + 1;
    const currentPage =
      visibleElements > 1
        ? Math.ceil(currentTrackIndex / this.visibleElements)
        : currentTrackIndex;

    return {
      visibleElements,
      rounded,
      elementsPerPage,
      resizingTotalSlideCount,
      currentTrackIndex,
      currentPage
    };
  }

  getSlideProgress(): string {
    const { resizingTotalSlideCount, currentPage } = this.slideProgressData();
    const displayText =
      currentPage && resizingTotalSlideCount
        ? `${currentPage} ${
            this.lang == "es" ? "de" : "of"
          } ${resizingTotalSlideCount}`
        : "";
    return displayText;
  }

  nextSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    const { resizingTotalSlideCount, currentPage } = this.slideProgressData();
    const restartSlides = currentPage === resizingTotalSlideCount;
    if (!restartSlides) {
      carousel.slickNext();
    }
    this.lastSlideActiveCehck = currentPage + 1 === resizingTotalSlideCount;
  }

  prevSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    carousel.slickPrev();
  }

  restoreToSlidePosition() {
    let restorePosition = this.CarouselRestoreService.getState()
      ? this.CarouselRestoreService.getState()
      : null;

    if (restorePosition) {
      if (typeof restorePosition == "string") {
        restorePosition = JSON.parse(restorePosition);
      }

      const {
        loadedObjects,
        currentSlideIndex,
        currentPage,
        totalItemCount,
        pagePosition
      } = restorePosition;

      if (loadedObjects) {
        this.currentPage = currentPage;
        this.articles = [...loadedObjects];
        this.totalItemCount = totalItemCount as number;

        setTimeout(() => {
          if (pagePosition)
            this._viewportScroller.scrollToPosition(pagePosition);
          this.carousel.slickGoTo(currentSlideIndex);
          this.count = 1;
          const {
            resizingTotalSlideCount,
            currentPage
          } = this.slideProgressData();
          this.lastSlideActiveCehck = currentPage === resizingTotalSlideCount;
        }, 800);

        this.CarouselRestoreService.deleteState();
      }
    } else {
      this.requestDefaultNews(this.pageSize);
    }
  }

  swipe(event) {
    const { isFinal, direction } = event;
    const currentTrackIndex = this.slideCurrentIndex + 1;
    const resizingTotalSlideCount = Math.ceil(
      this.newsService.getTotalPageCount / this.getVisibleElementsCount()
    );
    if (
      isFinal &&
      direction === 2 &&
      currentTrackIndex < resizingTotalSlideCount &&
      this.isEndOfSlideTrack() &&
      !this.disableNext
    ) {
      this.appendPage(Directions.Next, this.carousel);
    }
  }

  goHome() {
    window.open(
      this.currentCode == "latam"
        ? "https://siila.com/resource"
        : this.currentCode == "co"
        ? this.latamCountry == "latamBr"
          ? environment.applicationURLBr + "/resource"
          : environment.applicationURLMx + "/resource"
        : `resource`,
      this.handsetMode ? "_self" : "_blank"
    );
  }

  /**
   * @param  {} event
   */
  slickInit(event) {}

  /**
   * @param  {} event
   */
  afterChange(e) {
    const { currentSlide } = e;
    this.slideCurrentIndex = currentSlide;
  }

  /**
   * @param  {} event
   */
  breakpoint(event) {}

  /**
   * @param  {} event
   */
  beforeChange(event) {}

  /**
   * @param  {} event
   */
}
