import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-product-layout",
  templateUrl: "./product-layout.component.html",
  styleUrls: ["./product-layout.component.scss"]
})
export class ProductLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
