import { Component, OnInit, OnDestroy } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { NewFeature } from "./newFeature.model";

@Component({
  selector: "new-features-dialog",
  templateUrl: "./new-features-dialog.component.html",
  styleUrls: ["./new-features-dialog.component.scss"]
})
export class NewFeaturesDialogComponent implements OnInit, OnDestroy {
  translateSubscription: Subscription;
  newFeatures: NewFeature[] = [];
  translationKey: string;

  constructor(private translationService: TranslateService) {}

  ngOnInit(): void {
    this.translationKey =
      environment.platformId == "mx" || environment.platformId == "co"
        ? "new-features-mx"
        : "new-features";
    this.translateSubscription = this.translationService
      .stream(this.translationKey, {})
      .subscribe((res: NewFeature[]) => {
        this.newFeatures = res;
        console.log("mensaje", res);
      });
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  setNewFeaturesFlag() {}
}
