import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  Renderer2
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { DemoDialogService } from "../../../../core/services/dialog";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { MetaTagService } from "../../../services/meta-tag.service";
import { action, page } from "../../../utils/params";
import { DemoDialogComponent } from "../../dialogs";
import { filter, take } from "rxjs/operators";
import { CaptchaService } from "../../../services/captcha.service";
@Component({
  selector: "about",
  templateUrl: "./about.component.html",
  styleUrls: [
    "./about.component.scss",
    "../product-list/product-list.component.scss",
    "../grocs/grocs.component.scss",
    "../market-analytics/market-analytics.component.scss"
  ]
})
export class AboutComponent implements OnInit, AfterViewInit {
  aboutReixInfo: string;
  marketCardList = [];
  checkMember = [];
  checkMember2 = [];

  solutionCard = [];
  marketCardTranslatePath = "products.features-card.marketAnalytics.card-list.";
  solutionPath = "about.solutions.solution-cards.numbers.";
  subscription: Subscription;
  isMobile: boolean;
  currentCode: string;
  browserLang: string = "en";
  routerSub: Subscription;
  computerreixtitle: string;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private responsive: BreakpointObserver,
    private demo: DemoDialogService,
    private siilaTranslationService: SiilaTranslationService,
    private metatagservice: MetaTagService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private captchaService: CaptchaService
  ) {
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.aboutData();
  }

  ngAfterViewInit() {
    this.route.fragment.pipe(take(1)).subscribe(fragment => {
      if (fragment && fragment === "leadership") {
        this.scrollPageToLeadership();
      }
    });
  }

  scrollPageToLeadership() {
    setTimeout(() => {
      let element = null;
      const blockMobile = "start";

      element = this.elementRef.nativeElement.querySelector("#leadership");
      var rect = element.getBoundingClientRect();
      let title = this.isMobile ? 50 : 80;
      var topLeadership = rect.top - title;
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: blockMobile });
          console.log("Scrolling top ", rect.top, topLeadership, title);
          window.scrollTo({
            top: topLeadership,
            behavior: "smooth"
          });
        }, 0);
      }
    }, 0);
  }

  ngOnInit(): void {
    this.routerSub = this.route.paramMap.subscribe(params => {
      this.metatagservice.setAboutMetaTags();
    });

    this.subscription = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
        this._changeDetectorRef.detectChanges();
      });
    this.aboutReixInfo = this.siilaTranslationService.getTranslationFromJSONFiles(
      `about.${this.currentCode}.about.reix.reix-info`
    );
  }

  private aboutData() {
    this.marketCardList = [
      {
        background: "assets/img/products-list/indices.png",
        src: "assets/img/products-list/indices.svg",
        title: `${this.marketCardTranslatePath}card1.title`,
        text: `${this.marketCardTranslatePath}card1.text`,
        actionText: `${this.marketCardTranslatePath}card1.action-text`
      },
      {
        background: "assets/img/products-list/investors.png",
        src: "assets/img/products-list/investors.svg",
        title: `${this.marketCardTranslatePath}card2.title`,
        text: `${this.marketCardTranslatePath}card2.text`,
        actionText: `${this.marketCardTranslatePath}card2.action-text`
      },
      {
        background: "assets/img/products-list/grocs.png",
        src: "assets/img/products-list/grocs.svg",
        title: `${this.marketCardTranslatePath}card3.title`,
        text: `${this.marketCardTranslatePath}card3.text`,
        actionText: `${this.marketCardTranslatePath}card3.action-text`
      }
    ];

    this.computerreixtitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.company.computerreix"
    );
    this.checkMember = [
      {
        link: "https://www.linkedin.com/in/gnicastro/",
        background: "assets/img/company-list/Company-page-Giancarlo.png",
        name: `Giancarlo Nicastro`,
        job: `CHIEF EXECUTIVE OFFICER`,
        emailText: "gnicastro@siila.com",
        email: "mailto:gnicastro@siila.com",
        title: this.siilaTranslationService.getTranslationFromJSONFiles(
          "imgSEO.company.giancarlo"
        )
      },
      {
        link: "https://www.linkedin.com/in/alejo-delgado/",
        background:
          "assets/img/company-list/Company-page-Alejandro-Delgado.jpg",
        name: `Alejandro Delgado`,
        job: `COUNTRY MANAGER | MX`,
        emailText: "adelgado@siila.com.mx",
        email: "mailto:adelgado@siila.com.mx",
        title: this.siilaTranslationService.getTranslationFromJSONFiles(
          "imgSEO.company.alejandro"
        )
      }
    ];

    this.checkMember2 = [
      {
        link: "https://www.linkedin.com/in/fernanda-gomes-le%C3%A3o/",
        background: "assets/img/company-list/Company-page-Fernanda-Leao.png",
        name: `Fernanda Leao`,
        job: `CHIEF MARKETING OFFICER`,
        emailText: "fleao@siila.com",
        email: "mailto:fleao@siila.com",
        title: this.siilaTranslationService.getTranslationFromJSONFiles(
          "imgSEO.company.fernanda"
        )
      },
      {
        link: "https://www.linkedin.com/in/emerson-kiyohiro-komesu-50360253/",
        background: "assets/img/company-list/Company-page-Emerson-Komesu.png",
        name: `Emerson Komesu`,
        job: `RESEARCH & SALES MANAGER | BR`,
        emailText: "emerson.komesu@siila.com.br",
        email: "mailto:emerson.komesu@siila.com.br",
        title: this.siilaTranslationService.getTranslationFromJSONFiles(
          "imgSEO.company.emerson"
        )
      }
    ];

    this.solutionCard = [
      {
        id: `${this.solutionPath}1`,
        title: "about.solutions.solution-cards.Institutional",
        icon: "assets/icons/arrow-left.svg"
      },
      {
        id: `${this.solutionPath}2`,
        title: "about.solutions.solution-cards.Brokerage",
        icon: "assets/icons/arrow-left.svg"
      },
      {
        id: `${this.solutionPath}3`,
        title: "about.solutions.solution-cards.Developers",
        icon: "assets/icons/arrow-left.svg"
      },
      {
        id: `${this.solutionPath}4`,
        title: "about.solutions.solution-cards.Valuation",
        icon: "assets/icons/arrow-left.svg"
      },
      {
        id: `${this.solutionPath}5`,
        title: "about.solutions.solution-cards.Retail",
        icon: "assets/icons/arrow-left.svg"
      },
      {
        id: `${this.solutionPath}6`,
        title: "about.solutions.solution-cards.Tenants",
        icon: "assets/icons/arrow-left.svg"
      }
    ];
  }

  ngOnDestroy(): void {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  openReixLink() {
    window.open("http://reixcorp.com/", "_blank");
  }
  openContactDialog() {
    this.demo.open(DemoDialogComponent, false, page.about, action.touchs);
  }

  selectVideo() {
    if (this.browserLang == "en") {
      return "ZQDn4jGs1wg";
    } else if (this.browserLang == "pt") {
      return "S7g88rYJ9rY";
    } else {
      return "tVb58qqMus4";
    }
  }
}
