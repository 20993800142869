import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ForgotDialogComponent } from "../../../shared/components/dialogs/forgot-dialog/forgot-dialog.component";
import { NGXLogger } from "ngx-logger";
import { WindowRef } from "../../../window-ref.service";

@Injectable()
export class ForgotDialogService {
  dialogRef: any;

  constructor(
    public _d: MatDialog,
    private _logger: NGXLogger,
    private _windowRef: WindowRef
  ) {}

  /**
   * @param {*} [data]
   */
  openDialog(data?): void {
    this.dialogRef = this._d.open(ForgotDialogComponent, {
      id: "forgotDialog",
      height: "325px",
      width: "550px",
      data: data || {
        title: "SiiLA",
        icon_path: "/assets/img/siilabrandlogo.png"
      }
    });
  }

  /**
   * @returns
   */
  close() {
    this._d.closeAll();
  }
}
