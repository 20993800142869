import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { SiilaIndexService } from "../../services";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CapitalMarketsValues } from "./capital-markets.model";
import { Router } from "@angular/router";
import { MatomoTracker } from "ngx-matomo";
import { DOCUMENT } from "@angular/common";
import { environment } from "../../../../environments/environment";
import { zip } from "rxjs";
import { response } from "express";

@Component({
  selector: "app-capital-markets-section",
  templateUrl: "./capital-markets-section.component.html",
  styleUrls: ["./capital-markets-section.component.scss"]
})
export class CapitalMarketsSectionComponent implements OnInit {
  @ViewChild("slickModal", { static: true }) carousel: any;
  isSticky: boolean;
  isLargeScreen: boolean;
  currentPage: number = 1;
  pageAmount: number = 4;
  currentCode: string;
  capitalMarketsValues: CapitalMarketsValues[] = [
    /* {
    appreciationChg: null,
    appreciationVal: null,
    bovespaChg: null,
    bovespaVal: null,
    canChg: null,
    canVal: null,
    euroChg: null,
    euroVal: null,
    ifixChg: null,
    ifixVal: null,
    incomeChg: null,
    incomeVal: null,
    quarter: null,
    selicDate: "",
    selicRate: null,
    totalChg: null,
    totalVal: null,
    usdChg: null,
    usdVal: null,
    year: null
  } */
  ];

  slideConfig = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    mobileFirst: true,
    autoplay: true,
    nextArrow:
      "<div class='carousel-arrow carousel-arrow--next slick-next'></div>",
    prevArrow:
      "<div class='carousel-arrow carousel-arrow--prev slick-prev'></div>",
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }
    ]
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private siilaIndexService: SiilaIndexService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private matomoTracker: MatomoTracker
  ) {
    this.currentCode = environment.platformId;
  }

  ngOnInit() {
    if (this.currentCode == "latam" || this.currentCode == "co") {
      zip(
        this.siilaIndexService.getCapitalMarketsValues("br"),
        this.siilaIndexService.getCapitalMarketsValues("mx")
      ).subscribe(response => {
        if (response) {
          response[0].currentCode = "br";
          response[1].currentCode = "mx";
          this.capitalMarketsValues.push(response[0]);
          this.capitalMarketsValues.push(response[1]);
        }
      });
    } else {
      this.siilaIndexService
        .getCapitalMarketsValues()
        .subscribe((values: CapitalMarketsValues) => {
          if (values) {
            console.log("barra index", values);
            this.capitalMarketsValues.push(values);
          }
        });
    }

    this.breakpointObserver
      .observe(["(min-width: 1500px)"])
      .subscribe(result => {
        this.isLargeScreen = result.matches;
      });
  }

  goNextPage() {
    if (this.currentPage === this.pageAmount) {
      this.currentPage = 1;
    } else {
      this.currentPage = this.currentPage + 1;
    }
  }

  goPrevPage() {
    if (this.currentPage === 1) {
      this.currentPage = this.pageAmount;
    } else {
      this.currentPage = this.currentPage - 1;
    }
  }

  goToReportPage() {
    console.log("Track pageview: goToReportPage");
    const piwikTrackingUrl = `https://${this.document.location.hostname}/siila_index`;
    this.matomoTracker.trackPageView(piwikTrackingUrl);
    window.open("/siila_index", "blank");
  }

  stickyStatus(isSticky: boolean) {
    this.isSticky = isSticky;
  }
}
