import { Component, Input } from "@angular/core";

@Component({
  selector: "app-bovespa-values",
  templateUrl: "./bovespa-values.component.html",
  styleUrls: ["./bovespa-values.component.scss"]
})
export class BovespaValuesComponent {
  @Input() bovespaVal: number;
  @Input() bovespaPercent: number;
}
