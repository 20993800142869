import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { environment } from "../../../../environments/environment";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

@Component({
  selector: "highlighs-card",
  templateUrl: "./highlighs-card.component.html",
  styleUrls: ["./highlighs-card.component.scss"]
})
export class HighlighsCardComponent implements OnInit, OnChanges {
  @Input() currentCode: string;
  highlighsPath: string;
  browserLang: string = "en";
  constructor(private siilaTranslationService: SiilaTranslationService) {
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.highlighsPath = `fii-data.${this.currentCode}.fii-data.highlighs.highlighs-info.card`;
  }

  ngOnInit(): void {}

  openClubFii() {
    window.open(
      "https://www.clubefii.com.br/plano-de-assinatura-fii-data-professional",
      "_blank"
    );
  }
}
