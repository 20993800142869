<div class=" border-card-white mat-elevation-z15">
  <div class="strategy-card">
    <div class="methodology-info">
      <h1 class="title-gnrl siila-blue-color uppercase">
        {{ translatePathStrategy + ".methodology" | translate }}
      </h1>
      <h2 class="feature-title siila-menu-color">
        {{ translatePathStrategy + ".methodology-title" | translate }}
      </h2>
      <span
        [innerHTML]="translatePathStrategy + '.methodology-info' | translate"
        class="feature-sub-title siila-grey"
      >
      </span>
      <!-- <div class="btn-methodology">
        <button
          mat-flat-button
          color="accent"
          class="btn-more"
          (click)="playPauseVideo()"
        >
          {{ "more" | translate }}
        </button>
      </div> -->
    </div>
    <div class="methodology-video popupVideoButton">
      <div class="video-mobile" *ngIf="browserLang === 'en'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_cm0jceiyn2 seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/cm0jceiyn2/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-mobile" *ngIf="browserLang === 'es'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_qulwlve6dl seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/qulwlve6dl/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-mobile" *ngIf="browserLang === 'pt'">
        <div
          class="wistia_responsive_padding"
          style="padding:56.25% 0 0 0;position:relative;"
        >
          <div
            class="wistia_responsive_wrapper"
            style="height:100%;left:0;position:absolute;top:0;width:100%;"
          >
            <div
              class="wistia_embed wistia_async_p5v3zq5vpk seo=false videoFoam=true"
              style="height:100%;position:relative;width:100%"
            >
              <div
                class="wistia_swatch"
                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
              >
                <img
                  src="https://fast.wistia.com/embed/medias/p5v3zq5vpk/swatch"
                  style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
