import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-btn-up",
  templateUrl: "./btn-up.component.html",
  styleUrls: ["./btn-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtnUpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
}
