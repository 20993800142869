import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy
} from "@angular/core";
import { AuthenticationService } from "../auth/authentication.service";
import { environment } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { trigger, transition, style, animate } from "@angular/animations";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Gallery, GalleryItem, YoutubeItem } from "@ngx-gallery/core";
import { Lightbox } from "@ngx-gallery/lightbox";
import { ActivatedRoute, Router } from "@angular/router";
import { SiilaTranslationService } from "../services/siila-translation.service";
import { HomeVideosService } from "../services/home-videos.service";
import { HomeVideo, SlideVideo } from "../models/home-video.model";
import { ImageService } from "../../core/services/image.service";
import { PowerBiService } from "../../shared/services/powerbi.service";
import { element } from "protractor";
import { ResponsiveSlideHelper } from "../../core/_helpers";
import { DemoDialogService } from "../services/dialog";
import { DemoDialogComponent } from "../../shared/components";
import * as $ from "jquery";
import { url } from "inspector";
import { MetaTagService } from "../../shared/services/meta-tag.service";
import { I18nService } from "../../shared/services";
import { CaptchaService } from "../../shared/services/captcha.service";

@Component({
  selector: "home-view",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [
    trigger("slideAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate(250)]),
      transition(":leave", [style({ opacity: 1 }), animate(250)])
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild("slickCarousel", { static: true }) carousel: any;

  browserLang: string = "en";
  color = "#04354f";
  portraitMode: boolean = false;
  videoItems: GalleryItem[];
  homeVideos: HomeVideo[];
  slideVideos: SlideVideo[];
  brakpointSubscription: Subscription;
  translateSubscription: Subscription;
  isExpanded: boolean = false;
  currentCode: string;
  slidesConfig: any = ResponsiveSlideHelper.DEFAULT_SLIDE_CONFIG;
  SaleLease = "lease";
  propertyType = "office";
  property = "";
  playVideo = 0;
  urlVideo =
    "https://fast.wistia.net/embed/iframe/m9jprhk22h?videoFoam=true&autoplay=0";

  slideConfig: any = {
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
          //dots: true
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
          //dots: true
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
          //dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  isMobile = false;
  routerSub: Subscription;
  latamCountry = "latamMx";

  constructor(
    public sanitize: DomSanitizer,
    public breakpointObserver: BreakpointObserver,
    public auth: AuthenticationService,
    private _translate: TranslateService,
    private gallery: Gallery,
    private _lightbox: Lightbox,
    private siilaTranslationService: SiilaTranslationService,
    private videosService: HomeVideosService,
    private imgService: ImageService,
    private metaTagSrv: MetaTagService,
    private powerBiService: PowerBiService,
    private imageService: ImageService,
    private demoDialog: DemoDialogService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private i18n: I18nService,
    private router: Router
  ) {
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  get spotLogo() {
    return this.currentCode == "mx"
      ? "assets/img/home-list/logo-mx.png"
      : "assets/img/home-list/logo.png";
  }

  ngOnInit() {
    this.routerSub = this.route.paramMap.subscribe((params: any) => {
      this.metaTagSrv.setHomePageMetaTags();
    });
    this.getCarouselVideos();
    this.brakpointSubscription = this.subscribeBreakpoint();
    this.translateSubscription = this.subscribeToLangChange();
    this.powerBiService.prefetchReports();
  }

  ngOnDestroy() {
    this.brakpointSubscription.unsubscribe();
    this.translateSubscription.unsubscribe();
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  subscribeToLangChange() {
    return this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.browserLang != event.lang) {
        this.browserLang = event.lang;
        this.loadGallery(true);
      }
    });
  }

  subscribeBreakpoint() {
    return this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.portraitMode = result.matches;
      });
  }

  getCarouselVideos() {
    this.videosService.getHomeVideos().subscribe(
      (response: HomeVideo[]) => {
        this.homeVideos = response;
        this.loadGallery();
      },
      error => {
        var message = this.siilaTranslationService.getTranslationFromJSONFiles(
          "videos.errorMessage"
        );
        console.error(`${message}: ${error.error.message}`);
      }
    );
  }

  public sanitizeURL(URL: string) {
    return this.sanitize.bypassSecurityTrustResourceUrl(URL);
  }

  openCard(index: number): void {
    this._lightbox.open(index, "homeGallery");
  }

  loadGallery(reload?: boolean) {
    this.slideVideos = [];
    let parsedVideos = [];

    this.homeVideos.forEach(item => {
      const imgId = this.siilaTranslationService.getTranslation(
        item.thumbnailId
      );
      const thumb = this.imgService.largeThumbnail(imgId);

      this.slideVideos.push({ thumb });
      parsedVideos.push(
        new YoutubeItem({
          src: this.siilaTranslationService.getTranslation(item.videoId),
          thumb: thumb
        })
      );
    });

    if (reload) {
      this.carousel.unslick();
    }

    this.gallery.ref("homeGallery").load(parsedVideos);
  }

  nextSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    carousel.slickNext();
  }

  prevSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    carousel.slickPrev();
  }

  playButton(isExpanded: boolean) {
    this.isExpanded = !isExpanded;
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  openSearch() {
    window.open(
      `${
        (this.currentCode == "latam" || this.currentCode == "co") &&
        this.latamCountry == "latamMx"
          ? environment.spotURLMx
          : environment.spotURL
      }/spot-search/${this.SaleLease}/${this.propertyType}/${
        this.property == "" ? "all" : "all?keyword=" + this.property
      }/lang/${this.i18n.getCurrentLanguage()}`,
      "_blank"
    );
  }

  playPauseVideo() {
    /* if (this.browserLang === "en") {
      if (this.playVideo == 0) {
        this.playVideo = 1;
        this.urlVideo = `https://fast.wistia.net/embed/iframe/m9jprhk22h?videoFoam=true&autoplay=${this.playVideo}`;
      } else {
        this.playVideo = 0;
        this.urlVideo = `https://fast.wistia.net/embed/iframe/m9jprhk22h?videoFoam=true&autoplay=${this.playVideo}`;
      }
    }

    if (this.browserLang === "es") {
      if (this.playVideo == 0) {
        this.playVideo = 1;
        this.urlVideo = `https://fast.wistia.net/embed/iframe/3orowoyw8h?videoFoam=true&autoplay=${this.playVideo}`;
      } else {
        this.playVideo = 0;
        this.urlVideo = `https://fast.wistia.net/embed/iframe/3orowoyw8h?videoFoam=true&autoplay=${this.playVideo}`;
      }
    }

    if (this.browserLang === "pt") {
      if (this.playVideo == 0) {
        this.playVideo = 1;
        this.urlVideo = `https://fast.wistia.net/embed/iframe/d5ne2f8jzh?videoFoam=true&autoplay=${this.playVideo}`;
      } else {
        this.playVideo = 0;
        this.urlVideo = `https://fast.wistia.net/embed/iframe/d5ne2f8jzh?videoFoam=true&autoplay=${this.playVideo}`;
      }
    } */
    $(".popupVideoButton")[0].click();
  }

  public getCompanyLogoImg(id: any) {
    return id ? this.getMediumImg(id) : "../../../assets/no-company-logo.png";
  }

  public openDemoDialog() {
    this.demoDialog.open(DemoDialogComponent);
  }
}

export interface Article {
  date: string;
  headline: string;
  id: string;
}
