import { Injectable } from "@angular/core";
import { text } from "@fortawesome/fontawesome-svg-core";
import html2canvas from "html2canvas";
import { BehaviorSubject, Observable } from "rxjs";
import { captchaImg } from "../utils/params";

@Injectable()
export class CaptchaService {
  imgCaptcha = "";
  textCaptcha: string;
  usedIndexes = new Set<number>();
  img = captchaImg.images;

  constructor() {}

  async generateCaptcha() {
    let indexImg = Math.floor(Math.random() * this.img.length);

    while (this.usedIndexes.has(indexImg)) {
      indexImg = Math.floor(Math.random() * this.img.length);
    }

    this.usedIndexes.add(indexImg);
    if (this.usedIndexes.size === this.img.length) {
      this.usedIndexes.clear();
    }

    this.imgCaptcha = this.img[indexImg].img;
    this.textCaptcha = this.img[indexImg].text;
  }
}
