import { Injectable } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { InfoDialogComponent } from "../../../shared/components/dialogs/info-dialog/info-dialog.component";

@Injectable()
export class InfoDialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(data): void {
    //if (!this.dialog.openDialogs.length) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      height: "220px",
      width: "500px",
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
    });
  }
  //}
}
