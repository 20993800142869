import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { DynamicComponent, Regions } from "../models/region-component.type";
import { Router } from "@angular/router";
import { MatIcon } from "@angular/material/icon";
import {
  socialMediaIconsMX,
  contactIconsMX,
  productLinksMX,
  solutionsLinksMX,
  socialMediaIconsBR,
  contactIconsBR,
  productLinksBR,
  solutionsLinksBR,
  insightsLink,
  solutionsLinksLatam,
  contactIconsLatam,
  productLinksLatam,
  socialMediaIconsLatam,
  contactIconsCO,
  socialMediaIconsCO
} from "./footer-links";
import { ProductService } from "../services/products.service";
import { SolutionMenuItem } from "../models/products.model";
import { SiilaTranslationService } from "../services/siila-translation.service";
import { I18nService } from "../../shared/services";
import { StaticImagesSEOService } from "../services/static-images.service";
import { ImageSEO } from "../models/image-seo.model";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, DynamicComponent {
  ALLOWS_REGIONS: Regions[] = [Regions.BR, Regions.MX];

  fitWithFeatureBanner: boolean = false;
  handsetMode: boolean = false;
  appLink = environment.serverURL;
  solutions: SolutionMenuItem[];
  browserLang: string = "en";

  socialMediaIconsBR = socialMediaIconsBR;
  contactIconsBR = contactIconsBR;
  productLinksBR = productLinksBR;
  solutionsLinksBR = solutionsLinksBR;
  insightsLink = insightsLink;

  //MX Links
  socialMediaIconsMX = socialMediaIconsMX;
  contactIconsMX = contactIconsMX;
  productLinksMX = productLinksMX;
  solutionsLinksMX = solutionsLinksMX;
  //MX LInks end

  contactIconsCO = contactIconsCO;
  socialMediaIconsCO = socialMediaIconsCO;

  //Latam Links
  socialMediaIconsLatam = socialMediaIconsLatam;
  contactIconsLatam = contactIconsLatam;
  productLinksLatam = productLinksLatam;
  solutionsLinksLatam = solutionsLinksLatam;
  //Latam LInks end

  termsOfUseUrl: string;
  privacyUrl: string;

  currentEnv: string;
  currentSocial = [];
  currentContact = [];
  currentProduct = [];
  currentSolution = [];
  linkedingSEO: ImageSEO;
  youtubeSEO: ImageSEO;
  tiktokSEO: ImageSEO;
  facebookSEO: ImageSEO;
  instagramSEO: ImageSEO;
  twitterSEO: ImageSEO;
  footerLogoTitle: string;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private staticImgSrv: StaticImagesSEOService,
    private productService: ProductService,
    private siilaTranslationService: SiilaTranslationService,
    private i18: I18nService
  ) {
    this.currentEnv = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.handsetMode = result.matches;
      });
  }

  ngOnInit(): void {
    this.linkedingSEO = this.staticImgSrv.getLinkedinSEO();
    this.youtubeSEO = this.staticImgSrv.getYoutubeSEO();
    this.facebookSEO = this.staticImgSrv.getFacebookSEO();
    this.instagramSEO = this.staticImgSrv.getInstagramSEO();
    this.twitterSEO = this.staticImgSrv.getTwitterSEO();
    this.tiktokSEO = this.staticImgSrv.getTikTokSEO();
    this.footerLogoTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.footerTitle"
    );

    if (this.currentEnv.includes("mx")) {
      this.currentSocial = this.socialMediaIconsMX;
      this.currentContact = this.contactIconsMX;
      this.currentProduct = this.productLinksMX;
      this.currentSolution = this.solutionsLinksMX;
    } else if (this.currentEnv.includes("br")) {
      this.currentSocial = this.socialMediaIconsBR;
      this.currentContact = this.contactIconsBR;
      this.currentProduct = this.productLinksBR;
      this.currentSolution = this.socialMediaIconsBR;
    } else if (this.currentEnv.includes("latam")) {
      this.currentSocial = this.socialMediaIconsLatam;
      this.currentContact = this.contactIconsLatam;
      this.currentProduct = this.productLinksLatam;
      this.currentSolution = this.solutionsLinksLatam;
    } else {
      this.currentSocial = this.socialMediaIconsCO;
      this.currentContact = this.contactIconsCO;
      this.currentProduct = this.productLinksMX;
      this.currentSolution = this.solutionsLinksMX;
    }

    if (this.currentSocial) {
      for (let i = 0; i < this.currentSocial.length; i++) {
        let socialMediaLink: Link = this.currentSocial[i];
        let seo: ImageSEO = null;
        switch (i) {
          case 0:
            seo = this.linkedingSEO;
            break;
          case 1:
            seo = this.youtubeSEO;
            break;
          case 2:
            seo = this.facebookSEO;
            break;
          case 3:
            seo = this.instagramSEO;
            break;
          case 4:
            seo = this.twitterSEO;
            break;
          case 5:
            seo = this.tiktokSEO;
            break;
        }
        socialMediaLink.alt = seo.alt;
        socialMediaLink.title = seo.title;
      }
    }

    this.termsOfUseUrl = "/terms-of-use/" + this.browserLang;
    this.privacyUrl = "/privacy-policy/" + this.browserLang;

    if (!this.handsetMode) {
      this.currentProduct.map(product => (product.target = "_blank"));
    }

    this.productService.getSolutionList().subscribe(solutions => {
      this.solutions = solutions;
    });
  }

  goSolutions(solutions: SolutionMenuItem) {
    window.open(
      `/solutions/${solutions.id}/${this.i18.getTranslation(
        solutions.solutionName
      )}`,
      this.handsetMode ? "_self" : "_blank"
    );
  }
}

export interface Link {
  ref?: any;
  link: string;
  text?: string;
  textEs?: string;
  matIcon?: string;
  target?: string;
  class?: string;
  title?: string;
  alt?: string;
}
