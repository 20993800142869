import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { PressService } from "../../../services/press.service";
import { DatePipe } from "@angular/common";
import { environment } from "../../../../../environments/environment";
import { PageEvent } from "@angular/material/paginator";
import { Press } from "../../../models/press.model";
import { ImageService } from "../../../../core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-press",
  templateUrl: "./press.component.html",
  styleUrls: ["./press.component.scss"]
})
export class PressComponent implements OnInit {
  total = 0;
  currentPage = 0;
  pageSize = 5;
  isMobile = false;
  filters = null;
  currentCode: string;
  listPress: Press[] = [];

  constructor(
    public breakpointObserver: BreakpointObserver,
    private pressService: PressService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.defaultFilter();
    this.currentCode = environment.platformId;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.keyword) {
        this.filters.keywordFilter = params.keyword;
        this.searchKeyword();
      } else {
        this.fetchNews();
      }
    });
  }

  defaultFilter() {
    this.currentPage = 0;
    this.filters = {
      startPublishDate: null,
      endPublishDate: null,
      startDate: null,
      endDate: null,
      statusFilter: ["PUBLISHED"],
      keywordFilter: null
    };
  }

  clearFilter() {
    this.defaultFilter();
    this.fetchNews();
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event as any;
    this.fetchNewsItems(this.currentPage - 1, this.pageSize);
  }

  filterData() {
    const startDate = this.datePipe.transform(
      this.filters.startDate,
      "yyyy-MM-dd"
    );
    const endDate = this.datePipe.transform(this.filters.endDate, "yyyy-MM-dd");
    this.filters.startPublishDate = startDate;
    this.filters.endPublishDate = endDate;
    this.currentPage = 0;
    this.fetchNews();
  }

  fetchNews() {
    this.fetchNewsItems(this.currentPage, this.pageSize);
  }

  fetchNewsItems(page: number, pageSize: number) {
    this.cleanKeywordFilter();
    this.pressService
      .getFetchPressItems(this.currentCode, this.filters, pageSize, page)
      .then((response: any) => {
        this.total = response.totalElements;
        this.listPress = response.content;
      });
  }

  cleanKeywordFilter() {
    if (
      this.filters.startPublishDate != null ||
      this.filters.endPublishDate != null
    ) {
      this.filters.keywordFilter = null;
    }
  }

  searchKeyword() {
    this.filters.startPublishDate = null;
    this.filters.endPublishDate = null;
    this.filters.startDate = null;
    this.filters.endDate = null;
    this.currentPage = 0;

    this.fetchNews();
  }

  downloadPdf(press, lang) {
    this.pressService
      .incrementView(press.pressArticleId, press.hash)
      .subscribe();
    this.pressService
      .downloadPdf(press.pressArticleId, lang, press.hash)
      .subscribe((resp: any) => {
        const blob = new Blob([resp.body], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
  }

  openPress(press) {
    this.pressService
      .incrementView(press.pressArticleId, press.hash)
      .subscribe();
    window.open(press.articleUrl, "_blank");
  }

  headerImageNews(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  validateSearch() {
    return this.filters.keywordFilter == null ||
      this.filters.keywordFilter == ""
      ? true
      : false;
  }
}
