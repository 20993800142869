import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AccountService } from "../../../../core/auth";

@Component({
  selector: "forgot-dialog",
  templateUrl: "./forgot-dialog.component.html",
  styles: [``]
})
export class ForgotDialogComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _d: MatDialog,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.formGroup = this._fb.group({
      emailCtrl: [null, [Validators.required, Validators.email]]
    });
  }

  submit() {
    // TODO: Call forgot password API with email and show a message
    //this.infoDialog.openDialog({message: 'Password reset complete. An email with your new password has been sent.'});
    //this._d.getDialogById('forgotDialog').close({action: 'ok'});
    this.accountService
      .forgotPassword(this.formGroup.value.emailCtrl)
      .then((response: any) => {
        // Check error code from response
        this._d.getDialogById("forgotDialog").close({ action: "ok" });
        //this.infoDialog.openDialog({message: 'Password reset complete. An email with your new password has been sent.'});
      })
      .catch(err => {
        this._d.getDialogById("forgotDialog").close({ action: "error" });
        //this.infoDialog.openDialog({message: 'Password reset complete. An email with your new password has been sent.'});
      });
  }

  dismiss() {
    this._d.closeAll();
  }
}
