import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Queue } from "typescript-collections/dist/lib";
import { NewVersionComponent } from "../../shared/components/new-version/new-version.component";

@Injectable()
export class SnackBar {
  private snackBarNotificationsQueue: Queue<SnackBarNotification> = new Queue<
    SnackBarNotification
  >();
  private snackBarNotificationsForceQueue: Queue<
    SnackBarNotification
  > = new Queue<SnackBarNotification>();
  private actuallyDisplayedNotification: SnackBarNotification = null;
  private dialogOpened: boolean = false;

  constructor(private dialog: MatDialog) {}

  public displayNotification(notification: SnackBarNotification): void {
    if (notification.force) {
      this.snackBarNotificationsForceQueue.enqueue(notification);
    } else {
      this.snackBarNotificationsQueue.enqueue(notification);
    }
    this.runCarousel();
  }

  private loadNotificationToDisplay(): void {
    if (this.snackBarNotificationsForceQueue.size() > 0) {
      this.actuallyDisplayedNotification = this.snackBarNotificationsForceQueue.dequeue();
    } else if (this.snackBarNotificationsQueue.size() > 0) {
      this.actuallyDisplayedNotification = this.snackBarNotificationsQueue.dequeue();
    } else {
      this.actuallyDisplayedNotification = null;
      return;
    }
  }

  private runCarousel(): void {
    if (this.dialogOpened) {
      if (
        !this.actuallyDisplayedNotification.force &&
        this.snackBarNotificationsForceQueue.size() > 0
      ) {
        this.dialog.closeAll();
      }
      return;
    }
    this.loadNotificationToDisplay();
    if (this.actuallyDisplayedNotification == null) {
      return;
    }

    this.dialogOpened = true;

    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = 1000 * this.actuallyDisplayedNotification.duration;
    const callback = this.actuallyDisplayedNotification.callback;

    this.dialog
      .open(NewVersionComponent, {
        width: "300px",
        height: "200px"
      })
      .afterClosed()
      .subscribe(() => {
        if (callback) {
          callback();
        }
        this.dialogOpened = false;
        this.runCarousel();
      });
  }
}

export interface SnackBarNotification {
  message: string;
  action: string;
  duration: number;
  callback: () => void;
  force: boolean;
}
