import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class AccountService {
  public currentUser$ = new BehaviorSubject<any>(this.getCurrentUser());
  private endpoint: string = `${environment.apiURL}/account`;
  private forgotPasswordEndpoint: string = `${environment.apiURL}/forgot-password`;
  private missingDeviceNotificationEndpoint: string = `${environment.apiURL}/account/missing-device-notification`;
  private changePasswordEndpoint: string = `${environment.apiURL}/account/forced-change-password`;
  private externalIpEndpoint: string = `${environment.apiURL}/account/ip`;
  private keyForChangePassword: String;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  public getCurrentUser() {
    let user;
    try {
      user = localStorage.getItem("user");
    } catch (error) {}
    return user;
  }

  public hasCurrentUser() {
    try {
      return !!localStorage.getItem("user");
    } catch (error) {}
    return false;
  }

  public getAccount() {
    return this.http.get(this.endpoint);
  }

  public getUserPhoto() {
    return this.http.get(`${this.endpoint}/photos`);
  }

  public getAccountFast() {
    return this.http.get(`${this.endpoint}/deviceFast`);
  }

  public getUserIP() {
    return this.http.get(`${this.externalIpEndpoint}`).toPromise();
  }

  public getUserIPNoPromise() {
    return this.http.get(`${this.externalIpEndpoint}`);
  }

  public forgotPassword(body) {
    return this.http.post(`${this.forgotPasswordEndpoint}`, body).toPromise();
  }

  public changePassword(body) {
    return this.http.post(`${this.forgotPasswordEndpoint}`, body).toPromise();
  }

  public notifyMissingDeviceForUser() {
    return this.http
      .post(`${this.missingDeviceNotificationEndpoint}`, {})
      .toPromise();
  }

  setKeyForChangePassword(key: String) {
    this.keyForChangePassword = key;
  }

  getKeyForChangePassword() {
    return this.keyForChangePassword;
  }
}
