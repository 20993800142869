<div
  class="home-card-carousel"
  [ngStyle]="{ height: listings?.length == 0 ? '198px' : 'auto' }"
>
  <div class="carousel-container">
    <app-loading-spinner *ngIf="listings?.length == 0"></app-loading-spinner>
    <ngx-slick-carousel
      id="slick-listings"
      #slickCarousel
      #slickModal="slick-carousel"
      [config]="slideConfig"
      class="app-gradient carousel slick-carousel-tile carousel-dimensions"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)"
    >
      <div ngxSlickItem *ngFor="let listing of listings" class="slide">
        <featured-listing-card
          [listing]="listing"
          [latamCountry]="latamCountry"
          [hideLogo]="hideLogo"
        ></featured-listing-card>
      </div>
    </ngx-slick-carousel>
    <button
      mat-basic-button
      #videoPrev
      aria-label="left arrow"
      class="news-leftLs btnCard"
      (click)="loadPage('back', slickCarousel)"
      *ngIf="slickCarousel.currentIndex && !handsetMode"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-basic-button
      #videoNext
      aria-label="right arrow"
      class="news-rightLs btnCard xxrightarrowbtn"
      (click)="loadPage('next', slickCarousel)"
      [disabled]="disableNext"
      *ngIf="!lastSlideActiveCehck && !handsetMode"
    >
      <mat-icon class="nextIckk" *ngIf="!disableNext">chevron_right</mat-icon>
    </button>
    <span class="flex-row progress-display" [hidden]="true">{{
      getSlideProgress()
    }}</span>
  </div>
  <!--  <div class="banner mb-3" *ngIf="currentCode == 'co'">
    <span
      translate="products.{{
        currentCode
      }}.products.marketing-card.marketing-product.comingSoon"
    >
    </span>
  </div> -->
</div>
