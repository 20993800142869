import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "contact-dialog",
  template: `
    <div class="flex-btn">
      <button (click)="dismiss()" class="close-btn">X</button>
    </div>
    <div>
      <h3 translate="global.contactModal.header" class="title center"></h3>
      <p translate="global.contactModal.infoBr" class="subtitle center"></p>
      <button
        translate="global.contactModal.contactEmailBr"
        class="contact-btn"
        (click)="openEmail()"
      ></button>
      <p class="center">
        <span translate="global.contactModal.call">Or call: </span>
        <a href="tel:+55 11 3046-9595"> +55 11 3046-9595</a>
      </p>
    </div>
  `,
  styles: [
    `
      .contact-btn {
        background-color: #f90;
        color: #fff;
        border-radius: 0;
        padding: 13px 50px;
        margin: 0 auto 30px;
        display: block;
        border: none;
        float: none;
        font-size: 17px;
        font-weight: 400;
        cursor: pointer;
      }
      .contact-btn:hover {
        opacity: 0.7;
      }
      .flex-btn {
        display: flex;
        justify-content: flex-end;
      }
      .close-btn {
        -webkit-appearance: none;
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
        outline: none;
      }
      .close-btn:hover {
        opacity: 0.5;
      }
      .center {
        text-align: -webkit-center;
      }
      .title {
        font-size: 32px;
        text-align: center;
      }
      .subtitle {
        color: #767676;
        margin-bottom: 30px;
        font-size: 18px;
      }
    `
  ]
})
export class ContactDialogComponent implements OnInit {
  content: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _d: MatDialog
  ) {
    this.content = data ? data : null;
  }

  ngOnInit(): void {}

  dismiss() {
    this._d.closeAll();
  }

  openEmail() {
    window.location.href = `mailto:contato@siila.com.br`;
    return false;
  }
}
