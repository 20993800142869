import { Component, OnInit, Input, Injector } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { WindowRef } from "../../../window-ref.service";
import { NGXLogger } from "ngx-logger";

@Component({
  selector: "app-breadcrumb-nav",
  templateUrl: "./breadcrumb-nav.component.html",
  styleUrls: ["./breadcrumb-nav.component.scss"]
})
export class BreadcrumbNavComponent implements OnInit {
  @Input("") handsetMode: boolean;
  @Input("") textLabel: string;
  @Input("") returnFragment: string;
  @Input("") blueButtons: string;

  additionalIcon: BreadcrumbNavButton = {
    iconRef: faNewspaper,
    altLabel: "News Paper"
  };

  // TODO: Extract this functionality to a service
  // Reusing a component should happen once and it should be a class Extended by a component
  // This service should trigger reloading a component based on certain logic
  constructor(
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _windowRef: WindowRef,
    private _location: Location,
    private _injector: Injector,
    private _log: NGXLogger
  ) {
    this.updateOnLocationChange();
  }

  ngOnInit(): void {}

  updateOnLocationChange() {
    this._location.subscribe((location: LocationSnapshot) => {
      setTimeout(() => {
        this._log.info(
          "reusing component ---> this should only happen once per component"
        );
        this.reuseComponent(location);
      }, 200);
    });
  }

  reuseComponent(location: LocationSnapshot) {
    if (location.pop && location.url !== "" && !location.url.includes("#")) {
      const ref = this._injector.get(this._activeRoute.component);
      typeof ref.ngOnInit == "function"
        ? ref.ngOnInit()
        : this._log.warn("ng init does not exist on component reference.");
    }
  }

  back() {
    this._windowRef.nativeWindow.history.back();
  }

  returnToFragment() {
    this._router.navigate(["/"], { fragment: this.returnFragment || "" });
  }
}

export interface LocationSnapshot {
  pop: boolean;
  state: any;
  type: string;
  url: string;
}

export interface AdditionalButtons {
  buttonElements: BreadcrumbNavButton[];
}

export interface BreadcrumbNavButton {
  iconRef: any;
  altLabel: string;
}
