import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "error-dialog-component",
  template: `
    <div>
      <div>
        <p>Reason: {{ data.reason }}</p>
        <p>Status: {{ data.status }}</p>
      </div>
    </div>
  `,
  styles: [``]
})
export class ErrorDialogComponent {
  title = "Angular-Interceptor";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
