import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { access } from "fs";
import { Subscription } from "rxjs";
import { threadId } from "worker_threads";
import { environment } from "../../../../../environments/environment";
import { DemoDialogComponent } from "../../dialogs";
import {
  DetailedSolution,
  HeaderImage,
  ProductItem
} from "../../../../core/models/products.model";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { MetaService } from "@ngx-meta/core";
import { MetaTagService } from "../../../services/meta-tag.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { action, page } from "../../../utils/params";
import { DemoDialogService } from "../../../../core/services/dialog";
import { I18nService } from "../../../services";

@Component({
  selector: "app-solution",
  templateUrl: "./solution.component.html",
  styleUrls: [
    "./solution.component.scss",
    "../product-list/product-list.component.scss",
    "../fii-data-pro/fii-data-pro.component.scss",
    "../grocs/grocs.component.scss",
    "../market-analytics/market-analytics.component.scss"
  ]
})
export class SolutionComponent implements OnInit {
  @Input() productNumber: number;

  routerSub: Subscription;
  solutionId: number;
  solutions: DetailedSolution;
  checkList = [];
  solutionList = [];
  solutionListV2 = [];
  solutionListV3 = [];
  grocsList = [];
  translatePath = "products.features-card.marketAnalytics.check-list.";
  translateSolutionPath: string;
  translateSolutionPathV2: string;
  translateSolutionPathV3: string;
  grocsPath: string;
  marketCardList = [];
  products: ProductItem[];
  marketCardTranslatePath = "products.features-card.marketAnalytics.card-list.";
  marketImgCard: string[];
  currentCode: string;
  form: FormGroup;
  sortedComponent: {
    index: number;
    component: string;
  }[];
  browserLang: string = "en";
  isMobile = false;
  action = action;
  imgspotmacalt: string;
  imgAcademyAlt: string;
  solutionKey: string;
  imgFiiAltMx: string;
  imgFiiAlt: string;
  solutiongrocs: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private siilaTranslationService: SiilaTranslationService,
    private metaService: MetaTagService,
    private demo: DemoDialogService,
    private i18: I18nService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.solutions = {
      id: 0,
      content: null, // Provide a proper default value for Content
      solutionName: "",
      headerImage: <HeaderImage>{}, // Provide a proper default value for HeaderImage
      headerTitle: "",
      includeMarketAnalytics: false,
      includeSiilaSpot: false,
      includeSiilaAcademy: null, // Change null to the appropriate default if needed
      includeFiiDataPro: null, // Change null to the appropriate default if needed
      beneficts: [], // Empty array as a default for Benefict[]
      iconTexts: [], // Empty array as a default for IconText[]
      norder: 0,
      includeMarketAnalyticsV1: false,
      includeMarketAnalyticsV2: false,
      includeSiilaSpotV1: false,
      includeSiilaSpotV2: false,
      includeSiilaSpotV3: false,
      includeDownloadResource: false,
      shortName: ""
    };
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.translateSolutionPath = `spot.${this.currentCode}.spot.spot-market.market-list.`;
    this.translateSolutionPathV2 = `spot.${this.currentCode}.spot.spot-market.market-listV2.`;
    this.translateSolutionPathV3 = `spot.${this.currentCode}.spot.spot-market.market-listV3.`;
    this.grocsPath = `solutions.grocs.grocs-list.`;
    this.checkList = [...Array(5).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        title: `${this.translatePath}check${i + 1}.title`,
        info: `${this.translatePath}check${i + 1}.info`,
        desc: `${this.translatePath}check${i + 1}.desc`
      };
    });
    this.solutionList = [...Array(4).keys()].map(i => {
      return {
        title: `${this.translateSolutionPath}card${i + 1}.title`,
        icon: "assets/icons/check.svg",
        text: `${this.translateSolutionPath}card${i + 1}.text3`
      };
    });
    this.solutionListV2 = [...Array(4).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        text: `${this.translateSolutionPathV2}text${i + 1}`
      };
    });
    this.solutionListV3 = [...Array(2).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        text: `${this.translateSolutionPathV3}text${i + 1}`
      };
    });
    this.grocsList = [...Array(2).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        text: `${this.grocsPath}text${i + 1}`
      };
    });

    this.marketImgCard = [
      "assets/img/products-list/indices.png",
      "assets/img/products-list/investors.png",
      "assets/img/products-list/grocs.png"
    ];

    let requestType: string = "QUARTERLY";
    /*  if (this.productNumber === 103 || this.productNumber === 106) {
      requestType = "INDICES";
    } */
    this.form = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      position: ["", Validators.required],
      requestType: [requestType]
    });
  }

  headerImage(benefits: any): string {
    const imgMultiLang = this.findCustomBenefitsImg(benefits);
    if (imgMultiLang != null) {
      return imgMultiLang;
    } else {
      const item: HeaderImage = benefits.image;
      return `${environment.cloudfrontURL}${item.filePathInBucket}/640w/${item.filename}`;
    }
  }

  findCustomBenefitsImg(benefits: any) {
    const mainPath = "../../../../../assets/img/solution-benefits/";
    let img = null;
    if (this.currentCode == "co") {
      if (benefits.solutionId == 115 && benefits.id == 157) {
        if (this.browserLang == "en") {
          img = mainPath + "Note-Market-Statistics-Office.png";
        } else {
          img = mainPath + "Note-Estadisticas-de-Mercado-Oficinas.png";
        }
      }
    }

    if (img != null) {
      return img;
    }

    if (
      benefits.solutionId == 121 ||
      benefits.solutionId == 114 ||
      benefits.solutionId == 107
    ) {
      const mainPath = "../../../../../assets/img/solution-benefits/academy/";
      if (this.browserLang == "en") {
        if (benefits.id == 177 || benefits.id == 153) {
          img = mainPath + "ACADEMY MOCKUP MX EN.png";
        }
        if (benefits.id == 130) {
          img = mainPath + "ACADEMY MOCKUP BR EN.png";
        }
      }
    }

    return img;
  }

  headerImageAlt(item: any): string {
    let result = "";
    if (
      item &&
      item.solutionId &&
      (item.solutionId == 107 ||
        item.solutionId == 122 ||
        item.solutionId == 114 ||
        item.solutionId == 115 ||
        item.solutionId == 116 ||
        item.solutionId == 117 ||
        item.solutionId == 118 ||
        item.solutionId == 119 ||
        item.solutionId == 120 ||
        item.solutionId == 121 ||
        item.solutionId == 106 ||
        item.solutionId == 113 ||
        item.solutionId == 105 ||
        item.solutionId == 112 ||
        item.solutionId == 101 ||
        item.solutionId == 108 ||
        item.solutionId == 103 ||
        item.solutionId == 110 ||
        item.solutionId == 104 ||
        item.solutionId == 111 ||
        item.solutionId == 102 ||
        item.solutionId == 109)
    ) {
      switch (item.id) {
        case 101:
        case 131:
        case 155:
          return this.siilaTranslationService.getImgSEOTranslation(
            "solutions.dataqualityalt"
          );
        case 102:
        case 132:
        case 156:
          return this.siilaTranslationService.getImgSEOTranslation(
            "solutions.pulsemarketalt"
          );
        case 103:
        case 133:
        case 157:
          return this.siilaTranslationService.getImgSEOTranslation(
            "solutions.benchmarkalt"
          );
        case 104:
        case 134:
        case 158:
          return this.siilaTranslationService.getImgSEOTranslation(
            "solutions.trustedsourcealt"
          );
        case 112:
        case 139:
        case 159:
          return this.siilaTranslationService.getImgSEOTranslation(
            "developers.benefits.1"
          );
        case 113:
        case 140:
        case 160:
          return this.siilaTranslationService.getImgSEOTranslation(
            "developers.benefits.2"
          );
        case 114:
        case 141:
        case 161:
          return this.siilaTranslationService.getImgSEOTranslation(
            "developers.benefits.3"
          );
        case 115:
        case 142:
        case 162:
          return this.siilaTranslationService.getImgSEOTranslation(
            "developers.benefits.4"
          );
        case 116:
        case 143:
        case 165:
          return this.siilaTranslationService.getImgSEOTranslation(
            "tenants.benefits.1"
          );
        case 117:
        case 144:
        case 163:
          return this.siilaTranslationService.getImgSEOTranslation(
            "tenants.benefits.2"
          );
        case 118:
        case 145:
        case 164:
          return this.siilaTranslationService.getImgSEOTranslation(
            "tenants.benefits.3"
          );
        case 105:
        case 135:
        case 166:
          return this.siilaTranslationService.getImgSEOTranslation(
            "brokerage.benefits.1"
          );
        case 106:
        case 136:
        case 167:
          return this.siilaTranslationService.getImgSEOTranslation(
            "brokerage.benefits.2"
          );
        case 107:
        case 137:
        case 168:
          return this.siilaTranslationService.getImgSEOTranslation(
            "brokerage.benefits.3"
          );
        case 108:
        case 138:
        case 169:
          return this.siilaTranslationService.getImgSEOTranslation(
            "brokerage.benefits.4"
          );
        case 120:
        case 146:
        case 170:
          return this.siilaTranslationService.getImgSEOTranslation(
            "valuationAdvisor.benefits.1"
          );
        case 121:
        case 147:
        case 171:
          return this.siilaTranslationService.getImgSEOTranslation(
            "valuationAdvisor.benefits.2"
          );
        case 122:
        case 148:
        case 172:
          return this.siilaTranslationService.getImgSEOTranslation(
            "valuationAdvisor.benefits.3"
          );
        case 124:
        case 149:
        case 173:
          return this.siilaTranslationService.getImgSEOTranslation(
            "serviceProvider.benefits.1"
          );
        case 125:
        case 150:
        case 174:
          return this.siilaTranslationService.getImgSEOTranslation(
            "serviceProvider.benefits.2"
          );
        case 126:
        case 151:
        case 175:
          return this.siilaTranslationService.getImgSEOTranslation(
            "serviceProvider.benefits.3"
          );
        case 128:
        case 180:
        case 152:
        case 176:
          return this.siilaTranslationService.getImgSEOTranslation(
            "fiiInvestors.benefits.1"
          );
        case 129:
        case 182:
        case 153:
          return this.siilaTranslationService.getImgSEOTranslation(
            "fiiInvestors.benefits.2"
          );
        case 130:
        case 181:
        case 154:
          return this.siilaTranslationService.getImgSEOTranslation(
            "fiiInvestors.benefits.3"
          );
      }
    }
    return result;
  }

  ngOnInit(): void {
    this.solutiongrocs = this.siilaTranslationService.getImgSEOTranslation(
      "solutiongrocs"
    );
    this.imgspotmacalt = this.siilaTranslationService.getImgSEOTranslation(
      "solutions.imgspotmacalt"
    );
    this.imgAcademyAlt = this.siilaTranslationService.getImgSEOTranslation(
      "tenants.imgAcademyAlt"
    );
    this.imgFiiAltMx = this.siilaTranslationService.getImgSEOTranslation(
      "fiiInvestors.pcpro"
    );
    this.imgFiiAlt = this.siilaTranslationService.getImgSEOTranslation(
      "fiiInvestors.pc"
    );

    this.routerSub = this.route.paramMap.subscribe(params => {
      this.solutionId = +params.get("id");
      if (this.solutionId == 103 || this.solutionId == 110) {
        this.imgspotmacalt = this.siilaTranslationService.getImgSEOTranslation(
          "developers.imgspotmacalt"
        );
      }
      if (this.solutionId == 102 || this.solutionId == 109) {
        this.imgspotmacalt = this.siilaTranslationService.getImgSEOTranslation(
          "brokerage.imgspotmacalt"
        );
      }
      if (this.solutionId == 105 || this.solutionId == 112) {
        this.imgspotmacalt = this.siilaTranslationService.getImgSEOTranslation(
          "valuationAdvisor.imgspotmacalt"
        );
      }
      if (this.solutionId == 106 || this.solutionId == 113) {
        this.imgspotmacalt = this.siilaTranslationService.getImgSEOTranslation(
          "serviceProvider.imgspotmacalt"
        );
      }
      if (
        this.solutionId == 107 ||
        this.solutionId == 114 ||
        this.solutionId == 122
      ) {
        this.imgAcademyAlt = this.siilaTranslationService.getImgSEOTranslation(
          "fiiInvestors.imgAcademyAlt"
        );
        this.imgspotmacalt = this.siilaTranslationService.getImgSEOTranslation(
          "fiiInvestors.imgspotmacalt"
        );
      }
      this.solutionKey = `solutions${this.solutionId}`;
      this.productService.getSolution(this.solutionId).subscribe(solutions => {
        this.solutions = solutions;
        this.metaService.setSolutionstMetaTags(this.solutions);
      });
    });
    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.marketCardList = products.map((item, index) => {
        return {
          id: item.id,
          background: this.marketImgCard[index],
          src: `${environment.cloudfrontURL}${item.iconPath}/360w/${item.iconFilename}`,
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        };
      });
    });
  }

  downloadFormIndex() {
    let formValue = this.form.value;
    this.form.reset();
    let message = this.i18.get("global.newsForm.download");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.requestFileIndex(formValue).subscribe(
      response => {
        this.form.controls.requestType.setValue("QUARTERLY");
        this.productService.downloadPdf(response.resourceUrl);
        if (response.success === false) {
          this.snackBar.open(message, action);
        }
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
  }

  sortComponent(component: string): number {
    const order = {
      MA: 1,
      SPOT: 2,
      GROCS: 3,
      ACADEMY: 4,
      FII: 5,
      METHODOLOGY: 6,
      PILLARS: 7,
      SERVE: 8,
      DOWNLOAD: 9,
      ACCESS: 10
    };
    const orderTenants = {
      SPOT: 1,
      MA: 2,
      GROCS: 3,
      ACADEMY: 4,
      FII: 5,
      METHODOLOGY: 6,
      PILLARS: 7,
      SERVE: 8,
      DOWNLOAD: 9,
      ACCESS: 10
    };
    const orderReit = {
      FII: 1,
      MA: 2,
      SPOT: 3,
      GROCS: 4,
      ACADEMY: 4,
      METHODOLOGY: 6,
      PILLARS: 7,
      SERVE: 8,
      DOWNLOAD: 9,
      ACCESS: 10
    };
    if (
      this.solutionId === 107 ||
      this.solutionId === 114 ||
      this.solutionId == 122
    ) {
      return orderReit[component];
    } else if (this.solutionId === 104 || this.solutionId === 110) {
      return orderTenants[component];
    } else {
      return order[component];
    }
  }

  openContactDialog(action: string) {
    this.demo.open(
      DemoDialogComponent,
      false,
      page.spot,
      action,
      "",
      this.i18.get(`spot.${this.currentCode}.spot.title`),
      false
    );
  }

  openHomePage() {
    window.open(
      `${environment.spotURL}/home/lang/${this.i18.getCurrentLanguage()}`,
      "_blank"
    );
  }

  openGrocs() {
    window.open(
      `${
        environment.applicationURL
      }/products/101/grocs/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openAcademy() {
    window.open(
      `${
        this.currentCode == "co" ? environment.applicationURLMx + "/" : ""
      }academy/lang/${this.browserLang}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openFII() {
    window.open(
      `${
        this.currentCode == "co"
          ? environment.applicationURLBr + "/fii-data-pro"
          : this.currentCode == "br" || this.currentCode == "latam"
          ? `/fii-data-pro/lang/${this.i18.getCurrentLanguage()}`
          : `/fibra-analytics/lang/${this.i18.getCurrentLanguage()}`
      }`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openFIIMx() {
    window.open(
      `${
        this.currentCode == "co"
          ? environment.applicationURLMx +
            "/fibra-analytics" +
            `/lang/${this.i18.getCurrentLanguage()}`
          : "/fibra-analytics" + `/lang/${this.i18.getCurrentLanguage()}`
      }`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  imgValuationForm() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "all-products-item7-report-MX-EN.jpg";
      } else {
        img = img + "all-products-item7-report-MX-SP.jpg";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "all-products-item7-report-EN.jpg";
      } else {
        img = img + "all-products-item7-report-PT.jpg";
      }
    }

    return img;
  }

  imgSiila() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-ES.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-PT.png";
      }
    }

    return img;
  }

  imgFii() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      img = img + "FIBRAS-ANALYTICS - MX (ENGLISH AND SPANISH).png";
    } else {
      img = img + "FII-DATA-PRO-SiiLA-FIBRAS.png";
    }

    return img;
  }

  imgFiiMx() {
    let img = "../../../../../assets/img/products-list/";
    img = img + "FIBRAS-ANALYTICS - MX (ENGLISH AND SPANISH).png";

    return img;
  }

  imgAcademy() {
    let img = "../../../../../assets/img/academy-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "ACADEMY ENGLISH.png";
      } else {
        img = img + "ACADEMY ESPANISH.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "ACADEMY ENGLISH.png";
      } else {
        img = img + "ACADEMY PORTUGUES.png";
      }
    }

    return img;
  }

  openSpot(code) {
    window.open(
      `${
        code == "br"
          ? `${environment.spotURL}/lang/${this.i18.getCurrentLanguage()}`
          : `${environment.spotURLMx}/lang/${this.i18.getCurrentLanguage()}`
      }`,
      this.isMobile ? "_self" : "_blank"
    );
  }
}
