import { Pipe, PipeTransform } from "@angular/core";
import { LocalNumberPipe } from "../directives";

@Pipe({
  name: "localNumberWithSign",
  pure: false
})
export class LocalNumberWithSign implements PipeTransform {
  constructor(private numberPipe: LocalNumberPipe) {}

  transform(value: number, format: string) {
    const absVal = Math.abs(Number(value));
    if (value > 0) {
      return `+${this.numberPipe.transform(absVal, format)}`;
    } else if (value < 0) {
      return `-${this.numberPipe.transform(absVal, format)}`;
    } else if (value === 0 || value === null) {
      return `${this.numberPipe.transform(0, format)}`;
    } else {
      return value;
    }
  }
}
