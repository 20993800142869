import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MailchimpFormComponent } from "../mail-chimp/mail-chimp.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "section-header",
  template: `
    <span
      class="header-container"
      [ngClass]="{ 'center-elements': centerHeaderElements }"
    >
      <div
        class="section-title flex-alignment"
        [ngClass]="{
          'no-padding': removePadding,
          'smaller-text': handsetMode && showSubscribe,
          'remove-margin': centerHeaderElements
        }"
      >
        <span
          [ngStyle]="{
            'text-transform': capitalize ? 'capitalize' : 'uppercase'
          }"
          [translate]="title"
        ></span>
      </div>
      <button
        aria-label="search"
        mat-flat-button
        class="siila-blue-submit search-btn"
        *ngIf="hasSearchButton && !showBrandLogo && !handsetMode"
        (click)="navigateToListingSearch()"
      >
        <mat-icon>search</mat-icon>
        <span
          [ngStyle]="{
            'text-transform': capitalize ? 'capitalize' : 'uppercase'
          }"
          [translate]="searchButtonText"
        ></span>
      </button>
      <button
        aria-label="subscribe"
        mat-flat-button
        class="siila-orange-bg"
        [ngClass]="{
          'custom-btn-spacing': !handsetMode && centerHeaderElements,
          'scale-button-size': handsetMode && showSubscribe
        }"
        (click)="openMailchimpForm()"
        *ngIf="!showBrandLogo && showSubscribe"
      >
        <span style="font-size:1.2em;" translate="subscribe"></span>
      </button>
      <img
        alt="siila-brand-logo"
        class="brand-logo"
        *ngIf="showBrandLogo"
        src="assets/img/siilabrandlogo-new.png"
      />
    </span>
  `,
  styles: [
    `
      .header-container {
        display: flex;
        justify-content: space-between;
        padding: 5px;
      }
      .remove-margin {
        margin: unset !important;
      }
      button {
        text-transform: uppercase;
      }
      .search-btn {
        position: absolute;
        right: 0;
        margin-right: 10px;
        height: 33px;
      }
      .flex-alignment {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .mobile-btn {
        position: unset !important;
        right: 0;
        height: 33px;
        top: 2;
      }
      .center-elements {
        justify-content: center;
      }
      .smaller-text {
        font-size: 17px !important;
        padding-right: 10px;
      }
      .custom-btn-spacing {
        margin-left: 20px;
      }
      .brand-logo {
        width: 136px;
        height: 57px;
        padding-left: 20px;
      }
      .section-title {
        color: #454545;
        flex: 0.7;
        align-self: flex-start;
        margin-left: 20px;
        text-align: center;
        font-size: 26px;
        white-space: nowrap;
        font-weight: 600;
        margin: 16px auto;
      }
      .no-padding {
        padding-left: 0px;
      }
    `
  ]
})
export class SectionHeaderComponent implements OnChanges {
  @Input() title: string;
  @Input() capitalize: boolean;
  @Input() showSubscribe: boolean;
  @Input() removePadding: boolean;
  @Input() hasSearchButton: boolean;
  @Input() searchButtonText: string = "";
  @Input() showBrandLogo: boolean = false;
  @Input() centerHeaderElements: boolean = false;
  @Input() pageNumber: string;

  handsetMode: boolean = false;

  constructor(
    private _dialog: MatDialog,
    public breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.handsetMode = result.matches;
      });
  }

  openMailchimpForm(data: any) {
    this._dialog.open(MailchimpFormComponent, {
      width: "600px",
      height: "600px",
      data: this._dialog
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.pageNumber) {
      this.pageNumber = changes.pageNumber.currentValue;
    }
  }

  public navigateToLegacyListingSearch() {
    window.location.href = `${environment.serverURL}/#!/listings/search`;
  }

  public navigateToListingSearch() {
    if (!environment.spotURL.includes("localhost")) {
      window.open(environment.spotURL + "/search", "_blank");
    }
  }
}
