import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProductService } from "../../../../core/services/products.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { DemoDialogService } from "../../../../core/services/dialog";
import { DemoDialogComponent } from "../../dialogs";
import { action, page } from "../../../utils/params";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { I18nService } from "../../../services";
import { ActivatedRoute } from "@angular/router";
import { MetaTagService } from "../../../services/meta-tag.service";
import { CaptchaService } from "../../../services/captcha.service";

@Component({
  templateUrl: "./product-list.component.html",
  styleUrls: [
    "./product-list.component.scss",
    "../grocs/grocs.component.scss",
    "../about/about.component.scss",
    "../glossary/glossary.component.scss",
    "../fii-data-pro/fii-data-pro.component.scss"
  ]
})
export class ProductListComponent implements OnInit, OnDestroy {
  @Input() productNumber: number;
  resourseTranslatePanth: string;
  featureResouces = [];
  currentCode: string;
  form: FormGroup;
  isMobile: boolean;
  subscription: Subscription;
  lang: string;
  requestType: string = "QUARTERLY";
  routerSub: Subscription;
  latamCountry = "latamMx";
  imgMarketingTitle: string;
  imgAcademyAlt: string;
  imgFiiAlt: string;
  imgAllProductsAlt: string;
  SaleLease = "lease";
  propertyType = "office";
  property = "";

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private responsive: BreakpointObserver,
    private _changeDetectorRef: ChangeDetectorRef,
    private demo: DemoDialogService,
    private siilaTranslationService: SiilaTranslationService,
    private i18: I18nService,
    private metatagservice: MetaTagService,
    private route: ActivatedRoute,
    private captchaService: CaptchaService
  ) {
    this.currentCode = environment.platformId;
    this.lang = this.siilaTranslationService.getCurrentLanguage();

    /*  if (this.productNumber === 103 || this.productNumber === 106) {
      requestType = "INDICES";
    } */
    this.form = this.fb.group({
      fullName: ["", Validators.required],
      phoneNumber: [""],
      email: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      position: ["", Validators.required],
      requestType: ["QUARTERLY"]
    });

    this.featureResouces = [
      {
        tags: ["tags.brazil", "tags.marketAnalitic"],
        background: "assets/img/products-list/Card1.png",
        title: `${this.resourseTranslatePanth}card1.title`,
        text: `${this.resourseTranslatePanth}card1.text`,
        actionText: `${this.resourseTranslatePanth}card1.action-text`
      },
      {
        tags: ["tags.brazil", "tags.marketAnalitic"],
        background: "assets/img/products-list/Card2.png",
        title: `${this.resourseTranslatePanth}card2.title`,
        text: `${this.resourseTranslatePanth}card2.text`,
        actionText: `${this.resourseTranslatePanth}card2.action-text`
      },
      {
        tags: ["tags.brazil", "tags.marketAnalitic"],
        background: "assets/img/products-list/Card3.png",
        title: `${this.resourseTranslatePanth}card3.title`,
        text: `${this.resourseTranslatePanth}card3.text`,
        actionText: `${this.resourseTranslatePanth}card3.action-text`
      }
    ];
  }

  ngOnInit(): void {
    this.imgMarketingTitle = this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.productList.spot"
    );
    this.imgAcademyAlt = this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.productList.academy"
    );
    this.imgFiiAlt = this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.productList.imgFiiAlt"
    );
    this.imgAllProductsAlt = this.siilaTranslationService.getTranslationFromJSONFiles(
      "imgSEO.productList.imgAllProductsAlt"
    );
    this.routerSub = this.route.paramMap.subscribe(params => {
      this.metatagservice.setAllProductsMetaTags();
    });

    this.subscription = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  downloadFormIndex() {
    let formValue = this.form.value;
    this.form.reset();
    let message = this.i18.get("global.newsForm.download");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.requestFileIndex(formValue).subscribe(
      response => {
        this.productService.downloadPdf(response.resourceUrl);

        if (response.success === false) {
          this.snackBar.open(errorMsj, action);
        } else {
          this.snackBar.open(message, action);
        }
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
  }
  openContactDialog() {
    this.demo.open(DemoDialogComponent, false, page.products, action.learnMore);
  }

  openSpot(lang = null) {
    if (lang) {
      window.open(
        `${
          lang == "mx"
            ? environment.applicationURLMx
            : environment.applicationURLBr
        }/SPOT/lang/${lang == "mx" ? this.lang : "en"}`,
        this.isMobile ? "_self" : "_blank"
      );
    } else {
      window.open(
        `${
          environment.applicationURL
        }/SPOT/lang/${this.i18.getCurrentLanguage()}`,
        this.isMobile ? "_self" : "_blank"
      );
    }
  }

  openHome() {
    window.open(
      `${
        this.latamCountry == "latamMx"
          ? environment.spotURLMx
          : environment.spotURL
      }/home/lang/${this.i18.getCurrentLanguage()}`,
      "_blank"
    );
  }

  openAcademy() {
    window.open(
      `${
        this.currentCode == "co"
          ? environment.applicationURLMx
          : environment.applicationURL
      }/academy/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openFiiData() {
    window.open(
      `${
        this.currentCode == "br" ||
        this.currentCode == "latam" ||
        this.currentCode == "co"
          ? environment.applicationURL + "/fii-data-pro"
          : environment.applicationURL + "/fibra-analytics"
      }/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openFiiDataMx() {
    window.open(
      `${environment.applicationURL +
        "/fibra-analytics"}/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  selectVideo() {
    if (this.lang == "en") {
      return "ZQDn4jGs1wg";
    } else if (this.lang == "pt") {
      return "S7g88rYJ9rY";
    } else {
      return "tVb58qqMus4";
    }
  }

  imgMarketing() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.lang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-ES.png";
      }
    } else {
      if (this.lang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-PT.png";
      }
    }

    return img;
  }

  imgAcademy() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.lang == "en") {
        img = img + "SiiLA-ACADEMY - full screen - EN.png";
      } else {
        img = img + "SiiLA-ACADEMY - full screen - ES.png";
      }
    } else {
      if (this.lang == "en") {
        img = img + "SiiLA-ACADEMY - full screen - EN.png";
      } else {
        img = img + "SiiLA-ACADEMY - full screen - PT.png";
      }
    }

    return img;
  }

  imgFii() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      img = img + "FIBRAS-ANALYTICS - MX (ENGLISH AND SPANISH).png";
    } else {
      img = img + "FII-DATA-PRO-SiiLA-FIBRAS.png";
    }

    return img;
  }

  imgFiiMx() {
    let img = "../../../../../assets/img/products-list/";
    img = img + "FIBRAS-ANALYTICS - MX (ENGLISH AND SPANISH).png";
    return img;
  }

  imgAllProducts() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx") {
      if (this.lang == "en") {
        img = img + "all-products-item7-report-MX-EN.jpg";
      } else {
        img = img + "all-products-item7-report-MX-SP.jpg";
      }
    }
    if (this.currentCode != "mx" && this.currentCode != "co") {
      if (this.lang == "en") {
        img = img + "all-products-item7-report-EN.jpg";
      } else {
        img = img + "all-products-item7-report-PT.jpg";
      }
    }

    if (this.currentCode == "co") {
      if (this.lang == "en") {
        img = img + "all-products-item7-report-BOGOTÁ_EN.jpg";
      } else {
        img = img + "all-products-item7-report-BOGOTÁ.jpg";
      }
    }

    return img;
  }

  openSearch() {
    window.open(
      `${
        (this.currentCode == "latam" || this.currentCode == "co") &&
        this.latamCountry == "latamMx"
          ? environment.spotURLMx
          : environment.spotURL
      }/spot-search/${this.SaleLease}/${this.propertyType}/${
        this.property == "" ? "all" : "all?keyword=" + this.property
      }/lang/${this.i18.getCurrentLanguage()}`,
      "_blank"
    );
  }
}
