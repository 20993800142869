import { Injectable, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class NewVersionService {
  dialogRef: any;

  constructor(private dialog: MatDialog) {}

  public open(comp: any) {
    if (!this.dialog.openDialogs.length) {
      this.dialogRef = this.dialog.open(comp, {
        width: "300px",
        height: "200px"
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
