import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../environments/environment";
import {
  socialMediaIconsBR,
  socialMediaIconsCO,
  socialMediaIconsMX
} from "../../../core/footer/footer-links";
import { DemoDialogService } from "../../../core/services/dialog";
import { ProductService } from "../../../core/services/products.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { I18nService } from "../../services";
import { DemoDialogComponent } from "../dialogs/demo-dialog/demo-dialog.component";
import { ImageSEO } from "../../../core/models/image-seo.model";
import { StaticImagesSEOService } from "../../../core/services/static-images.service";

@Component({
  selector: "app-exclusive-access",
  templateUrl: "./exclusive-access.component.html",
  styleUrls: ["./exclusive-access.component.scss"]
})
export class ExclusiveAccessComponent implements OnInit {
  currentCode: string;
  socialMediaIconsBR = socialMediaIconsBR;
  socialMediaIconsMX = socialMediaIconsMX;
  socialMediaIconsCO = socialMediaIconsCO;
  currentSocial = [];
  email: string = "";
  browserLang: string = "en";
  commercialestatenewslatamSEO: ImageSEO;
  linkedingSEO: ImageSEO;
  youtubeSEO: ImageSEO;
  tiktokSEO: ImageSEO;
  facebookSEO: ImageSEO;
  instagramSEO: ImageSEO;
  twitterSEO: ImageSEO;
  constructor(
    private staticImgSrv: StaticImagesSEOService,
    private siilaTranslationService: SiilaTranslationService,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private i18: I18nService
  ) {
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    if (this.currentCode.includes("mx") || this.currentCode.includes("latam")) {
      this.currentSocial = this.socialMediaIconsMX;
    } else if (this.currentCode.includes("br")) {
      this.currentSocial = this.socialMediaIconsBR;
    } else {
      this.currentSocial = this.socialMediaIconsCO;
    }
  }

  ngOnInit(): void {
    this.linkedingSEO = this.staticImgSrv.getLinkedinSEO();
    this.youtubeSEO = this.staticImgSrv.getYoutubeSEO();
    this.facebookSEO = this.staticImgSrv.getFacebookSEO();
    this.instagramSEO = this.staticImgSrv.getInstagramSEO();
    this.twitterSEO = this.staticImgSrv.getTwitterSEO();
    this.tiktokSEO = this.staticImgSrv.getTikTokSEO();
    if (this.currentSocial) {
      for (let i = 0; i < this.currentSocial.length; i++) {
        let socialMediaLink: Link = this.currentSocial[i];
        let seo: ImageSEO = null;
        switch (i) {
          case 0:
            seo = this.linkedingSEO;
            break;
          case 1:
            seo = this.youtubeSEO;
            break;
          case 2:
            seo = this.facebookSEO;
            break;
          case 3:
            seo = this.instagramSEO;
            break;
          case 4:
            seo = this.twitterSEO;
            break;
          case 5:
            seo = this.tiktokSEO;
            break;
        }
        socialMediaLink.alt = seo.alt;
        socialMediaLink.title = seo.title;
      }
    }

    this.commercialestatenewslatamSEO = this.staticImgSrv.getCommercialestatenewslatam();
  }

  public sendEmail() {
    let message = this.i18.get("global.newsForm.messageEmail");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.sendEmail(this.email).subscribe(
      () => {
        this.snackBar.open(message, action);
        this.email = "";
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
    /* this.demoDialog.open(DemoDialogComponent, true, null, null, this.email); */
  }

  validEmail() {
    var EMAIL_REGEX = /\S+@\S+\.\S+/;

    if (this.email.match(EMAIL_REGEX)) {
      return false;
    } else {
      return true;
    }
  }
}

export interface Link {
  ref?: any;
  link: string;
  text?: string;
  matIcon?: string;
  target?: string;
  title?: string;
  alt?: string;
}
