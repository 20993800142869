import { Injectable } from "@angular/core";
import {
  TranslateService,
  LangChangeEvent,
  TranslateParser
} from "@ngx-translate/core";
import { Subscription, Observable } from "rxjs";
import { Location } from "@angular/common";
import { Logger } from "./logger.service";
import * as enUS from "../../../assets/i18n/en.json";
import * as ptBR from "../../../assets/i18n/pt.json";

const log = new Logger("I18nService");
const languageKey = "language";

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

interface SpotURI {
  url: string;
  queryParams: string;
}

@Injectable()
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;
  private supportedLanguagesByURL: string[] = ["pt-br", "en"];
  private currentLanguage: string;

  constructor(private translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation("en-US", enUS);
    translateService.setTranslation("pt-BR", ptBR);

    this.translateService.onLangChange.subscribe((response: any) => {
      this.currentLanguage = this.getLangBasedSPOTLanguage(response.lang);
    });
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = "";

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        try {
          sessionStorage.setItem(languageKey, event.lang);
        } catch (error) {
          console.log("No cookies enabled. Cant access local/session storage");
        }
      }
    );
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    this.langChangeSubscription.unsubscribe();
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the session storage.
   * If no parameter is specified, the language is loaded from session storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language =
      language ||
      sessionStorage.getItem(languageKey) ||
      this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split("-")[0];
      language =
        this.supportedLanguages.find(supportedLanguage =>
          supportedLanguage.startsWith(language)
        ) || "";
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language} - ${this.getCurrentLanguage()}`);
    this.translateService.use(language);
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  getTranslation(textValue: string): string {
    try {
      if (textValue && JSON.parse(textValue)) {
        // Using the default language in lower case in order to get the pt-br values defined in some JSON objects.
        const defaultLanguage = this.getCurrentLanguage();
        let newValue = JSON.parse(textValue)[defaultLanguage.toLowerCase()];
        return newValue
          ? newValue
          : JSON.parse(textValue)[Object.keys(JSON.parse(textValue))[0]];
      } else {
        return "";
      }
    } catch (e) {
      console.error(
        "failed to parse locale string",
        textValue,
        this.getCurrentLanguage()
      );
    }
    return this.translateService.currentLang;
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  /**
   * Get the value defined on the translations JSON files.
   * @param translationKey
   */
  get(translationKey: string, interpolateParams?: Object): string {
    let result = "";
    this.translateService
      .get(translationKey, interpolateParams)
      .subscribe(data => {
        result = data;
      });
    return result;
  }

  setLanguageBasedOnURL(url: string) {
    const urlParts = url.split("/");
    let lang: string = urlParts[urlParts.length - 1];
    const indexOfQueryParams = lang.indexOf("?");
    if (indexOfQueryParams != -1) {
      lang = lang.substr(0, indexOfQueryParams);
    }
    if (
      lang.toLocaleLowerCase() === "pt-br" &&
      this.language &&
      this.language === "pt-BR"
    ) {
      return;
    }
    if (
      lang.toLocaleLowerCase() === "en" &&
      this.language &&
      this.language === "en-US"
    ) {
      return;
    }
    switch (lang.toLowerCase()) {
      case "pt-br":
        this.language = "pt-BR";
        break;
      case "en":
        this.language = "en-US";
        break;
      default:
        this.language = "en-US";
        break;
    }
  }

  isLanguageParam(requestParam: string) {
    return this.supportedLanguagesByURL.includes(requestParam);
  }

  removeLanguageFromURL(url: string) {
    let newUrl = url;
    const urlParams = url.split("/");
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      const langPathURL: string = urlParams[urlParams.length - 2];
      if (this.isLanguageParam(langParam)) {
        newUrl = urlParams.slice(0, urlParams.length - 2).join("/");
      } else if (langPathURL === "lang") {
        newUrl = urlParams.slice(0, urlParams.length - 2).join("/");
      }
    }
    return newUrl;
  }

  getLanguagePathURL() {
    const currentURL = window.location.href;
    const urlParams = currentURL.split("/");
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      const langPathURL: string = urlParams[urlParams.length - 2];
      if (this.isLanguageParam(langParam) && langPathURL === "lang") {
        return "";
      }
    }

    if (this.language === "en-US" || this.language === "en") {
      return "/lang/en";
    } else if (this.language === "pt-BR" || this.language === "pt") {
      return "/lang/pt-br";
    }
    return "";
  }

  concatLangParamBasedOnURL(url: any) {
    if (this.hasURLLangPathParam(url)) {
      return this.concatLanguagePathURL(url);
    }
    return url;
  }

  hasURLLangPathParam(url: any) {
    const spotURI = this.separateURIAndQueryParams(url);
    const currentURL = spotURI.url;

    const urlParams = currentURL.split("/");
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      const langPathURL: string = urlParams[urlParams.length - 2];
      if (this.isLanguageParam(langParam) && langPathURL === "lang") {
        return true;
      }
    }
    return false;
  }

  concatLanguagePathURL(currentPath: string) {
    let path = currentPath;
    if (path == null || path === undefined) {
      return path;
    }
    if (path.endsWith("/")) {
      path = path.slice(0, path.length - 1);
    }
    if (this.language === "en") {
      path = path.concat("/lang/en");
    } else if (this.language === "pt") {
      path = path.concat("/lang/pt-br");
    }
    return path;
  }

  currentUrlContainsCurrentLanguage() {
    const currentURL = window.location.href;
    const urlParams = currentURL.split("/");
    if (urlParams.length >= 2) {
      let langParam: string = urlParams[urlParams.length - 1];
      const langPathURL: string = urlParams[urlParams.length - 2];
      if (this.language === "en-US" && langParam === "en") {
        return true;
      } else if (this.language === "pt-BR" && langParam === "pt-br") {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the current language defined for the user
   * It returns en, pt-br
   */
  getCurrentLanguage() {
    if (this.currentLanguage === undefined) {
      let storageValue = null;
      try {
        storageValue = sessionStorage.getItem(languageKey);
      } catch (error) {}
      const currentLanguageStorage = storageValue;
      if (currentLanguageStorage) {
        return this.getLangBasedSPOTLanguage(currentLanguageStorage);
      } else {
        return this.getLangBasedSPOTLanguage(
          /*  this.translateService.getBrowserLang() */
          "en-US"
        );
      }
    }
    return this.currentLanguage;
  }

  getCurrentLocale() {
    return this.getLocaleBasedSPOTLanguage(this.getCurrentLanguage());
  }

  private getLangBasedSPOTLanguage(language: string) {
    if (language === "en-US") {
      return "en";
    } else if (language === "pt-BR" || language === "pt") {
      return "pt-br";
    }
    return language;
  }

  private getLocaleBasedSPOTLanguage(language: string) {
    if (language === "en") {
      return "en-US";
    } else if (language === "pt-br") {
      return "pt-BR";
    }
    return language;
  }

  public getTranslationInTargetLanguage(
    key: string,
    language: string,
    interpolateParams?: Object
  ): string {
    let currentLanguage: string = this.translateService.currentLang;
    this.translateService.currentLang = language;
    let translation: string = this.translateService.instant(
      key,
      interpolateParams
    );

    this.translateService.currentLang = currentLanguage;

    return translation;
  }

  /**
   * If language path param is not present, the detaul metatag language is PT.
   * @param translationKey
   */
  public getMetatagTranslation(
    translationKey: string,
    interpolateParams?: Object
  ) {
    if (this.currentUrlContainsCurrentLanguage()) {
      return this.get(translationKey, interpolateParams);
    } else {
      let result = this.getTranslationInTargetLanguage(
        translationKey,
        "pt-BR",
        interpolateParams
      );
      return result;
    }
  }

  public addCurrentLanguageToPath(currentPath: string) {
    const spotURI = this.separateURIAndQueryParams(currentPath);
    const url = spotURI.url;
    const queryParams = spotURI.queryParams;

    let newURL = this.concatLanguagePathURL(this.removeLanguageFromURL(url));
    if (queryParams) {
      newURL = newURL.concat(queryParams);
    }

    return newURL;
  }

  private separateURIAndQueryParams(currentPath: string): SpotURI {
    const indexQueryParam = currentPath.indexOf("?");
    let url = currentPath;
    let queryParams = null;

    if (indexQueryParam != -1) {
      url = currentPath.substring(0, indexQueryParam);
      queryParams = currentPath.substring(indexQueryParam, currentPath.length);
    }

    return { url: url, queryParams: queryParams };
  }
}
