<div class="value-container">
  <h6
    class="badge siila-value"
    translate="global.siilaIndex.ibovespaTitle"
  ></h6>
  <div>
    <span class="badge" [negativePositiveBg]="bovespaPercent">
      {{ (bovespaPercent | localNumberWithSign: "1.2-2") || "–" }} %
    </span>
    <span class="badge">
      {{ (bovespaVal | localNumber: "1.2-2") || "–" }}
      <span class="points d-none d-sm-inline">PTS</span>
    </span>
  </div>
</div>
