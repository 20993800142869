import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Video, HomeVideo } from "../models/home-video.model";

@Injectable()
export class HomeVideosService {
  constructor(private http: HttpClient) {}

  getHomeVideos() {
    let videoParams = new HttpParams()
      .set("per_page", String(50))
      .set("page", String(1))
      .set("only_enabled", String(true));

    return this.http
      .get(`${environment.apiURL}/homepage/contents/videos`, {
        params: videoParams
      })
      .pipe(
        map((response: Video[]) =>
          response.map((video: Video) => ({
            thumbnailId: video.imageIdsByLang,
            videoId: video.urlVideosByLang
          }))
        )
      );
  }
}
