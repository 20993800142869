import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class NewsDetailService {
  private host: string = `${environment.apiURL}/storys/`;
  private allStoriesEndpoint: string = `${environment.apiURL}/storys/displayable/fastIds`;

  constructor(private http: HttpClient) {}

  public getAllArticleIdList() {
    return this.http.get(this.allStoriesEndpoint).toPromise();
  }

  public getArticleById(
    id: string,
    withActiveListings: boolean = false
  ): Promise<any> {
    return this.http
      .get(this.host + id + "?" + "withActiveListings=" + withActiveListings, {
        observe: "response"
      })
      .toPromise();
  }
}
