import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { InstallPromptComponent } from "../../../shared/components/dialogs/install-prompt-dialog/install-prompt.component";
import { CookieService } from "ngx-cookie-service";
import { NGXLogger } from "ngx-logger";
import { WindowRef } from "../../../window-ref.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class SafariPromptDialogService {
  dialogRef: any;
  expireTime: number = 90;
  safariBrowserDetected: boolean = false;

  /**
   * @param {MatDialog} _d
   * @param {CookieService} _cookies
   * @param {NGXLogger} _logger
   */
  constructor(
    public _d: MatDialog,
    private _cookies: CookieService,
    private _logger: NGXLogger,
    private _window: WindowRef,
    private _detector: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this._logger.log("current-device-data ->", this._detector.getDeviceInfo());
  }

  /**
   * @param {*} [data]
   */
  openDialog(data?): void {
    if (
      this.cookieExpired() &&
      this.browserIsSafari() &&
      !this.isStandaloneLaunch()
    ) {
      const dialogRef = this._d.open(InstallPromptComponent, {
        height: "400px",
        width: "400px",
        data: data || {
          title: "SiiLA",
          icon_path: "/assets/img/siilabrandlogo.png"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        let timestamp = new Date();
        timestamp.setDate(timestamp.getDate() + this.expireTime);
        this._cookies.set("ios-install-interact", JSON.stringify(timestamp));
      });
    }
  }

  /**
   * Checks if cookie is expires
   * @returns {boolean} Returns if the Cookie has expired
   */
  cookieExpired() {
    try {
      const cookieValue = this._cookies.get("ios-install-interact");
      return cookieValue.length
        ? new Date() > new Date(JSON.parse(cookieValue))
        : true;
    } catch (e) {
      this._logger.error(e);
    }
  }

  browserIsSafari() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    return /^((?!chrome|android).)*safari/i.test(
      this._window.nativeWindow.navigator.userAgent
    );
  }

  isStandaloneLaunch() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    return this._window.nativeWindow.navigator.standalone;
  }

  /**
   * @returns
   */
  close() {
    this._d.closeAll();
  }
}
