<div class="row">
  <button class="close" mat-flat-button (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="row">
  <div class="slide">
    <img src="{{ imgInstall }}" alt="" style="width: inherit" />
  </div>
</div>
