<!-- app toaster notifier -->
<toaster-notification></toaster-notification>

<h1 mat-dialog-title style="font-weight: 600;">
  {{ "navLinks.newVersion" | translate }}
</h1>
<div mat-dialog-content>
  <p>{{ "navLinks.launchVersion" | translate }}</p>
</div>
<div mat-dialog-actions style="color: orange;">
  <button
    mat-button
    class="remove-outline"
    style=" text-transform: uppercase; padding: unset;"
    (click)="launch()"
  >
    {{ "navLinks.launchNewVersion" | translate }}
  </button>
  <button
    mat-button
    class="remove-outline"
    style="text-transform: uppercase"
    (click)="dismissAsDialog()"
    cdkFocusInitial
  >
    {{ "navLinks.dismiss" | translate }}
  </button>
</div>
