<div
  class="mat-elevation-z8 container-sections mt-4"
  style="border-radius: 8px;"
>
  <div style="text-align: center;">
    <div (click)="goHome()" class="cursor-p">
      <label
        *ngIf="currentCode == 'br'"
        style="margin-right: 10px;"
        class="font-title current"
        for=""
        >REsource BR</label
      >
      <label
        *ngIf="currentCode == 'mx'"
        style="margin-right: 10px;"
        class="font-title current"
        for=""
        >REsource MX</label
      >
      <label
        *ngIf="currentCode == 'latam'"
        style="margin-right: 10px;"
        class="font-title current"
        for=""
        >REsource LATAM</label
      >
      <!-- <label for="" class="font-title re">{{ "news.re" | translate }}</label>
      <label for="" class="font-title source">{{
        "news.source" | translate
      }}</label> -->
    </div>
  </div>
  <hr class="hr-sections" />
  <section class="news-container">
    <div>
      <div style="text-align: center;">
        <span class="font-size-title">
          {{ "news.titleSearch" | translate }}
        </span>
        <h4 *ngIf="keyword == null || keyword == ''">
          {{ "news.placeholderSearch" | translate }}
        </h4>
      </div>

      <div class="d-flex input-search mt-3">
        <input
          class="form-control"
          type="text"
          (keyup.enter)="getNewsParam()"
          (change)="validateKeyword()"
          [(ngModel)]="keyword"
        />

        <mat-icon class="search-ico" (click)="getNewsParam()">search</mat-icon>
      </div>
    </div>
    <hr />
    <table *ngIf="news">
      <tr *ngFor="let news2 of news.content">
        <td>
          <div class="mat-elevation-z8 grid-cards mb-4 most-read">
            <div>
              <img
                (click)="openNews(news2.id, news2)"
                class="w-100 img-card aspect-14-9 cursor-p"
                [alt]="getAltTxt(news2.headerImageCaption)"
                [title]="getAltTxt(news2.headerImageCaption)"
                [src]="headerImageNews(news2.headerImage.fullPath)"
              />
            </div>
            <div class="card">
              <div
                *ngIf="news2.contentTags"
                class="d-flex card-header border-none pb-0 tags"
              >
                <div
                  class="tag1 cursor-p"
                  (click)="goNewsTags(news2.contentTags.propertyTypeTags[0], 1)"
                >
                  <span>
                    {{
                      news2.contentTags.propertyTypeTags[0].name | langSelector
                    }}
                  </span>
                </div>
                <div
                  class="tag2 cursor-p"
                  (click)="goNewsTags(news2.contentTags.stateTags[0], 2)"
                >
                  <span>
                    {{ news2.contentTags.stateTags[0].name | langSelector }}
                  </span>
                </div>
              </div>
              <div class="card-body border-none pb-0">
                <h2
                  (click)="openNews(news2.id, news2)"
                  class="title-text cursor-p mt-4"
                  [innerHTML]="
                    news2.headline | langSelector | highlightSearch: keyword
                  "
                ></h2>
              </div>

              <div class="ellipsis-text card-body border-none pt-0">
                <span
                  [innerHTML]="
                    news2.primaryContentPlainText
                      | langSelector
                      | highlightSearch: keyword
                      | slice: 0:300
                  "
                ></span>
              </div>
              <div class="card-footer border-none" style="margin-bottom: 10px;">
                <span class="date date-text" *ngIf="getLanguage() === 'en'">
                  {{ formatDate(news2.publishDate, "MM/dd/yyyy") }}
                </span>
                <span class="date date-text" *ngIf="getLanguage() === 'es'">
                  {{ formatDate(news2.publishDate, "dd/MM/yyyy") }}
                </span>
                <span class="date date-text" *ngIf="getLanguage() === 'pt'">
                  {{ formatDate(news2.publishDate, "dd/MM/yyyy") }}
                </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <div style="justify-content: space-between;display: flex;">
      <ngb-pagination
        [collectionSize]="total"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="getNews()"
        [maxSize]="isMobile ? 3 : 5"
      ></ngb-pagination>
      <select
        class="form-select"
        style="width: auto; height: 38px;"
        [(ngModel)]="pageSize"
        (ngModelChange)="getNews()"
      >
        <option [ngValue]="10">10 items</option>
        <option [ngValue]="15">15 items</option>
        <option [ngValue]="20">20 items</option>
      </select>
    </div>
  </section>
</div>
