import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { routes } from "./news.routing.module";
import { SharedModule } from "../../shared/shared.module";
import { NewsDetailComponent } from "./news-detail.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../material.module";
import { NewsDetailService } from "./services/";
import { ImageService } from "../../core/services/image.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
  declarations: [NewsDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    MaterialModule,
    HttpClientModule,
    SlickCarouselModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes)
  ],
  exports: [],
  providers: [NewsDetailService, ImageService]
})
export class NewsFeatureModule {}
