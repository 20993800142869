import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { DemoDialogService } from "../../../../core/services/dialog";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { I18nService } from "../../../services";
import { action, page } from "../../../utils/params";
import { DemoDialogComponent } from "../../dialogs";
import { MetaTagService } from "../../../services/meta-tag.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-spot",
  templateUrl: "./spot.component.html",
  styleUrls: [
    "./spot.component.scss",
    "../grocs/grocs.component.scss",
    "../market-analytics/market-analytics.component.scss",
    "../product-list/product-list.component.scss",
    "../solution/solution.component.scss"
  ]
})
export class SpotComponent implements OnInit {
  isMobile = false;
  responsiveSub: Subscription;
  currentCode: string;
  spotPath = "spot.spot-info.spot-list.";
  spotBenefitsPath: string;
  spotMarketPath: string;
  spotDemoPath: string;
  spotServicePath: string;
  spotList = [];
  spotBenefits = [];
  spotMarket = [];
  demoList = [];
  serviceList = [];
  form: FormGroup;
  browserLang: string = "en";
  action = action;
  latamCountry = "latamBr";
  routerSub: Subscription;
  tagId = null;
  spotPic: string;
  imgHomePageSpotAlt: string;
  imgDigitalAlt: string;
  constructor(
    private responsive: BreakpointObserver,
    private productService: ProductService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private siilaTranslationService: SiilaTranslationService,
    private demo: DemoDialogService,
    private i18: I18nService,
    private metaTagSrv: MetaTagService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.spotBenefitsPath = `spot.${this.currentCode}.spot.spot-info.spot-benefits.`;
    this.spotMarketPath = `spot.${this.currentCode}.spot.spot-market.market-list.`;
    this.spotDemoPath = `products.${this.currentCode}.products.demo.demo-list.`;
    this.spotServicePath = `spot.${this.currentCode}.spot.spot-service.service-list.`;
    this.spotList = [...Array(4).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        title: `${this.spotPath}check${i + 1}.title`,
        desc: `${this.spotPath}check${i + 1}.desc`
      };
    });

    if (this.currentCode == "mx" || this.currentCode == "co") {
      this.tagId = 67;
    }

    if (this.currentCode == "br" || this.currentCode == "latam") {
      this.tagId = 63;
    }

    /* if (this.currentCode == "latam") {
      this.tagId = 71;
    } */

    this.spotBenefits = [...Array(5).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        title: `${this.spotBenefitsPath}card${i + 1}.title`,
        desc: `${this.spotBenefitsPath}card${i + 1}.text`
      };
    });

    this.spotMarket = [...Array(4).keys()].map(i => {
      return {
        title: `${this.spotMarketPath}card${i + 1}.title`,
        text: `${this.spotMarketPath}card${i + 1}.text1`,
        desc: `${this.spotMarketPath}card${i + 1}.text2`
      };
    });

    this.demoList = [...Array(4).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        title: `${this.spotDemoPath}text${i + 1}`
      };
    });

    let img = "assets/img/spot-list/";

    this.serviceList = [
      {
        icon: `${img}photos - spot-item4-services.jpg`,
        title: `${this.spotServicePath}text${1}`,
        alt: this.siilaTranslationService.getImgSEOTranslation(
          "spot.solutions.1"
        )
      },
      {
        icon: `${img}${
          this.currentCode == "mx" || this.currentCode == "co"
            ? "Tour Virtual SPOT.png"
            : "Tour Virtual SPOT.png"
        }`,
        title: `${this.spotServicePath}text${2}`,
        alt: this.siilaTranslationService.getImgSEOTranslation(
          "spot.solutions.2"
        )
      },
      {
        icon: `${img}${
          this.currentCode == "mx" || this.currentCode == "co"
            ? "Content Creation - spot-item8-services.jpg"
            : "Content Creation - spot-item8-services.jpg"
        }`,
        title: `${this.spotServicePath}text${3}`,
        alt: this.siilaTranslationService.getImgSEOTranslation(
          "spot.solutions.3"
        )
      },
      {
        icon:
          this.currentCode == "mx" || this.currentCode == "co"
            ? `${img}Insights _ News - spot-item5-services.jpg`
            : `${img}Insights _ News - spot-item5-services.jpg`,
        title: `${this.spotServicePath}text${4}`,
        alt: this.siilaTranslationService.getImgSEOTranslation(
          "spot.solutions.4"
        )
      },
      {
        icon:
          this.currentCode == "mx" || this.currentCode == "co"
            ? `${img}SPOT Social Media - Mexico.png`
            : `${img}SPOT Social Media - Brasil.png`,
        title: `${this.spotServicePath}text${5}`,
        alt: this.siilaTranslationService.getImgSEOTranslation(
          "spot.solutions.5"
        )
      },
      {
        icon: `${img}EVENTS - ]spot-item9-services-EVENTS.jpg`,
        title: `${this.spotServicePath}text${6}`,
        alt: this.siilaTranslationService.getImgSEOTranslation(
          "spot.solutions.6"
        )
      }
    ];

    this.form = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: [""],
      email: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      position: ["", Validators.required],
      requestType: ["EBOOK"]
    });
  }

  ngOnInit(): void {
    this.spotPic = this.siilaTranslationService.getImgSEOTranslation(
      "spot.spotPic"
    );
    this.imgHomePageSpotAlt = this.siilaTranslationService.getImgSEOTranslation(
      "spot.imgHomePageSpotAlt"
    );
    this.imgDigitalAlt = this.siilaTranslationService.getImgSEOTranslation(
      "spot.imgDigitalAlt"
    );
    this.routerSub = this.route.paramMap.subscribe(params => {
      this.metaTagSrv.setSpotPageMetaTags();
    });
    this.responsiveChanges();
  }

  ngOnDestroy(): void {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  responsiveChanges() {
    this.responsiveSub = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  downloadFormIndex(opt = null) {
    if (opt == 1) {
      this.form.controls.firstName.setValue("ANONYMOUS");
      this.form.controls.lastName.setValue("ANONYMOUS");
      this.form.controls.phoneNumber.setValue("ANONYMOUS");
      this.form.controls.email.setValue("anonymous@localhost");
      this.form.controls.company.setValue("ANONYMOUS");
      this.form.controls.position.setValue("ANONYMOUS");
    } else {
      this.form.controls.requestType.setValue("MARKETING-GUIDE");
    }
    let formValue = this.form.value;
    this.form.reset();
    let message = this.i18.get("global.newsForm.download");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.requestFileIndex(formValue).subscribe(
      response => {
        this.form.controls.requestType.setValue("");
        this.productService.downloadPdf(response.resourceUrl);
        if (response.success === false) {
          this.snackBar.open(errorMsj, action);
        } else {
          this.snackBar.open(message, action);
        }
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
  }

  openSpot() {
    window.open(
      `${environment.spotURL}/home/lang/${this.i18.getCurrentLanguage()}`,
      "_blank"
    );
  }

  openTenants() {
    window.open(
      this.currentCode == "mx" || this.currentCode == "co"
        ? `${
            environment.applicationURL
          }/solutions/111/tenants/lang/${this.i18.getCurrentLanguage()}`
        : `${
            environment.applicationURL
          }/solutions/104/tenants/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openContactDialog(action: string) {
    this.demo.open(
      DemoDialogComponent,
      false,
      page.spot,
      action,
      "",
      this.i18.get(`spot.${this.currentCode}.spot.title`),
      false
    );
  }

  selectVideo() {
    if (this.browserLang == "en") {
      return "4FHvlXypNes?rel=0";
    } else if (this.browserLang == "pt") {
      return "tx7HX6PhNYE?rel=0";
    } else {
      return "4FHvlXypNes?rel=0";
    }
  }

  imgProperty() {
    let img = "../../../../../assets/img/spot-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "Property screens - all languages.png";
      } else {
        img = img + "Property screens - all languages MX.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "Property screens - all languages.png";
      } else {
        img = img + "Property screens - all languages.png";
      }
    }

    return img;
  }

  imgHomePageSpot() {
    let img = "../../../../../assets/img/spot-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-ES.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "homepage-item3-mockupSPOT-EN.png";
      } else {
        img = img + "homepage-item3-mockupSPOT-PT.png";
      }
    }

    return img;
  }

  imgDigital() {
    let img = "../../../../../assets/img/spot-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "english.png";
      } else {
        img = img + "espanish.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "english.png";
      } else {
        img = img + "portuguese.png";
      }
    }

    return img;
  }
}
