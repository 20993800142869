import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable()
export class PagerService {
  constructor() {}

  getSlidePager(currentPage, totalItems, displayedItems) {
    //console.log(currentPage, Math.ceil(totalItems/ displayedItems));

    return {
      currentPage: currentPage,
      slideCount: Math.ceil(totalItems / displayedItems)
    };
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 5) {
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;

    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    startPage = currentPage;
    endPage = totalPages;

    //break logic
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    );

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      startPage: startPage,
      totalPages: totalPages,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
