import { Pipe, PipeTransform } from "@angular/core";
import { formatNumber } from "@angular/common";
import { LanguageService } from "../services/language.service";

@Pipe({
  name: "localNumber"
})
export class LocalNumberPipeSpot implements PipeTransform {
  constructor(private language: LanguageService) {}

  transform(value: any, format: string, local = null) {
    if (value == null) {
      return "";
    } // !value would also react to zeros.
    if (!format) {
      format = ".2-2";
    }

    return formatNumber(value, local ? "en" : this.language.locale, format);
  }
}
