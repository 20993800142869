import { Component, Inject, Input, OnInit } from "@angular/core";
import { PropertyService } from "../../services/property.services";
import { Location } from "@angular/common";
import { environment } from "../../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-send-notification",
  templateUrl: "./send-notification.component.html",
  styleUrls: ["./send-notification.component.css"]
})
export class SendNotificationComponent implements OnInit {
  subject = "";
  message = "";
  success = false;

  constructor(
    private propertyService: PropertyService,
    @Inject(MAT_DIALOG_DATA) public id: any | null,
    private _d: MatDialog
  ) {}

  ngOnInit(): void {}

  sendNotification() {
    let user = JSON.parse(localStorage.getItem("user"));
    const fullUrl = `${environment.redirectURL}building/${this.id}`;
    let message = {
      toUser: { id: user.id },
      externalEmail: user.email,
      id: null,
      subject: this.subject,
      content: `${this.message}<br><br><a href="${fullUrl}" target="_blank">${fullUrl}</a>`
    };
    this.propertyService.sendNotification(message).then(resp => {
      this.success = true;
    });
  }

  close() {
    this._d.closeAll();
  }
}
