import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { from } from "form-data";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { DemoDialogService } from "../../../../core/services/dialog";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { ResponsiveSlideHelper } from "../../../../core/_helpers";
import { I18nService } from "../../../services";
import { MetaTagService } from "../../../services/meta-tag.service";
import { action, page } from "../../../utils/params";
import { DemoDialogComponent } from "../../dialogs";
import { WindowRef } from "../../../../window-ref.service";

@Component({
  selector: "app-siila-academy",
  templateUrl: "./siila-academy.component.html",
  styleUrls: [
    "./siila-academy.component.scss",
    "../market-analytics/market-analytics.component.scss",
    "../fii-data-pro/fii-data-pro.component.scss",
    "../about/about.component.scss",
    "../product-list/product-list.component.scss"
  ]
})
export class SiilaAcademyComponent implements OnInit {
  @ViewChild("slickCarousel", { static: true })
  carousel: SlickCarouselComponent;
  translatePathStrategy: string;

  currentCode: string;
  currentCodeMx = "mx";
  currentCodeBr = "br";
  aboutPath: string;
  aboutList = [];
  isMobile: boolean;
  subscription: Subscription;
  action = action;
  form: FormGroup;
  lang: string;
  formEbook: FormGroup;
  routerSub: Subscription;
  courseLatam: any;
  slideConfig: any = ResponsiveSlideHelper.DEFAULT_SLIDE_CONFIG_ACADEMY;
  slideCurrentIndex = 1;
  imgAcademyAlt: string;
  classesAlt: string;
  officebr: string;
  officemx: string;
  industrialmx: string;
  industrialbr: string;
  personmobile: string;
  public screenWidth: any;
  public handsetMode: boolean = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private responsive: BreakpointObserver,
    private demo: DemoDialogService,
    private fb: FormBuilder,
    private productService: ProductService,
    private i18: I18nService,
    private snackBar: MatSnackBar,
    private siilaTranslationService: SiilaTranslationService,
    private metatagservice: MetaTagService,
    private route: ActivatedRoute,
    private window: WindowRef
  ) {
    this.currentCode = environment.platformId;
    this.translatePathStrategy = `analytics.${this.currentCode}.strategy`;
    this.lang = this.siilaTranslationService.getCurrentLanguage();

    this.aboutList = [...Array(5).keys()].map(i => {
      this.aboutPath = `academy.${this.currentCode}.academy.courses.about.course-list.`;
      return {
        icon: "assets/icons/check.svg",
        title: `${this.aboutPath}text${i + 1}`
      };
    });

    this.form = this.fb.group({
      fullName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      description: ["", Validators.required],
      page: ["academy"],
      action: [this.i18.get("global.newsForm.action")]
    });

    this.formEbook = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: [""],
      email: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      position: ["", Validators.required],
      requestType: ["EBOOK"]
    });

    this.screenWidth = this.window.nativeWindow.innerWidth;
    this.screenWidth < 1000
      ? (this.handsetMode = true)
      : (this.handsetMode = false);
  }

  ngOnInit(): void {
    this.personmobile = this.siilaTranslationService.getImgSEOTranslation(
      "academy.personmobile"
    );
    this.imgAcademyAlt = this.siilaTranslationService.getImgSEOTranslation(
      "academy.main"
    );
    this.classesAlt = this.siilaTranslationService.getImgSEOTranslation(
      "academy.classes"
    );
    this.officebr = this.siilaTranslationService.getImgSEOTranslation(
      "academy.cursos.officebr"
    );
    this.officemx = this.siilaTranslationService.getImgSEOTranslation(
      "academy.cursos.officemx"
    );
    this.industrialmx = this.siilaTranslationService.getImgSEOTranslation(
      "academy.cursos.industrialmx"
    );
    this.industrialbr = this.siilaTranslationService.getImgSEOTranslation(
      "academy.cursos.industrialbr"
    );
    this.routerSub = this.route.paramMap.subscribe(params => {
      this.metatagservice.setAcademyMetaTags();
    });
    this.subscription = this.responsive
      .observe(Breakpoints.XSmall)
      .subscribe(result => {
        this.isMobile = result.matches;
        this._changeDetectorRef.detectChanges();
        if (this.currentCode == "latam") {
          this.courseLatam = [
            {
              img:
                "../../../../../assets/img/academy-list/ACADEMY-OFFICES-mx.jpg",
              alt: this.officemx,
              title: `academy.${this.currentCodeMx}.academy.courses.card-info.title`,
              description: `academy.${this.currentCodeMx}.academy.courses.card-info.description`,
              code: "mx",
              width: this.isMobile ? "width:54%" : "width::556px"
            },
            {
              img:
                "../../../../../assets/img/academy-list/ACADEMY-INDUSTRIAL-mx.jpg",
              alt: this.industrialmx,
              title: `academy.${this.currentCodeMx}.academy.courses.card-info.title2`,
              description: `academy.${this.currentCodeMx}.academy.courses.card-info.description2`,
              code: "mx",
              width: this.isMobile ? "width:45%" : "width:556px"
            },
            {
              img:
                "../../../../../assets/img/academy-list/ACADEMY-OFFICES-BR.jpg",
              title: `academy.${this.currentCodeBr}.academy.courses.card-info.title`,
              alt: this.officebr,
              description: `academy.${this.currentCodeBr}.academy.courses.card-info.description`,
              code: "br",
              width: this.isMobile ? "width:54%" : "width:556px"
            },
            {
              img:
                "../../../../../assets/img/academy-list/ACADEMY-INDUSTRIAL-BR.jpg",
              title: `academy.${this.currentCodeBr}.academy.courses.card-info.title2`,
              alt: this.industrialbr,
              description: `academy.${this.currentCodeBr}.academy.courses.card-info.description2`,
              code: "br",
              width: this.isMobile ? "width:45%" : "width:556px"
            }
          ];
        }
      });
  }

  ngOnDestroy(): void {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  openContactDialog(action) {
    this.demo.open(
      DemoDialogComponent,
      false,
      page.academy,
      action,
      "",
      "ACADEMY",
      false
    );
  }

  submitMessage() {
    let formValue = this.form.value;
    this.form.reset();
    let message = this.i18.get("global.newsForm.message");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.demoRequest(formValue).subscribe(
      response => {
        this.snackBar.open(message, action);
      },
      () => this.snackBar.open(errorMsj)
    );
  }

  imgContactUs() {
    let img = "../../../../../assets/img/products-list/contactAcademy.png";

    return img;
  }

  imgAcademy() {
    let img = "../../../../../assets/img/products-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.lang == "en") {
        img = img + "2-3 ACADEMY ENGLISH.png";
      } else {
        img = img + "23 ACADEMY ESPANISH.png";
      }
    } else {
      if (this.lang == "en") {
        img = img + "2-3 ACADEMY ENGLISH.png";
      } else {
        img = img + "23 ACADEMY PORTUGUES.png";
      }
    }

    return img;
  }

  imgAcademyMobile() {
    let img = "../../../../../assets/img/academy-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.lang == "en") {
        img = img + "ACADEMY ENGLISH.png";
      } else {
        img = img + "ACADEMY ESPANISH.png";
      }
    } else {
      if (this.lang == "en") {
        img = img + "ACADEMY ENGLISH.png";
      } else {
        img = img + "ACADEMY PORTUGUES.png";
      }
    }

    return img;
  }

  downloadFormIndex() {
    this.formEbook.controls.firstName.setValue("ANONYMOUS");
    this.formEbook.controls.lastName.setValue("ANONYMOUS");
    this.formEbook.controls.phoneNumber.setValue("ANONYMOUS");
    this.formEbook.controls.email.setValue("anonymous@localhost");
    this.formEbook.controls.company.setValue("ANONYMOUS");
    this.formEbook.controls.position.setValue("ANONYMOUS");
    this.formEbook.controls.requestType.setValue("EBOOK");
    let formValue = this.formEbook.value;
    this.formEbook.reset();
    let message = this.i18.get("global.newsForm.download");
    let action = this.i18.get("global.newsForm.action");
    let errorMsj = this.i18.get("global.newsForm.errorMsj");
    this.productService.requestFileIndex(formValue).subscribe(
      response => {
        this.productService.downloadPdf(response.resourceUrl);
        if (response.success === false) {
          this.snackBar.open(errorMsj, action);
        } else {
          this.snackBar.open(message, action);
        }
      },
      () => {
        this.snackBar.open(errorMsj, action);
      }
    );
  }

  openCouse(course?: string) {
    if (this.currentCode == "mx" || course == "mx") {
      if (this.lang == "en") {
        window.open("https://mailchi.mp/siila/academy-advanced", "_blank");
      } else {
        window.open("https://mailchi.mp/siila/academy-advanced", "_blank");
      }
    } else {
      if (this.lang == "en") {
        window.open("https://mailchi.mp/siila.com.br/qy3ta8o8nk", "_blank");
      } else {
        window.open("https://mailchi.mp/siila.com.br/qy3ta8o8nk", "_blank");
      }
    }
  }

  changeSlideNext(slide) {
    if (this.isMobile) {
      if (slide.currentIndex == 3) {
        slide.slickGoTo(0);
      } else {
        slide.slickNext();
      }
    } else {
      if (slide.currentIndex == 2) {
        slide.slickGoTo(1);
      } else {
        slide.slickNext();
      }
    }
  }

  changeSlideBack(slide) {
    slide.slickPrev();
  }
  /**
   * @param  {} event
   */
  slickInit(event) {}

  /**
   * @param  {} event
   */
  afterChange(e) {
    const { currentSlide } = e;
    this.slideCurrentIndex = currentSlide;
  }

  /**
   * @param  {} event
   */
  breakpoint(event) {}

  /**
   * @param  {} event
   */
  beforeChange(event) {}

  /**
   * @param  {} event
   */
}
