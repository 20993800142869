import { Injectable } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { ErrorDialogComponent } from "../../../shared/components/dialogs/error-dialog/error-dialog.component";

@Injectable()
export class ErrorDialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(data): void {
    if (!this.dialog.openDialogs.length) {
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        height: "400px",
        width: "500px",
        data: data
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log("The dialog was closed");
      });
    }
  }
}
