import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class FeaturedListingService {
  latamCountry = "";
  constructor(private http: HttpClient) {}

  private buildinglistingResource = `/buildinglisting`;
  private detailUrlDataAPI = `${this.buildinglistingResource}/{id}/detail/url-data/{lang}`;

  async checkListingDetailRoute(id: any, lang?: string) {
    return await this.http
      .get(environment.apiURL + "/listings/" + id + "/detail/url-data/" + lang)
      .toPromise()
      .then((result: any) => {
        return result;
      });
  }

  getSpotBuildingDetailURLFormat(id: any, lang?: string) {
    const url =
      (this.latamCountry == "latamMx"
        ? environment.apiURLMX
        : this.latamCountry == "latamBr"
        ? environment.apiURLBR
        : "/api") +
      `${this.buildinglistingResource}/${id}/detail/url-data/${lang}`;

    return this.http
      .get(url, { observe: "response" })
      .pipe(
        map(
          (val: any) =>
            `${
              this.latamCountry == "latamMx"
                ? environment.spotURLMx
                : environment.spotURL
            }/${val.body.detailsURL}`
        )
      );
  }
}
