/*
  Formats email Sharing
*/
export interface MailOptions {
  mailTo: string;
  body: string;
  subject: string;
}

/*
  Social Media Sharing sets meta and og properties and opens frame to share.
*/
export enum SocialMediaProviders {
  Facebook = "facebook",
  LinkedIn = "linkedin",
  Twitter = "twitter",
  Email = "email",
  Copy = "copy",
  Whatsapp = "whatsapp",
  Share = "share"
}

export interface LinkedInShareOptions {
  options: any;
}

export interface TwitterShareOptions {
  options: any;
}

export interface FacebookShareOptions {
  options: any;
}

export function shareFacebook(options?) {
  let urlString = "https://www.facebook.com/sharer/sharer.php?u=";
  urlString += encodeURI(window.location.href);

  window.open(urlString, "sharer", "toolbar=0, width=600,height=600");
}

export function shareLinkedIn(options) {
  let randomNumber = Math.floor(Math.random() * 6 + 1);
  let urlString = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
    options.url
  )}?hideFooter=${randomNumber}`;

  window.open(urlString, "popup", "width=600,height=600");
}

export function shareTwitter(options) {
  let urlString = "https://www.twitter.com/intent/tweet?";
  urlString += "text=" + encodeURIComponent(options.title);
  urlString += "&url=" + encodeURI(options.url);

  window.open(urlString, "sharer", "toolbar=0,status=0,width=600,height=600");
}

export function shareMail(mailOptions: any) {
  const { mailTo, subject, body } = mailOptions;
  const urlString = `mailTo:${mailTo}?body=${body}&subject=${subject}`;
  window.open(urlString, "_blank");
}

export function textFromHtml(html) {
  var temporalDivElement = document.createElement("pre");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export function shareSocialMedia(socialLink) {
  const providerId = socialLink.providerId;
  switch (providerId) {
    case SocialMediaProviders.Facebook:
      shareFacebook(socialLink.options);
      break;
    case SocialMediaProviders.LinkedIn:
      shareLinkedIn(socialLink.options);
      break;
    case SocialMediaProviders.Twitter:
      shareTwitter(socialLink.options);
      break;
    case SocialMediaProviders.Email:
      shareMail(socialLink.options);
      break;
    default:
      break;
  }
}
