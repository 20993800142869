import {
  Directive,
  OnInit,
  ElementRef,
  Input,
  HostBinding,
  OnChanges,
  DoCheck,
  Renderer2
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";

@Directive({
  selector: "[localize]"
})
export class LocaleChangerDirective implements OnChanges {
  @Input() public textValue: any;

  constructor(
    private _el: ElementRef,
    private _ts: TranslateService,
    private _r: Renderer2,
    private siilaTranslationService: SiilaTranslationService
  ) {
    this._ts.onLangChange.subscribe(response => {
      this.ngOnChanges();
    });
  }

  ngOnChanges() {
    if (this.textValue) {
      const result = this.siilaTranslationService.getTranslation(
        this.textValue
      );
      this._r.setProperty(this._el.nativeElement, "innerHTML", result);
    } else {
      console.error("No text input found on localize directive");
    }
  }
}
