import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WindowRef } from '../../../window-ref.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class LoginDialogService {
  public loginDialog: MatDialog;

  constructor(public dialog: MatDialog, private _window: WindowRef, @Inject(PLATFORM_ID) private platformId: any) {
    this.loginDialog = dialog;
  }

  open(comp: any, h?: string, w?: string) { 
    if (!this.dialog.openDialogs.length) {
      this.loginDialog.open(comp, {
        height: h ? h : '320px',
        width: w ? w : '600px'
      });
    }


    this.loginDialog.afterAllClosed.subscribe(e => {
      if (!isPlatformBrowser(this.platformId)) {
        this._window.nativeWindow.focus();
      }
    });
  }

  close() {
    this.loginDialog.closeAll();
  }
}