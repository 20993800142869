import { NgModule, Injector, APP_INITIALIZER, LOCALE_ID } from "@angular/core";

import { AppComponent } from "./app.component";
import { HomeComponent } from "./core/home/home.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatSelectModule } from "@angular/material/select";
import { Meta, Title } from "@angular/platform-browser";
import { MaterialModule } from "./material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { EmbedVideo } from "ngx-embed-video";
import { SharedModule } from "./shared/shared.module";
import { LayoutModule } from "@angular/cdk/layout";
import { httpInterceptorProviders } from "./interceptors";
import { MatTabsModule } from "@angular/material/tabs";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatomoModule } from "ngx-matomo";
import { LightboxModule, LIGHTBOX_CONFIG } from "@ngx-gallery/lightbox";
import { GalleryModule } from "@ngx-gallery/core";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { I18nService } from "../app/shared/services/i18n.service";
import { PropertyTypeHelperService } from "../app/shared/services/property-helper.service";
import { AnalyticsService } from "./shared/services/analytics.service";
import { CommonGenericService } from "../app/shared/services/common.service";
import { NotifierModule } from "angular-notifier";
import { DeviceDetectorModule } from "ngx-device-detector";
import { CoreModule } from "./core/core.module";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SnackBar } from "./core/services/snack-bar.service";
import { RegionLoaderService } from "./shared/services";
import { LocaleCheckerDirective } from "./locale-checker.directive";
import { MetaModule, MetaLoader } from "@ngx-meta/core";
import { SiilaTranslationService } from "./core/services/siila-translation.service";
import { appInitializerFactory } from "./custom-factories/app-initializer-factory";
import { metaFactory } from "./custom-factories/meta-factory";
//import es from "@angular/common/locales/es-MX";
import "@angular/common/locales/global/pt";
import "@angular/common/locales/global/es";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AgmCoreModule } from "@agm/core";

//registerLocaleData(es);

@NgModule({
  declarations: [AppComponent, HomeComponent, LocaleCheckerDirective],
  imports: [
    CommonModule,
    MaterialModule,
    MetaModule.forRoot(),
    YouTubePlayerModule,
    MatomoModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    //NgxMatomoTrackerModule.forRoot({
    //  siteId: 3, // your Matomo's site ID (find it in your Matomo's settings)
    //  trackerUrl: '//piwik.siila.com.mx/', // your matomo server root url
    //}),
    GalleryModule,
    LightboxModule,
    TranslateModule.forChild(),
    CommonModule,
    LayoutModule,
    NotifierModule,
    DeviceDetectorModule.forRoot(),
    EmbedVideo.forRoot(),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    SlickCarouselModule,
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyBzR4bgsFqSQZXpQQj6ePNlI3ERTJ18M7I"
    // }),
    HttpClientModule,
    NgbModule,
    NgbCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey
    })
  ],
  providers: [
    ...httpInterceptorProviders,
    Title,
    Meta,
    SnackBar,
    RegionLoaderService,
    I18nService,
    PropertyTypeHelperService,
    CommonGenericService,
    { provide: LOCALE_ID, useValue: "es-MX" },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, SiilaTranslationService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
