import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ImageService {
  publicImageURL: string = "/images";
  publicImageURLMx: string = "/mx-be/images";
  publicImageURLBr: string = "/br-be/images";
  latamCountry = "";
  latamCountryNews = "";

  constructor() {}

  getFullImagePath(id) {
    return `${
      this.latamCountry == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountry == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/content`;
  }

  largeThumbnail(id) {
    return `${
      this.latamCountry == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountry == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/thumbnail/l`;
  }

  mediumThumbnail(id) {
    return `${
      this.latamCountry == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountry == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/thumbnail/m`;
  }

  mediumThumbnailNews(id) {
    return `${
      this.latamCountryNews == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountryNews == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/thumbnail/m`;
  }

  mediumLargeThumbnail(id) {
    return `${
      this.latamCountry == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountry == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/thumbnail/ml`;
  }

  smallThumbnail(id) {
    return `${
      this.latamCountry == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountry == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/thumbnail/s`;
  }

  smallThumbnailNews(id) {
    return `${
      this.latamCountryNews == "latamMx"
        ? this.publicImageURLMx
        : this.latamCountryNews == "latamBr"
        ? this.publicImageURLBr
        : this.publicImageURL
    }/${id}/thumbnail/s`;
  }
}
