import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class CarouselRestoreService {
  state: CarouselState;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  setState(state: CarouselState) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.setItem("carouselState", JSON.stringify(state));
      } catch (e) {}
    } else {
      console.error("Not a browser, cant set state");
    }
  }

  getState(): CarouselState {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const carouselState = localStorage.getItem("carouselState")
      ? JSON.parse(localStorage.getItem("carouselState"))
      : null;

    return carouselState;
  }

  deleteState(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    try {
      localStorage.removeItem("carouselState");
    } catch (error) {}
  }
}

export interface CarouselState {
  currentPage: number;
  totalItemCount: number;
  currentSlideIndex: number;
  loadedObjects: any[];
  pagePosition?: any;
}
