<mat-card>
  <a style="text-decoration:none;" (click)="openCard(listing.id, $event)">
    <img
      class="list-img-card img-specs"
      *ngIf="
        (listing.listingPhotos && listing.listingPhotos.length) ||
        listing?.buildingPhoto?.image.id
      "
      mat-card-image
      [src]="
        getMediumLargeImg(
          listing.mainImageId
            ? listing.mainImageId
            : listing.buildingPhoto.image.id
        )
      "
      [alt]="buildingTitle"
      [title]="buildingTitle"
      default="assets/img/defaultImage.png"
    />
    <img
      class="img-specs"
      [alt]="buildingTitle"
      [title]="buildingTitle"
      *ngIf="
        (!listing.listingPhotos || !listing.listingPhotos.length) &&
        !listing?.buildingPhoto?.image.id
      "
      mat-card-image
      [src]="
        getMediumLargeImg(
          !detail ? listing?.mainImageId : listing?.buildingPhoto?.image?.id
        )
      "
      default="assets/img/defaultImage.png"
    />
    <div
      *ngIf="listing.premium"
      id="floatingPagination"
      class="premium__floating"
    >
      <div class="premium__floating__section">
        <img
          style="width: 22px !important;height: 22px !important;"
          src="assets/icons/stars.svg"
          alt=""
        />
        <label
          class="premium__floating__text"
          [translate]="'global.listing-card.gold'"
        ></label>
      </div>
    </div>
    <div
      *ngIf="listing.featured"
      id="floatingPagination"
      class="premium__floating"
    >
      <div class="premium__floating__section">
        <img class="img_premium" src="assets/icons/auto_awesome.svg" alt="" />
        <label
          class="premium__floating__text"
          [translate]="'global.listing-card.platinum'"
        ></label>
      </div>
    </div>
    <div class="flex-col-nowrap flex-grow-1">
      <div class="row">
        <div class="grid-card-container full-width">
          <!-- Header-->
          <div id="header" class="flex flex-column header">
            <div
              class="building-title"
              localize
              [textValue]="
                listing.buildingTitle ? listing.buildingTitle : listing.title
              "
            ></div>
            <div class="flex">
              <span class="card-subheader">
                {{ getSubHeaderCardInfo() }}
              </span>
            </div>
          </div>
          <div class="card-info-separator"></div>
          <div class="spot-building-main-info spot__company__card__data">
            <div class="price__title">
              <span>{{ "global.listing-card.propertyType" | translate }}:</span>
            </div>
            <div class="price__title classvaluedb">
              <span *ngIf="detail">{{ "detail.class" | translate }}</span>
              <span *ngIf="!detail">{{
                commonService.getAvailableAreaLabel(listing)
              }}</span>
            </div>

            <div class="type__value">
              <span>{{ propertyType | translate }}</span>
            </div>
            <div class="type__value">
              <span>{{
                detail ? listing?.classType?.name : getAvailableAreaText()
              }}</span>
            </div>

            <div class="price__title">
              <span *ngIf="!detail"
                >{{ "global.listing-card.listingType" | translate }}:</span
              >
              <span *ngIf="detail">{{ "detail.totalArea" | translate }}</span>
            </div>
            <div class="price__title">
              <span *ngIf="!detail">
                {{ commonService.getAskingRentPriceLabel(listing) }}
              </span>
              <span *ngIf="detail">{{
                platformId == "br"
                  ? ("detail.region" | translate)
                  : ("detail.subMarket" | translate)
              }}</span>
            </div>
            <div class="type__value totalareadb">
              <span>{{
                detail
                  ? (listing.rentableArea | localNumber)
                  : (spotType | translate)
              }}</span>
            </div>
            <div class="type__value">
              <span>{{ getAvailableTexRegion() }}</span>
            </div>
          </div>
          <div *ngIf="!hideLogo" class="flex full-width logo__fav_section">
            <div class="flex">
              <img
                class="logo_fav"
                [src]="getCompanyLogoImg(listing.companyLogoImageId)"
                default="assets/no-company-logo.png"
              />
            </div>

            <div class="flex-align-content-end align-items-center"></div>
          </div>
        </div>
      </div>
    </div>
  </a>
</mat-card>
