<div style="width:100%;display:flex;justify-content:flex-end;">
  <button
    aria-label="close"
    style="background:white;"
    mat-flat-button
    (click)="dismiss()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<form [formGroup]="formGroup">
  <div style="text-align:center;" class="siila-blue-color">
    <span translate="subscribeToMailingList" style="font-size:1.5em;"></span>
    <span></span>
    <div>
      <span translate="indicatesRequired"></span>
    </div>
  </div>
  <mat-form-field style="width:100%;">
    <input
      matInput
      placeholder="{{ 'global.newsForm.email' | translate }}"
      type="email"
      formControlName="emailCtrl"
      required
    />
  </mat-form-field>
  <mat-form-field style="width:100%;">
    <input
      matInput
      placeholder="{{ 'global.newsForm.firstName' | translate }}"
      formControlName="firstNameCtrl"
      required
    />
  </mat-form-field>
  <br />
  <mat-form-field style="width:100%;">
    <input
      matInput
      placeholder="{{ 'global.newsForm.lastName' | translate }}"
      formControlName="lastNameCtrl"
      required
    />
  </mat-form-field>
  <br />
  <mat-form-field style="width:100%;">
    <input
      matInput
      type="tel"
      placeholder="{{ 'global.newsForm.phone' | translate }}"
      formControlName="phoneCtrl"
    />
  </mat-form-field>
</form>
<div style="width:100%;display:flex;justify-content:center;">
  <button
    aria-label="submit"
    class="siila-submit"
    mat-button
    style="font-size:1.2em;width:100%;"
    [disabled]="formGroup.invalid"
    (click)="submit()"
  >
    <span translate="subscribe"></span>
  </button>
</div>
