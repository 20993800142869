import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { NewsDetailService } from "./services/news-detail.service";
import { ImageService } from "../../core/services";
// import * as ionicons from 'ionicons';
import {
  faTwitter,
  faFacebook,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import { Meta, Title } from "@angular/platform-browser";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  shareSocialMedia,
  SocialMediaProviders,
  textFromHtml
} from "../../core/_helpers/share-helper";
import { TranslateService } from "@ngx-translate/core";
import { MetaService } from "@ngx-meta/core";
import { environment } from "../../../environments/environment";
import { DOCUMENT } from "@angular/common";
import { MatomoTracker } from "ngx-matomo";
import { AuthenticationService, AccountService } from "../../core/auth/";
import { isString } from "../../websockets/socket-hash.helper";
import { SiilaTranslationService } from "../../core/services/siila-translation.service";
import { LocaleChangerDirective } from "../../shared/directives/locale-changer/locale-changer.directive";
import { NgNavigatorShareService } from "ng-navigator-share";

@Component({
  selector: "news-detail",
  templateUrl: "./news-detail.component.html",
  styleUrls: ["./news-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NewsDetailComponent implements OnInit, OnChanges {
  articleId: any;
  article: any = {
    storyListings: []
  };
  currentPosition: DetailPosition = {
    hasPrevious: false,
    currentIndex: 1,
    hasNext: false,
    listReference: []
  };

  handsetMode: boolean;
  public loggedIn: boolean;
  public currentUserIp: any;
  @ViewChild("toolTip") el: ElementRef;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1
  };

  socialMediaLinks: any = [
    {
      img: "../../../assets/icons/copy.png",
      providerId: "copy",
      link: this.document.location.href,
      options: {
        title: this.siilaTranslationService.getTranslationFromJSONFiles(
          "global.menu.entities.copy"
        )
      }
    },
    {
      img: "../../../assets/icons/whatsapp.png",
      providerId: "whatsapp",
      link: this.document.location.href,
      options: {
        title: null
      }
    },
    {
      img: "../../../assets/icons/mail.png",
      providerId: "email",
      link: "mailto:",
      options: {
        mailTo: "",
        subject: null,
        body: null
      }
    },
    {
      img: "../../../assets/icons/facebook.png",
      providerId: "facebook",
      link: "https://web.facebook.com/SiiLA.BR/",
      options: {
        title: null
      }
    },
    {
      img: "../../../assets/icons/in.png",
      providerId: "linkedin",
      link: "https://www.linkedin.com/company/siilabrasil",
      options: {
        title: null
      }
    },
    {
      img: "../../../assets/icons/share.png",
      providerId: "share",
      link: this.document.location.href,
      options: {
        title: null
      }
    }
  ];

  socialMediaLinksMX: any = [
    {
      ref: faTwitter,
      class: "ion-logo-twitter",
      providerId: "email",
      link: "https://www.twitter.com/siilamexico",
      options: {
        title: null
      }
    },
    {
      ref: faFacebook,
      class: "ion-logo-facebook",
      providerId: "facebook",
      link: "https://www.facebook.com/siilamexico",
      options: {
        title: null
      }
    },
    {
      ref: faLinkedin,
      class: "ion-logo-linkedin",
      providerId: "linkedin",
      link: "https://www.linkedin.com/company/siilamexico",
      options: {
        title: null
      }
    },
    {
      icon: faEnvelope,
      class: "ion-md-mail",
      link: "mailto:",
      options: {
        mailTo: "",
        subject: null,
        body: null
      },
      providerId: "email",
      ref: faEnvelope
    }
  ];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private newsDetailService: NewsDetailService,
    private imageService: ImageService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private matomoTracker: MatomoTracker,
    private account: AccountService,
    private auth: AuthenticationService,
    private _ngxMeta: MetaService,
    private siilaTranslationService: SiilaTranslationService,
    private titleService: Title,
    private ngNavigatorShareService: NgNavigatorShareService,
    private renderer: Renderer2
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.setUserIP();
    this.auth.isLoggedIn().subscribe(loggedIn => (this.loggedIn = loggedIn));
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(results => (this.handsetMode = results.matches));
  }

  async ngOnInit() {
    this.articleId = this.route.snapshot.paramMap.get("id");
    const currentArticleId = this.articleId;
    this.trackNewsDetail(currentArticleId);
    try {
      await this.newsDetailService
        .getAllArticleIdList()
        .then(response => this.setPositionData(response));

      const article = await this.newsDetailService.getArticleById(
        currentArticleId,
        true
      );

      this.article = article.body;

      const articleTitle = this.getDefaultTranslation(this.article.title);
      const articleDescription = this.getDefaultTranslation(
        this.article.content
      );
      const articleImage = `${
        environment.applicationURL
      }${this.imageService.mediumThumbnail(this.article.storyImages[0].id)}`;

      this.setNewsMetatags(articleTitle, articleDescription, articleImage);
    } catch (error) {
      this.navigateToNotFoundPage();
    }
  }

  setNewsMetatags(articleTitle, articleDescription, articleImage) {
    //set ngx meta on article load
    this._ngxMeta.setTag("name", `${articleTitle}`);
    this._ngxMeta.setTag("author", "SiiLA");
    this._ngxMeta.setTag(
      "description",
      `${this.removeHTMLCode(articleDescription)}`
    );
    this._ngxMeta.setTag("image", `${encodeURI(articleImage)}`);

    //this.titleService.setTitle(`${articleTitle}`);
    this._ngxMeta.setTitle(`${articleTitle}`);
    this._ngxMeta.setTag("og:site_name", "SiiLA");
    this._ngxMeta.setTag("og:name", `${articleTitle}`);
    this._ngxMeta.setTag("og:title", `${articleTitle}`);
    this._ngxMeta.setTag("og:image:width", "378");
    this._ngxMeta.setTag("og:image:height", "250");
    this._ngxMeta.setTag("og:image", `${encodeURI(articleImage)}`);
    this._ngxMeta.setTag(
      "og:description",
      `${this.removeHTMLCode(articleDescription)}`
    );
    this._ngxMeta.setTag("og:type", "article");
    this._ngxMeta.setTag("og:url", `${encodeURI(window.location.href)}`);
    this._ngxMeta.setTag("og:site_url", `${encodeURI(window.location.href)}`);

    this._ngxMeta.setTag("twitter:card", "summary_large_image");
    this._ngxMeta.setTag("twitter:title", `${articleTitle}`);
    this._ngxMeta.setTag("twitter:description", `${articleDescription}`);
    this._ngxMeta.setTag("twitter:image", `${encodeURI(articleImage)}`);
  }

  ngOnChanges(changes: any): void {}

  removeHTMLCode(htmlText: string) {
    var div = document.createElement("div");
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || "";
    return text;
  }

  async setUserIP() {
    this.currentUserIp = await this.account.getUserIP();
  }

  generateUserId() {
    const currentUserLogin = this.account.getCurrentUser
      ? JSON.parse(this.account.getCurrentUser())
      : null;
    !this.loggedIn || !currentUserLogin
      ? this.matomoTracker.setUserId(`ANONYMOUS ${this.currentUserIp.ip}`)
      : this.matomoTracker.setUserId(
          `${currentUserLogin} ${this.currentUserIp.ip}`
        );
  }

  trackNewsDetail(id: any) {
    //this.generateUserId();
    console.log("Track pageview: trackNewsDetail");
    const piwikTrackingUrl = `https://${this.document.location.hostname}/news/detail/${id}`;
    this.matomoTracker.trackPageView(piwikTrackingUrl);
  }

  navigateToLink(link: string) {
    if (window) {
      window.location.href = link;
    }
  }

  setPositionData(list) {
    const detailIndex = list.findIndex(id => {
      return id == this.articleId;
    });

    const currentPosition: DetailPosition = {
      hasPrevious: detailIndex > 0,
      currentIndex: detailIndex,
      hasNext: detailIndex < list.length - 1,
      listReference: list
    };

    this.currentPosition = currentPosition;
    this.cdr.detectChanges();
  }

  getMediumImg(img: any) {
    const imageId = img.id;
    return this.imageService.mediumThumbnail(imageId);
  }

  navigateToNext() {
    if (this.currentPosition.hasNext) {
      const nextArticle = this.currentPosition.listReference[
        this.currentPosition.currentIndex + 1
      ];
      this.redirectTo(nextArticle);
    }
  }

  navigateToPrevious() {
    if (this.currentPosition.hasPrevious && this.currentPosition.currentIndex) {
      const previousArticle = this.currentPosition.listReference[
        this.currentPosition.currentIndex - 1
      ];
      this.redirectTo(previousArticle);
    }
  }

  redirectTo(uri: string) {
    this.router.navigate([`/news/detail/${uri}`]).then(() => {
      this.ngOnInit();
    });
  }

  getDefaultTranslation(translationArtifact) {
    try {
      return this.siilaTranslationService.getTranslation(translationArtifact);
    } catch (e) {
      console.log(e);
    }
  }

  navigateToNotFoundPage() {
    const state: any = {
      badURL: window.location.href
    };
    this.router.navigateByUrl("/not-found", { state: state });
  }

  shareLink(socialLink) {
    const articleTitle = this.getDefaultTranslation(this.article.title);
    const content = this.getDefaultTranslation(this.article.content);

    switch (socialLink.providerId) {
      case SocialMediaProviders.Facebook:
      case SocialMediaProviders.LinkedIn:
        socialLink.options.body = textFromHtml(
          this.getDefaultTranslation(content)
        );
        socialLink.options.url = window.location.href;
        socialLink.options.title = articleTitle;
        shareSocialMedia(socialLink);
        break;
      case SocialMediaProviders.Email:
        const subject = `SiiLA News: ` + articleTitle;
        const body =
          textFromHtml(`${content}`).substring(0, 300) +
          textFromHtml(
            ` ...\n\n<br><br><a href="${window.location.href}">${window.location.href}</a>`
          );
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoUrl;
        break;
      case SocialMediaProviders.Copy:
        const elem = document.createElement("input");
        elem.value = socialLink.link;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
        this.renderer.setAttribute(
          this.el.nativeElement,
          "style",
          "display: block"
        );
        setTimeout(() => {
          this.renderer.setAttribute(
            this.el.nativeElement,
            "style",
            "display: none"
          );
        }, 2000);
        break;
      case SocialMediaProviders.Whatsapp:
        window.open(
          `https://api.whatsapp.com/send?text=${this.getDefaultTranslation(
            this.article.title
          )}%20${socialLink.link}`,
          "_blank"
        );
        break;
      case SocialMediaProviders.Share:
        this.ngNavigatorShareService
          .share({
            title: this.getDefaultTranslation(this.article.title),
            text: "",
            url: socialLink.link
          })
          .then(response => {})
          .catch(error => {});
        break;
      default:
        break;
    }
  }
}

export interface DetailPosition {
  hasPrevious: boolean;
  currentIndex: number;
  hasNext: boolean;
  listReference: any[];
}
