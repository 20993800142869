import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { SolutionMenuItem } from "../../../../core/models/products.model";
import { ProductService } from "../../../../core/services/products.service";
import { I18nService } from "../../../services";

@Component({
  selector: "app-solution-list",
  templateUrl: "./solution-list.component.html",
  styleUrls: ["./solution-list.component.scss"]
})
export class SolutionListComponent implements OnInit {
  @Input() val: number = 0;
  solutions: SolutionMenuItem[];
  currentCode: string;
  isMobile = false;
  constructor(
    private productService: ProductService,
    private cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    private i18n: I18nService
  ) {
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    this.productService.getSolutionList().subscribe(solutions => {
      this.solutions = solutions;
      this.cdr.markForCheck();
    });
  }

  openServe(id: number) {
    if (id == 101 || id == 108 || id == 115) {
      this.openInstitutionalInvestors(id);
    }

    if (id == 103 || id == 110 || id == 117) {
      this.openOperators(id);
    }

    if (id == 104 || id == 111 || id == 118) {
      this.openTenants(id);
    }

    if (id == 102 || id == 109 || id == 116) {
      this.openBrokerage(id);
    }

    if (id == 105 || id == 112 || id == 119) {
      this.openValuation(id);
    }

    if (id == 106 || id == 113 || id == 120) {
      this.openServiceProviders(id);
    }

    if (id == 107 || id == 114 || id == 122 || id == 121) {
      this.openReitInvestors(id);
    }
  }

  openInstitutionalInvestors(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/institutional-investors/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }

  openOperators(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/operators-&-developers/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }

  openTenants(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/tenants/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }
  openBrokerage(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/brokerage-firms/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }
  openValuation(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/valuation-advisors/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }
  openServiceProviders(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/service-providers/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }
  openReitInvestors(id: number) {
    window.open(
      `${
        environment.applicationURL
      }/solutions/${id}/reit-investors/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }
  openAllProducts() {
    window.open(
      `${
        environment.applicationURL
      }/products/lang/${this.i18n.getCurrentLanguage()}`,
      this.isMobile || this.val == 1 ? "_self" : "_blank"
    );
  }
}
