import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";
import { ImageService } from "../../../core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-image-gallery",
  templateUrl: "./image-gallery.component.html",
  styleUrls: ["./image-gallery.component.scss"]
})
export class ImageGalleryComponent implements OnInit, AfterViewInit {
  @Input() photos;
  index = 1;
  @ViewChild("slickCarousel") slickCarousel: SlickCarouselComponent;
  serverURL = environment.serverURL;

  constructor(
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public photo: any | null,
    private _d: MatDialog,
    private http: HttpClient
  ) {}
  ngAfterViewInit(): void {
    console.log(this.slickCarousel);
    this.slickCarousel.initSlick();
  }

  ngOnInit(): void {
    this.photos = this.photo ? this.photo : this.photos;
    console.log("fotos", this.photos);
  }

  getImage(id) {
    return this.imageService.mediumLargeThumbnail(id);
  }

  afterChange(event: any) {
    this.index = event.currentSlide + 1;
  }

  close() {
    this._d.closeAll();
  }

  download(img) {
    const url = `${this.serverURL}/images/${img.image.id}/content`;
    const filename = img.image.file;

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(() => {
        console.error("Error");
      });
  }
}
