<section class="analytic-container">
  <div
    *ngIf="!isMobile; else mobile"
    class=" border-card-fii mat-elevation-z15"
  >
    <div class="fii-info">
      <h1
        class="title-gnrl"
        translate="fii-data.{{ currentCode }}.fii-data.title"
      ></h1>
      <h2
        class="fii-sub-title"
        translate="fii-data.{{ currentCode }}.fii-data.sub-title"
      ></h2>
      <p
        class="fii-description"
        translate="fii-data.{{ currentCode }}.fii-data.description"
      ></p>
      <button
        (click)="openContactDialog()"
        mat-flat-button
        color="accent"
        class="btn-subs uppercase"
      >
        {{ "subs" | translate }}
      </button>
    </div>
    <div class="fii-media">
      <img [alt]="imgFiiAlt" [title]="imgFiiAlt" [src]="imgFii()" />
    </div>
  </div>
  <ng-template #mobile>
    <div class="fii-info border-card-fii mat-elevation-z15">
      <h1
        class="title-gnrl"
        translate="fii-data.{{ currentCode }}.fii-data.title"
      ></h1>
      <h2
        class="fii-sub-title"
        translate="fii-data.{{ currentCode }}.fii-data.sub-title"
      ></h2>
      <div class="fii-media">
        <img
          class="main-img"
          [alt]="imgFiiAlt"
          [title]="imgFiiAlt"
          [src]="imgFii()"
        />
      </div>
      <p
        class="fii-description"
        translate="fii-data.{{ currentCode }}.fii-data.description"
      ></p>
      <button
        (click)="openContactDialog()"
        mat-flat-button
        color="accent"
        class="btn-subs uppercase"
      >
        {{ "subs" | translate }}
      </button>
    </div>
  </ng-template>
  <div class=" border-card-white mat-elevation-z15">
    <div class="highlighs-info">
      <h1
        class="title-gnrl siila-blue-color uppercase"
        translate="fii-data.{{ currentCode }}.fii-data.highlighs.title"
      ></h1>
      <h2
        class="fii-second-title siila-menu-color"
        translate="fii-data.{{ currentCode }}.fii-data.highlighs.sub-title"
      ></h2>
      <img
        class="high-img"
        [alt]="laptops"
        [title]="laptops"
        [src]="imgFiiProducts()"
      />
    </div>
    <highlighs-card [currentCode]="currentCode"></highlighs-card>
  </div>
  <div
    style="margin-top: 30px;"
    *ngIf="!isMobile; else segment"
    class="border-card-segment mat-elevation-z15"
  >
    <div class="fii-segment">
      <h1
        class="title-gnrl uppercase"
        translate="fii-data.{{ currentCode }}.fii-data.segment.title"
      ></h1>
    </div>
    <div class="market-segment">
      <div class="card-segment">
        <img
          [alt]="iconsInvestor"
          [title]="iconsInvestor"
          src="../../../../../assets/img/fii-data-pro/investor.svg"
        />
        <h3 class="segment-info">
          {{ fiiDataPath + ".investors" | translate }}
          <div class="orange-border-fii"></div>
        </h3>
      </div>
      <div class="card-segment">
        <img
          [alt]="iconsadvisor"
          [title]="iconsadvisor"
          src="../../../../../assets/img/fii-data-pro/advisor.svg"
        />
        <h3 class="segment-info">
          {{ fiiDataPath + ".advisors" | translate }}
          <div class="orange-border-fii"></div>
        </h3>
      </div>
      <div class="card-segment">
        <img
          [alt]="iconsmarket"
          [title]="iconsmarket"
          src="../../../../../assets/img/fii-data-pro/banker.svg"
        />
        <h3 class="segment-info">
          {{ fiiDataPath + ".analysts" | translate }}
          <div class="orange-border-fii"></div>
        </h3>
      </div>
      <div class="card-segment">
        <img
          [alt]="iconsfinancial"
          [title]="iconsfinancial"
          src="../../../../../assets/img/fii-data-pro/analysts.svg"
        />
        <h3 class="segment-info">
          {{ fiiDataPath + ".bankers" | translate }}
          <div class="orange-border-fii"></div>
        </h3>
      </div>
    </div>
  </div>
  <div>
    <industry-news
      [tagId]="tagId"
      [isMarket]="true"
      id="news"
      #news
      class="industry-container mat-elevation-z15"
    ></industry-news>
  </div>
  <ng-template #segment>
    <div class="border-card-segment mat-elevation-z15">
      <div class="fii-segment">
        <h1
          class="title-gnrl uppercase"
          translate="fii-data.{{ currentCode }}.fii-data.segment.title"
        ></h1>
      </div>
      <div class="market-segment">
        <div class="card-segment">
          <img
            [alt]="iconsInvestor"
            [title]="iconsInvestor"
            src="../../../../../assets/img/fii-data-pro/investor.svg"
          />
          <h3 class="segment-info">
            {{ fiiDataPath + ".investors" | translate }}
            <div class="orange-border-fii"></div>
          </h3>
        </div>
        <div class="card-segment">
          <img
            [alt]="iconsadvisor"
            [title]="iconsadvisor"
            src="../../../../../assets/img/fii-data-pro/advisor.svg"
          />
          <h3 class="segment-info">
            {{ fiiDataPath + ".advisors" | translate }}
            <div class="orange-border-fii"></div>
          </h3>
        </div>
        <div class="card-segment">
          <img
            [alt]="market"
            [title]="market"
            src="../../../../../assets/img/fii-data-pro/analysts.svg"
          />
          <h3 class="segment-info">
            {{ fiiDataPath + ".analysts" | translate }}
            <div class="orange-border-fii"></div>
          </h3>
        </div>
        <div class="card-segment">
          <img
            [alt]="financial"
            [title]="financial"
            src="../../../../../assets/img/fii-data-pro/banker.svg"
          />
          <h3 class="segment-info">
            {{ fiiDataPath + ".bankers" | translate }}
            <div class="orange-border-fii"></div>
          </h3>
        </div>
      </div>
    </div>
  </ng-template>
  <!--  <app-news-insights></app-news-insights> -->
  <div class=" border-card-white mat-elevation-z15">
    <div>
      <div class="glossary-info">
        <h1
          class="title-gnrl siila-blue-color uppercase"
          translate="fii-data.{{ currentCode }}.fii-data.glossary-title"
        ></h1>
        <h2
          class="fii-sub-title siila-menu-color "
          translate="fii-data.{{ currentCode }}.fii-data.glossary-sub-title"
        ></h2>
      </div>
      <div class="glossary-card">
        <div class="glossary-img">
          <img
            class="fundamental-img"
            [alt]="colleagues"
            [title]="colleagues"
            src="../../../../../assets/img/fii-data-pro/glossary.png"
          />
        </div>
        <div class="glossary">
          <p
            class="glossary-description siila-grey"
            translate="fii-data.{{ currentCode }}.fii-data.glossary-description"
          ></p>

          <button
            (click)="openMarket()"
            mat-flat-button
            color="accent"
            class="btn-more"
          >
            {{ "more" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-methodology></app-methodology>
  <div class=" border-card-white mat-elevation-z15">
    <div class="fii-main-title">
      <h1 class="demo-title siila-menu-color" translate="request"></h1>
    </div>
    <div class="demo-container">
      <div class="demo-img">
        <img
          class="fii-img"
          [alt]="demoAlt"
          [title]="demoAlt"
          [src]="imgFiiDemo()"
        />
      </div>
      <div class="demo-request">
        <form [formGroup]="form">
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "fullName" | translate
            }}</mat-label>
            <input matInput formControlName="fullName" />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "address" | translate }}</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{ "company" | translate }}</mat-label>
            <input matInput formControlName="company" />
          </mat-form-field>
          <mat-form-field class="info-size">
            <mat-label class="name-text">{{
              "global.newsForm.description" | translate
            }}</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
          <div class="demo-btn">
            <button
              (click)="submitDemo()"
              [disabled]="form.invalid"
              mat-flat-button
              color="accent"
              class="btn-demo-request"
            >
              {{ "get-demo" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- <app-featured-resources></app-featured-resources> -->
  <app-exclusive-access></app-exclusive-access>
  <app-btn-up></app-btn-up>
</section>
