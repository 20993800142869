<div class="home-card-white mat-elevation-z15">
  <section-header
    title="newsHeader"
    (click)="goHome()"
    [pageNumber]="slideProgress"
    class="header-title"
  ></section-header>
  <div class="btn-latam" *ngIf="currentCode == 'latam' || currentCode == 'co'">
    <button
      class="form-control"
      [ngClass]="latamCountry == 'latamMx' ? 'btn-active' : 'btn-no-active'"
      (click)="changeCountry('latamMx')"
    >
      {{ "navLinks.siilaMexico" | translate }}
    </button>
    <button
      class="form-control"
      [ngClass]="latamCountry == 'latamBr' ? 'btn-active' : 'btn-no-active'"
      (click)="changeCountry('latamBr')"
    >
      {{ "navLinks.siilaBrasil" | translate }}
    </button>
    <button *ngIf="currentCode == 'co'" class="form-control btn-no-active">
      {{ "navLinks.siilaCol" | translate }}
      <p class="cooming-co">
        <img
          class="cooming-img"
          src="../../../../assets/icons/skyrocket icon - SiiLA blue color23.png"
          alt=""
        />
      </p>
    </button>
    <app-loading-spinner
      *ngIf="!showNews"
      style="position: absolute;  margin: 78px 34px 36px 146px;"
    ></app-loading-spinner>
  </div>
  <div
    class="carousel-container"
    [ngStyle]="{ height: !showNews ? '198px' : 'auto' }"
    style="width:100%;position:relative;"
  >
    <ngx-slick-carousel
      [ngStyle]="{ position: !showNews ? 'fixed' : 'initial' }"
      id="slick-news"
      #slickCarousel
      [config]="slideConfig"
      class="app-gradient carousel slick-carousel-tile carousel-dimensions"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)"
    >
      <div
        style="display:flex;justify-content:center;"
        ngxSlickItem
        *ngFor="let article of articles2?.content"
        class="slide"
      >
        <news-card
          *ngIf="showNews"
          [totalItemCount]="totalItemCount"
          [currentSlideIndex]="slickCarousel.currentIndex"
          [loadedItems]="null"
          [currentPage]="currentPage"
          [latamCountry]="latamCountry"
          [article]="article"
          [article2]="article"
        ></news-card>
      </div>
    </ngx-slick-carousel>
    <button
      mat-basic-button
      #videoPrev
      aria-label="left arrow"
      class="news-leftRs btnCard"
      *ngIf="slickCarousel.currentIndex"
      (click)="loadPage('back', slickCarousel)"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-basic-button
      #videoNext
      aria-label="right arrow"
      class="news-rightRs btnCard"
      [disabled]="disableNext"
      *ngIf="!lastSlideActiveCehck"
      (click)="loadPage('next', slickCarousel)"
    >
      <mat-icon *ngIf="!disableNext">chevron_right</mat-icon>
      <mat-icon *ngIf="disableNext">hourglass_full</mat-icon>
    </button>
  </div>
  <div
    style="    margin: 0 auto;
  margin-bottom: 14px;"
  >
    <button
      mat-flat-button
      color="accent"
      (click)="goHome()"
      class="button-main"
    >
      {{ "news.readMore" | translate }}
    </button>
  </div>
</div>
