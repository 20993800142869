import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent implements OnInit {
  wrongRoute: string;
  constructor(private location: Location) {}

  ngOnInit(): void {
    document.head.innerHTML +=
      '<meta name="prerender-status-code" content="404">';
    if (window.history.state && window.history.state.badURL) {
      this.wrongRoute = window.history.state.badURL;
    } else {
      this.wrongRoute = window.location.href;
    }

    this.location.go("/404");
  }
}
